<template>
    <article class="content-blocks">
        <div class="text-box text-box-text" v-if="agreedInfo.agreed">
            <b-icon icon="check-circle-fill" color="#009bfa" class="mr--8"></b-icon>
            <p class="text-box__text"><strong>{{ username }}</strong>님은 <strong>{{ agreedInfo.agreedDtm }}</strong>에 대회 규정에
                동의하였습니다.</p>
        </div>
        <div class="content-group">
            <template v-for="(r, idx) in rules">
                <div class="form-group" :key="idx">
                    <div class="form-group__title">{{ r.title }}</div>
                    <!-- <div class="rule__content" v-html="r.content"></div> -->
                    <div class="common-form">
                        <div ref="preview" class="ProseMirror">
                            <div v-html="r.content"></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </article>
</template>

<script>
import {
    computed,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    reactive,
    toRefs,
    nextTick,
    watch,
    ref,
} from "@vue/composition-api";
import { BIcon } from 'bootstrap-vue'
import LeagueApi from '@/lib/api/League'
import modalControler from "@/helper/modalControler";

export default {
    name: 'LeagueRule',
    components: {
        BIcon,
    },
    props: [
        'rules',
        'agreedInfo',
        'username',
    ],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            leagueId: route.params.leagueId,

        })

        onMounted(() => {

        })


        return {
            ...toRefs(state),
        }
    }
}

</script>