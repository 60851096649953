<template>
    <div>
        <GNBCompetition />
        <router-view :key="$route.fullPath"></router-view>
        <Footer />
    </div>
</template>

<script>
import GNBCompetition from '@/components/gnb/GNBCompetition';
import Footer from '@/components/global/Footer';

export default {
    name: 'LeagueRoot',
    components: {
        GNBCompetition,
        Footer,
    }
}
</script>