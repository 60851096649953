import httpClient from './httpClient';

/**
 *  
 *  COMPETITION STATUS
 * 
 *  1 : CREATED
 *  2 : TESTING
 *  3 : APPLYTING
 *  4 : RUNNING
 *  5 : HOLDING
 *  6 : FINALIZED
 * 
 *  isopen
 * 
 *  0 : GPU 제공형
 *  1 : 개방형
 * 
 *  isjoin
 * 
 *  0 : 대회미참여 상태 
 *  1 : 대회참여상태 
 *  2 : 팀 승인완료 
 *  3 : 해당 과제 참여상태
 * 
 */

export default {

    // 대회 리스트
    getCompetitionList(param) {
        return httpClient.get(`/competition/getCompetitionList/limit${param}`)
    },

    // 내가 참여중인 경진대회 리스트
    getMyCompetitionList() {
        return httpClient.get('/competition/getMyCompetitionList')
    },

    // 대회 상세정보
    getCompetitionDetailInfo(param) {
        return httpClient.get(`/competition/getCompetitionDetailInfo?competitionId=${param}`)
    },

    // 대회 참가신청서
    getCompetitionRegistForms(id) {
        return httpClient.get(`/competition/file/application-form?competitionId=${id}`)
    },

    // 대회 서약서
    getAgreement(id) {
        return httpClient.get(`/competition/agreement?competitionId=${id}`)
    },

    // 대회 참가하기
    joinCompetition(id, param) {
        return httpClient.post(`/competition/joinCompetition?competitionId=${id}`, param,  {headers: {'Content-Type': 'multipart/form-data'}});
    },
    
    // 특정 대회 리더보드
    getCompetitionLeaderboard(id) {
        return httpClient.get(`/competition/${id}/leaderboards?rankSize=9999`)
    },

    /**
     *  게시판 관련
     * 
     */

    // 특정 대회 공지사항
    getCompetitionNotice(competitionId) {
        // return httpClient.get(`competition/${param}/getCompetitionQnaList${page}`)
        return httpClient.get(`/community/competition/${competitionId}/notice`)
    },
    // (과제 상세 페이지) QnA 글 불러오기
    getCompetitionQnaDetail(param) {
        return httpClient.get(`/competition/getCompetitionQnADetail${param}`)
    },    
    // QnA 작성
    writeQnA(param) {
        return httpClient.post(`/competition/writeQnA`, param, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    // QnA 수정
    updateQnA(param) {
        return httpClient.put('/competition/updateQnA', param, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    // QnA 삭제
    deleteQnA(param) {
        return httpClient.put('/competition/deleteQnA', param);
    },
    // 댓글 작성
    writeQnAReply(param) {
        return httpClient.post('/competition/writeQnAReply', param)
    },
    // 댓글 수정
    updateQnAReply(param) {
        return httpClient.put('/competition/updateQnAReply', param)
    },
    // 댓글 삭제
    deleteQnAReply(param) {
        return httpClient.put('/competition/deleteQnAReply', param)
    },


    /**
     *  팀 관련
     * 
     */

    getMyTeam(param) {
        return httpClient.get(`/competition/getCompetitionMyTeam${param}`)
    },
    searchTeam(param) {
        return httpClient.get(`/competition/searchUser${param}`)
    },    
    requestJoinTeam(param) {
        return httpClient.post('/competition/requestJoinTeam', param);
    },
    getJoinTeamApprovalList(param) {
        return httpClient.get(`/competition/getJoinTeamApprovalList${param}`)
    },
    approvalJoinTeam(param) {
        return httpClient.put('competition/approvalJoinTeam', param)
    },
    changeTeamName(param) {
        return httpClient.put('/competition/changeTeamName', param)
    },    
    kickTeamMember(param) {
        return httpClient.put(`/competition/kickTeamMember`, param);
    },
    switchTeamLeader(param) {
        return httpClient.patch(`/competition/changeTeamLeader`, param);
    },


    /**
     * 
     * 
     *  팀 빌딩 게시판
     * 
     */

    getTeamBuildList(competitionId, params) {
        return httpClient.get(`/competition/${competitionId}/team-boards${params}`)
    },
    getTeamBuildDetail(competitionId, qnaId, params) {
        return httpClient.get(`/competition/${competitionId}/team-boards/${qnaId}${params}`)
    },
    writeTeamBuild(competitionId, params) {
        return httpClient.post(`/competition/${competitionId}/team-boards`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    updateTeamBuild(competitionId, qnaId, params) {
        return httpClient.put(`/competition/${competitionId}/team-boards/${qnaId}`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    deleteTeamBuild(competitionId, qnaId) {
        return httpClient.delete(`/competition/${competitionId}/team-boards/${qnaId}`)
    },
    writeTeamBuildReply(competitionId, qnaId, params) {
        return httpClient.post(`/competition/${competitionId}/team-boards/${qnaId}/reply`, params)
    },
    updateTeamBuildReply(competitionId, qnaId, replyId, params) {
        return httpClient.put(`/competition/${competitionId}/team-boards/${qnaId}/reply/${replyId}`, params)
    },
    deleteTeamBuildReply(competitionId, qnaId, replyId) {
        return httpClient.delete(`/competition/${competitionId}/team-boards/${qnaId}/reply/${replyId}`)
    },

    /*
        연습제출
    */
    // 연습제춢 동의서 
    agreePracticeSubmit(competitionId) {
        return httpClient.put(`/competition/${competitionId}/userAgreementRule`)
    },
    

}