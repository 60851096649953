<template>
    <div></div>
</template>

<script>
import utils from '@/helper/utils';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'Oauth',
    data() {
        return {
            CLIENT_ID: 'HsLwKC8lLmc6NhgllukJ',
        }
    },
    created() {
        if (this.$route.query) {
            // 비밀번호 재설정
            if (this.$route.query.resetPassword) {
                resetPassword(encodeURIComponent(this.$route.query.resetPassword))
            }

            // 계정 활성화
            if (this.$route.query.signin_verify) {
                this.activation()
            }

            // Socail auth error cases
            if (this.$route.query.error && this.$route.query.error === 'already_linked_email') {
                window.alreadyLinked(this.$route.query.msg);
            } else if (this.$route.query.error && this.$route.query.error === 'no_linked_email') {
                window.signup();
            } else if (this.$route.query.error && this.$route.query.error === 'already_signed_up_email') {
                window.alreadySignedUp();
            } else if (this.$route.query.error && this.$route.query.error === 'disagree_profile') {
                window.disagreeProfile();
                // window.close();
            } else if (this.$route.query.error && this.$route.query.error === 'deleted_email_cannot_signup') {
                window.cannotSignUp();
            }

            // provider 에 따른 처리
            if (this.$route.query.provider && this.$route.query.provider === 'nice') {              // nice 본인인증
                window.opener.niceCertificate(this.$route.query.token)
                window.close();

            // naver
            } else if (this.$route.query.provider && this.$route.query.provider === 'naver') {      // naver 
                const action = this.$route.query.action
                if (action === 'link') {
                    // TODO : store 방식 변경해야함
                    if (store.getters['auth/authToken'] === null) {
                        window.naverLink(this.$route.query.token);
                    } else {
                        window.naverAuthLink(this.$route.query.token);
                    }
                } else if (action === 'signin') {
                window.socialSignin(this.$route.query.token);
                } else if (action === 'signup') {
                window.socialSignup(this.$route.query.token);
                }                

            // google
            } else if (this.$route.query.provider && this.$route.query.provider === 'google') {     // google
                const action = this.$route.query.action
                if (action === 'link') {
                    if (store.getters['auth/authToken'] === null) {
                        window.googleLink(this.$route.query.token);
                    } else {
                        window.googleAuthLink(this.$route.query.token);
                    }
                } else if (action === 'signin') {
                window.socialSignin(this.$route.query.token);
                } else if (action === 'signup') {
                window.socialSignup(this.$route.query.token);
                }                
            }

        }

    },

    methods: {
        activation() {
            const param = encodeURIComponent(this.$route.query.signin_verify)
            if (param) {
                Authenticate.signInActivation(param).then(response => {
                    
                    const msg = "사전접수가 완료되었습니다! 본 접수가 시작되면 가입된 이메일로 안내드리겠습니다."
                    // confirm(msg)
                    window.alert(msg)
                    // utils.sendMessageNative(msg)
                    this.$router.push({ path: '/' })

                    // modalControler.ShowModalDialog('사전접수 완료', msg, '', '확인', (val) => {
                        // if (val) {
                            // this.$router.push({ path: '/' })
                            // window.close()
                        // }
                    // })
                }).catch(err => {
                    const msg = '이미 접수 완료된 계정입니다.'
                    window.alert(msg)
                    // utils.sendMessageNative(msg)

                    this.$router.push({ path: '/' })
                    // modalControler.ShowModalDialog('사전접수 완료', '이미 접수 완료된 계정입니다.', '', '확인', (val) => {
                    //     if (val) {
                    //         this.$router.push({ path: '/' })
                    //     }
                    // })
                    // window.close()
                });
            }
        },
        resetPassword(param) {
            if (param) {
                modalControler.ShowLoading()
                Authenticate.resetPassword(param).then(response => {
                    // TODO : 비밀번호 초기화 완료 alert
                    modalControler.ShowSuccessToast('비밀번호 초기화 완료')
                }).catch(err => {
                    // TODO: 오류 alert
                    modalControler.ShowErrorToast('서버오류')
                }).finally(() => modalControler.HideLoading());
            }
        },        
    },
}
</script>