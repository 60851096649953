<template>
    <section class="content-section" id="section-4">
        <div class="content-inner" v-if="ruleList.length">
            <header class="content-section__header">
                <h2 class="content-section__title">규정</h2>
            </header>
            <div class="content-group" v-if="props.ruleDesc">
                <div class="ProseMirror" v-html="props.ruleDesc"></div>
            </div>
            <div class="common-accordion accordion" id="accordionExample2">
                <template v-for="(r, idx) in ruleList">
                    <div class="common-accordion__item accordion-item" :key="idx" v-if="r.title && r.content">
                        <header class="common-accordion__header accordion-header" :id="'heading-' + idx + '-rule'">
                            <button class="common-accordion__button accordion-button  collapsed" type="button" :key="idx"
                                data-bs-toggle="collapse" :data-bs-target="'#collapse-' + idx + '-rule'"
                                aria-expanded="true" :aria-controls="'collapse-' + idx + '-rule'">
                                {{ r.title }}
                            </button>
                        </header>
                        <div class="accordion-collapse collapse" :id="'collapse-' + idx + '-rule'"
                            :aria-labelledby="'heading-' + idx + '-rule'" data-bs-parent="#accordionExample2"
                            v-if="r.content">
                            <template>
                                <div class="common-accordion__body accordion-body">
                                    <div class="common-accordion__content ProseMirror" v-html="r.content"></div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
            <!-- <template v-for="(r, idx) in ruleList">
                <div class="rule" :key="idx">
                    <h3 class="rule__title">{{ r.title }}</h3>
                    <div class="rule__content" v-html="r.content"></div>
                </div>
            </template> -->

        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';


export default {
    name: 'Rule',
    props: ['competitionId', 'ruleDesc'],
    setup(props, context) {

        const state = reactive({
            ruleList: [],
        });

        onMounted(() => {
            getCompetitionRule()
        });

        const getCompetitionRule = () => {
            CompetitionApi.getAgreement(props.competitionId).then(response => {
                if (response.data.http_status_code === 200) {
                    state.ruleList = response.data.result.filter(v => v.expose === 1)
                }
            }).catch(err => {
                // TODO : error alert
                modalControler.ShowErrorToast('대회규정을 불러올 수 없습니다.')
            })
        }

        return {
            ...toRefs(state),
            props,
        }
    }
}
</script>

<style scoped lang="scss"></style>