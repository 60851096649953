<template>
  <v-app>
    <div class="main-wrap bg-contents-wrap">
      <header class="page-header__section pattern-section">
        <div class="page-header__inner">
          <div class="page-header__title-box">
            <h2 class="page-header__title">Task</h2>
            <p class="page-header__sub-text">역대 AI CONNECT에서 제출한 경진대회 과제를 확인해보세요.
            </p>
          </div>
          <!-- <img src="../../assets/images/img-header-task.png" class="bg-img" /> -->
        </div>
      </header>
      <!--모든-->
      <section class="task-list-section content-section">
        <div class="content-inner">
          <div class="swiper-slide-group">
            <h3 class="swiper-slide-group__title">키워드로 찾아보세요.</h3>
            <section class="swiper__chip hover-btn swiper-unset">
              <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
                <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                  icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
              </button>
              <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                  icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
              </button>
              <div v-swiper:slideSwiper="swiperOption">

                <div class="swiper-wrapper">
                  <swiper-slide v-for="(k, i) in keywords" :key="'keyword-' + i">
                    <button 
                      type="button" 
                      :class="{'on': selectedIndex.includes(i) }"
                      class="btn-keyword-toggle" @click="filterKeywords(k, i)" :name="k" :id="'key-' + i" :value="k">
                      {{ k }}
                    </button>
                  </swiper-slide>
                </div>
              </div>
            </section>
          </div>
          <div class="option-control__group option-control__right">
            <v-checkbox class="common-checkbox" label="연습 제출 과제만 보기" hide-details color="#009bfa"
              v-model="showOnlyPractice" @change="chkPracticeShwoing">
            </v-checkbox>
            <span class="tooltip__group">
              <button type="button" class="btn-help" @click="changeToolTipStatus">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#a9abae"
                  icon-name=""><icon-info /></icon-base>
              </button>
              <span :class="{ 'on': showToolTip }" class="tooltip__box tooltip__box--right">
                <span class="tooltip__inner">종료된 대회의 과제를 풀어보고 점수를 확인해보세요.</span>
                <button @click="hideToolTip" type="button" class="btn-closed">
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                    icon-name="icon-closed"><icon-closed /></icon-base>
                </button>
              </span>
            </span>

          </div>
          <div class="card-list card-list--3">
            <div class="card-box" v-for="(c, idx) in showingList" :key="idx">
              <div class="card-box__content">
                <a @click="$router.goTaskPages('TaskInfo', c.assignmentId, c.competitionId)" class="card-box__link">
                  <h3 class="card-box__title ellipsis-1">{{ c.assignmentName }}</h3>
                  <p class="card-box__text" v-if="c.desc">{{ c.desc }}</p>
                  <div class="card-box__tags">
                    <span class="tag" v-for="(k, ki) in c.keywords" :key="'keyword-' + ki">{{ k.name }}</span>
                  </div>
                  <div class="card-box__option">
                    <div class="card-box__sub-text">{{ c.competitionName }}</div>
                  </div>
                  <div class="card-box__option">
                    <div class="prize" v-if="(c.prizemoney >= 10000)">
                      <span class="icon icon__size--300 icon--prize">
                        <span class="blind">상금</span>
                      </span>
                      <div class="prize__text"><span class="money font-num-bold">{{
                        utils.numberToKorean(c.prizemoney)
                      }}</span> 만원</div>
                    </div>
                    <div class="prize" v-if="c.categoryCodeName === 'A003'"><span
                        class="icon icon__size--300 icon--target">
                        <span class="blind">목표</span>
                      </span>
                      <strong class="prize__text">연습문제</strong>
                    </div>

                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </v-app>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
} from "@vue/composition-api";
import moment from "moment";
import utils from "@/helper/utils";
import { tokenStore } from "@/stores/auth";
import { stateStore } from "@/stores/state";

import TaskApi from '@/lib/api/Task'
import CompetitionApi from "@/lib/api/Competition";

import modalControler from "@/helper/modalControler";
import Carousels from "@/components/competition/Carousels";

import 'swiper/css/swiper.css'



export default {
  name: "CompetitionTaskList",
  components: {
    // SwiperSlide,
  },
  setup(props, context) {
    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const swiper = ref(null);
    const state = reactive({
      competitionId: Number(route.params.competitionId),
      categories: [],
      assignmentList: [],
      showingList: [],
      doing: false,
      showingStatus: [2, 3, 4, 5], // OPEN : 2, APPLYING : 3, RUNNING : 4 , HOLDING: 5
      competition: 1,
      study: 2,
      recruitment: 3,
      options: {
        page: 1,
        itemsPerPage: 0,
        categoryId: 0,
      },
      swiperOption: {
        loop: false,
        slidesPerView: 'auto',
        focusableElements: 'input',
        // freeMode: true,
        // slidesPerView: 8,
        // spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-btn__middle__right',
          prevEl: '.swiper-btn__middle__left'
        },
      },
      interval: null,
      showOnlyPractice: false,
      showToolTip: false,
      keywords: [],
      search: '',
      selectedIndex: [],
      selectedKeywords: [],
    });

    onMounted(async () => {
      await getAssignmentList()

    });

    const getAssignmentList = () => {
      let params = {
        status: 6,
        sortBy: 'createdtm',
        sortDesc: 'desc',
        pageSize: 1000
      }
      TaskApi.getPracticeAssignmentList(utils.makeParams(params))
        .then(response => {
          const data = response.data.result
          state.assignmentList = data.data
          state.showingList = state.assignmentList
          state.keywords = data.keywords
        })
        .catch(err => {

        })
    }

    const chkPracticeShwoing = (value) => {
      if (value) {
        state.showingList = state.assignmentList.filter(t => t.categoryCodeName === 'A003')
      } else {
        state.showingList = state.assignmentList
      }
    }

    // const showTooltip = () => {
    //   state.showToolTip = true
    // }

    const hideToolTip = () => {
      state.showToolTip = false
    }

    const changeToolTipStatus = () => {
      state.showToolTip = (state.showToolTip) ? false : true
    }


    const filterKeywords = (k, i) => {
      if (!state.selectedIndex.includes(i)) {
        state.selectedIndex.push(i)
        state.selectedKeywords.push(k)
        if (k) {
          state.showingList = state.assignmentList.filter((t, i) => {
            for (var i = 0; i < t.keywords.length; i++) {
              if (state.selectedKeywords.includes(t.keywords[i].name)) {
                return t
              }
            }

          })
        } else {
          state.showingList = state.assignmentList
        }
      } else {
        let idx = state.selectedIndex.indexOf(i)
        state.selectedIndex.splice(idx, 1)
        let idx2 = state.selectedKeywords.indexOf(k)
        state.selectedKeywords.splice(idx2, 1)

        if (!state.selectedKeywords.length) {
          state.showingList = state.assignmentList
        }
      }
    }

    return {
      ...toRefs(state),
      utils,
      moment,
      chkPracticeShwoing,
      // showTooltip,
      hideToolTip,
      changeToolTipStatus,
      filterKeywords,
    };
  },
};
</script>