<template>
    <article class="section-2__content">
        <BoardDetailView detailType="TaskCodeShare" @updateQnA="updateQnA" @deleteQnA="deleteQnA"
            @writeReply="writeQnaReply" @updateReply="updateQnaReply" @deleteReply="deleteQnaReply"
            @fetchMoreReply="fetchMoreReply" :replyList="replyList" :qnaData="qnaData" />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';
import BoardDetailView from '@/components/BoardDetailView'

export default {
    name: 'TaskCodeShareDetail',
    components: {
        BoardDetailView
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            // 댓글 더보기
            options: {
                page: 1,
                itemsPerPage: 0
            },
            qnaData: {},
            replyList: [],
        });

        onMounted(() => {
            let params = {
                Info: route.params.qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage
            }
            getCodeshareDetail(params)
        });

        const getCodeshareDetail = (params) => {
            modalControler.ShowLoading()
            TaskApi.getCodeshareDetail(utils.getPage(params)).then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    state.qnaData.files.forEach(v => {
                        getBlobFromUrl(v.url).then(async file => {
                            if (file) {
                                document.querySelector('#ipynb2html').parentNode.style.display = 'block'
                                let text = await (new Response(file)).text()
                                document.querySelector('#ipynb2html').appendChild(ipynb2html.render(JSON.parse(text)))
                            }
                        })
                    })

                    // state.replyList = response.data.result.replyList
                    state.replyList = []
                    response.data.result.replyList.forEach(el => {
                        state.replyList.push(el)
                    })
                } else {
                    modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                    router.goTaskPages('TaskCodeShare', route.params.taskId, route.params.competitionId)
                }
            }).catch(err => {
                modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = () => {
            router.push({
                name: 'TaskCodeShareEdit',
                params: { qnaId: route.params.qnaId },
                query: { type: 'update' }
            })
        }

        const deleteQnA = () => {
            let params = {
                assignmentId: route.params.taskId,
                codeShareId: route.params.qnaId
            }
            modalControler.ShowLoading()
            TaskApi.deleteCodeshare(params).then(response => {
                router.goTaskPages('TaskCodeShare', route.params.taskId, route.params.competitionId)
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnaReply = (content) => {
            let params = {
                assignmentId: route.params.taskId,
                codeshareId: route.params.qnaId,
                content: content
            }
            TaskApi.writeCodeshareReply(params).then(response => {
                let params2 = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getCodeshareDetail(params2)
            }).catch(err => {

            })
        }

        const updateQnaReply = (replyId, content) => {
            let params = {
                assignmentId: route.params.taskId,
                codeshareId: route.params.qnaId,
                codeshareReplyId: replyId,
                content: content
            }
            TaskApi.updateCodeshareReply(params).then(response => {
                let params2 = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getCodeshareDetail(params2)

            }).catch(err => {

            })
        }

        const deleteQnaReply = (replyId) => {
            let params = {
                assignmentId: route.params.taskId,
                codeshareId: route.params.qnaId,
                codeshareReplyId: replyId,
            }
            TaskApi.deleteCodeshareReply(params).then(response => {
                let params2 = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getCodeshareDetail(params2)
            }).catch(err => {

            })
        }

        const fetchMoreReply = () => {
            if (state.qnaData.replycount > (state.options.page * state.options.itemsPerPage)) {
                ++state.options.page
                let params = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                // getCompetitionQnaDetail(params)
                getCodeshareDetail(params)
            }
        }

        const getBlobFromUrl = async (url) => {
            const fileName = new URL(url).pathname.replace(/^.*[\\\\/]/, '');
            if (fileName.split('.').pop() === 'ipynb') {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                });
                const data = await response.blob();
                return new File([data], fileName);
            }
            return null;
        }

        return {
            ...toRefs(state),
            getCodeshareDetail,
            updateQnA,
            deleteQnA,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            fetchMoreReply,
        }


    }
}
</script>

<style scoped lang="scss">
.data_file_download {
    display: flex;
    padding: 2rem 0;
}
</style>