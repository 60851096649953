<template>
    <div class="news-wrap contents-wrap">
        <section class="news-view-section">
            <header class="page-header--line__section">
                <h2 class="page-header--line__title">{{ news.subject }}</h2>
            </header>
            <div class="medium-content-inner">
                <div class="ProseMirror" v-html="news.content"></div>
                <div class="btn-inline btn-inline--section btn-inline--center">
                    <v-btn class="btn btn-secondary btn-large" @click="onClickList"><strong>목록</strong></v-btn>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import NewsApi from "@/lib/api/News";
import modalControler from '@/helper/modalControler';
import sanitizeHtml from 'sanitize-html';

export default {
    name: 'CompetitionNewsDetail',
    components: {

    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            news: {},
            options: {
                allowedAttributes: {
                    // a: ['style'],
                }
            }
        });

        onMounted(() => {
            getNewsDetail(route.params.newsId)
        })

        const getNewsDetail = (newsId) => {
            let id = {
                id: newsId
            }
            modalControler.ShowLoading()
            NewsApi.getNewsDetail(utils.makeParams(id)).then(response => {
                state.news = response.data.result
            }).catch(err => {
                modalControler.ShowErrorToast('뉴스를 불러 올 수 없습니다.')
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const onClickList = () => {
            router.push({ name: 'CompetitionNews' })
        }

        return {
            ...toRefs(state),
            onClickList,
        }

    }

}
</script>

<style scoped lang="scss">
.app-g-page-header {
    height: 80px;
    background-color: inherit;
}

.tab-title {
    font-size: var(--font-22);
    color: var(--gray0);
    margin-bottom: 13px;
}

.card {
    padding: 40px;
    border-radius: 4px;
    border: solid 1px var(--gray7);
    background-color: var(--white);
    box-shadow: 1px 1px 5px 0 rgba(28, 30, 34, 0.1);
    margin-top: 50px;
}
</style>