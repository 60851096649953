import httpClient from './httpClient';

export default {
    getNotificationCount() {
        return httpClient.get('/noti/getNotificationCount');
    },
    getNotification() {
        return httpClient.get('/noti/getNotification');
    },
    updateNotificationRead(param) {
        return httpClient.put('/noti/read', param);
    },
    updateNotificationDelete(param) {
        return httpClient.put('/noti/delete', param);
    },
}