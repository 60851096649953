// list pages
import LeagueRootPage from '@/pages/league/LeagueRoot'
import LeagueListPage from '@/pages/league/LeagueList'
import CodingTestPage from '@/pages/league/CodingTest'
import LeagueAgreementPage from '@/pages/league/LeagueAgreement'
// detail pages
import LeagueDetailRoot from '@/pages/league/LeagueDetailRoot'
import LeagueInfo from '@/pages/league/tab/LeagueInfo'
import LeagueData from '@/pages/league/tab/LeagueData'
import LeagueLeaderboard from '@/pages/league/tab/LeagueLeaderboard'
import LeagueSubmit from '@/pages/league/tab/LeagueSubmit'
import LeagueCommunity from '@/pages/league/tab/LeagueCommunity'
import LeagueRule from '@/pages/league/tab/LeagueRule'

import LeagueCommunityDetail from '@/pages/league/tab/LeagueCommunityDetail'
import LeagueCommunityEdit from '@/pages/league/tab/LeagueCommunityEdit'

const leagueTabRoutes = [
    {
        path: 'info',
        title: '리그 설명',
        name: 'LeagueInfo',
        components: {
            leagueTab: LeagueInfo
        },
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'data',
        title: '리그 데이터',
        name: 'LeagueData',
        components: {
            leagueTab: LeagueData   
        },
        meta: {
            anonymousCallable: true,
        }
    },    
    {
        path: 'leaderboard',
        title: '리그 리더보드',
        name: 'LeagueLeaderboard',
        components: {
            leagueTab: LeagueLeaderboard
        },
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'submit',
        title: '리그 결과제출',
        name: 'LeagueSubmit',
        components: {
            leagueTab: LeagueSubmit
        },
        meta: {
            anonymousCallable: true,
        }
    },    
    {
        path: 'community',
        title: '리그 커뮤니티',
        name: 'LeagueCommunity',
        components: {
            leagueTab: LeagueCommunity
        },
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'community/edit',
        title: '리그 커뮤니티',
        name: 'LeagueCommunityCreate',
        components: {
            leagueTab: LeagueCommunityEdit
        },
        meta: {
            anonymousCallable: false,
        }
    },
    {
        path: 'community/:postId',
        title: '리그 커뮤니티',
        name: 'LeagueCommunityDetail',
        components: {
            leagueTab: LeagueCommunityDetail
        },
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'community/:postId/edit',
        title: '리그 커뮤니티',
        name: 'LeagueCommunityEdit',
        components: {
            leagueTab: LeagueCommunityEdit
        },
        meta: {
            anonymousCallable: false,
        }
    },    
    {
        path: 'rule',
        title: '리그 규정 및 서약서',
        name: 'LeagueRule',
        components: {
            leagueTab: LeagueRule
        },
        meta: {
            anonymousCallable: true,
        }
    },

]

const aileagueRoutes = [
    {
        path: 'list',
        title: '리그리스트',
        name: LeagueListPage.name,
        component: LeagueListPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'coding-test',
        title: '코딩테스트',
        name: CodingTestPage.name,
        component: CodingTestPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: ':leagueId',
        title: '리그상세',
        name: 'LeagueDetailRoot',
        component: LeagueDetailRoot,
        children: leagueTabRoutes,
        redirect: {
            name: 'LeagueInfo',
        },
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: ':leagueId/agreement',
        title: '리그동의',
        name: 'LeagueAgreement',
        component: LeagueAgreementPage,
        meta: {
            anonymousCallable: false,
        }
    },
]

export default {
    path: '/league',
    title: 'AI LEAGUE',
    name: LeagueRootPage.name,
    component: LeagueRootPage,
    children: aileagueRoutes,
    redirect: {
        name: LeagueListPage.name
    },
    meta: {
        anonymousCallable: true,
    }
}