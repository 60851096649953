<template>
    <section class="task-info-section">
        <article class="content-blocks" v-if="props.leagueInfo.content !== ''">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">과제개요</h3>
            </header>
            <div ref="contentPreview" class="content-blocks__text ProseMirror">
                <div class="font-small" v-html="props.leagueInfo.content"></div>
            </div>
        </article>
        <article class="content-blocks" v-if="props.leagueInfo.qualification !== ''">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">제한사항</h3>
            </header>
            <div ref="qualificationPreview" class="content-blocks__text ProseMirror">
                <div class="font-small" v-html="props.leagueInfo.qualification"></div>
            </div>
        </article>
        <article class="content-blocks" v-if="props.leagueInfo.rating !== ''">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">평가지표</h3>
            </header>
            <div ref="ratingPreview" class="content-blocks__text ProseMirror">
                <div v-html="props.leagueInfo.rating"></div>
            </div>
        </article>
        <article 
            class="content-blocks"
            v-if="isWritten">
            <div class="no-data">
                <p class="no-data__text">작성된 리그 설명이 없습니다.</p>
            </div>
        </article>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch, nextTick
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';

export default {
    name: 'LeagueInfo',
    props: ['leagueInfo'],
    setup(props, context) {
        const route = context.root.$route
        const contentPreview = ref(null)
        const qualificationPreview = ref(null)
        const ratingPreview = ref(null)
        const state = reactive({
            taskId: route.params.taskId,
            taskInfo: '',
            content: '',
            qualification: '',
            rating: '',
            contents: [],
        });

        watch(() => props.leagueInfo.content, (nv, ov) => {
            contentKatexRender()
        })

        watch(() => props.leagueInfo.qualification, (nv, ov) => {
            qualificationKatexRender()
        })

        watch(() => props.leagueInfo.rating, (nv, ov) => {
            ratingKatexRender()
        })
        const isWritten = computed(() => {
            return (
                props.leagueInfo.content === '' && 
                props.leagueInfo.qualification === '' && 
                props.leagueInfo.rating === ''
            )
        })

        onMounted(async () => {
            nextTick(() => {
                try {
                    // state.content = props.leagueInfo.content
                    // state.qualification = props.leagueInfo.qualification
                    // state.rating = props.leagueInfo.rating
                    contentKatexRender()
                    qualificationKatexRender()
                    ratingKatexRender()

                } catch {

                }

            })
        });

        const contentKatexRender = () => {
            const previewDiv = contentPreview.value
            utils.katexRender(previewDiv)
        }

        const qualificationKatexRender = () => {
            const previewDiv = qualificationPreview.value
            utils.katexRender(previewDiv)
        }

        const ratingKatexRender = () => {
            const previewDiv = ratingPreview.value
            utils.katexRender(previewDiv)
        }

        return {
            props,
            utils,
            ...toRefs(state),
            contentPreview,
            qualificationPreview,
            ratingPreview,
            isWritten
        }
    }

}
</script>

<style scoped></style>