<template>
    <div class="activities-wrap contents-wrap">
        <div class="medium-content-inner">
            <header class="page-header--line__section">
                <h2 class="page-header--line__title">Activities</h2>
            </header>
            <div class="timeline-group">
                <ul class="timeline">
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                    <li class="timeline__item">
                        <div class="timeline__badge">
                            <span class="blind">포인트</span>
                        </div>
                        <div class="timeline__panel">
                            <div class="timeline__header">
                                <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                        세그멘테이션</strong>에 참여하였습니다.
                                </h4>
                            </div>
                            <div class="timeline__footer">
                                <p class="text-right">2023-03-02 21:00:15</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="content-group__btn-area">
                <div class="btn-inline btn-inline--center">
                    <v-btn class="btn btn-secondary btn-large">
                        <strong>더보기</strong>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'Activities',
}

</script>
