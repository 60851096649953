<template>
  <div>
    <template v-for="(item, index) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
      <menu-item v-else :key="index" v-bind="item" />
    </template>
  </div>
</template>

<script>
import MenuItem from './EditorMenuItem.vue'
export default {
  components: {
    MenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'code-view',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code'),
        },
        {
          icon: 'mark-pen-line',
          title: 'Highlight',
          action: () => this.editor.chain().focus().toggleHighlight().run(),
          isActive: () => this.editor.isActive('highlight'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'h-1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 }),
        },
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'list-check-2',
          title: 'Task List',
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive('taskList'),
        },
        {
          type: 'divider',
        },        
        {
          icon: 'code-box-line',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock'),
        },
        {
            icon: 'brackets-line',
            title: 'Math Inline',
            action: () => this.editor.chain().focus().toggleFormulaMark().run()
        },
        {
            icon: 'functions',
            title: 'Math Block',
            action: () => this.editor.chain().focus().addKatex().run()
        },        
        {
          type: 'divider',
        },
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () => this.editor.chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run(),
        },
        {
            icon: 'image-fill',
            title: 'Image',
            action: () => this.addImage()
        },
        {
            icon: 'link',
            title: 'Link',
            action: () => this.setLink(),
            isActive: () => this.editor.isActive('link')
        },
        {
            icon: 'link-unlink',
            title: 'Unlink',
            action: () => this.editor.chain().focus().unsetLink().run()
        },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
        {
          type: 'divider',
        },             
        {
            icon: 'table-2',
            title: 'Insert Table',
            action: () => this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
        },
        {
            icon: 'insert-column-left',
            title: 'Add Column Before',
            action: () => this.editor.chain().focus().addColumnBefore().run()
        },
        {
            icon: 'insert-column-right',
            title: 'Add Column After',
            action: () => this.editor.chain().focus().addColumnAfter().run()
        },
        {
            icon: 'delete-column',
            title: 'Delete Column',
            action: () => this.editor.chain().focus().deleteColumn().run()
        },
        {
            icon: 'insert-row-top',
            title: 'Add Row Before',
            action: () => this.editor.chain().focus().addRowBefore().run()
        },
        {
            icon: 'insert-row-bottom',
            title: 'Add Row After',
            action: () => this.editor.chain().focus().addRowAfter().run()
        },
        {
            icon: 'delete-row',
            title: 'Delete Row',
            action: () => this.editor.chain().focus().deleteRow().run()
        },
        {
            icon: 'merge-cells-horizontal',
            title: 'Merge Cells',
            action: () => this.editor.chain().focus().mergeCells().run()
        },
        {
            icon: 'split-cells-horizontal',
            title: 'Split Cell',
            action: () => this.editor.chain().focus().splitCell().run()
        },
        {
          type: 'divider',
        },     
        {
          icon: 'youtube-fill',
          title: 'Youtube',
          action: () => this.addVideo()
        },
      ],
    }
  },
  methods: {
    setLink() {
        const previousUrl = this.editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

            return
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: 'http://' + url.replace(/^.*:\/\//i, ''), target: '_blank' })
          .run()
    },
    addImage() {
        const url = window.prompt('URL')
        if (url) {
            this.editor.chain().focus().setImage({ src: url }).run()
        }
    },
    addVideo() {
      const url = window.prompt('Enter YouTube URL')

      this.editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(this.width, 10)) || 640,
        height: Math.max(180, parseInt(this.height, 10)) || 480,
      })
    },    
  }
}
</script>

<style lang="scss">
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(#000, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
</style>