<template>
    <article class="section-2__content">
        <BoardDetailView 
            detailType="TeamBuild"
            @updateQnA="updateQnA"
            @deleteQnA="deleteQnA"
            @writeReply="writeQnaReply"
            @updateReply="updateQnaReply"
            @deleteReply="deleteQnaReply"
            @fetchMoreReply="fetchMoreReply"
            :replyList="replyList"
            :qnaData="qnaData"            
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from '@/lib/api/Competition';
import modalControler from '@/helper/modalControler';
import BoardDetailView from '@/components/BoardDetailView'

export default {
    name: 'TeamBuildDetail',
    components: {
        BoardDetailView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            // 댓글 더보기
            options: {
                page: 1,
                itemsPerPage: 0
            },
            qnaData: {},
            replyList: [],
        });

        onMounted(() => {
            let params ={
                Info: route.params.qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage                
            }
            getTeamBuildDetail(params)
        })

        const getTeamBuildDetail = (params) => {
            modalControler.ShowLoading()
            CompetitionApi.getTeamBuildDetail(route.params.competitionId, route.params.qnaId, utils.getPage(params)).then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    state.qnaData.files.forEach(v => {
                        getBlobFromUrl(v.url).then(async file => {
                            if (file) {
                                document.querySelector('#ipynb2html').parentNode.style.display = 'block'
                                let text = await (new Response(file)).text()
                                document.querySelector('#ipynb2html').appendChild(ipynb2html.render(JSON.parse(text)))
                            }
                        })
                    })

                    // state.replyList = response.data.result.replyList
                    state.replyList = []
                    response.data.result.replyList.forEach(el => {
                        state.replyList.push(el)
                    })                                        
                } else {
                    modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                    router.goTaskPages('TeamBuild', route.params.taskId, route.params.competitionId)                    
                }
            }).catch(err => {
                modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                router.goTaskPages('TeamBuild', route.params.taskId, route.params.competitionId)                
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = () => {
            router.push({
                name: 'TeamBuildEdit',
                params: {qnaId: route.params.qnaId, competitionId: route.params.competitionId, taskId: route.params.taskId},
                query: {type: 'update'}
            })
        }

        const deleteQnA = () => {
            modalControler.ShowLoading()
            CompetitionApi.deleteTeamBuild(route.params.competitionId, route.params.qnaId).then(response => {
                if (!response.data.error)
                    router.goTaskPages('TeamBuild', route.params.taskId, route.params.competitionId)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnaReply = (content) => {
            let params = {
                content: content
            }
            CompetitionApi.writeTeamBuildReply(route.params.competitionId, route.params.qnaId, params).then(response => {
                if (!response.data.error) {
                    let params = {
                        Info: route.params.qnaId,
                        page: state.options.page,
                        itemsPerPage: state.options.itemsPerPage
                    }
                    getTeamBuildDetail(params) 
                }

            }).catch(err => {
                console.log(err)
            })
        }

        const updateQnaReply = (replyId, content) => {
            let params = {
                assignmentId: route.params.taskId,
                codeshareId: route.params.qnaId,
                codeshareReplyId: replyId,
                content: content                
            }
            CompetitionApi.updateTeamBuildReply(
                    route.params.competitionId, route.params.qnaId, replyId, params
                ).then(response => {
                    if (!response.data.error) {
                        let params = {
                            Info: route.params.qnaId,
                            page: state.options.page,
                            itemsPerPage: state.options.itemsPerPage
                        }
                        getTeamBuildDetail(params)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }

        const deleteQnaReply = (replyId) => {
            CompetitionApi.deleteTeamBuildReply(
                route.params.competitionId, route.params.qnaId, replyId).then(response => {
                    if (!response.data.error) {
                        let params = {
                            Info: route.params.qnaId,
                            page: state.options.page,
                            itemsPerPage: state.options.itemsPerPage
                        }
                        getTeamBuildDetail(params)                        
                    }
            }).catch(err => {
                console.log(err)
            })
        }

        const fetchMoreReply = () => {
            if (state.qnaData.replycount > (state.options.page * state.options.itemsPerPage)) {
                ++state.options.page
                let params = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getTeamBuildDetail(params)
            }
        }

        const getBlobFromUrl = async (url) => {
            const fileName = new URL(url).pathname.replace(/^.*[\\\\/]/, '');
            if (fileName.split('.').pop() === 'ipynb') {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {'Cache-Control': 'no-cache'},
                });
                const data = await response.blob();
                return new File([data], fileName);
            }
            return null;            
        }        


        return {
            ...toRefs(state),
            utils,
            updateQnA,
            deleteQnA,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            fetchMoreReply,
        }        

    }
}

</script>

<style scoped lang="scss">

</style>