<template>
  <div>
    <aside class="task-section-3 my-task-pannel" :class="{ 'on': leftActive }">
      <div class="my-task-pannel__content">
        <button class="btn-closed" @click="closeRsb()">
          <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#a9abae"
            icon-name="icon-closed"><icon-closed /></icon-base>
        </button>
        <article class="greeting-box">
          <div class="greeting">
            <strong>{{ accountInfo.nickname }}</strong>님,<br />안녕하세요!
          </div>
          <div class="status-block" v-if="props.isJoin === 0">
            <span class="status-badge status-badge--black">
              대회 미참여
            </span>
            <p class="text">
              대회에 먼저 참여해 주세요.
            </p>
          </div>
          <div class="status-block" v-if="props.isJoin === 1 || props.isJoin === 2">
            <span class="status-badge status-badge--black">
              과제 미참여
            </span>
            <p class="text">
              팀 리더가 과제참여를 해주세요.
            </p>
          </div>
          <div class="status-block" v-if="props.isJoin === 3">
            <div class="both">
              <span class="status-badge status-badge--primary">
                참가신청 완료
              </span>
              <button type="button" class="btn-secondary btn-xsmall" @click="dropTask">과제참여취소</button>
            </div>
            <p class="text">
              과제 참여 완료되었습니다!
            </p>
          </div>
        </article>
        <template v-if="participants">
          <article class="content-box">
            <header class="content-box__header content-box__header--both">
              <h3 class="content-box__title">팀 구성</h3>
              <button type="button" class="btn-more btn-more__small" @click="goToTeamBuild">
                더보기
                <span class="icon">
                  <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                    icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                </span>
              </button>
            </header>
            <div class="team-box">
              <h3 class="team-box__title">팀명</h3>
              <div class="team-box__content">{{ teamData.teamname }}</div>
            </div>
            <div class="team-box">
              <h3 class="team-box__title">팀 인원</h3>
              <div class="team-box__content">
                <span>{{ teamMembersCnt + 1 }}명</span> / {{ props.memberLimit }}명
              </div>
            </div>
            <div class="team-box">
              <h3 class="team-box__title">팀 리더</h3>
              <div class="team-box__content">
                <div class="team-leader">
                  <span class="badge-king none-shadow"></span>
                  <span class="vertical-middle">{{ teamData.leader }}</span>
                </div>
              </div>
            </div>
            <div class="team-box">
              <h3 class="team-box__title">승인 상태</h3>
              <div class="team-box__content">
                <p class="team-status_badge" v-if="teamData.status === 'JOIN'">
                  승인대기
                </p>
                <p class="team-status_badge-on" v-else-if="teamData.status === 'PASS'">
                  승인완료
                </p>
              </div>
            </div>
          </article>
        </template>
        <article class="content-box">
          <header class="content-box__header">
            <h3 class="content-box__title">참여중인 과제</h3>
          </header>
          <div class="task-box">
            <template v-if="myTaskList.length">
              <div v-for="(t, idx) in myTaskList" :key="'task-' + idx">
                <button type="button" class="task-box__link" @click="goToMyTask(t)">
                  <div class="task-box__item">
                    <div class="competition ellipsis-1">{{ t.competitionname }}</div>
                    <div class="task ellipsis-1">{{ t.assignmentname }}</div>
                    <div class="submit">
                      <span class="submit__title">제출 수</span>
                      <span class="submit__content">{{ t.submitcount }}회</span>

                      <div v-if="t.submitdtm">
                        <span class="submit__title">최종제출</span>
                        <span class="submit__content">{{ t.submitdtm }}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </template>
            <template v-else>
              <div class="no-data">
                <p class="no-data__text">참여중인 과제가 없습니다.</p>
              </div>
            </template>
          </div>
        </article>
        <!-- <article class="content-box">
          <header class="content-box__header">
            <h3 class="content-box__title">Weekly hot!</h3>
            <p>이번주 가장 인기있는 커뮤니티 게시글이에요.</p>
          </header>
        </article> -->
      </div>
    </aside>
    <div class="floating__btn">
      <button @click="showProfile" type="button" class="floating__btn--my"> <icon-base viewBox="0 0 24 24" width="24"
          height="24" iconColor="#fff" icon-name="icon-user"><icon-user /></icon-base></button>
    </div>
  </div>
</template>

<script>

import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  ref,
} from "@vue/composition-api";
import utils from "@/helper/utils";
import TaskApi from "@/lib/api/Task";
import Authenticate from "@/lib/api/Authenticate";
import CompetitionApi from "@/lib/api/Competition";
import modalControler from "@/helper/modalControler";
import { tokenStore } from "@/stores/auth";
import { stateStore } from '@/stores/state';

export default {
  name: "RightPanel",

  props: {
    memberLimit: {
      required: false,
      type: Number,
    },
    competitionId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    taskTitle: {
      type: String,
      required: false
    },
    status: {
      type: Number,
      required: false
    },
    isJoin: {
      type: Number,
      required: true
    },
    isOpen: {
      type: Number,
      required: true
    },
    container: {
      type: Object,
      required: false
    },
    competitionRecentNotice: {
      type: Array,
      required: false
    },
    taskRecentNotice: {
      type: Array,
      required: false
    },
    shareRecentNotice: {
      type: Array,
      required: false
    },
    category: {
      required: false
    }

  },
  setup(props, context) {
    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const stateStorage = stateStore()
    const profileEl = ref(null);
    const state = reactive({
      rsb: null,
      header: null,
      section1: null,
      section2: null,
      section4: null,
      openRsbBtn: null,
      totalContainer: null,
      teamNameContainer: null,
      teamNameWrapper: null,
      myTaskList: [],
      teamData: {},
      teamMembersCnt: 0,
      profileImg: [],
      previewURL: store.getAccountInfo.profileimg
        ? store.getAccountInfo.profileimg
        : null,
      profile: {},
      leftActive: false,
      // profile_rules: [
      //   value => !value || value.size < 5000000 || '프로필 사진 파일 최대 크기는 5MB를 초과할 수 없습니다.',
      // ],
      // participants: (props.isJoin !== 0) ? true : false
    });

    const accountInfo = computed(() => {
      return store.getAccountInfo;
    });

    const participants = computed(() => {
      return props.isJoin !== 0 ? true : false;
    });

    const tooltip = (content) => { };

    onMounted(() => {
      // Right panel CSS Setting
      state.section1 = document.querySelector(".task-section-1");
      state.section4 = document.querySelector(".task-section-4");
      state.teamNameContainer = document.querySelectorAll(
        ".team-name__container"
      );
      state.teamNameWrapper = document.querySelectorAll(".team-name__wrapper");

      getMyTeam();
      getMyTaskList();
    });

    const getMyTeam = () => {
      let params = {
        competitionId: route.params.competitionId,
      };
      CompetitionApi.getMyTeam(utils.makeParams(params))
        .then((response) => {
          if (!response.data.error) {
            state.teamData = response.data.result;
            state.teamMembersCnt = response.data.result.teamuser.length;
          } else {
            if (response.data.error_code === 7500) {
              // Do nothing
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => { });
    };

    const getMyTaskList = () => {
      let params = {
        pageSize: 5,
        pageNo: 1,
        status: "ing",
      };
      TaskApi.getAssignmentMyList(utils.makeParams(params)).then((response) => {
        if (response.data.http_status_code === 200) {
          state.myTaskList = response.data.result.data;
        }
      });
    };

    const goToMyTask = async (t) => {
      // await router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)
      location.href = `/competition/detail/${t.competitionId}/task/${t.assignmentId}/taskInfo`;
    };

    const updateProfileImg = (e, data) => {
      if (data && data.size > state.profileImgMaxSize) {
        profileEl.value.reset();
        modalControler.ShowErrorToast("프로필 이미지 최대 크기는 5MB 입니다");
      } else {
        state.previewURL = URL.createObjectURL(e);

        let formData = new FormData();
        formData.append("files", state.profileImg);
        Authenticate.updateProfile(formData)
          .then((response) => { })
          .catch((err) => {
            modalControler.ShowErrorToast("profile update 에러");
          });
      }
    };

    const toMenualFAQ = () => {
      window.open(
        "https://aichallenge.notion.site/AI-Challenge-FAQ-2ae8ea571dd3473b996b7dadeedb6bce",
        "_blank"
      );
    };

    const dropTask = () => {
      if (props.status !== 3) {
        modalControler.ShowErrorToast("과제참여 취소는 대회가 접수중일때만 가능합니다.")
        return
      }

      modalControler.ShowModalDialog('과제참여취소', `${props.taskTitle} 과제 참여를 취소하시겠습니까?<br>\
        취소 시, 모든 팀원들이 자동으로 과제 참여 취소됩니다.`, '취소', '확인', (val) => {
        if (val) {
          let params = {
            competitionId: route.params.competitionId,
            assignmentId: route.params.taskId
          }
          modalControler.ShowLoading()
          TaskApi.dropAssignment(params).then(response => {
            if (!response.data.error) {
              modalControler.HideLoading()
              modalControler.ShowSuccessToast('과제 참여를 취소하셨습니다.')
              router.goTaskPages('TaskInfo', route.params.taskId, route.params.competitionId)
              location.reload()
            } else {
              if (response.data.error_code === 7500) {
                modalControler.ShowErrorToast(response.data.error_detail)
              }
            }
          }).catch(err => {
            modalControler.ShowErrorToast(err.response.error_detail)
          }).finally(() => {
            modalControler.HideLoading()
          })
        }
      })

    }

    const showProfile = () => {
      state.leftActive = state.leftActive ? false : true
    }

    const goToTeamBuild = () => {
      state.leftActive = false
      stateStorage.setCompetitionDetailTab('competitionTeam')
      router.goCompetitionPages(
        'CompetitionDetail',
        route.params.competitionId
      )
    }

    function closeRsb() {
      showProfile()
    }
    function openRsb() {
      modalControler.OpenRightPanel();
      // state.rsb.style.width = "24%";
      // state.section2.style.width = "62%";
      // state.totalContainer.style.display = "flex";
      // state.totalContainer.style.flexDirection = "column";
      // state.totalContainer.style.alignItems = "center";

      for (let i = 0, len = state.teamNameContainer.length; i < len; i++) {
        teamNameContainer[i].style.gap = "0.3rem";
      }

      for (let i = 0, len = state.teamNameWrapper.length; i < len; i++) {
        teamNameWrapper[i].style.gap = "0.2rem";
      }
    }


    return {
      ...toRefs(state),
      utils,
      props,
      store,
      closeRsb,
      accountInfo,
      goToMyTask,
      getMyTaskList,
      updateProfileImg,
      toMenualFAQ,
      participants,
      tooltip,
      dropTask,
      showProfile,
      goToTeamBuild,
    };
  },
};
</script>

<style scoped lang="scss"></style>
