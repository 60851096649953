import _, {isUndefined} from 'lodash'


// form settings
const formNameMin = 2
const formMin = 4
const formMax = 20
const formBelongNameMax = 30
const passwordMin = 8
const passwordMax = 20
const teamNameMin = 2
const teamNameMax = 20
// const nameRegexRule = /[\.~!@#$%^★&*()_+|<>?:{}\[\]\s]/   // 특수문자, 공백 필터링
const nameRegexRule = /[^\p{L}\p{N}]+/gmu   // 특수문자, 공백 필터링
const emailRegexRule = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[0-9a-zA-Z][0-9a-zA-Z\\_\\-]*[0-9a-zA-Z](\.[a-zA-Z]{2,6}){1,3}$/   // 이메일 폼 확인
const passwordRegexRule = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/    // 비밀번호 폼

export default {

    // validators
    nicknameValidator(v) {
        const errMsg = `특수문자, 공백없이 최소 ${formMin}~${formMax}자 ${v.length}/${formMax}`
        if (v === "") return "닉네임은 필수 입력사항입니다."
        if (!this.nicknameChecker(v) || v.length < formMin || v.length > formMax) {
            return errMsg
        } else {
            return ""
        }
    },
    emailValidator(v) {
        const errMsg = `이메일 양식을 지켜주세요, ex)example@email.com`
        if (v === "") return "이메일은 필수 입력사항입니다."
        if (!this.emailChecker(v)) {
            return errMsg
        } else {
            return ""
        }
    },
    nameValidator(v) {
        const errMsg = `특수문자, 공백없이 최소 ${formNameMin}~${formMax}자 ${v.length}/${formMax}`
        if (v === "") return "이름은 필수 입력사항입니다."
        if (!this.nicknameChecker(v) || v.length < formNameMin || v.length > formMax) {
            return errMsg
        } else {
            return ""
        }
    },
    belongNameValidator(v) {
        const errMsg = `특수문자, 공백없이 최대 ${formBelongNameMax}자 ${v.length}/${formBelongNameMax}`
        if (!this.belongnameChecker(v) || v.length > formBelongNameMax) {
            return errMsg
        } else {
            return ""
        }
    },
    passwordValidator(v) {
        const errMsg = `영문, 숫자, 특수문자 포함 / 최소 ${passwordMin}자 최대 ${passwordMax}자`
        if (v === "") return "비밀번호는 필수 입력사항입니다."
        if (!this.passwordChecker(v)) {
            return errMsg
        } else {
            return ""
        }
    },
    teamNameValidator(v) {
        const errMsg = `특수문자, 공백없이 최소 ${teamNameMin}, 최대 ${teamNameMax}자`
        if (v === "") return "팀명은 필수 입력사항입니다."
        if (!this.nicknameChecker(v) || v.length < teamNameMin || v.length > teamNameMax) {
            return errMsg
        } else {
            return ""
        }
    },

    // checkers
    nicknameChecker(v) {
        if (v === null) return false
        if (_.isEmpty(v)) return false
        return !nameRegexRule.test(v)
    },
    belongnameChecker(v) {
        return !nameRegexRule.test(v)
    },
    emailChecker(v) {
        if (v === null) return false
        if (_.isEmpty(v)) return false
        return emailRegexRule.test(v)
    },
    passwordChecker(v) {
        if (v === null) return false
        if (_.isEmpty(v)) return false
        return passwordRegexRule.test(v)
    },
    mobileChecker(v) {

    },

}
