<template>
  <div class="editor" v-if="editor" @click.prevent.stop="onFocus">
    <menu-bar class="editor__header" :editor="editor" />
    <editor-content ref="editorContent" class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Youtube from '@tiptap/extension-youtube'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import MenuBar from './EditorMenuBar.vue'
import FormulaComponent from './FormulaExtension.js'
import FormulaMark from './FormulaMark.js'

export default {
  name: 'UserEditor',
  components: {
    EditorContent,
    MenuBar,
  },
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      editor: null,

    }
  },
  computed: {
    resultAsHtml() {
      if (!this.editor) {
        return ''
      }
      return this.editor.getHTML()
    },
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    }
  },
  updated() {
    this.editor.commands.setContent(this.value, false)
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        Image.configure({
          inline: true,
          allowBase64: true,
        }),
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,        
        Link.configure({
          protocols: ['ftp', 'mailto'],
        }),
        Highlight,
        TaskList,
        TaskItem,
        FormulaComponent,
        FormulaMark,
        Youtube,
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }

    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    onFocus() {
      this.editor.commands.focus()
    },

  },

}
</script>

