import httpClient from './httpClient';

export default {
    getCompetitionList(param) {
        return httpClient.get(`/competition/getCompetitionList/limit${param}`);
    },
    
    getLeaderboards() {
        return httpClient.get(`/competition/leaderboards`);
    },
    getCompetitionSummary() {
        return httpClient.get('/competition/getCompetitionSummary');
    },

}
