<template>
    <section class="content-section" id="section-7">
        <div class="content-inner">
            <header class="content-section__header content-section__header--both">
                <h2 class="content-section__title">공지사항</h2>
                <button class="btn-more" type="button" v-if="showMorebtn" @click="showMoreNotice">
                    더보기
                    <span class="icon">
                        <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                    </span>
                </button>
            </header>
            <div class="content-container">
                <div class="common-accordion accordion" id="accordionExample">
                    <template v-if="noticeList.length">
                        <div class="common-accordion__item  accordion-item" v-for="(notice, idx) in showingList" :key="idx">
                            <h2 class="common-accordion__header accordion-header" :id="'heading-' + idx">
                                <button class="common-accordion__button accordion-button  collapsed" type="button"
                                    data-bs-toggle="collapse" :data-bs-target="'#collapse-' + idx" aria-expanded="true"
                                    :aria-controls="'collapse-' + idx">
                                    <span class="accordion-text">{{ notice.title }} <span class="accordion-date">{{
                                        notice.createdtm
                                    }}</span></span>

                                </button>
                            </h2>
                            <div :id="'collapse-' + idx" class="accordion-collapse collapse"
                                :aria-labelledby="'heading-' + idx" data-bs-parent="#accordionExample">
                                <div class="common-accordion__body accordion-body" v-html="notice.content"
                                    :key="'content-' + idx"></div>
                                <template v-for="(file, idx) in notice.files">
                                    <div class="common-accordion__body" :key="idx">
                                        <a class="btn-filedown" :href="file.url">
                                            <font-awesome-icon :icon="['fas', 'paperclip']" /><strong class="file-name">{{
                                                file.name }}</strong>
                                        </a>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </template>

                    <div class="no-data" v-else>
                        <p class="no-data__text">등록된 공지사항이 없습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';


export default {
    name: 'CompetitionNotice',
    props: {
        competitionId: {
            type: Number,
            required: true
        }
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            options: {
                page: 1,
                itemsPerPage: 0,
                showNotice: 1,
                searchText: ''
            },
            noticeList: [],
            contentsList: [],
            showingList: [],
            showMorebtn: false,
            maxShowRow: 5,
        });

        onMounted(() => {
            getCompetitionNotice()
        });

        const getCompetitionNotice = () => {
            // let searchOptions = utils.getPage(state.options)
            CompetitionApi.getCompetitionNotice(props.competitionId).then(response => {
                if (!response.data.error) {
                    state.noticeList = response.data.result
                    // state.noticeList.forEach(el => {
                    //     getCompetitionNoticeContent(el.id)
                    // })
                    if (state.noticeList.length > state.maxShowRow) {
                        state.showingList = state.noticeList.slice(0, state.maxShowRow)
                        state.showMorebtn = true
                    } else {
                        state.showingList = state.noticeList
                    }
                }
            }).catch(err => {
                // TODO : error alert
                // modalControler.ShowErrorToast('대회 공지를 불러올 수 없습니다.')
                console.log(err)
            })
        }

        const getCompetitionNoticeContent = async (qnaId) => {
            state.contentsList = []
            let params = {
                Info: qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage
            }
            const response = await CompetitionApi.getCompetitionNoticeDetail(utils.getPage(params))
            if (!response.data.error) {
                state.contentsList.push(response.data.result.data)
            } else {

            }
        }

        const showMoreNotice = () => {
            router.push({
                name: 'CommunityList'
            })
        }

        return {
            ...toRefs(state),
            showMoreNotice,
            getCompetitionNoticeContent,
        }
    }
}

</script>

<style scoped lang="scss">
.no-notice {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
</style>