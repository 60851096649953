<template>
  <footer class="footer">
    <div class="content-inner">
      <div class="service__group">
        <div class="service__box">
          <strong class="service__title">
            <a @click.prevent.stop="$router.push({ path: '/' })">AI Competition</a></strong>
          <ul class="service__items">
            <li class="service__item">
              <a @click.prevent.stop="$router.push({ name: 'CompetitionList' })">Competition
              </a>
            </li>
            <li class="service__item">
              <a @click.prevent.stop="$router.push({ name: 'CompetitionTaskList' })">Task</a>
            </li>
          </ul>
        </div>
        <div class="service__box">
          <strong class="service__title">
            <a @click.prevent.stop="$router.push({ name: 'LeagueRoot' })">AI League</a>
          </strong>
        </div>
        <div class="service__box">
          <strong class="service__title">
            <a @click.prevent.stop="$router.push({ name: 'CodingTest' })">AI-bility Test</a>
          </strong>
        </div>
        <div class="service__box">
          <strong class="service__title">
            <a @click.prevent.stop="$router.push({ name: 'CommunityList' })">Community</a>
          </strong>
        </div>
        <div class="service__box">
          <strong class="service__title">
            <a @click.prevent.stop="$router.push({ name: 'CompetitionNews' })">News</a>
          </strong>
        </div>
      </div>
      <div class="footer-sitemap">
        <div class="footer-sitemap__items">
          <button type="button" class="footer-sitemap__item" @click="showNIPA">회사소개</button>
          <button type="button" class="footer-sitemap__item" @click="showServicePolicy">이용약관</button>
          <button type="button" class="footer-sitemap__item" @click="showPrivacyPolicy">개인정보처리방침</button>
          <button type="button" class="footer-sitemap__item" @click="toMenualFAQ">FAQ 및 매뉴얼</button>

        </div>
        <div class="btn-group">
          <a href="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/files/MNC-AI+Connect+Jul+2023_F.pdf"
            target="_blank" download class="btn btn-secondary btn-small">AI
            CONNECT 소개서</a>
          <a href="https://docs.google.com/forms/d/184ppGB9z3I8mpHVF9MaNnxW07jxG6293xMFF7efVvP8/viewform?edit_requested=true "
            target="_blank" class="btn btn-primary btn-small">경진대회 개최 문의</a>
        </div>
      </div>
      <div class="footer-info">
        <div>
          <p class="footer-info__text">
            <span>(주)마인즈앤컴퍼니</span>
            | 대표 전상현,고석태 | 사업자번호 763-81-01040 | 서울특별시 강남구 남부순환로 2621 13층 1313호 (도곡동, 에스앤아이 강남빌딩)
          </p>
          <p class="footer-info__text">© 2017-2023 MINDS AND COMPANY. All Rights Reserved</p>
        </div>
        <div class="sns__group">
          <a href="https://www.facebook.com/mindsncompany" target="_blank" class="sns__item"><img
              src="../../assets/images/icon-facebook.svg" /></a>
          <a href="https://www.linkedin.com/company/mindsandcompany/" target="_blank" class="sns__item"><img
              src="../../assets/images/icon-linkdin.svg" /></a>
          <a href="https://www.instagram.com/aiconnect_mnc/" target="_blank" class="sns__item"><img
              src="../../assets/images/icon-instagram.svg" /></a>
        </div>
      </div>
    </div>
    <div class="floating-btn">
      <button type="button" class="floating-btn__top" @click="scrollToTop">
        <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
          icon-name="icon-arrow-up"><icon-arrow-up /></icon-base>
      </button>
    </div>
    <div class="testserver-btn" v-if="bottem_message != ''">
      <button type="button" class="testserver-btn" style="color: #0d6efd">
        <span class="service__testserver"> {{ bottem_message }} </span>
      </button>
    </div>
  </footer>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, nextTick
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';

export default {
  name: 'Footer',
  components: {
  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const state = reactive({
      bottem_message: process.env.VUE_APP_BOTTOM_ALERT_MESSAGE,
    });

    const showNIPA = () => {
      window.open('https://mnc.ai', '_blank')
    }

    const showServicePolicy = () => {
      router.push({ name: 'Service' })
    }

    const showPrivacyPolicy = () => {
      router.push({ name: 'Privacy' })

    }

    const toMenualFAQ = () => {
      // window.open(
      //   "https://mindsncom.notion.site/AI-CONNECT-1d619e6803a64972ab04505882ae8efb",
      //   "_blank"
      // );
      router.push({ name: 'faq' })
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      // document.body.scrollTop = 0;
      // document.getElementById('header').scrollIntoView();
    }

    return {
      ...toRefs(state),
      showNIPA,
      showServicePolicy,
      showPrivacyPolicy,
      toMenualFAQ,
      scrollToTop,
    }

  }
}
</script>
