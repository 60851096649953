<template>
    <div>
        <!-- task-header -->
        <GNBCompetition />

        <!-- <GNBTask 
                                                :isJoin=" isJoin" :competitionTitle="taskInfo.competitionname"
        :competitionStatus="taskInfo.competitionstatus" :taskTitle="taskInfo.assignmentname"
        :competitionId="Number($route.params.competitionId)" /> -->
        <!-- task-section-1 -->
        <div class="task-wrap contents-wrap">
            <section class="task-section">
                <div class="content-inner ">
                    <div class="column-02">
                        <div class="task-contents left-content">
                            <Summary v-if="!isLoading" :key="$route.fullPath" :info="taskInfo"
                                :competitionTitle="taskInfo.competitionname" :taskTitle="taskInfo.assignmentname"
                                :taskDesc="taskInfo.assignmentcontent"
                                :taskKeywords="taskKeywords"
                                :taskCategory="taskInfo.categoryCodeName" :container="container" :agreed="agreed" />
                            <LeftNavbar :agreed="agreed" :competitionId="Number($route.params.competitionId)"
                                :taskTitle="taskInfo.assignmentname" :status="status" :isJoin="isJoin" :isOpen="isOpen"
                                :category="category" :container="container"
                                :competitionRecentNotice="competitionRecentNotice" :taskRecentNotice="taskRecentNotice"
                                :shareRecentNotice="shareRecentNotice" :taskId="Number($route.params.taskId)" />
                            <div class="task-content">
                                <template v-if="$route.name === 'TaskInfo'">
                                    <router-view v-if="!isLoading" :key="$route.fullPath" :info="taskInfo"></router-view>
                                </template>

                                <template v-else-if="$route.name === 'TaskSubmit'">
                                    <router-view v-if="!isLoading" :key="$route.fullPath" :status="status" :isOpen="isOpen"
                                        :container="container" :category="category" :fileExtGroupIdList="fileExtGroupIdList"
                                        :submitLimitDesc="submitDesc" :privateExpose="privateExpose"
                                        :finalExpose="finalExpose" :maxSubmitSize="maxSubmitSize"
                                        :maxSubmitTime="maxSubmitTime" :maxSubmitTerm="maxSubmitTerm"
                                        :limitFileCount="limitFileCount" :agreed="agreed" :isJoin="isJoin"></router-view>
                                </template>
                                <template v-else-if="$route.name === 'TaskLeaderboard'">
                                    <router-view v-if="!isLoading" :key="$route.fullPath" :privateExpose="privateExpose"
                                        :finalExpose="finalExpose" :scoreSortBy="scoreSort"></router-view>
                                </template>
                                <template v-else-if="$route.name === 'TaskTeam'">
                                    <router-view :key="$route.fullPath" :status="status" :memberLimit="memberLimit"
                                        :isJoin="isJoin"></router-view>
                                </template>
                                <template v-else-if="$route.name === 'TaskData'">
                                    <router-view v-if="!isLoading" :agreed="agreed" :key="$route.fullPath" :status="status"
                                        :isJoin="isJoin"></router-view>
                                </template>
                                <template v-else>
                                    <router-view :key="$route.fullPath"></router-view>
                                </template>
                            </div>
                        </div>
                        <!-- <LeaderBoadPannel /> -->
                        <RightPanel v-if="store.isAuthenticated" :competitionId="Number($route.params.competitionId)"
                            :taskId="Number($route.params.taskId)" :taskTitle="taskInfo.assignmentname"
                            :competitionRecentNotice="competitionRecentNotice" :taskRecentNotice="taskRecentNotice"
                            :shareRecentNotice="shareRecentNotice" :memberLimit="memberLimit" :isJoin="isJoin"
                            :isOpen="isOpen" :status="status" :category="category" :container="container" />
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, provide, watch
} from '@vue/composition-api';
import moment from 'moment'
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import CompetitionApi from '@/lib/api/Competition';
import CommunityApi from '@/lib/api/Community';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import Summary from "@/components/task/Summary"
// import LeaderBoadPannel from "@/components/task/LeaderBoadPannel"
import RightPanel from "@/components/task/RightPanel"
import LeftNavbar from "@/components/task/LeftNavbar"
import modalControler from '@/helper/modalControler';
import Footer from '@/components/global/Footer';
import { EventBus } from '@/lib/event-bus';
import GNBCompetition from '@/components/gnb/GNBCompetition';


export default {
    name: 'TaskRoot',
    components: {
        GNBCompetition,
        LeftNavbar,
        // GNBTask,
        Summary,
        RightPanel,
        Footer,
        // LeaderBoadPannel,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const vuetify = context.root.$vuetify
        const store = tokenStore()
        const store2 = stateStore()
        const state = reactive({
            taskId: context.root.$route.params.taskId,
            taskInfo: '',
            status: 0,
            isOpen: 0,
            isJoin: 0,
            category: 0,
            agreed: false,
            fileExtGroupIdList: [],
            submitDesc: '',
            maxSubmitSize: 0,
            maxSubmitTime: 0,
            maxSubmitTerm: 0,
            limitFileCount: 0,
            memberLimit: 0,
            scoreSort: '',
            privateExpose: false,
            finalExpose: false,
            container: null,
            options: {
                page: 1,
                itemsPerPage: 9999,
                showNotice: 1,
            },
            noticeRange: 3,     // 3 days
            competitionRecentNotice: [],
            taskRecentNotice: [],
            shareRecentNotice: [],
            mobileWidth: 996,
            windowWidth: window.innerWidth,
            summaryMobile: null,
            isLoading: false,
            taskKeywords: [],
        });

        onMounted(async () => {
            await getTaskDetailInfo()
            getCompetitionQnaRecent()
            if (store.isAuthenticated) {
                getCommunityRecent()
                // getCodeShareRecent()
            }
            store2.setTaskActiveRoute(router.currentRoute.name)

            EventBus.$on('summaryUpdate', getTaskDetailInfo())
        });

        const getTaskDetailInfo = () => {
            state.isLoading = true
            modalControler.ShowLoading()
            TaskApi.getAssignmentDetailInfo(utils.makeParams({ assignmentId: state.taskId })).then(response => {
                if (!response.data.error) {
                    state.taskInfo = response.data.result
                    state.isJoin = state.taskInfo.isjoin
                    state.isOpen = state.taskInfo.isopen
                    state.agreed = state.taskInfo.agreed
                    state.maxSubmitSize = state.taskInfo.limitfilesize
                    state.maxSubmitTime = state.taskInfo.submitofday
                    state.maxSubmitTerm = state.taskInfo.submitdelay
                    state.status = state.taskInfo.competitionstatus
                    state.memberLimit = state.taskInfo.maxteamperson
                    state.privateExpose = state.taskInfo.isprivateexpose
                    state.finalExpose = state.taskInfo.isfinalexpose
                    state.container = state.taskInfo.container
                    state.category = state.taskInfo.categoryCodeName
                    state.fileExtGroupIdList = state.taskInfo.fileExtGroupIdList
                    state.submitDesc = state.taskInfo.assignmentsubmitdesc
                    state.limitFileCount = state.taskInfo.limitfilecount
                    state.scoreSort = (state.taskInfo.scoresort) ? state.taskInfo.scoresort : 'desc'
                    state.isLoading = false
                    state.taskKeywords = state.taskInfo.keywords
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
                router.goCompetitionPages('CompetitionDetail', route.params.competitionId)

            }).finally(() => {
                modalControler.HideLoading()
                state.isLoading = false
            })
        }

        const getCompetitionQnaRecent = () => {
            // let searchOptions = utils.getPage(state.options)
            // CompetitionApi.getCompetitionQnaList(route.params.competitionId, searchOptions).then(response => {
            //     if (!response.data.error) {
            //         const now = moment()
            //         state.competitionRecentNotice = response.data.result.data.filter(v => v.isnotice === 1 && v.isopen === 1 && (now.diff(v.createdtm, 'days') <= state.noticeRange))
            //     }
            // }).catch(err => {
            //     console.log(err)
            // })
        }

        const getCommunityRecent = () => {
            let searchOptions = utils.getPage(state.options)
            CommunityApi.getTaskCommunityList(route.params.taskId, searchOptions).then(response => {
                if (!response.data.error) {
                    const now = moment()
                    state.taskRecentNotice = response.data.data.filter(v => (now.diff(v.createdtm, 'days') <= state.noticeRange))
                }

            }).catch(err => {
                console.log(err)
            })
        }

        const getCodeShareRecent = () => {
            // let searchOptions = utils.getPage(state.options)
            // TaskApi.getCodeshareList(route.params.taskId, searchOptions).then(response => {
            //     if (!response.data.error) {
            //         const now = moment()
            //         state.shareRecentNotice = response.data.result.data.filter(v => v.isnotice === 1  && (now.diff(v.createdtm, 'days') <= state.noticeRange))
            //     }
            // }).catch(err => {
            //     console.log(err)
            // })
        }

        return {
            route,
            store,
            ...toRefs(state),

        }


    }
}
</script>
