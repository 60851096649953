<template>
    <v-menu width="120" offset-y nudge-left="252" open-on-hover :content-class="'header-menu-list'" v-model="dialogOpen"
        attach="">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>account_circle</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="$router.push({ name: 'CompetitionListProfile' })" class="user-profile">
                <v-list-item-avatar>
                    <v-img
                        :src="(accountInfo.profileimg) ? accountInfo.profileimg : require('@/assets/images/img-profile-blue.svg')"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title><strong>{{ accountInfo.nickname }}</strong></v-list-item-title>
                    <v-list-item-subtitle class="color-primary">{{ accountInfo.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <div class="my-task">
                <v-subheader class="my-task__header" @click="toTaskList">
                    <span>참여중인 과제</span>
                    <span class="btn-more btn-more__small">
                        더보기
                        <span class="icon">
                            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                                icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                        </span>
                    </span>
                </v-subheader>
                <div class="my-task__list">
                    <template v-if="myTaskList && myTaskList.length">
                        <v-list-item v-for="(t, idx) in myTaskList" :key="'task-' + idx" @click="goToMyTask(t)">
                            <v-list-item-content>
                                <v-list-item-title class="task-name"><strong>{{
                                    t.assignmentname
                                }}</strong></v-list-item-title>
                                <v-list-item-subtitle>{{ t.competitionname }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <div class="no-data">
                            <p class="no-data__text">참여중인 과제가 없습니다.</p>
                        </div>
                    </template>
                </div>
            </div>
            <v-list-item @click="toFAQ" class="line-top mt-4">
                <v-list-item-title>매뉴얼/FAQ</v-list-item-title>
            </v-list-item>
            <template v-if="accountInfo.userrole === 1 || accountInfo.hasCompetitionUserrole">
                <v-list-item @click="openAdminPage">
                    <v-list-item-title>관리자페이지</v-list-item-title>
                </v-list-item>
            </template>
            <v-list-item @click="$router.signout()">
                <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from "@/helper/utils";
import TaskApi from "@/lib/api/Task";
import { tokenStore } from '@/stores/auth';


export default {
    name: 'UserMenu',
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            dialogOpen: false,
            accountInfo: tokenStore().getAccountInfo,
            myTaskList: [],
            allowedStatus: [3, 4, 5],
        });

        onMounted(() => {
            getMyTaskList()
        })

        const openAdminPage = () => {
            if (process.env.VUE_APP_MODE === 'prod') {
                window.open(`https://admin-web.aiconnect.kr/`)
            } else if (process.env.VUE_APP_MODE === 'stage') (
                window.open(`https://admin-dev-web.aiconnect.kr`)
            )
        }

        const getMyTaskList = () => {
            let params = {
                pageSize: 1000,
                pageNo: 1
            }
            TaskApi.getAssignmentMyList(utils.makeParams(params)).then((response) => {
                if (response.data.http_status_code === 200) {
                    state.myTaskList = response.data.result.data.filter(t => (state.allowedStatus.includes(t.competitionstatus)))
                }
            })
        }

        const goToMyTask = async (t) => {
            // await router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)
            location.href = `/competition/detail/${t.competitionId}/task/${t.assignmentId}/taskInfo`;
        }

        const toTaskList = () => {
            router.push({ name: 'CompetitionListProfile' })
        }

        const toFAQ = () => {
            router.push({ name: 'faq' })
        }

        return {
            ...toRefs(state),
            accountInfo,
            openAdminPage,
            goToMyTask,
            toFAQ,
            toTaskList,
        }
    }
}
</script>

<style>
.v-menu__content {
    max-height: 500px;
}
</style>
