<template>
    <section id="section-2">
        <div class="content-inner">
            <div class="card-list card-list--3" v-if="taskList.length">
                <div class="card-box" v-for="(task, idx) in taskList" :key="idx">
                    <div class="card-box__content">
                        <a class="card-box__link" @click.prevent="toTaskDetail(task.assignmentId)">
                            <h3 class="card-box__title ellipsis-2">{{ task.name }}</h3>
                            <span class="card-box__text">
                                {{ task.desc }}
                            </span>
                            <div class="card-box__tags">
                                <span v-if="task.type && task.type !== '' && task.type !== null" class="tag">{{
                                    task.type
                                }}</span>
                                <template v-if="task.keywords.length">
                                    <template v-for="(k, idx) in task.keywords">
                                        <span v-if="k.name !== ''" :key="'k-' + idx" class="tag">{{
                                            k.name
                                        }}</span>
                                    </template>
                                </template>
                            </div>
                            <div class="card-box__option">
                                <div class="info-box">
                                    <div class="info-box__item" v-if="task.prizemoney !== 0">
                                        <span class="info-box__icon"> <icon-base viewBox="0 0 24 24" width="20" height="20"
                                                iconColor="#101216" icon-name="icon-award"><icon-award /></icon-base></span>
                                        <span class="info-box__text">{{
                                            utils.numberToKorean(task.prizemoney)
                                        }}만원</span>
                                    </div>
                                    <div class="info-box__item">
                                        <span class="info-box__icon"> <icon-base viewBox="0 0 24 24" width="20" height="20"
                                                iconColor="#101216" icon-name="icon-user"><icon-user /></icon-base></span>
                                        <span class="info-box__text">{{ task.joinusercnt }}명 / {{
                                            task.jointeamcnt
                                        }}팀</span>
                                    </div>
                                </div>
                                <div class="card-box__btn-area">
                                    <v-btn v-if="!joinedTask.includes(task.assignmentId)
                                        && joinedTask.length < maxAssignment
                                        && isJoin !== 0
                                        && competitionStatus === 3" class="btn-medium btn-primary btn-full"
                                        @click.prevent="taskApply(task)">
                                        참여하기
                                    </v-btn>
                                    <v-btn v-if="joinedTask.includes(task.assignmentId)" disabled
                                        class="btn-medium btn-primary btn-full">과제참여중</v-btn>
                                </div>

                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="no-data" v-else>
                <p class="no-data__text">참여할 수 있는 과제가 아직 생성되지 않았습니다.</p>
            </div>
        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import TaskApi from '@/lib/api/Task';
import { swiper } from "@/static/swiper";
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
    name: 'TaskList',

    directives: {
        directive,
    },
    props: {
        competitionId: {
            type: Number,
            required: true
        },
        isJoin: {
            type: Number,
            required: false
        },
        maxAssignment: {
            type: Number,
            required: false
        },
        joinedTask: {
            type: Array,
            required: false
        },
        competitionStatus: {
            type: Number,
            required: false
        }
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const totalTaskList = () => {
            router.goCompetitionPages('TaskList', props.competitionId)
        }
        const swiper = ref(null)
        const state = reactive({
            c_id: props.competitionId,
            taskList: [],
            swiperOption: {
                loop: false,
                // slidesPerView: 3.2,

                navigation: {
                    nextEl: '.first__btn-league--right',
                    prevEl: '.first__btn-league--left'
                },
                breakpoints: {
                    1023: { slidesPerView: 3 },
                    768: { slidesPerView: 2 },
                    360: { slidesPerView: 1 },
                },
            },
        });

        onMounted(() => {
            getTaskList();
        });

        const getTaskList = () => {
            TaskApi.getAssignmentList(props.competitionId).then(response => {
                if (!response.data.error) {
                    state.taskList = response.data.result
                }
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('과제 목록을 불러올 수 없습니다.')
            });
        }

        const toTaskDetail = (taskId) => {
            router.goTaskPages('TaskInfo', taskId, props.competitionId)
        }

        const clickSlide = (a, b) => {

        }

        const prev = () => {
            swiper.value.$swiper.slidePrev()
        }

        const next = () => {
            swiper.value.$swiper.slideNext()
        }

        const taskApply = (task) => {
            let params = {
                competitionId: route.params.competitionId,
                assignmentId: task.assignmentId
            }
            modalControler.ShowLoading()
            TaskApi.joinAssignment(params).then(response => {
                if (!response.data.error) {
                    modalControler.HideLoading()
                    router.goTaskPages('TaskInfo', task.assignmentId, route.params.competitionId)
                    // location.reload()
                } else {
                    // QQQ : 팀 승인 관련 에러는 따로 처리 (errorHandler.js)
                    if (response.data.error_code === 7500) {
                        modalControler.ShowErrorToast(response.data.error_detail)
                    }
                    // else if (response.data.error_code === 12002) {
                    //     modalControler.ShowErrorToast(response.data.error_detail)
                    // }                    
                }
            }).catch(err => {
                console.log(err)
                // modalControler.ShowErrorToast(err.response.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        return {
            props,
            swiper,
            ...toRefs(state),
            utils,
            totalTaskList,
            toTaskDetail,
            clickSlide,
            prev,
            next,
            taskApply,
        }

    }

}

</script>

<style scoped lang="scss">
.task-list {
    margin-left: -16px;
    margin-right: -16px;

    .swiper {
        margin-top: -16px;
        padding-top: 16px;
    }
}
</style>