<template>
    <v-app>
        <section class="password-section">
            <div class="medium-content-inner">
                <header class="page-header--line--left__section">
                    <h2 class="page-header--line__title">비밀번호 변경</h2>
                    <ul class="list list__box list__box--line">
                        <li class="list__item">동일한 문자 반복, 나란히 있는 문자열 등 추측하기 쉬운 비밀번호 사용은 피해주세요.</li>
                        <li class="list__item ">타 사이트의 비밀번호와 같으면 도용되거나 유출될 위험이 있으니, 주기적으로 변경하여 사용해 주세요.</li>
                        <li class="list__item ">비밀번호 변경 후 90일이 지나면 비밀번호를 변경해 주세요.</li>
                    </ul>
                </header>
                <div>
                    <form>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">현재 비밀번호</div>
                            <v-text-field ref="passwordEl" class="common-form-control" outlined
                                placeholder="현재 비밀번호를 입력해주세요." :type="pwShow1 ? 'text' : 'password'"
                                :append-icon="pwShow1 ? 'mdi-eye-off' : 'mdi-eye'" @click:append="pwShow1 = !pwShow1"
                                v-model="currentpassword" @blur="checkCurrentPassword(currentpassword)"
                                :error-messages="errorStatus.currentPasswordErrorMessage">
                            </v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">새 비밀번호</div>
                            <v-text-field ref="passwordEl" class="common-form-control" outlined
                                placeholder="8자~20자 영문, 숫자, 특수문자를 조합하여 주세요." :type="pwShow2 ? 'text' : 'password'"
                                :append-icon="pwShow2 ? 'mdi-eye-off' : 'mdi-eye'" @click:append="pwShow2 = !pwShow2"
                                v-model="newpassword" @blur="checkPassword(newpassword)"
                                :error-messages="errorStatus.passwordErrorMessage">

                            </v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">비밀번호 재입력</div>
                            <v-text-field ref="passwordEl" class="common-form-control" outlined
                                :type="pwShow3 ? 'text' : 'password'" :append-icon="pwShow3 ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="pwShow3 = !pwShow3" placeholder="새 비밀번호를 다시 입력해주세요." v-model="newrepassword"
                                @blur="checkRePassword(newpassword, newrepassword)" @keypress.enter="changePassword"
                                :error-messages="errorStatus.repasswordErrorMessage">
                            </v-text-field>
                        </div>
                        <div class="content-group__btn-area">
                            <div class="btn-inline btn-inline--center">
                                <v-btn class="btn btn-large btn-tertiary"
                                    @click="$router.push({ name: 'CompetitionListProfile' })"><strong>취소</strong></v-btn>
                                <v-btn class="btn btn-large btn-primary" @click="changePassword"
                                    :disabled="!canSubmit"><strong>확인</strong></v-btn>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import validator from "@/config/formValidator"
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'PasswordEdit',
    components: {

    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const state = reactive({
            pwShow1: false,
            pwShow2: false,
            pwShow3: false,
            currentpassword: '',
            newpassword: '',
            newrepassword: '',
            errorStatus: {
                currentPasswordErrorMessage: '',
                passwordErrorMessage: '',
                repasswordErrorMessage: '',
            },
            data: {
                email: '',
                password: '',
                newpassword: '',
            },
        });

        const getAccountInfo = computed(() => {
            return store.getAccountInfo
        });

        const canSubmit = computed(() => {
            if ((state.errorStatus.currentPasswordErrorMessage === '' &&
                state.errorStatus.passwordErrorMessage === '' &&
                state.errorStatus.repasswordErrorMessage === '') &&
                (state.currentpassword !== '' && state.newpassword !== '' && state.newrepassword === state.newpassword)) {
                return true
            } else {
                return false
            }
        });

        const checkCurrentPassword = (currentpassword) => {
            // state.errorStatus.currentPasswordErrorMessage = validator.passwordValidator(currentpassword)
        }

        const checkPassword = (passwd) => {
            state.errorStatus.passwordErrorMessage = validator.passwordValidator(passwd)
        }

        const checkRePassword = (repasswd) => {
            state.errorStatus.repasswordErrorMessage = (state.newpassword !== state.newrepassword) ? "비밀번호가 일치하지 않습니다." : ""
        }

        const changePassword = () => {
            if (!canSubmit) return
            if (state.currentpassword === state.newpassword) {
                return state.errorStatus.passwordErrorMessage = '현재 비밀번호와 같습니다.'
            }
            let params = {
                password: state.currentpassword,
                newPassword: state.newpassword
            }
            Authenticate.changePassword(params).then(response => {
                let msg
                if (response.data.http_status_code === 200) {
                    msg = `비밀번호가 변경되었습니다. 다시 로그인 해주세요.`
                    modalControler.ShowModalDialog('비밀번호 변경', msg, '', '확인', (val) => { })
                    router.signout()
                } else {
                    msg = `에러가 발생했습니다. 잠시 후 다시 시도해주세요.`
                    modalControler.ShowModalDialog('비밀번호 변경', msg, '', '확인', (val) => { })
                }
            }).catch(err => {
                modalControler.ShowErrorToast('비밀번호 변경에 실패했습니다.')
            })
        }



        return {
            ...toRefs(state),
            canSubmit,
            checkCurrentPassword,
            checkPassword,
            checkRePassword,
            changePassword,
        }

    }
}

</script>

<style scoped lang="scss"></style>