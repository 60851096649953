import { assign } from 'lodash';
import httpClient from './httpClient';

export default {
    getCommunityList(params) {
        return httpClient.get(`/community/posts${params}`)
    },
    writeCommunity(params) {
        return httpClient.post(`/community/post`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    getCommunityDetail(postId) {
        return httpClient.get(`/community/post/${postId}`)
    },
    updateCommunity(postId, params) {
        return httpClient.put(`/community/post/${postId}`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    deleteCommunity(postId) {
        return httpClient.delete(`/community/post/${postId}`)
    },
    writeCommunityReply(postId, params) {
        return httpClient.post(`/community/post/${postId}/reply`, params)
    },
    updateCommunityReply(postId, replyId, params) {
        return httpClient.put(`/community/post/${postId}/reply/${replyId}`, params)
    },
    deleteCommunityReply(postId, replyId) {
        return httpClient.delete(`/community/post/${postId}/reply/${replyId}`)
    },
    updateLikePost(postId) {
        return httpClient.put(`/community/post/${postId}/like`)
    },
    getAssignmentCommunityList(assignmentId, params) {
        return httpClient.get(`/community/assignment/${assignmentId}`, params);
    },
    writeAssignmentCommunity(assignmentId, params) {
        return httpClient.post(`/community/assignment/${assignmentId}/post`, params);
    },

    getTaskCommunityList(assignmentId, params) {
        return httpClient.get(`/community/assignment/${assignmentId}${params}`)
    },
    // 과제 커무니티
    writeTaskCommunityWrite(assignmentId, params) {
        return httpClient.post(`/community/assignment/${assignmentId}/post`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    updateTaskCommunity(assignmentId, postId, params) {
        return httpClient.put(`/community/assignment/${assignmentId}/post/${postId}`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    deleteTaskCommunity(assignmentId, postId) {
        return httpClient.delete(`/community/assigmnemt/${assignmentId}/post/${postId}`)
    },

    // 리그
    getLeagueCommunityList(leagueId, params) {
        return httpClient.get(`/community/league/${leagueId}${params}`)
    },
    writeLeagueCommunityWrite(leagueId, params) {
        return httpClient.post(`/community/league/${leagueId}/post`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    updateLeagueCommunityWrite(leagueId, postId, params) {
        return httpClient.put(`/community/post/${postId}`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
    },    
    getLeagueCommunityDetail(postId) {
        return httpClient.get(`/community/post/${postId}`)
    },
    deleteLeagueCommunity(leagueId, postId) {
        return httpClient.delete(`/community/post/${postId}`)
    },
    writeLeagueCommunityReply(leagueId, postId, params) {
        return httpClient.post(`/community/post/${postId}/reply`, params)
    },
    updateLeagueCommunityReply(leagueId, postId, replyId, params) {
        return httpClient.put(`/community/post/${postId}/reply/${replyId}`, params)
    },
    deleteLeagueCommunityReply(leagueId, postId, replyId) {
        return httpClient.delete(`/community/post/${postId}/reply/${replyId}`)
    },

       
}