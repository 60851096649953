<template>
  <section class="task-m-section-3">
    <div class="total-container">
      <article class="profile">
        <div class="greeting__wrapper">
          <div class="greeting-name">
            <span class="greeting-name_nickname">{{ accountInfo.nickname }}</span>님,<br />안녕하세요!
          </div>
          <p class="greeting-info">
            {{ accountInfo.email }}
          </p>
          <p class="greeting-info">
            {{ accountInfo.dateofbirth }}
          </p>
        </div>

        <div class="profile__img-wrapper">
          <!-- <img
            :src="(previewURL) ? previewURL : require('@/assets/images/profile-large.svg')" alt="" /> -->
          <v-row>
            <v-btn class="profile__img-wrapper-btn" outlined color="#343B94"
              @click="$router.goCompetitionPages('CompetitionDetailProfile', $route.params.competitionId)">개인정보변경</v-btn>
          </v-row>
        </div>

      </article>

      <article class="attend">
        <h2>참가신청 확인</h2>
        <div class="attend__wrapper">
          <p v-if="isJoin === 0" class="left">대회에 먼저 참여해 주세요.</p>
          <p v-if="isJoin === 1 || isJoin === 2" class="left">팀 리더가 과제참여를 해주세요.</p>
          <p v-if="isJoin === 3" class="left">과제 참여 완료되었습니다!</p>
          <p v-if="isJoin === 3" class="right attend-status_badge">참가신청 완료</p>
          <p v-if="isJoin === 0" class="right attend-status_badge-step1 chip-error">대회 미참여</p>
          <p v-if="isJoin === 1 || isJoin === 2" class="right attend-status_badge-step2">과제 미참여</p>
        </div>
      </article>
      <article class="notice" v-if="taskRecentNotice.length">
        <div class="notice__header">
          <h2>새로운 공지사항</h2>
        </div>
        <a class="notice__wrapper" v-for="(notice, idx) in taskRecentNotice" :key="idx + '-notice'">
          <div class="left__container"
            @click="$router.goQnaDetails('TaskNoticeDetail', notice.id, $route.params.competitionId, $route.params.taskId)">
            <h3 class="left-title">{{ notice.title }}</h3>
            <p class="left-text">
              {{ utils.beforeDate(notice.createdtm) }}
            </p>
          </div>
        </a>
      </article>
      <article class="team" v-if="teamData.leader">
        <div class="team__header">
          <h2>팀 구성</h2>
        </div>
        <div class="team-top__container">
          <div class="team-top__img">
            <div class="wrapper">
              <div class="team-leader">
                <div class="user">
                  <div class="user_wrapper_left">
                    <img
                      :src="(teamData.leaderProfileImg) ? teamData.leaderProfileImg : require('@/assets/images/img-profile-blue.svg')"
                      alt="" class="user-img" />
                  </div>
                  <div class="user_wrapper_right">
                    <strong>팀 리더</strong>
                    <p class="username_txt">{{ teamData.leader }}</p>
                  </div>
                </div>

              </div>
              <div class="team-member">
                <div class="user" v-for="(m, idx) in teamData.teamuser" :key="idx">
                  <img :src="(m.PROFILEIMG) ? m.PROFILEIMG : require('@/assets/images/img-profile-blue.svg')" alt=""
                    class="user-img" />
                  <p class="username_txt">{{ m.NICKNAME }}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="team-top__wrapper">
            <div class="row">
              <div class="item">
                <dt>팀명</dt>
                <dd>{{ teamData.teamname }}</dd>
              </div>
              <div class="item">
                <dt>팀 인원</dt>
                <dd><span>{{ teamMembersCnt + 1 }}명</span>/{{ memberLimit }}명</dd>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <dt>팀 리더</dt>
                <dd>{{ teamData.leader }}</dd>
              </div>
              <div class="item">
                <dt>승인 상태</dt>
                <dd v-if="teamData.status === 'JOIN'">승인대기</dd>
                <dd v-else-if="teamData.status === 'PASS'">승인완료</dd>
              </div>
            </div>
          </div>
        </div>
        <div class="team-bottom__container">
          <div class="team-bottom__category">
            <h2>참여중인 과제</h2>
          </div>
          <template v-if="myTaskList.length">
            <div class="team-bottom__box" v-for="(t, idx) in myTaskList" :key="'team-' + idx" @click="goToMyTask(t)">
              <div class="top-box__header">
                <h4>{{ t.assignmentname }}</h4>
              </div>
              <div class="top-box__container">
                <div class="box__container">
                  <div class="box__wrapper">
                    <div class="row">
                      <div class="item">
                        <p class="item-title">순위</p>
                        <p class="item-blue item-text">{{ t.ranking }}위</p>
                      </div>
                      <div class="item">
                        <p class="item-title">퍼센티지</p>
                        <p class="item-text">{{ t.percentage }}%</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="item">
                        <p class="item-title">제출 수</p>
                        <p class="item-text">{{ t.submitcount }}회</p>
                      </div>
                      <div class="item">
                        <p class="item-title">최종제출</p>
                        <p class="item-text">{{ t.submitdtm }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="team-bottom__assignment">
            <p class="assignment-text">
              <span>
                <b-icon icon="exclamation" scale="1"></b-icon>
                참여중인 과제가없습니다.
              </span>
            </p>
          </div>
        </div>
        <v-btn class="task-m-section-3__btn--faq" @click="toMenualFAQ">
          매뉴얼 FAQ 바로가기
        </v-btn>
      </article>
    </div>
  </section>
</template>

<script>
import { BIcon } from 'bootstrap-vue'
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import moment from 'moment'
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import Authenticate from '@/lib/api/Authenticate';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';


export default {
  name: 'MySpaceMobile',
  components: {
    BIcon,
  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = tokenStore()
    const profileEl = ref(null);
    const state = reactive({
      summaryMobile: null,
      myTaskList: [],
      teamData: {},
      teamMembersCnt: 0,
      profileImg: [],
      previewURL: (store.getAccountInfo.profileimg) ? store.getAccountInfo.profileimg : null,
      profile: {},
      isJoin: 0,
      memberLimit: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        showNotice: 1,
      },
      taskRecentNotice: [],
    });

    const accountInfo = computed(() => {
      return store.getAccountInfo
    })

    // const participants = computed(() => {
    //   return (props.isJoin !== 0) ? true : false
    // })


      onMounted(() => {
        // My Space 에서만 위 summary 가리기
        // state.summaryMobile = document.querySelector(".section-2__header--m");
        // state.summaryMobile.style.display = "none";
        getTaskDetailInfo()
        getMyTeam()
        getMyTaskList()
        // getTaskQnaRecent()
      });

    onUnmounted(() => {
      // unmount 시 다시 summary 보이게
      // state.summaryMobile.style.display = "flex";
    });

    const getMyTeam = () => {
      let params = {
        competitionId: route.params.competitionId
      }
      CompetitionApi.getMyTeam(utils.makeParams(params)).then(response => {
        if (!response.data.error) {
          state.teamData = response.data.result
          state.teamMembersCnt = response.data.result.teamuser.length
        } else {
          if (response.data.error_code === 7500) {
            // Do nothing
          }
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {

      })
    }

    const getMyTaskList = () => {
      let params = {
        pageSize: 5,
        pageNo: 1,
        status: 'ing'
      }
      TaskApi.getAssignmentMyList(utils.makeParams(params)).then(response => {
        if (response.data.http_status_code === 200) {
          state.myTaskList = response.data.result.data
        }
      })
    }

    const goToMyTask = async (t) => {
      // await router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)
      location.href = `/competition/detail/${t.competitionId}/task/${t.assignmentId}/taskInfo`
    }

    const updateProfileImg = (e, data) => {
      if (data && data.size > state.profileImgMaxSize) {
        profileEl.value.reset()
        modalControler.ShowErrorToast('프로필 이미지 최대 크기는 5MB 입니다')
      } else {
        state.previewURL = URL.createObjectURL(e)

        let formData = new FormData()
        formData.append('files', state.profileImg)
        Authenticate.updateProfile(formData).then(response => {
        }).catch(err => {
          modalControler.ShowErrorToast('profile update 에러')
        })
      }
    }

    const toMenualFAQ = () => {
      window.open("https://aspiring-scene-da1.notion.site/AI-CONNECT-ca92fda2a7a94921997c0ea5bddcedf5")
    }

    const getTaskDetailInfo = () => {
      modalControler.ShowLoading()
      TaskApi.getAssignmentDetailInfo(utils.makeParams({ assignmentId: route.params.taskId })).then(response => {
        if (!response.data.error) {
          state.isJoin = response.data.result.isjoin
          state.memberLimit = response.data.result.maxteamperson
        }
      }).catch(err => {
        modalControler.ShowErrorToast(err.response.data.error_detail)
        router.goCompetitionPages('CompetitionDetail', route.params.competitionId)
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

      const getTaskQnaRecent = () => {
          let searchOptions = utils.getPage(state.options)
          // TaskApi.getAssignmentQnaList(route.params.taskId, searchOptions).then(response => {
          //     if (!response.data.error) {
          //         const now = moment()
          //         state.taskRecentNotice = response.data.result.data.filter(v => v.isnotice === 1 && v.isopen === 1 && (now.diff(v.createdtm, 'days') <= state.noticeRange))
          //     }
          // }).catch(err => {
          //     console.log(err)
          // })
      }      


    return {
      ...toRefs(state),
      utils,
      props,
      store,
      accountInfo,
      goToMyTask,
      toMenualFAQ,
    }

  }

}
</script>

<style scoped lang="scss">
#aa {
  display: none;
}
</style>