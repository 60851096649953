<template>
    <article class="section-2__content">
        <BoardEditView 
          v-if="!isLoading"
          editorTitle="코드 공유" 
          :mode="mode" 
          :contentsTitle="contentsTitle"
          :contents="contents"
          :attachment="attachment"
          :isNotice="isNotice"
          :isOpen="isOpen"
          @writeQnA="writeQnA"
          @updateQnA="updateQnA"          
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from "@/lib/api/Task";
import modalControler from '@/helper/modalControler';
import BoardEditView from "@/components/BoardEditView"


export default {
    name: 'TaskCodeShareEdit',
    components: {
        BoardEditView
    },
    setup(props, context) {
      const route = context.root.$route
      const router = context.root.$router
      const state = reactive({
        contentsTitle: '',
        contents: '',
        attachment: [],
        instances: [],
        qnaData: {},
        options: {
            page: 1,
            itemsPerPage: 10
        },     
        isNotice: false,
        isOpen: true,
        mode: 'write',
        isLoading: true,         
      });

      onMounted(async () => {
        if (route.query.type === 'update') {
          state.isLoading = true
          state.mode = 'update'
          await getTaskCodeShareDetail()
        } else {
          state.isLoading = false
        }
      });

      const getTaskCodeShareDetail = () => {
        let params = {
          Info: route.params.qnaId,
          // page: state.options.page,
          // itemsPerPage: state.options.itemsPerPage          
        }
        modalControler.ShowLoading()
        TaskApi.getCodeshareDetail(utils.makeParams(params)).then(async response => {
          if (!response.data.error) {
            state.qnaData = response.data.result.data
            // 첨부파일 넣기 
            if (state.qnaData.files) {
              state.qnaData.files.forEach(async file => {
                await getBlobFromUrl(file.url, file.name).then(file => {
                  state.attachment.push(file)
                })
              })
            }            

            /* 
              for QnA update
            */
            state.contentsTitle = state.qnaData.title
            state.contents = state.qnaData.content
            // state.attachment = state.qnaData.files
            state.isNotice = state.qnaData.isnotice
            state.isopen = (state.qnaData.isopen === 1) ? true : false
            state.isLoading = false     
          }
        }).catch(err => {

        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const writeQnA = (params, files) => {
        const formData = new FormData()
        formData.append('codeshareInfo', JSON.stringify(params))
        formData.append('fileList', files)  
        // files.forEach(file => {
        // })
        modalControler.ShowLoading()
        TaskApi.writeCodeshare(formData).then(response => {
          router.goTaskPages('TaskCodeShare', route.params.taskId, route.params.competitionId)
          // TODO : success alarm

        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const updateQnA = (params, files) => {
        const formData = new FormData()
        formData.append('codeshareInfo', JSON.stringify(params))
        if (files && files.length && files.length >= 1) {
          formData.append('fileList', files[0])
        } else {
          formData.append('fileList', files)
        }
        // files.forEach(file => {
        // })
        modalControler.ShowLoading()
        TaskApi.updateCodeshare(formData).then(response => {
          // TODO : success alarm
          router.goTaskPages('TaskCodeShare', route.params.taskId, route.params.competitionId)
        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const getBlobFromUrl = async (url, name) => {
        modalControler.ShowLoading()
        const response = await fetch(url)
        const data = await response.blob()
        const file = new File([data], name, { type: name.split('.').pop() });
        processFile(file).then((dataURL) => {
          state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
        });
        modalControler.HideLoading()
        return file
      }      

      const processFile = async (file) => {
        return await new Promise((resolve) => {
          let reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })
      }          

      return {
        ...toRefs(state),
        writeQnA,
        updateQnA
      }



    }
}
</script>
