<template>
  <div class="coding-main-wrap bg-contents-wrap">
    <div class="primary-section">
      <header class="page-header__section primary-section">
        <div class="page-header__inner">
          <div class="page-header__title-box">
            <h2 class="page-header__title">AI-bility Test</h2>
            <p class="page-header__sub-text">
              국내 No.1 AI Competition Platform AI 커넥트가 만든<br />
              데이터 과학자, AI 개발자를 위한 가장 진화한 역량진단 테스트
            </p>
            <div class="page-header__btns">
              <a @click="scrollToCustomer" class="btn btn-medium btn-header-primary"><strong>일반 고객용</strong></a>
              <a @click="scrollToEnterprise" class="btn btn-medium btn-header-secondary"><strong>기업 고객용</strong></a>
            </div>
          </div>
        </div>
      </header>
      <section class="coding-intro-section">
        <div class="content-inner">
        <header class="content-section__header content-section--center mb--0">
            <h2 class="content-section__title content-section__title--white">
               AI 코딩역량 인증시험 AI-bility Test 를 소개합니다.
            </h2>
        </header>
          <div class="coding-intro-section__lottie">
            <lottie :options="defaultOptions" :height="'100%'
              " :width="'100%'" v-on:animCreated="handleAnimation" />
          </div>
        </div>
      </section>
    </div>
    <section class="large-content-section">
      <div class="content-inner">
        <header class="large-content-section__header content-section--center">
          <h2 class="large-content-section__title">
            기업의 AI 혁신을 실현하는 Data Scientist<br />
            AI CONNECT Coding Test 로 AI 전문가로서의 역량을 확인하세요.
          </h2>
        </header>
        <div class="box__items">
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--computer"><span class="blind">컴퓨터</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
                  Data Scientist · ML Engineer<br/>
          맞춤형 역량 검증 코딩테스트
              </strong>
              <p class="box__text">
                AI 전문인력의 역량을 확인할 수 없는 일반 코딩테스트와 달리
                AI 실무 역량 검증을 위한 국내 유일 AI 코딩 테스트
              </p>
            </div>
          </div>
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--model"><span class="blind">모델</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
               AI 전문가 마인즈앤컴퍼니가<br/>
  직접 만든 문제 구성과 평가 지표
              </strong>
              <p class="box__text">
                5년 간 100개 이상의 AI 프로젝트를 수행한 마인즈앤컴퍼니와
                국내 최대 규모의 AI 경진대회를 수행하는 AI CONNECT가
                수많은 데이터와 모델링 Task의 경험으로 문제를 출제
              </p>
            </div>
          </div>
          <div class="box__item">
            <div class="box__img-box">
              <span class="icon icon__size--400 icon--ai"><span class="blind">ai</span></span>
            </div>
            <div class="box__text-box">
              <strong class="box__small-title">
               응시자의 AI 기술 이해도 및<br/>
  현업 실무 역량을 종합적으로 평가
              </strong>
              <p class="box__text">
                기업 내부에 AI 전문가가 없더라도 지원자의 역량을
                구체적으로 판단할 수 있도록 AI 전문가가 작성한 리포트 제공
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="large-content-section bg-gray">
      <div class="content-inner">
        <header class="large-content-section__header content-section--center">
          <h2 class="large-content-section__title ">
            일반 코딩테스트로는 확인할 수 없는 AI 전문 인력의 역량,<br />
            AI 전문 역량 검증 테스트가 필요합니다
          </h2>
          <p class="large-content-section__text">
            AI bility Test는 기본적인 코딩 실력만 검증하는 일반 코딩테스트와 다릅니다.<br />
            현업에서 요구하는 AI 프로젝트 시 필요한 역량을 검증할 수 있도록 정확한 기준과 전문가의 평가를 바탕으로 설계되었습니다.
          </p>
        </header>
        <figure>
          <table class="line-table">
            <colgroup>
              <col style="width:20% ;">
              <col style="width:30% ;">
              <col style="width:25% ;">
              <col style="width:25% ;">
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>일반 개발 코딩 테스트</th>
                <th>AI bility Test</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowspan="4">프로그래밍 역량</th>
                <th>파이썬 자료형 및 연산</th>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>파이썬 기본 패키지</th>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>프로그래밍 로직</th>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>알고리즘 이해도 </th>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th rowspan="3">데이터 처리 역량</th>
                <th>데이터 I/O</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>데이터 변환</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>데이터 정제</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th rowspan="4">모델링 역량</th>
                <th>모델 아키텍처 구현</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>학습 코드 구현</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>ML 모델링</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
              <tr>
                <th>학습 자동화·최적화</th>
                <td>
                  <div class="closed">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-closed"><icon-closed /></icon-base>
                  </div>
                </td>
                <td>
                  <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                    icon-name="icon-check"><icon-check /></icon-base>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
      </div>
    </section>
    <section id="customer-section" class="large-content-section">
              <div class="content-inner">
                <header class="large-content-section__header content-section--center">
                  <div class="large-content-section__sub-title">AI 분야 취업을 희망하는 응시자라면?</div>
                  <h2 class="large-content-section__title">
                    AI-bility Test 응시하고 AI CONNECT 인재풀에 등록해보세요!
                  </h2>
                  <p class="large-content-section__text">
                    데이터 처리, 모델링 등 일반 코딩테스트로는 평가할 수 없는<br />
                    AI 역량을 단기간 내에 객관적으로 증명할 수 있습니다.
                  </p>
                </header>
                <div class="content-graph">
                  <header class="content-graph__header content-graph__left">
                    <h2 class="content-graph__title">
                      AI CONNECT 인재풀 등록
                    </h2>
                    <p class="content-section__text">
                   응시자의 시험 결과, 경진대회 성적 등의 내용을 토대로 <br>
  사전동의한 분에 한해 AI CONNECT 의 제휴 기업이 관련 인재 채용 시 <br>
  인재 추천을 진행하는 채용매칭 서비스입니다.
                    </p>
                  </header>
                  <div class="content-graph__right">
                   <img src="../../assets/images/img-landing-01.png" alt="인재풀" />
                  </div>
                </div>
                <div class="content-graph">
                  <header class="content-graph__header content-graph__left">
                    <h2 class="content-graph__title">
                      시험 결과 리포트 제공
                    </h2>
                    <p class="content-section__text">
                      각 문제별 어떤 역량을 평가한 문항인지 상세한 설명과 함께
                      응시자가 취득한 점수와 응시자 전체 평균 점수를 비교하여 확인할 수 있는 리포트를 발급해드립니다.
                    </p>
                    <div class="mt--12">
                      <a class="content-header__link" target="_blank" href="https://aiconnect.kr/competition/news/69">
                        2023년 AI-bility test 결과 확인
                        <span class="icon">
                          <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                        </span>
                      </a>

                    </div>
                  </header>
                    <div class="content-graph__right">
                       <img src="../../assets/images/img-landing-02.png" alt="리포트" />
                    </div>
                </div>
                <div class="content-graph">
                  <header class="content-graph__header content-graph__left">
                    <h2 class="content-graph__title">
                      수료 자격증 발급
                    </h2>
                    <p class="content-section__text">
                      수료 기준을 통과한 응시자의 경우 마인즈앤컴퍼니 명의의 공인 자격증을 발급해드립니다.
                      취업/진학 시 자신의 AI 역량을 증명하실 때 인증 용도로 활용하실 것은 권장드립니다.<br />
                      AI-bility Test 자격증은 등록 민간자격으로서 효력을 가집니다.<br />
                      <span class="mt--12 guide-text guide-text--primary">등록번호: 2023-005268</span>
                    </p>
                  </header>
                  <div class="content-graph__right">
                    <img src="../../assets/images/img-landing-03.png" alt="수료증" />
                  </div>
                </div>
                <div class="btn-inline--section">
                  <div class="btn-inline btn-inline--center">
                    <a class="btn btn-large btn-primary" href="https://aiconnect.kr/competition/detail/238" target="_blank">
                      <strong>AI-bility Test 응시 대기명단 접수</strong>
                    </a>
                    <div class="mt--24 guide-text guide-text--primary"> 
                      <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                                icon-name="icon-info"><icon-info /></icon-base>
                                대기명단에 접수해주시면, AI-bility Test 정기 시험이 열릴 때 가장 먼저 알림을 보내드립니다.
                    </div>
                  </div>
                </div>
              </div>
           </section>
            <section id="enterprise-section" class="large-content-section bg-gray">
            <div class="content-inner">
              <header class="large-content-section__header">
                <div class="large-content-section__sub-title">AI 인재 채용과 육성을 담당하는 기업 고객이라면?</div>
                <h2 class="large-content-section__title">AI-bility Test for Enterprise</h2>
                <p class="large-content-section__text">
                  AI-bility Test는 기업고객을 위해서도 제공됩니다.<br />
                  기업의 인재 채용 및 내부인력 교육 시 AI-bility Test를 사용해보세요.</p>
              </header>
              <article class="content-section">
                <div class="content-graph">
                  <div class="content-section__header content-graph__left">
                      <h3 class="content-graph__title">
                          객관적·체계적 AI 실무 역량 진단
                      </h3>
                      <p class="content-section__text">우리 회사 맞춤형 AI 인재 선발과 평가를 위해 실제 현업 과제를 중심으로 필요한
                          역량만을 정확하게 진단할 수 있도록 문제 구성부터 평가, 채점, 운영까지 A to Z 로 한번에 해결할 수 있습니다.
                      </p>
                  </div>
                  <div class="content-graph__right">
                  <div class="visual-circle--area">
                    <div class="visual-circle">
                      <div class="box__text-box">
                          <strong class="visual-circle__title">
                          채용 및 평가
                        </strong>
                        <p class="visual-circle__text">
                          AI 인재 선발을 위한 서류 전형 단계 또는
                          사내 직원 인사평가 자료로 활용
                        </p>
                      </div>
                    </div>
                    <div class="visual-circle">
                      <div class="box__text-box">
                        <strong class="visual-circle__title">
                          사내 AI 교육
                        </strong>
                        <p class="visual-circle__text">
                          사내 AI 교육 커리큘럼 내 교육생의 이해도를 확인하기 위한 퀴즈나 평가로 활용
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </article>
              <article class="content-section">
                <header class="coding-content-blcok__header">
                  <h2 class="coding-content-blcok__title">맞춤형 서비스 제공 </h2>
                </header>
                <div class="box__items mb--44">
                  <div class="box__item box--line-gray">
                      <div class="box-badge__area">
                        <div class="box-badge box-badge--black">Basic Service</div>
                      </div>
                    <div class="box__icon-box">
                      <span class="icon icon__size--300 icon--algorithm"><span class="blind">알고리즘</span></span>
                    </div>
                    <div class="box__text-box">
                      <strong class="box__small-title">
                        공신력있는 문제 개발과 평가
                      </strong>
                      <p class="box__text">
                        국내 최고 AI 교육기관의
                        AI 전문가, Kaggle Master 와
                        공동 협업하여 문제 및 평가지표 개발
                      </p>
                    </div>
                  </div>
                  <div class="box__item box--line-gray">
                    <div class="box-badge__area">
                        <div class="box-badge box-badge--black">Basic Service</div>
                    </div>
                    <div class="box__icon-box">
                      <span class="icon icon__size--300 icon--multiple"><span class="blind">객관식</span></span>
                    </div>
                    <div class="box__text-box">
                      <strong class="box__small-title">
                        다양한 문제 유형
                      </strong>
                      <p class="box__text">
                        객관식/ 주관식/ 프로그래밍/
                        AI CONNECT 와 연계한 모의경진대회 등
                        다양한 유형으로 문제 구성하여
                        각 레벨별 맞춤형 테스트 제작
                      </p>
                    </div>
                  </div>
                   <div class="box__item box--line-gray">
                    <div class="box-badge__area">
                        <div class="box-badge box-badge--black">Basic Service</div>
                    </div>
                    <div class="box__icon-box">
                      <span class="icon icon__size--300 icon--server"><span class="blind">서버</span></span>
                    </div>
                    <div class="box__text-box">
                      <strong class="box__small-title">
                        안정적인 서버 제공 및 운영 지원
                      </strong>
                      <p class="box__text">
                        누적 대회참여 인원 수 10,000명 이상의
                        AI CONNECT 의 안정적인 서버 활용
                        <span class="guide-text">(응시자 개별 컨테이너 환경 제공)</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="box__items ct-right-left">
                 <div class="box__item box--line-primary">
                  <div class="box-badge__area">
                      <div class="box-badge box-badge--primary">Custom Service</div>
                  </div>
                    <div class="box__icon-box">
                      <span class="icon icon__size--300 icon--task"><span class="blind">문재
                          개발</span></span>
                    </div>
                    <div class="box__text-box">
                      <strong class="box__small-title">
                        맞춤형 문제 개발
                      </strong>
                      <p class="box__text">
                        실제 현업에서 사용하는 Data type, Framework 등을 반영하여 필요한 역량 / 스킬 / 태스크만 평가
                      </p>
                    </div>
                  </div>
                  <div class="box__item box--line-primary">
                    <div class="box-badge__area">
                        <div class="box-badge box-badge--primary">Custom Service</div>
                    </div>
                    <div class="box__icon-box">
                      <span class="icon icon__size--300 icon--evaluation"><span class="blind">평가</span></span>
                    </div>
                    <div class="box__text-box">
                      <strong class="box__small-title">
                        전문가 평가
                      </strong>
                      <p class="box__text">
                        상세한 코드 검증 (실시간 코드 검증) 및 AI 전문가의 수기 평가/해석으로 응시자별 구체적인 정성 평가 추가
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <article class="content-section">
              <div class="content-inner">
                <header class="coding-content-blcok__header">
                  <h2 class="coding-content-blcok__title">
                    문제 개발 예시
                  </h2>
                </header>
                <section class="test-content__section swiper__banner  hover-btn swiper-unset">
                  <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
                    <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                      icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                  </button>
                  <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                    <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                      icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                  </button>
                  <div v-swiper:slideSwiper="swiperOption">
                    <div class="swiper-wrapper" ref="swiperWrapper">
                      <swiper-slide>
                        <div class="test-content__item">
                          <header class="test-content__header">
                            <div class="test-content__icon">
                              <span class="icon icon__size--200 icon--data-handling">
                                <span class="blind">데이터 핸들링</span>
                              </span>
                            </div>
                            <h3 class="test-content__title">데이터 핸들링</h3>
                          </header>

                          <p class="test-content__text">
                            Object Detection(OD)은 이미지 내의 객체의 위치와 종류를 판별하는 computer vision
                            태스크입니다.<br />
                            OD 태스크의 데이터 형식에는
                            여러가지가 있고, 모델마다 이 중 하나를 주로 사용합니다. 대표적인 형식으로는 COCO, YOLO, Pascal VOC 등이
                            있습니다.<br />
                            <br />
                            Pascal VOC 형식 xml 파일들을 읽어 COCO로 변환해 dictionary로 반환하는 코드를 작성하세요.
                          </p>
                        </div>
                      </swiper-slide>
                      <swiper-slide>
                        <div class="test-content__item">
                          <header class="test-content__header">
                            <div class="test-content__icon">
                              <span class="icon icon__size--200 icon--data-preprocessing">
                                <span class="blind">데이터 전처리</span>
                              </span>
                            </div>
                            <h3 class="test-content__title">데이터 전처리</h3>
                          </header>
                          <p class="test-content__text">
                            이미지 모델을 학습시킬 때 데이터에 다양한 변형을 가해 데이터 증강을 할 수 있습니다. <br />여러 augmentation
                            기법의
                            인자(cv2 기준)를 입력으로 받아 변형된 이미지를 반환하는 함수를 작성하세요.
                          </p>
                          <div class="list__box list__box--defualt mt--12">
                            <span class="list__title--small">함수 입력값</span>
                            <ul class="list">
                              <li class="list__item list__item--small"><strong class="mr--8">Rotate (중심
                                  기준)</strong>각도
                                (degree)</li>
                              <li class="list__item list__item--small"><strong class="mr--8">Flip</strong>flip()의
                                flipCode
                                기준. 단, -99의 경우 flip 없음</li>
                              <li class="list__item list__item--small"><strong class="mr--8">Blur</strong>blur()의
                                blurring kernel size</li>
                            </ul>
                          </div>
                        </div>
                      </swiper-slide>
                      <swiper-slide>
                        <div class="test-content__item">
                          <header class="test-content__header">
                            <div class="test-content__icon">
                              <span class="icon icon__size--200 icon--model-implementation">
                                <span class="blind">모델 구현</span>
                              </span>
                            </div>

                            <h3 class="test-content__title">모델 구현</h3>
                          </header>
                          <p class="test-content__text">
                            아래 이미지와 같은 구조를 가지는 모델을 torch.nn 모듈만 사용하여 구현하세요.</p>
                          <div class="mt--12 mb--16">
                            <img src="../../assets/images/img-ct-idea-model.png" alt="" />
                          </div>
                          <p class="test-content__text">
                            BERT 한국어 사전학습 모델이 주어졌을 때, 이를 사용하여 Classfication (Task) <span class="small-text">classification,
                              QA, MRC, NER 등 NLP 태스크로 변형
                              가능</span>에
                            fine tuning할 수 있는 모델로 변형하세요.
                          </p>
                        </div>
                      </swiper-slide>
                      <swiper-slide>
                        <div class="test-content__item">
                          <header class="test-content__header">
                            <div class="test-content__icon">
                              <span class="icon icon__size--200 icon--modelling">
                                <span class="blind">모델링</span>
                              </span>
                            </div>
                            <h3 class="test-content__title">모델링</h3>
                          </header>
                          <p class="test-content__text">
                            여러 각도에서 드론으로 촬영한 이미지 내의 도로 위 차량, 이륜차, 사람 등을 인식하는 Object Detection 태스크를
                            수행하세요.<br />
                            제공된 모든 데이터는 학습에 사용할 수 있고, 추론 과정은 모델 클래스 내의 inference 함수로 작성하여 제출하면 숨겨진
                            테스트 케이스의
                            결과가
                            제출됩니다.
                          </p>
                          <div class="list__box list__box--defualt mt--12">
                            <span class="list__title--small"> 데이터 상세 내용</span>
                            <ul class="list">
                              <li class="list__item list__item--small"><strong class="mr--8">Class</strong>person,
                                bicycle, car, motorcycle, bus,
                                truck</li>
                              <li class="list__item list__item--small"><strong class="mr--8">
                                  바운딩박스
                                  좌표체계</strong>[x_min, y_min, x_max, y_max]</li>
                              <li class="list__item list__item--small"><strong class="mr--8">추론 평가
                                  지표</strong> mAP@IoU=0.75
                              </li>
                            </ul>
                            <div class="mathematical">
                              <img src="../../assets/images/img-ct-mathematical.png" />
                            </div>
                          </div>
                        </div>
                      </swiper-slide>
                    </div>
                  </div>
                </section>
              </div>
            </article>

          </section>
           <section class="keyword-section">
              <div class="flow-keywords--section">
                <div class="flow-keywords">
                  <div class="flow-wrap keyword-items-01 keyword-items">
                    <span class="keyword-item keyword-item--type__01">
                      자료형/연산
                    </span>
                    <span class="keyword-item keyword-item--type__01">
                      numpy</span>
                    <span class="keyword-item keyword-item--type__01">tensor</span>
                    <span class="keyword-item keyword-item--type__01">dict</span>
                    <span class="keyword-item keyword-item--type__01">string</span>
                    <span class="keyword-item keyword-item--type__01">list</span>
                    <span class="keyword-item keyword-item--type__01">파이썬 활용</span>
                    <span class="keyword-item keyword-item--type__01">경로 관리</span>
                    <span class="keyword-item keyword-item--type__01">random</span>
                    <span class="keyword-item keyword-item--type__01">날짜·시간</span>
                    <span class="keyword-item keyword-item--type__01">object-oriented</span>
                  </div>
                  <div class="flow-wrap keyword-items-01 keyword-items">
                    <span class="keyword-item keyword-item--type__01">
                      자료형/연산
                    </span>
                    <span class="keyword-item keyword-item--type__01">
                      numpy</span>
                    <span class="keyword-item keyword-item--type__01">tensor</span>
                    <span class="keyword-item keyword-item--type__01">dict</span>
                    <span class="keyword-item keyword-item--type__01">string</span>
                    <span class="keyword-item keyword-item--type__01">list</span>
                    <span class="keyword-item keyword-item--type__01">파이썬 활용</span>
                    <span class="keyword-item keyword-item--type__01">경로 관리</span>
                    <span class="keyword-item keyword-item--type__01">random</span>
                    <span class="keyword-item keyword-item--type__01">날짜·시간</span>
                    <span class="keyword-item keyword-item--type__01">object-oriented</span>
                  </div>
                    <div class="flow-wrap keyword-items-01 keyword-items">
                      <span class="keyword-item keyword-item--type__01">
                        자료형/연산
                      </span>
                      <span class="keyword-item keyword-item--type__01">
                        numpy</span>
                      <span class="keyword-item keyword-item--type__01">tensor</span>
                      <span class="keyword-item keyword-item--type__01">dict</span>
                      <span class="keyword-item keyword-item--type__01">string</span>
                      <span class="keyword-item keyword-item--type__01">list</span>
                      <span class="keyword-item keyword-item--type__01">파이썬 활용</span>
                      <span class="keyword-item keyword-item--type__01">경로 관리</span>
                      <span class="keyword-item keyword-item--type__01">random</span>
                      <span class="keyword-item keyword-item--type__01">날짜·시간</span>
                      <span class="keyword-item keyword-item--type__01">object-oriented</span>
                    </div>
                </div>
                  <div class="flow-keywords">
                  <div class="flow-wrap keyword-items-02 keyword-items">
                    <span class="keyword-item keyword-item--type__01">데이터 변환</span>
                    <span class="keyword-item keyword-item--type__01">dataframe 처리</span>
                    <span class="keyword-item keyword-item--type__01">manipulation</span>
                    <span class="keyword-item keyword-item--type__01">transform</span>
                    <span class="keyword-item keyword-item--type__01">데이터 정제</span>
                      <span class="keyword-item keyword-item--type__01">augmentation</span>
                      <span class="keyword-item keyword-item--type__01">nlp embedding</span>
                      <span class="keyword-item keyword-item--type__01">결측치/이상치 처리</span>
                      <span class="keyword-item keyword-item--type__01">normalization</span>
                      <span class="keyword-item keyword-item--type__01">nlp tokenization</span>
                      <span class="keyword-item keyword-item--type__01">data imbalance</span>
                      <span class="keyword-item keyword-item--type__01">신호처리</span>
                      <span class="keyword-item keyword-item--type__01">vocab dictionary</span>
                      <span class="keyword-item keyword-item--type__01">데이터 분석</span>
                      <span class="keyword-item keyword-item--type__01">통계량 분석</span>
                      <span class="keyword-item keyword-item--type__01">시각화</span>
                      <span class="keyword-item keyword-item--type__01">PCA/차원축소</span>
                      <span class="keyword-item keyword-item--type__01">clustering</span>
                      <span class="keyword-item keyword-item--type__01">feature engineering</span>
                      <span class="keyword-item keyword-item--type__01">feature selection</span>
                  </div>
                    <div class="flow-wrap keyword-items-02 keyword-items">
                      <span class="keyword-item keyword-item--type__01">데이터 변환</span>
                      <span class="keyword-item keyword-item--type__01">dataframe 처리</span>
                      <span class="keyword-item keyword-item--type__01">manipulation</span>
                      <span class="keyword-item keyword-item--type__01">transform</span>
                      <span class="keyword-item keyword-item--type__01">데이터 정제</span>
                        <span class="keyword-item keyword-item--type__01">augmentation</span>
                        <span class="keyword-item keyword-item--type__01">nlp embedding</span>
                        <span class="keyword-item keyword-item--type__01">결측치/이상치 처리</span>
                        <span class="keyword-item keyword-item--type__01">normalization</span>
                        <span class="keyword-item keyword-item--type__01">nlp tokenization</span>
                        <span class="keyword-item keyword-item--type__01">data imbalance</span>
                        <span class="keyword-item keyword-item--type__01">신호처리</span>
                        <span class="keyword-item keyword-item--type__01">vocab dictionary</span>
                        <span class="keyword-item keyword-item--type__01">데이터 분석</span>
                        <span class="keyword-item keyword-item--type__01">통계량 분석</span>
                        <span class="keyword-item keyword-item--type__01">시각화</span>
                        <span class="keyword-item keyword-item--type__01">PCA/차원축소</span>
                        <span class="keyword-item keyword-item--type__01">clustering</span>
                        <span class="keyword-item keyword-item--type__01">feature engineering</span>
                        <span class="keyword-item keyword-item--type__01">feature selection</span>
                    </div>
                  </div>
                <div class="flow-keywords">
                  <div class="flow-wrap  keyword-items-03 keyword-items">
                    <span class="keyword-item keyword-item--type__01">학습 과정 구현</span>
                    <span class="keyword-item keyword-item--type__01">custom dataset</span>
                    <span class="keyword-item keyword-item--type__01">negative sampling</span>
                    <span class="keyword-item keyword-item--type__01">loss function</span>
                    <span class="keyword-item keyword-item--type__01">optimizer</span>
                    <span class="keyword-item keyword-item--type__01">backpropagation</span>
                    <span class="keyword-item keyword-item--type__01">metric</span>
                    <span class="keyword-item keyword-item--type__01">early stopping</span>
                    <span class="keyword-item keyword-item--type__01">scheduler</span>
                    <span class="keyword-item keyword-item--type__01">성능향상(실험)</span>
                    <span class="keyword-item keyword-item--type__01">ensemble</span>
                    <span class="keyword-item keyword-item--type__01">pipeline</span>
                    <span class="keyword-item keyword-item--type__01">네트워크 요소 구현</span>
                    <span class="keyword-item keyword-item--type__01">dense layer</span>
                    <span class="keyword-item keyword-item--type__01">convolution layer</span>
                    <span class="keyword-item keyword-item--type__01">recurrent layer</span>
                    <span class="keyword-item keyword-item--type__01">pooling</span>
                    <span class="keyword-item keyword-item--type__01">dropout</span>
                    <span class="keyword-item keyword-item--type__01">batch normalization</span>
                    <span class="keyword-item keyword-item--type__01">activation function</span>
                    <span class="keyword-item keyword-item--type__01">skip layer</span>
                    <span class="keyword-item keyword-item--type__01">모델 구현</span>
                    <span class="keyword-item keyword-item--type__01">ML</span>
                    <span class="keyword-item keyword-item--type__01">CNN</span>
                    <span class="keyword-item keyword-item--type__01">RNN</span>
                    <span class="keyword-item keyword-item--type__01">Transformer</span>
                    <span class="keyword-item keyword-item--type__01">Auto Encoder</span>
                    <span class="keyword-item keyword-item--type__01">Diffusion</span>
                    <span class="keyword-item keyword-item--type__01">GAN</span>
                    <span class="keyword-item keyword-item--type__01">AutoML</span>
                    <span class="keyword-item keyword-item--type__01">논문/도식 구현</span>
                    <span class="keyword-item keyword-item--type__01">XAI</span>
                    <span class="keyword-item keyword-item--type__01">학습 기법</span>
                    <span class="keyword-item keyword-item--type__01">transfer learning</span>
                    <span class="keyword-item keyword-item--type__01">self-supervised learning</span>
                    <span class="keyword-item keyword-item--type__01">few/one/zero-shot learning</span>
                    <span class="keyword-item keyword-item--type__01">reinforcemen learning</span>
                    <span class="keyword-item keyword-item--type__01">active learning</span>
                    <span class="keyword-item keyword-item--type__01">curriculum learning</span>
                    <span class="keyword-item keyword-item--type__01">multitask learning</span>
                    <span class="keyword-item keyword-item--type__01">PyTorch</span>
                  </div>
                   <div class="flow-wrap  keyword-items-03 keyword-items">
                      <span class="keyword-item keyword-item--type__01">학습 과정 구현</span>
                      <span class="keyword-item keyword-item--type__01">custom dataset</span>
                      <span class="keyword-item keyword-item--type__01">negative sampling</span>
                      <span class="keyword-item keyword-item--type__01">loss function</span>
                      <span class="keyword-item keyword-item--type__01">optimizer</span>
                      <span class="keyword-item keyword-item--type__01">backpropagation</span>
                      <span class="keyword-item keyword-item--type__01">metric</span>
                      <span class="keyword-item keyword-item--type__01">early stopping</span>
                      <span class="keyword-item keyword-item--type__01">scheduler</span>
                      <span class="keyword-item keyword-item--type__01">성능향상(실험)</span>
                      <span class="keyword-item keyword-item--type__01">ensemble</span>
                      <span class="keyword-item keyword-item--type__01">pipeline</span>
                      <span class="keyword-item keyword-item--type__01">네트워크 요소 구현</span>
                      <span class="keyword-item keyword-item--type__01">dense layer</span>
                      <span class="keyword-item keyword-item--type__01">convolution layer</span>
                      <span class="keyword-item keyword-item--type__01">recurrent layer</span>
                      <span class="keyword-item keyword-item--type__01">pooling</span>
                      <span class="keyword-item keyword-item--type__01">dropout</span>
                      <span class="keyword-item keyword-item--type__01">batch normalization</span>
                      <span class="keyword-item keyword-item--type__01">activation function</span>
                      <span class="keyword-item keyword-item--type__01">skip layer</span>
                      <span class="keyword-item keyword-item--type__01">모델 구현</span>
                      <span class="keyword-item keyword-item--type__01">ML</span>
                      <span class="keyword-item keyword-item--type__01">CNN</span>
                      <span class="keyword-item keyword-item--type__01">RNN</span>
                      <span class="keyword-item keyword-item--type__01">Transformer</span>
                      <span class="keyword-item keyword-item--type__01">Auto Encoder</span>
                      <span class="keyword-item keyword-item--type__01">Diffusion</span>
                      <span class="keyword-item keyword-item--type__01">GAN</span>
                      <span class="keyword-item keyword-item--type__01">AutoML</span>
                      <span class="keyword-item keyword-item--type__01">논문/도식 구현</span>
                      <span class="keyword-item keyword-item--type__01">XAI</span>
                      <span class="keyword-item keyword-item--type__01">학습 기법</span>
                      <span class="keyword-item keyword-item--type__01">transfer learning</span>
                      <span class="keyword-item keyword-item--type__01">self-supervised learning</span>
                      <span class="keyword-item keyword-item--type__01">few/one/zero-shot learning</span>
                      <span class="keyword-item keyword-item--type__01">reinforcemen learning</span>
                      <span class="keyword-item keyword-item--type__01">active learning</span>
                      <span class="keyword-item keyword-item--type__01">curriculum learning</span>
                      <span class="keyword-item keyword-item--type__01">multitask learning</span>
                      <span class="keyword-item keyword-item--type__01">PyTorch</span>
                    </div>
                </div>
              </div>
              </section>
          <section class="coding-contact-section  large-content-section" id="demo-form">
            <div class="medium-content-inner">
              <header class="large-content-section__header">
                <h2 class="large-content-section__title">기업 고객 무료 체험 프로모션</h2>
                <p class="large-content-section__text">
                  기업 고객을 대상으로 테스트 1회 무료 프로모션을 진행합니다.<br />
                  AI-bility Test 를 직접 체험해보세요!
                </p>
              </header>
              <div class="mb--24">
                <ul class="list list__box list__box--line ">
                  <li class="list__item">최대 10명까지 응시할 수 있으며, 응시자 1인당 개별 GPU 서버를 제공합니다.</li>
                  <li class="list__item">응시 기간은 협의하에 결정할 수 있으며, 응시 기간동안 시스템 모니터링 및 기술지원을 제공합니다.</li>
                </ul>
              </div>
              <div class="coding-contact-group">
                <div class="coding-contact-group__right">
                  <form>
                    <div class="content-group">
                      <v-app>
                        <div class="form-group form-group__detail">
                          <div class="form-group__title">
                            회사명<span class="essential">*</span>
                          </div>
                          <v-text-field v-model="formData.companyName" placeholder="회사명을 입력해주세요." class="common-form-control"
                            outlined></v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                          <div class="form-group__title">
                            담당자 이름<span class="essential">*</span>
                          </div>
                          <v-text-field v-model="formData.name" placeholder="담당자 이름을 입력해주세요" class="common-form-control"
                            outlined></v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                          <div class="form-group__title">
                            이메일 주소<span class="essential">*</span>
                          </div>
                          <v-text-field v-model="formData.email" placeholder="email@example.com" class="common-form-control"
                            outlined @blur="checkEmail(formData.email)" @keyup="onKeyup($event, 'email')"></v-text-field>
                        </div>
                        <div class="form-group">
                          <div class="form-group__title">
                            문의 내용
                          </div>
                          <div class="editor-box">
                            <div class="common-textarea">
                              <v-textarea v-model="formData.contents" rows="10" placeholder="내용을 입력해주세요. (최대 1500자)"
                                no-resize></v-textarea>
                            </div>
                          </div>
                        </div>

                        <div class="content-group__btn-area">
                          <div class="btn-inline btn-inline--center">
                            <v-btn class="btn btn-large btn-primary" @click="submitDemoForm">
                              <strong>프로모션 신청</strong>
                            </v-btn>
                          </div>
                        </div>
                      </v-app>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </section>

  </div>
</template>


<script>



import {
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
} from "@vue/composition-api";

// lottie
import lottie from "@/components/global/Lottie"
import animationData from '@/assets/json/header-ct.json';
import validator from "@/config/formValidator"
import LeagueApi from '@/lib/api/League'
import modalControler from '@/helper/modalControler';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "CodingTest",
  components: {
    lottie,     // lottie
    SwiperSlide,
  },

  setup(props, context) {
    const state = reactive({
      defaultOptions: { animationData: animationData },   // lottie option
      formData: {
        name: '',
        email: '',
        companyName: '',
        contents: ''
      },
      errorStatus: {
        emailErrMsg: ''
      },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-btn__middle__right',
          prevEl: '.swiper-btn__middle__left'
        }
      },
      interval: null,
      isEnterpriseCustomerTab: true
    });

    // onMounted(() => {
    //     //롤링 배너 복제본 생성
    //     let roller = document.querySelector('.roller');
    //     roller.id = 'roller1';

    //     let clone = roller.cloneNode(true);
    //     clone.id = 'roller2';
    //     document.querySelector('.wrap').appendChild(clone); //부착

    //     //원본, 복제본 배너 위치 지정
    //     document.querySelector('#roller1').style.left = '0px';
    //     // document.querySelector('#roller2').style.display = 'flex'
    //     document.querySelector('#roller2').style.left = document.querySelector('.roller div').offsetWidth + 'px';
    //     // document.querySelector('#roller2').style.left = '200%';

    //     //클래스 할당
    //     roller.classList.add('original');
    //     clone.classList.add('clone');


    // })
    const scrollToCustomer = () => {
      const targetDiv = document.querySelector('#customer-section');
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToEnterprise = () => {
      const targetDiv = document.querySelector('#enterprise-section');
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    };

    // lottie
    const handleAnimation = (anim) => {
      // this.anim = anim;
    };

    const checkEmail = (email) => {
      state.errorStatus.emailErrMsg = validator.emailValidator(email)
      if (state.errorStatus.emailErrMsg !== '') {
        return false
      }
      else {
        return true
      }
    }

    const onKeyup = (e, type) => {
      if (type === 'email') {
        checkEmail(state.formData.email)
      }
    }

    const enableSubmit = () => {
      if (!state.formData.name) {
        modalControler.ShowInfoToast('이름은 필수 입력사항입니다.')
        return false;
      }
      if (!state.formData.email) {
        modalControler.ShowInfoToast('이메일은 필수 입력사항입니다.')
        return false;
      }
      if (!checkEmail(state.formData.email)) {
        modalControler.ShowInfoToast('이메일 양식을 지켜주세요. ex) example@email.com')
        return false;
      }
      return true;
    }

    const submitDemoForm = () => {
      if (!enableSubmit()) {
        return;
      }
      LeagueApi.submitDemoForm(state.formData).then((response) => {
        modalControler.ShowSuccessToast('요청되었습니다.')
      }).catch((error) => {
        modalControler.ShowErrorToast('요청이 실패하였습니다.' + error)
      });
    };

    return {
      ...toRefs(state),
      handleAnimation,
      scrollToCustomer,
      scrollToEnterprise,
      onKeyup,
      checkEmail,
      submitDemoForm,
      enableSubmit,
    };
  },
};
</script>
