<template>
  <div class="main-wrap">
    <!--fixed main banner /-->
    <section class="main-banner__section">
      <div v-swiper:bannerSlider="swiperOption" class="swiper" ref="swiper">
        <div class="main-swiper-btns">
          <button type="button" class="swiper-btn-next" slot="button-next">
            <span class="material-icons-sharp">
              arrow_forward_ios
            </span>
          </button>
          <button type="button" class="swiper-btn-prev" slot="button-prev">
            <span class="material-icons-sharp">
              arrow_back_ios
            </span>
          </button>
        </div>
        <!-- Main Banner -->
        <div class="swiper-wrapper">
          <swiper-slide v-for="(c, idx) in mainBannerList" :key="idx">
            <div class="main-banner" v-bind:style="{ backgroundColor: c.backgroundColor }">
              <a v-bind:href="c.linkUrl" class="main-banner__link"
                v-bind:target="c.openLinkNewTab == true ? '_blank' : '_self'">
                <div class="main-banner__content">
                  <h2 class="main-banner__title" v-html="c.title"></h2>
                  <p class="main-banner__desc" v-html="c.description"></p>
                </div>
                <div class="main-banner__box">
                  <img v-bind:src="c.imgUrl" class="main-banner__img" />
                </div>
              </a>
            </div>
          </swiper-slide>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <hr />

    <header class="page-header__section">
      <div class="page-header__inner">
        <div class="page-header__title-box">
          <h2 class="page-header__title">AI League</h2>
          <p class="page-header__sub-text">언제든지 참여하여 실력을 확인하고 능력을 키울 수 있는 학습용 모의 경진대회입니다.
          </p>
        </div>
        <img src="../../assets/images/img-header-task.png" />
      </div>
    </header>

    <hr />

    <header class="page-header--line--left__section">
      <div class="content-inner">
        <h2 class="page-header--line__title">비밀번호 입력</h2>
        <ul class="list list__box list__box--line">
          <li class="list__item">개인정보 수정을 위해 본인 확인이 필요합니다.</li>
          <li class="list__item">비밀번호를 다시 한 번 입력해 주세요.</li>
        </ul>
      </div>
    </header>
    <hr />

    <header class="page-header--line__section">
      <span class="page-header--line__sub-title">AI CONNECT 이용 매뉴얼</span>
      <h2 class="page-header--line__title">GPU 매뉴얼 수정 테스트 </h2>
      <p class="page-header--line__sub-text">AI커넥트는 데이터에 관심 있는 사람들이 모이고,<br /> 전문가들과 함께 더 나은 기술발전을 향해
        나아갑니다.</p>
    </header>

    <hr />

    <div class="uikit-content-wrap">
      <section class="uikit-content-section">
        <div class="content-inner">
          <header class="uikit-content-section__header">
            <h2 class="uikit-content-section__title">
              layout - content section</h2>
          </header>
          <section class="large-content-section">
            <div class="content-inner">
              <header class="large-content-section__header">
                <span class="large-content-section__sub-title">large content section sub title</span>
                <h2 class="large-content-section__title">
                  large content section title</h2>
                <p class="large-content-section__text">
                  large content section text
                </p>
              </header>
            </div>
          </section>
          <section class="content-section--padding bg-gray">
            <div class="content-inner">
              <header class="content-section__header">
                <h2 class="content-section__title">
                  content section title</h2>
                <p class="content-section__text">
                  content section text
                </p>
              </header>
            </div>
          </section>
          <section class="content-group">
            <div class="content-inner">
              <header class="content-group__header">
                <h2 class="content-group__title">
                  content group title</h2>
                <p class="content-group__text">
                  content group title
                </p>
              </header>
            </div>
          </section>
          <article class="content-blocks">
            <div class="content-inner">
              <header class="content-blocks__header">
                <h4 class="content-blocks__title">
                  ccontent blocks title</h4>
              </header>
              <p class="content-blocks__text">
                content blocks text content blocks text content blocks text content blocks text content blocks text
              </p>
            </div>
          </article>
        </div>
      </section>



      <section class="main-content-section">
        <div class="content-inner">
          <header class="content-section__header">
            <h2 class="content-section__title">Buttons</h2>
          </header>
          <div class="btn-inline--center btn-inline--section">
            <button class="btn-primary btn-xsmall " type="button"><span class="icon-left">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />
              </span><strong>btn-primary</strong>
            </button>
            <button class="btn-primary btn-small" type="button"><span class="icon-left">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />
              </span><strong>btn-primary</strong></button>
            <button class="btn-primary btn-medium" type="button"><span class="icon-left">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />
              </span><strong>btn-primary</strong></button>
            <button class="btn-primary btn-large" type="button"><span class="icon-left">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />
              </span><strong>btn-primary</strong></button>
            <button class="btn-primary btn-large btn-full " type="button"><span class="icon-left">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />
              </span><strong>btn-primary</strong></button>
          </div>
          <div class="btn-inline--center btn-inline--section">
            <button class="btn-primary btn-xsmall" type="button"><strong>btn-primary</strong></button>
            <button class="btn-primary btn-small" type="button"><strong>btn-primary</strong></button>
            <button class="btn-primary btn-medium" type="button"><strong>btn-primary</strong></button>
            <button class="btn-primary btn-large" type="button"><strong>btn-primary</strong></button>
          </div>
          <div class="btn-inline--center btn-inline--section">
            <button class="btn-secondary btn-xsmall" type="button"><strong>btn-primary</strong></button>
            <button class="btn-secondary btn-small" type="button"><strong>btn-secondary</strong></button>
            <button class="btn-secondary btn-medium" type="button"><strong>btn-secondary</strong></button>
            <button class="btn-secondary btn-large" type="button"><strong>btn-secondary</strong></button>
          </div>
          <div class="btn-inline--center btn-inline--section">
            <button class="btn-tertiary btn-xsmall" type="button"><strong>btn-tertiary</strong></button>
            <button class="btn-tertiary btn-small" type="button"><strong>btn-tertiary</strong></button>
            <button class="btn-tertiary btn-medium" type="button"><strong>btn-tertiary</strong></button>
            <button class="btn-tertiary btn-large" type="button"><strong>btn-tertiary</strong></button>
          </div>
        </div>
      </section>

    </div>

  </div>
</template>
<script>
import {
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  ref,
} from "@vue/composition-api";
import moment from "moment";
import utils from "@/helper/utils";
import { tokenStore } from "@/stores/auth";
import CompetitionApi from "@/lib/api/Competition";
import BannerApi from "@/lib/api/Banner";
import modalControler from "@/helper/modalControler";
import NewsSwiper from "@/components/index/NewsSwiper";
import CompetitionSwiper from "@/components/index/CompetitionSwiper";
// import "swiper/swiper-bundle.css";
import 'swiper/css/swiper.css'

// import { Swiper as SwiperClass, Pagination, Navigation, ScrollBar, Autoplay } from 'swiper/swiper.esm.js'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// SwiperClass.use([Pagination, Navigation, ScrollBar, Autoplay])
// const { directive } = getAwesomeSwiper(SwiperClass)
// const { SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: "UiKit",
  components: {
    // SwiperSlide,
    // NewsSwiper,

  },
  directive: {
    // swiper: directive
  },
  setup(props, context) {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };

    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const swiper = ref(null);
    const state = reactive({
      competitionId: Number(route.params.competitionId),
      categories: [],
      myCompetition: [],
      competitionList: [],
      mainBannerList: [],
      doing: false,
      showingStatus: [2, 3, 4, 5], // OPEN : 2, APPLYING : 3, RUNNING : 4 , HOLDING: 5
      competition: 1,
      study: 2,
      recruitment: 3,
      options: {
        page: 1,
        itemsPerPage: 0,
        categoryId: 0,
      },
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 5000,     // bannder img slide time
          disableOnInteraction: false
        },
        initialSlide: 1,
      },
      interval: null,
      loadBannerList: false,

    });

    onMounted(() => {
      if (store.isAuthenticated) {
        getMyCompetitionList();
      }
      getMainBannerList();
      getCompetitionList();
    });

    onUnmounted(() => {
      clearInterval(state.interval);
    });

    const getMyCompetitionList = () => {
      state.myCompetition = [];
      CompetitionApi.getMyCompetitionList().then((response) => {
        if (!response.data.error) {
          state.myCompetition = response.data.result.filter(c => state.showingStatus.includes(c.status))
          state.myCompetition = sortCompetition(state.myCompetition)
        }
      });
    };

    const getMainBannerList = () => {
      state.mainBannerList = [];
      BannerApi.getMainBannerList().then((response) => {
        if (!response.data.error) {
          state.mainBannerList = response.data.result
          state.mainBannerList.forEach((currentElement, index, array) => {
            currentElement.backgroundColor = currentElement.backgroundColor != '' ? '#' + currentElement.backgroundColor : '';
          });
          swiper.value.swiper.params.loopedSlides = state.mainBannerList.length
        }
      });
    };

    const getCompetitionList = () => {
      modalControler.ShowLoading();
      CompetitionApi.getCompetitionList(utils.getPage(state.options))
        .then((response) => {
          const data = response.data.result;
          state.competitionList = data.data
          state.competitionList = state.competitionList.sort((a, b) => a.status - b.status);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          modalControler.HideLoading();
        });
    };

    const sortCompetition = (array) => {
      /*
        4(RUNNING) -> 2(OPEN) -> 5(HOLDING) -> 3(APPLYING) -> 6(FINALIZED) -> 1(CREATED)
      */
      // step 1. 4(RUNNING), 2(OPEN) 순으로
      array.sort((a, b) => {
        a = a.status
        b = b.status
        if (a > b) return -1
        if (a === b) return 0
        if (a < b) return 1
      })
      let tmp = []
      let sorted = []
      let finalized = []

      // step 2. 1(CREATED), 3(APPLYING), 5(HOLDING), 6(FINALIZED) 따로 뺀다
      sorted = array.filter(el => {
        if (el.status === 3 || el.status === 5) {
          tmp.push(el)
        } else if (el.status === 1 || el.status === 6) {     // created / finalzed
          finalized.push(el)
        } else {
          return el
        }
      })

      sorted = sorted.concat(tmp)    // sorting 된 상태로 merge
      sorted = sorted.concat(finalized)
      return sorted

    }

    const showOnlyDoing = () => { };

    const showKOSSA = () => {
      window.open("https://maicon.kr", "_blank");
    };
    const showAIPLAYGROUND = () => {
      window.open("https://ai-korea.kr/main/main.do", "_blank");
    };

    const next = () => {
      swiper.value.$swiper.slideNext();
    };

    const show403ErrorToast = (status) => {
      if (status === 2) {
        modalControler.ShowWarningToast("준비중입니다. 잠시만 기다려주세요!");
      }
      else {
        modalControler.ShowErrorToast("접근권한이 없는 페이지입니다.");
      }
    };

    return {
      swiper,
      onSwiper,
      onSlideChange,
      ...toRefs(state),
      utils,
      showOnlyDoing,
      moment,
      showKOSSA,
      showAIPLAYGROUND,
      show403ErrorToast,
    };
  },
};
</script>
<style scoped lang="scss">

</style>