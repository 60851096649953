<template>
    <svg class="feather feather-arrow-up-circle" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
        stroke-linejoin="round" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10" stroke-width="1" opacity="0.6" />
        <polyline points="16 12 12 8 8 12" stroke-width="2" />
        <line x1="12" x2="12" y1="16" y2="8" stroke-width="2" />
    </svg>
</template>

<script>
export default {};
</script>