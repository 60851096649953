<template>
    <section class="task-info-section">
        <article class="content-blocks" v-if="props.info.assignmentcontent">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">과제개요</h3>
            </header>
            <div ref="contentPreview" class="content-blocks__text ProseMirror">
                <div class="font-small" v-html="props.info.assignmentcontent"></div>
            </div>
        </article>
        <article class="content-blocks" v-if="props.info.assignmentqualification">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">제한사항</h3>
            </header>
            <div ref="qualificationPreview" class="content-blocks__text ProseMirror">
                <div class="font-small" v-html="props.info.assignmentqualification"></div>
            </div>
        </article>
        <article class="content-blocks" v-if="props.info.assignmentrating !== ''">
            <header class="content-blocks__header">
                <h3 class="content-blocks__title">평가지표</h3>
            </header>
            <div ref="ratingPreview" class="content-blocks__text ProseMirror">
                <div v-html="props.info.assignmentrating"></div>
            </div>
        </article>
        <article class="content-blocks"
            v-if="!props.info.assignmentcontent && !props.info.assignmentqualification && !props.info.assignmentrating">
            <div class="no-data">
                <p class="no-data__text">작성된 과제 설명이 없습니다.</p>
            </div>
        </article>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch, nextTick, onUpdated,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';


export default {
    name: 'TaskInfo',
    props: ['info'],
    setup(props, context) {
        const route = context.root.$route
        const contentPreview = ref(null)
        const qualificationPreview = ref(null)
        const ratingPreview = ref(null)
        const state = reactive({
            taskId: route.params.taskId,
            taskInfo: '',
        });

        watch(() => props.info.assignmentcontent, (nv, ov) => {
            assignmentKatexRender()
        })

        watch(() => props.info.assignmentqualification, (nv, ov) => {
            qualificationKatexRender()
        })

        watch(() => props.info.assignmentrating, (nv, ov) => {
            ratingKatexRender()
        })

        onMounted(async () => {
            nextTick(() => {
                try {
                    contentKatexRender()                    
                    qualificationKatexRender()
                    ratingKatexRender()
                } catch {

                }
            })
        });

        const contentKatexRender = () => {
            const previewDiv = contentPreview.value
            utils.katexRender(previewDiv)
        }

        const qualificationKatexRender = () => {
            const previewDiv = qualificationPreview.value
            utils.katexRender(previewDiv)
        }

        const ratingKatexRender = () => {
            const previewDiv = ratingPreview.value
            utils.katexRender(previewDiv)
        }

        return {
            props,
            utils,
            ...toRefs(state),
            contentPreview,
            qualificationPreview,
            ratingPreview,
        }
    }

}
</script>

<style scoped></style>