<template>
    <article class="section-2__content">
        <PasswordEdit />   
    </article>
</template>

<script>
import PasswordEdit from '@/components/user/PasswordEdit'


export default {
    components: {
        PasswordEdit
    }
}
</script>

<style scoped lang="scss">

</style>