import Vue from 'vue'
import store from '@/stores/modal'

const toastPosition = 'top-right'
const toastDuration = 3000

export default {
    ShowLoading: () => {
        store.commit('SET_GLOBAL_LOADING', true)
    },
    HideLoading: () => {
        store.commit('SET_GLOBAL_LOADING', false)
    },
    ShowModalDialog: (title, message, cancelButtonText, confirmButtonText, didCloseCallback, width, height) => {
        const params = {
            title: title,
            message: message,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            didCloseDialog: didCloseCallback,
            width: width,
            height: height
        }
        store.commit('SHOW_MODAL_DIALOG', params)
    },
    ShowSuccessToast: (successMessage) => {
        const options = {
            type: 'success',
            position: toastPosition,
            message: successMessage,
            duration: toastDuration
        }
        Vue.$toast.open(options)
    },    
    ShowErrorToast: (errorMessage) => {
        const options = {
            type: 'error',
            position: toastPosition,
            message: errorMessage,
            duration: toastDuration
        }
        Vue.$toast.open(options)
    },
    ShowInfoToast: (infoMessage) => {
        const options = {
            type: 'info',
            position: toastPosition,
            message: infoMessage,
            duration: toastDuration
        }
        Vue.$toast.open(options)
    },
    ShowWarningToast: (warningMessage) => {
        const options = {
            type: 'warning',
            position: toastPosition,
            message: warningMessage,
            duration: toastDuration            
        }
        Vue.$toast.open(options)
    },

    OpenRightPanel: () => {

        // Right panel CSS Setting
        const rsb = document.querySelector(".task-section-3");
        const section2 = document.querySelector(".task-section-2");
        // const section1 = document.querySelector(".task-section-1");
        // const section4 = document.querySelector(".task-section-4");
        // const openRsbBtn = document.querySelector(".open-rsb");
        const totalContainer = document.querySelector(".total-container");
        // const teamNameContainer = document.querySelectorAll(".team-name__container");
        // const teamNameWrapper = document.querySelectorAll(".team-name__wrapper");
        const togglePanelBtn = document.querySelector(".header--right_panel");
        rsb.style.width = "24%";
        section2.style.width = "62%";
        togglePanelBtn.style.backgroundColor = "#e9e9f6";
        totalContainer.style.display = "flex";
        totalContainer.style.flexDirection = "column";
        totalContainer.style.alignItems = "center";        
        store.commit('SET_RIGHT_PANEL', true)
    },
    CloseRightPanel: () => {
        // Right panel CSS Setting
        const rsb = document.querySelector(".task-section-3");
        const section2 = document.querySelector(".task-section-2");
        // const section1 = document.querySelector(".task-section-1");
        // const section4 = document.querySelector(".task-section-4");
        // const openRsbBtn = document.querySelector(".open-rsb");
        const totalContainer = document.querySelector(".total-container");
        // const teamNameContainer = document.querySelectorAll(".team-name__container");
        // const teamNameWrapper = document.querySelectorAll(".team-name__wrapper");
        const togglePanelBtn = document.querySelector(".header--right_panel");
        rsb.style.width = "0%";
        rsb.style.overflow = "hidden";
        section2.style.width = "86%";
        togglePanelBtn.style.backgroundColor = "transparent";
        totalContainer.style.display = "none";        
        store.commit('SET_RIGHT_PANEL', false)
    },
}   