<template>
    <div></div>
</template>

<script>
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';

export default {
    name: 'Oauth',
    data() {
        return {
            CLIENT_ID: 'HsLwKC8lLmc6NhgllukJ',
        }
    },
    created() {
        const store = tokenStore()
        if (this.$route.query) {
            // 비밀번호 재설정
            if (this.$route.query.resetPassword) {
                this.resetPassword(encodeURIComponent(this.$route.query.resetPassword))
            }

            // 계정 활성화
            if (this.$route.query.signin_verify) {
                this.activation()
            }

            if (this.$route.query.token && !this.$route.query.provider) {
                store.storeAuthToken(this.$route.query.token)
                this.redirectFromAdmin()
            }

            // Socail auth error cases
            if (this.$route.query.error && this.$route.query.error === 'already_linked_email') {
                window.opener.alreadyLinked(this.$route.query.msg);
                window.close()
            } else if (this.$route.query.error && this.$route.query.error === 'no_linked_email') {
                window.signup();
            } else if (this.$route.query.error && this.$route.query.error === 'already_signed_up_email') {
                window.alreadySignedUp();
            } else if (this.$route.query.error && this.$route.query.error === 'disagree_profile') {
                window.disagreeProfile();
                // window.close();
            } else if (this.$route.query.error && this.$route.query.error === 'deleted_email_cannot_signup') {
                window.cannotSignUp();
            } else if (this.$route.query.error && this.$route.query.error === 'unactivated_email') {
                modalControler.ShowModalDialog('비활성화된 계정', '활성화되지 않은 계정입니다.<br>이메일 내의 링크를 눌러 계정활성화를 진행해주세요', '', '확인')
                router.push({ name: 'SignIn' })
            }

            // provider 에 따른 처리
            if (this.$route.query.provider && this.$route.query.provider === 'nice') {              // nice 본인인증
                window.opener.niceCertificate(this.$route.query.token)
                window.close();

            // naver
            } else if (this.$route.query.provider && this.$route.query.provider === 'naver') {      // naver 
                const action = this.$route.query.action
                if (action === 'link') {
                    if (store.authToken === null) {
                        window.naverLink(this.$route.query.token);
                    } else {
                        window.opener.naverAuthLink(this.$route.query.token);
                        window.close();
                    }
                } else if (action === 'signin') {
                    window.socialSignin(this.$route.query.token);
                } else if (action === 'signup') {
                    window.socialSignup(this.$route.query.token);
                }                

            // google
            } else if (this.$route.query.provider && this.$route.query.provider === 'google') {     // google
                const action = this.$route.query.action
                if (action === 'link') {
                    if (store.authToken === null) {
                        window.googleLink(this.$route.query.token);
                    } else {
                        window.opener.googleAuthLink(this.$route.query.token);
                        window.close();
                    }
                } else if (action === 'signin') {
                    window.socialSignin(this.$route.query.token);
                } else if (action === 'signup') {
                    window.socialSignup(this.$route.query.token);
                }                
            }

        }

    },

    methods: {
        activation() {
            const param = encodeURIComponent(this.$route.query.signin_verify)
            if (param) {
                Authenticate.signInActivation(param).then(response => {
                    if (response.data.error === true) {
                        modalControler.ShowErrorToast(response.data.error_detail)
                    }else {
                        modalControler.ShowSuccessToast('인증되었습니다. 로그인해주세요.')
                    }

                    // TODO : Show modal dialog
                    this.$router.push({ name: 'SignIn' })
                }).catch(err => {
                    console.log(err)
                    if (err.response.data.error_code !== 2107) {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    }
                    // window.close()
                });
            }
        },
        resetPassword(param) {
            if (param) {
                modalControler.ShowLoading()
                Authenticate.resetPassword(param).then(response => {
                    if (!response.data.error) {
                        modalControler.ShowSuccessToast('비밀번호 초기화 완료')
                        const msg = "사용자 비밀번호가 초기화 되었습니다.<br>초기화 된 비밀번호는 입력하신 이메일로 전달됩니다.<br>\
                                    이메일 확인하신 후 로그인하시기 바랍니다.<br>"
                        modalControler.ShowModalDialog('비밀번호 초기화 완료', msg, '', '확인', (val) => {
                            if (val) {
                                modalControler.HideLoading()
                                this.$router.push({ path: '/' })
                            }
                        })
                    } else {
                        modalControler.ShowErrorToast(response.data.error_detail)
                        // window.close();
                    }
                }).catch(err => {
                    console.log(err)
                    modalControler.ShowErrorToast('서버오류')
                }).finally(() => modalControler.HideLoading());
            }
        },        
        redirectFromAdmin() {
            // 복수 대회 디테일
            if (this.$route.query.goCompetitionDetail) {
                return this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}`
                });
            }
            // 단일 대회 디테일
            if (this.$route.query.goCompetitionPrivateDetail) {
                return this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}`
                });
            }
            // 단일 과제 개요
            if (this.$route.query.goPrivateAssignmentDetail) {
                return this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}/task/${this.$route.query.assignmentId}/taskInfo`
                });
            }
            // 과제 디테일
            if (this.$route.query.goAssignmentDetail) {
                return this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}/task/${this.$route.query.assignmentId}/taskInfo`
                });
            }
            // 단일 과제 대회 문의사항
            if (this.$route.query.goSingleCompetitionQna) {
                this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}/qna/${this.$route.query.qnaId}`
                });
            }
            // 복수 과제 대회 대회 문의
            if (this.$route.query.goMultiCompetitionQna) {
                this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}/qna/${this.$route.query.qnaId}`
                })
            }
            // 복수 과제 대회 과제 문의
            if (this.$route.query.goMultiAssignmentQna) {
                this.$router.push({
                    path: `/competition/detail/${this.$route.query.competitionId}/task/${this.$route.query.assignmentId}/notice/detail/${this.$route.query.qnaId}`
                })
            }
        }
    },
}
</script>