<template>
    <div class="content-header">
        <a @click.prevent.stop="router.goCompetitionPages('CompetitionDetail', info.competitionid)"
            class="content-header__link">{{
                competitionTitle
            }}
            <span class="icon">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                    icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
            </span>
        </a>
        <h2 class="content-header__title">{{ taskTitle }}</h2>
        <!--div class="content-header__tags" v-if="taskKeywords.length">
            <span class="tag" v-for="(k, ki) in taskKeywords" :key="'key-' + ki">{{ k.name }}</span>
        </div-->
        <div class="content-info content-info--both mb--0">
            <div class="content-info__items">
                <div class="content-info__item" v-if="c_ddateText && c_ddate">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-calendar"><icon-calendar /></icon-base></span>
                    <span class="item__text">
                        <template v-if="c_status === 2"><span
                                class="status-badge status-badge--black">진행예정</span></template>
                        <template v-else-if="c_status === 6"><span
                                class="status-badge status-badge--gray">종료됨</span></template>
                        <template v-else>
                            <span class="status-badge status-badge--white" v-if="c_ddateText && c_ddate">{{
                                c_ddateText
                            }} {{ c_ddate }}</span>
                        </template>
                    </span>
                </div>
                <!--div class="content-info__item">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-eye"><icon-eye /></icon-base></span>
                    <span class="item__text">
                        1,234
                    </span>
                </div-->
                <div class="content-info__item">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-user"><icon-user /></icon-base></span>
                    <span class="item__text">
                        {{ props.info.joinusercnt }}명
                    </span>
                </div>
                <div class="content-info__item" v-if="props.info.assignmentprizemoney !== 0">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-award"><icon-award /></icon-base></span>
                    <span class="item__text">
                        {{ utils.numberToKorean(props.info.assignmentprizemoney) }}만원
                    </span>
                </div>
                <!--div class="content-info__item">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-upload"><icon-upload /></icon-base></span>
                    <span class="item__text">
                        1,234
                    </span>
                </div-->
            </div>
            <div class="content-info__btn">
                <template v-if="props.info.competitionstatus === 3">
                    <!-- 대회 참여 X -->
                    <template v-if="props.info.isjoin === 0">
                        <v-btn class="btn-primary btn-medium" @click="applyCompetition">대회 참여</v-btn>
                    </template>
                    <!-- 대회 참여 O || 팀 승인 O -->
                    <template v-else-if="props.info.isjoin === 1 || props.info.isjoin === 2">
                        <v-btn class="btn-primary btn-medium" @click="applyTask">과제 참여</v-btn>
                    </template>
                    <!-- 과제 참여 O -->
                    <template v-else-if="props.info.isjoin === 3">
                        <template>
                            <v-btn class="btn-primary btn-medium" @click="submit">결과제출</v-btn>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <!-- FINALIZED-->
                    <template v-if="props.info.competitionstatus === 6">
                        <!-- 연습제출 -->
                        <template v-if="taskCategory === 'A003'">
                            <div class="tooltip__group" ref="tipBox">
                                <div :class="{ 'on': store2.practiceTooltipOpen }" class="tooltip__box tooltip__box--right">
                                    <div class="tooltip__inner">내 점수를 확인해보세요!</div>
                                    <button type="button" class="btn-closed" @click="closeTipbox">
                                        <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                                            icon-name="icon-closed"><icon-closed /></icon-base>
                                    </button>
                                </div>

                                <v-btn class="btn-primary btn-medium" @click="practiceSubmit">연습 제출</v-btn>
                            </div>
                        </template>
                        <template v-else>
                            <v-btn class="btn-primary btn-medium" disabled>대회종료</v-btn>
                        </template>
                    </template>
                    <!-- RUNNING || HOLDING-->
                    <template v-else>
                        <!-- 과제 참여 O -->
                        <template v-if="props.info.isjoin === 3">
                            <!-- GPU 제공형 -->
                            <template v-if="props.info.isopen === 0">
                                <template v-if="taskCategory !== 'A002'">
                                    <v-btn class="btn-primary btn-medium" @click="openContainer">컨테이너 접속</v-btn>
                                </template>
                            </template>
                            <!-- 개방형 -->
                            <template v-else>
                                <v-btn class="btn-primary btn-medium" @click="submit">결과제출</v-btn>
                            </template>
                        </template>
                        <!-- 과제 참여 X -->
                        <template v-else>
                            <v-btn class="btn-primary btn-medium" disabled>과제 참여불가</v-btn>
                        </template>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from "@/helper/modalControler";
import TaskApi from "@/lib/api/Task";
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';

export default {
    name: 'Summary',
    props: [
        'info',
        'competitionTitle',
        'taskTitle',
        'taskDesc',
        'taskKeywords',
        'taskCategory',
        'container',
        'agreed',
    ],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const store2 = stateStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            c_status: 0,
            c_schedule: [],
            c_ddate: '',
            c_ddateText: '',
        });


        onMounted(() => {
            getCompetitionDetailInfo()
        })

        const closeTipbox = () => {
            store2.closePracticeTooltip()
        }

        const getCompetitionDetailInfo = () => {
            CompetitionApi.getCompetitionDetailInfo(route.params.competitionId).then(response => {
                if (!response.data.error) {
                    const data = response.data.result
                    state.c_status = data.status
                    state.c_schedule = data.scheduleList
                    scheduleState()
                }
            })
        }

        const scheduleState = () => {
            const { noDDay, DDay, DDayText } = utils.statusToScheduleTitle(state.c_status, state.c_schedule)
            if (!noDDay) {
                state.c_ddate = DDay
                state.c_ddateText = DDayText
            } else {
                // do nothing
            }
        }

        const applyCompetition = () => {
            router.goCompetitionPages('CompetitionApply', route.params.competitionId)
        }

        const openContainer = (IP, PORT) => {
            if (!props.container) { // 1) Jupyter 접속 정보가 없고, SSH 접속 정보가 없는 경우
                modalControler.ShowErrorToast('컨테이너가 할당되지 않았습니다.')
            } else if (props.container.JUPYTERIP && props.container.JUPYTERPORT) { // 2) Jupyter 접속 정보가 있는 경우
                try {
                    utils.openContainer(props.container.JUPYTERIP, props.container.JUPYTERPORT)
                } catch {
                    modalControler.ShowErrorToast('컨테이너가 할당되지 않았습니다.')
                }
            } else if (props.container.SSHID && props.container.SSHPW && props.container.SSHIP && props.container.SSHPORT) { // 3) Jupyter 접속 정보가 없고, SSH 접속 정보가 있는 경우
                modalControler.ShowInfoToast('SSH 접속 정보를 확인 후 터미널에서 실행해주세요.<br/>ID : ' + props.container.SSHID + '<br/>Password : ' + props.container.SSHPW)
            } else { // 4) 그 외
                modalControler.ShowErrorToast('컨테이너가 할당되지 않았습니다.')
            }
        }

        const applyTask = () => {
            modalControler.ShowModalDialog('과제참여', `${props.taskTitle} 에 참가 신청하시겠습니까?<br>\
          대회가 시작되면 변경할 수 없습니다.`, '취소', '참가하기', (val) => {
                if (val) {
                    let params = {
                        competitionId: route.params.competitionId,
                        assignmentId: route.params.taskId
                    }
                    modalControler.ShowLoading()
                    TaskApi.joinAssignment(params).then(response => {
                        if (!response.data.error) {
                            modalControler.HideLoading()
                            modalControler.ShowSuccessToast('과제참여 완료!')
                            router.goTaskPages('TaskInfo', route.params.taskId, route.params.competitionId)
                            location.reload()

                        } else {
                            if (response.data.error_code === 7500) {
                                modalControler.ShowErrorToast(response.data.error_detail)
                            } 
                        }
                    }).catch(err => {
                        console.log(err)
                        // modalControler.ShowErrorToast(err.response.error_detail)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                }
            })
        }

        const submit = () => {
            router.goTaskPages('TaskSubmit', route.params.taskId, route.params.competitionId)

        }

        const practiceSubmit = () => {
            if (accountInfo.nickname) {
                // 대회 참여자
                if (props.agreed) {
                    router.goTaskPages('TaskSubmit', route.params.taskId, route.params.competitionId)
                } else {
                    // 대회 미참여자
                    modalControler.ShowModalDialog('', '대회 규정에 동의하신 후 참여할 수 있습니다.', '취소', '확인', (val) => {
                        if (val) {
                            // router.goCompetitionPages('CompetitionAgreement', route.params.competitionId)
                            router.push({
                                name: 'CompetitionAgreement',
                                params: {
                                    competitionId: route.params.competitionId,
                                    taskId: route.params.taskId
                                },
                            })
                        }
                    })
                }
            } else {
                router.push({ name: 'SignIn' })
            }
        }

        return {
            ...toRefs(state),
            store2,
            router,
            props,
            utils,
            applyCompetition,
            openContainer,
            applyTask,
            practiceSubmit,
            closeTipbox,
            submit,
        }
    }

}
</script>
