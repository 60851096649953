<template>
    <div class="tem-wrap contents-wrap">
        <Team :status="status" :memberLimit="memberLimit" :isJoin="isJoin" />
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import Team from '@/components/competition/Team'
import { stateStore } from '@/stores/state';

export default {
    name: 'CompetitionTeam',
    components: {
        Team,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const stateStorage = stateStore()
        const state = reactive({
            competitionId: Number(route.params.competitionId),
            status: 0,
            memberLimit: 0,
            isJoin: 0,
            isLoading: false
        })

        onMounted(() => {
            stateStorage.removeGnbItems()       // gnb list 지운다

            getCompetitionDetailInfo(state.competitionId)
        })

        const getCompetitionDetailInfo = (competitionId) => {
            state.isLoading = true
            modalControler.ShowLoading()
            CompetitionApi.getCompetitionDetailInfo(competitionId).then(response => {
                if (!response.data.error) {
                    const data = response.data.result
                    state.status = data.status
                    state.isJoin = data.isjoin
                    state.memberLimit = data.maxteamperson
                    state.isLoading = false
                }
            }).catch(err => {
                console.log(err)
                state.isLoading = false
            }).finally(() => {
                modalControler.HideLoading()
            })

        }

        return {
            ...toRefs(state),
            props,
        }
    }
}
</script>

<style scoped lang="scss">

</style>