var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-line"},[_vm._m(0),_c('thead',{staticClass:"table-line__thead"},[_c('tr',[_vm._l((_vm.tableHeader),function(header,idx){return [_c('th',{key:'aic-tb-header-' + idx},[(header.sortable)?[_c('button',{staticClass:"btn-sort",class:{ 'on': _vm.clickedHeaderIndex === idx },on:{"click":function($event){return _vm.clickHeader(header, idx)}}},[_vm._v(" "+_vm._s(header.title)+" "),(header.sortBy === 'asc')?_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-up']}})],1):_vm._e(),(header.sortBy === 'desc')?_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-down']}})],1):_vm._e()])]:[_vm._v(" "+_vm._s(header.title)+" ")]],2)]})],2)]),_c('tbody',{staticClass:"table-line__body"},[(_vm.data && _vm.data.length)?[_vm._l((_vm.data.slice(0, _vm.maxShow)),function(row,idx){return [_c('tr',{key:idx,class:{ 'practice__submit': row.practice ? true : false }},[_vm._l((_vm.tableHeader),function(header,idx2){return [_c('td',{key:'column-' + idx2,attrs:{"data-label":header.title}},[_c('div',{staticClass:"flex-shrink"},[(header.submitScore)?[_vm._l((_vm.evaluationParams),function(param,paramIdx){return [(
                      header.value === 'scoreList-' + paramIdx &&
                      row['scoreList-' + paramIdx]
                    )?[(row['scoreList-' + paramIdx].publicScore >= 0)?_c('div',{key:'publicScore-' + paramIdx,staticClass:"team__submit"},[_c('span',{staticClass:"team__submit__title"},[_vm._v("Public Score")]),_c('strong',{staticClass:"team__submit__text"},[_vm._v(" "+_vm._s(row["scoreList-" + paramIdx].publicScore.toFixed(4))+" ")])]):_vm._e(),(
                        _vm.isPrivateExpose &&
                        row['scoreList-' + paramIdx].privateScore >= 0
                      )?_c('div',{key:'privateScore-' + paramIdx,staticClass:"team__submit"},[_c('span',{staticClass:"team__submit__title"},[_vm._v("Private Score")]),_c('strong',{staticClass:"team__submit__text"},[_vm._v(" "+_vm._s(row["scoreList-" + paramIdx].privateScore.toFixed(4))+" ")])]):_vm._e(),(
                        _vm.isFinalExpose &&
                        row['scoreList-' + paramIdx].finalScore >= 0
                      )?_c('div',{key:'finalScore-' + paramIdx,staticClass:"team__submit"},[_c('span',{staticClass:"team__submit__title"},[_vm._v(" Final Score ")]),_c('strong',{staticClass:"team__submit__text"},[_vm._v(" "+_vm._s(row["scoreList-" + paramIdx].finalScore.toFixed(4))+" ")])]):_vm._e()]:_vm._e()]})]:[_vm._t(header.value,function(){return [(typeof(row[header.value]) === 'number' && header.value != 'index' && header.value != 'id' && header.value != 'idx')?[_vm._v(" "+_vm._s(row[header.value].toFixed(4))+" ")]:[_vm._v(" "+_vm._s(row[header.value])+" ")]]},{"row":row})]],2)])]})],2)]})]:[(_vm.boardType === 'leaderboard')?[_c('tr',[_c('td',{staticClass:"no-data",attrs:{"colspan":_vm.tableHeader.length}},[_c('p',{staticClass:"no-data__text"},[_vm._v("결과 제출 시 리더보드를 확인할 수 있습니다.")])])])]:(_vm.boardType === 'submitTable')?[_c('td',{staticClass:"no-data",attrs:{"colspan":_vm.tableHeader.length}},[_c('p',{staticClass:"no-data__text"},[_vm._v("제출이력이 없습니다.")])])]:_vm._e()]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col'),_c('col'),_c('col'),_c('col')])}]

export { render, staticRenderFns }