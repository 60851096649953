<template>
    <div class="help-wrap contents-wrap">
        <section class="help-section">
            <header class="page-header--line__section">
                <h2 class="page-header--line__title">1:1 문의 등록</h2>
                <p class="page-header--line__sub-text">1:1 문의를 등록하시면 영업 시간 24시간 이내에 답변해드리고 있습니다.<br /> 답변이 완료되면
                    이메일로 알림을 받아볼 수
                    있습니다.</p>
            </header>
            <div class="medium-content-inner">
                <form>
                    <div class="content-group">
                        <v-app>
                            <div class="form-group">
                                <div class="form-group__title">
                                    답변 받을 이메일<span class="essential">*</span>
                                </div>
                                <v-text-field v-model="inquiryForm.email" placeholder="email@example.com"
                                    class="common-form-control" outlined hide-details></v-text-field>
                            </div>
                            <div class="form-group form-group__detail">
                                <div class="form-group__title">
                                    문의 유형 선택<span class="essential">*</span>
                                </div>
                                <div class="multiple">
                                    <v-select v-model="selectedMainCategoryId" :items="mainCategory" item-text="name"
                                        item-value="id" single-line label="문의 분류를 선택해주세요." class="common-form-control"
                                        outlined></v-select>
                                    <v-select v-model="selectedSubCategoryId" :items="subCategory" item-text="name"
                                        item-value="id" single-line label="문의 분류를 선택해주세요." class="common-form-control"
                                        outlined></v-select>
                                </div>
                            </div>
                            <!--div class="faq-group">
                                                                                <div class="faq-group__title">혹시 이것이 궁금하지 않으셨나요?</div>
                                                                                <ul class="line-list__items">
                                                                                    <li class="line-list__item line-list__item--small"><a href="">특정 대회와 과제에 관련한
                                                                                            질문일 경우 선택해주세요</a></li>
                                                                                    <li class="line-list__item line-list__item--small"><a href="">특정 대회와 과제에 관련한
                                                                                            질문일 경우 선택해주세요</a></li>
                                                                                    <li class="line-list__item line-list__item--small"><a href="">특정 대회와 과제에 관련한
                                                                                            질문일 경우 선택해주세요</a></li>
                                                                                    <li class="line-list__item line-list__item--small"><a href="">특정 대회와 과제에 관련한
                                                                                            질문일 경우 선택해주세요</a></li>
                                                                                </ul>
                                                                            </div-->
                            <div class="form-group form-group__detail">
                                <div class="form-group__header">
                                    <div class="form-group__title">대회 및 과제 선택</div>
                                    <p class="sub-text">특정 대회와 과제에 관련한 질문일 경우 선택해주세요.</p>
                                </div>
                                <div class="multiple">
                                    <v-select single-line label="대회를 선택해주세요." class="common-form-control"
                                        :items="myCompetitionList" item-text="competitionname" item-value="competitionId"
                                        v-model="selectedCompetitionId" outlined></v-select>
                                    <v-select single-line label="과제를 선택해주세요." :items="myAssignmentList"
                                        item-text="ASSIGNMENTNAME" item-value="ASSIGNMENTID" v-model="selectedAssignmentId"
                                        class="common-form-control" outlined></v-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">
                                    문의내용<span class="essential">*</span>
                                </div>
                                <v-text-field outlined class="common-form-control" dense hide-details
                                    placeholder="제목을 입력해 주세요. (최대 100자)" v-model="inquiryForm.title"></v-text-field>
                                <div class="editor-box">
                                    <div class="common-textarea">
                                        <v-textarea v-model="inquiryForm.content" rows="10"
                                            placeholder="내용을 입력해주세요. (최대 1500자)" no-resize></v-textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">파일첨부</div>
                                <!--FileInput ref="file" v-on:change="onChangeFile" /-->
                                <v-file-input v-model="inquiryForm.file" placeholder="최대 50mb까지 첨부" outlined hide-details
                                    class="common-form-control" show-size prepend-inner-icon="mdi mdi-paperclip"
                                    prepend-icon="" dense type="file" ref="attachement"
                                    :error-messages="errorStatus.attachmentErrMsg"
                                    @change="validate($event, inquiryForm.file)"></v-file-input>
                            </div>
                            <div class="content-group__btn-area">
                                <div class="btn-inline btn-inline--center">
                                    <v-btn class="btn btn-large btn-tertiary" @click="cancel">
                                        <strong>취소</strong>
                                    </v-btn>
                                    <v-btn class="btn btn-large btn-primary" :disabled="enableSubmit"
                                        @click="submitInquiry">
                                        <strong>등록</strong>
                                    </v-btn>
                                </div>
                            </div>
                        </v-app>
                    </div>
                </form>
            </div>

        </section>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';
import jwtDecode from 'jwt-decode';
import { BIcon } from 'bootstrap-vue'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import httpClient from '@/lib/api/httpClient';
import Authenticate from '@/lib/api/Authenticate';
import CompetitionApi from "@/lib/api/Competition";
import SupportApi from "@/lib/api/Support";
import modalControler from '@/helper/modalControler';
import validator from "@/config/formValidator";
// import FileInput from "@/components/competition/FileInput";
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import FileInput from "@/components/competition/FileInput";

export default {
    name: 'InquiryForm',
    components: {
        // FileInput,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const attachement = ref(null)
        const accountInfo = store.getAccountInfo
        const state = reactive({
            inquiryForm: {
                email: accountInfo.email,
                mainCategory: '',
                subCategory: '',
                selectedCompetition: '',
                selectedAssignment: '',
                title: '',
                content: '',
                file: null,
            },
            mainCategory: [],
            selectedMainCategoryId: null,
            subCategory: [],
            selectedSubCategoryId: null,
            myCompetitionList: [],
            selectedCompetitionId: null,
            myAssignmentList: [],
            selectedAssignmentId: null,
            enableSubmit: false,
            inquiryTitle: '',
            errorStatus: {
                attachmentErrMsg: ''
            },
            maxAttachmentSize: 52_428_800,  // 50MB
            manualList: [],
            maxAllowedFileName: 100,
            maxTitleLength: 100,
            maxContentLength: 1500,
        })

        onMounted(() => {
            getMyCompetitionList()
            getInquiryCategories()
        })

        watch(() => state.selectedMainCategoryId, (nv, ov) => {
            if (state.selectedMainCategoryId) {
                getManualList()
            }
        })

        watch(() => state.selectedCompetitionId, (nv, ov) => {
            try {
                state.myAssignmentList = state.myCompetitionList.filter(
                    v => v.competitionId === state.selectedCompetitionId
                )[0].assignmentlist

            } catch {
                // do nothing ??
            }
        })

        watch(() => state.selectedMainCategoryId, (nv, ov) => {
            try {
                state.subCategory = []
                state.categories.forEach((c, i) => {
                    if (c.parentId === state.selectedMainCategoryId) {
                        state.subCategory.push(c)
                    }
                })
            } catch {
                // do nothing ??
            }
        })

        const getManualList = () => {
            let params = {
                pageNo: 1,
                pageSize: 5
            }
            SupportApi.getManualList(utils.makeParams(params)).then(response => {
                if (response.data.code === 0) {
                    state.manualList = response.data.result.filter(m => (m.categoryId === state.selectedMainCategoryId))

                }
            })
        }

        const enableSubmit = computed(() => {
            return !(
                state.selectedMainCategoryId &&
                state.inquiryForm.title &&
                state.inquiryForm.content
            )
        })

        const getMyCompetitionList = () => {
            CompetitionApi.getMyCompetitionList().then(response => {
                if (response.data.code === 0) {
                    state.myCompetitionList = response.data.result
                }
            })
        }

        const getInquiryCategories = () => {
            SupportApi.getInquiryCategories().then(response => {
                if (response.data.code === 0) {
                    response.data.result.forEach((c, i) => {
                        if (!c.parentId) {
                            state.mainCategory.push(c)
                        }
                    })
                    state.categories = response.data.result
                }
            })
        }

        const submitInquiry = () => {

            if (state.inquiryForm.title.length > state.maxTitleLength) {
                return modalControler.ShowErrorToast('제목은 최대 100글자까지 작성할 수 있습니다.')
            }

            if (state.inquiryForm.content.length > state.maxContentLength) {
                return modalControler.ShowErrorToast('내용은 최대 1500자까지 작성할 수 있습니다.')
            }

            const formData = new FormData()
            formData.append('categoryParentId', state.selectedMainCategoryId)
            formData.append('title', state.inquiryForm.title)
            formData.append('content', state.inquiryForm.content)
            formData.append('responseEmail', state.inquiryForm.email)

            if (state.selectedSubCategoryId)
                formData.append('categoryChildId', state.selectedSubCategoryId)
            if (state.selectedCompetitionId)
                formData.append('competitionId', state.selectedCompetitionId)
            if (state.selectedAssignmentId)
                formData.append('assignmentId', state.selectedAssignmentId)
            if (state.inquiryForm.file)
                formData.append('file', state.inquiryForm.file)

            SupportApi.RegistInquiry(formData).then(response => {
                if (response.data.code === 0) {
                    modalControler.ShowSuccessToast('1:1 문의가 접수되었습니다.')
                    cancel()
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            })
        }

        const cancel = () => {
            router.push({
                name: 'faq'
            })
        }

        const validate = (e, data) => {
            if (data && data.name.length > state.maxAllowedFileName) {
                attachement.value = null
                state.inquiryForm.file = null
                modalControler.ShowErrorToast(`제출 파일명은 최대 ${state.maxAllowedFileName}자를 초과할 수 없습니다.`)
                return
            }

            if (data && data.size > state.maxAttachmentSize) {
                attachement.value = null
                state.inquiryForm.file = []
                state.errorStatus.attachmentErrMsg = '첨부파일 크기는 50MB를 초과할 수 없습니다.'
                modalControler.ShowWarningToast('첨부파일 크기는 50MB를 초과할 수 없습니다.')
                return
            } else {
                state.errorStatus.attachmentErrMsg = ''
            }
        }

        return {
            utils,
            ...toRefs(state),
            cancel,
            enableSubmit,
            submitInquiry,
            validate,
        }
    }

}
</script>
