<template>
    <v-app>
        <section class="profile-section content-section--padding bg-gray">
            <div class="content-inner">
                <article class="status-info-box">
                    <div class="status-card">
                        <div class="profile-img profile-img__size--600">
                            <img
                                :src="(accountInfo.profileimg) ? accountInfo.profileimg : require('@/assets/images/img-profile-blue.svg')" />
                        </div>
                        <div class="nickname">{{ accountInfo.nickname }}</div>
                        <span class="email">{{ accountInfo.email }}</span>
                        <div class="btn-inline btn-inline--group">
                            <v-btn class="btn btn-small btn-secondary" @click="changePassword">비밀번호 변경</v-btn>
                            <v-btn class="btn btn-small btn-secondary" @click="changeProfile">개인정보 변경</v-btn>
                        </div>
                    </div>
                </article>
            </div>
        </section>
        <section class="profile-section content-section--padding">
            <div class="content-inner">
                <div class="column-02">
                    <article class="task-info-box">
                        <div class="content-group">
                            <header class="content-group__header">
                                <h3 class="content-group__title">AI COMPETITION</h3>
                            </header>
                            <!-- <div class="accordion__items" v-if="competition.length" accordion multiple v-model="panel"> -->
                            <div class="accordion__items" v-if="competition.length" accordion multiple>
                                <div v-for="(c, idx) in competition" :class="{ 'on': idx === clickedCompetitionIdx }"
                                    class="common-accordion__item accordion__item" :key="idx">
                                    <div @click="openCompetition(c, idx)"
                                        class="common-accordion__button accordion__button">
                                        <header class="common-accordion__header common-accordion__flex">
                                            <div class="thumb-square thumb-square--s">
                                                <img :src="(c.imgurl) ?
                                                        c.imgurl : '../../assets/images/img-main-dummy.png'" />
                                            </div>
                                            <div class="pl--16">
                                                <div class="common-accordion__status-badge">
                                                    <span v-if="c.status === 2" class="status-badge status-badge--white">오픈
                                                        예정</span>
                                                    <span v-else-if="c.status === 3"
                                                        class="status-badge status-badge--black">접수중</span>
                                                    <span v-else-if="c.status === 4"
                                                        class="status-badge status-badge--primary">진행중</span>
                                                    <span v-else-if="c.status === 5"
                                                        class="status-badge status-badge--white">점검중</span>
                                                    <span v-else-if="c.status === 6"
                                                        class="status-badge status-badge--gray">종료</span>
                                                </div>
                                                <h4 class="common-accordion__title">{{ c.competitionname }}</h4>
                                                <span class="common-accordion__info">
                                                    <span>{{ c.teamname }}</span> 팀
                                                </span>
                                            </div>
                                        </header>
                                    </div>
                                    <div class="common-accordion__body accordion__body">
                                        <template>
                                            <div class="invite" v-for="(t, idx) in c.invitedTeam" :key="'team-' + idx">
                                                <p><strong>{{ t.teamname }}</strong> 팀에 초대받았습니다.</p>
                                                <div class="btn-group" :key="idx">
                                                    <v-btn class="btn btn-secondary btn-small"
                                                        @click="deleteInvitation(c, t)">거절</v-btn>
                                                    <v-btn class="btn btn-primary btn-small"
                                                        @click="approveInvitation(c, t)">수락</v-btn>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="!c.assignmentlist.filter(t => t.ISJOIN === 1).length">
                                            <div class="task-card">
                                                <div class="no-task">
                                                    <a @click.prevent.stop="goCompetitionTaskList(c)" class="text-link"
                                                        href="">{{ c.competitionname }}</a>의 참여중인 과제가 없습니다.
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="task-card__items">
                                                <section class="swiper__banner swiper-unset">

                                                    <template v-if="c.assignmentList && c.assignmentList.length > 3">
                                                        <button type="button" class="swiper-btn swiper-btn__middle__left"
                                                            slot="button-next">
                                                            <icon-base viewBox="0 0 24 24" width="24" height="24"
                                                                iconColor="#101216"
                                                                icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                                                        </button>
                                                        <button type="button" class="swiper-btn swiper-btn__middle__right"
                                                            slot="button-prev">
                                                            <icon-base viewBox="0 0 24 24" width="24" height="24"
                                                                iconColor="#101216"
                                                                icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                                                        </button>
                                                    </template>
                                                    <div v-swiper:[idx]="swiperOption" class="swiper">
                                                        <div class="swiper-wrapper">
                                                            <swiper-slide
                                                                v-for="(t, idx) in c.assignmentlist.filter(t => t.ISJOIN === 1)"
                                                                :key="'t-' + idx">
                                                                <div class="task-card link"
                                                                    @click="goTaskPagesWithoutOpenStatusCode(c, t)">
                                                                    <div class="task-card__header">
                                                                        <div class="task-card__title">
                                                                            {{ t.ASSIGNMENTNAME }}
                                                                        </div>
                                                                        <div class="task-card__btn"
                                                                            v-if="(t.RANK || t.SUBMITCOUNT) && t.ISJOIN && c.status === 6">
                                                                            <v-btn class="btn-xsmall btn-secondary"
                                                                                @click.stop.prevent="openPrint(t.ASSIGNMENTID)">인증서
                                                                                발급</v-btn>
                                                                        </div>
                                                                    </div>
                                                                    <div class="task-detail">
                                                                        <div class="task-item">
                                                                            <span class="task-item__title">순위</span>
                                                                            <span v-if="t.RANK"
                                                                                class="task-item__content">{{
                                                                                    t.RANK
                                                                                }}위</span>
                                                                            <span v-else
                                                                                class="task-item__content">미제출</span>
                                                                        </div>
                                                                        <div class="task-item">
                                                                            <span class="task-item__title">퍼센티지</span>
                                                                            <span v-if="t.PERCENTAGE"
                                                                                class="task-item__content">{{
                                                                                    t.PERCENTAGE
                                                                                }}%</span>
                                                                            <span v-else class="task-item__content">{{
                                                                                t.PERCENTAGE
                                                                            }}0%</span>
                                                                        </div>
                                                                        <div class="task-item">
                                                                            <span class="task-item__title">제출수</span>
                                                                            <span class="task-item__content">{{
                                                                                t.SUBMITCOUNT
                                                                            }}회</span>
                                                                        </div>
                                                                        <div class="task-item">
                                                                            <span class="task-item__title">최종제출</span>
                                                                            <span v-if="t.SUBMITDTM"
                                                                                class="task-item__content">{{
                                                                                    t.SUBMITDTM
                                                                                }}</span>
                                                                            <span v-else
                                                                                class="task-item__content">미제출</span>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </swiper-slide>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </template>
                                        <div class="task-card mt--16"
                                            v-if="c.container && (c.container.jupyterip || c.container.sship)">
                                            <div class="task-card__header">
                                                <div class="task-card__title">컨테이너 접속정보</div>
                                            </div>
                                            <div class="container-content">
                                                <figure class="table-task__box">
                                                    <figcaption class="table-task__title">SSH</figcaption>
                                                    <table class="table-task">
                                                        <tbody class="table-task__body">
                                                            <tr>
                                                                <th>IP</th>
                                                                <td>{{ c.container.sship }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>PORT</th>
                                                                <td>{{ c.container.sshport }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>ID</th>
                                                                <td>{{ c.container.sshid }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>PW</th>
                                                                <td>{{ c.container.sshpw }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </figure>
                                            </div>
                                            <div class="container-content">
                                                <figure class="table-task__box">
                                                    <figcaption class="table-task__title">JupyterLab</figcaption>
                                                    <table class="table-task">
                                                        <tbody class="table-task__body">
                                                            <tr>
                                                                <th>IP</th>
                                                                <td>{{ c.container.jupyterip }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>PORT</th>
                                                                <td>{{ c.container.jupyterport }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>PW</th>
                                                                <td>{{ c.container.jupyterpw }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </figure>
                                                <div class="btn-inline btn-inline__group btn-inline--right">
                                                    <v-btn class="btn btn-small btn-primary"
                                                        @click="openContainer(c.container.jupyterip, c.container.jupyterport)">JupyterLab
                                                        접속</v-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-data">
                                <p class="no-data__text">참여중인 대회가 없습니다.</p>
                            </div>
                        </div>

                        <!-- TODO : 3단계 이후 개발 -->
                        <!--div class="content-group">
                            <header class="content-group__header">
                                <h3 class="content-group__title">AI League</h3>
                            </header>
                            <div class="accordion__items" v-if="leagueList.length" accordion multiple>
                                <div
                                    v-for="(l, idx) in leagueList"
                                    :class="{ 'on': idx === clickedLeagueIdx }"
                                    class="common-accordion__item accordion__item " 
                                    :key="'league-' + idx">
                                    <div
                                        @click="openLeague(l, idx)" 
                                        class="common-accordion__button accordion__button">
                                        <header class="common-accordion__header common-accordion__flex">

                                            <div class="thumb-square thumb-square--s">
                                                <img src="../../assets/images/img-main-dummy.png" />
                                            </div>
                                            <div class="pl--16">
                                                <h4 class="common-accordion__title">{{ l.name }}</h4>
                                                <span class="common-accordion__info">
                                                    <span class="icon-info">
                                                        <span class="icon">
                                                            <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                                iconColor="#101216"
                                                                icon-name="icon-eye"><icon-eye /></icon-base>
                                                        </span>
                                                        <strong class="text">{{ l.viewCount }}</strong>
                                                    </span>
                                                    <span class="icon-info">
                                                        <span class="icon">
                                                            <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                                iconColor="#101216"
                                                                icon-name="icon-user"><icon-user /></icon-base>
                                                        </span>
                                                        <strong class="text">{{ l.userCount }}</strong>
                                                    </span>
                                                    <span class="icon-info">
                                                        <span class="icon">
                                                            <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                                iconColor="#101216"
                                                                icon-name="icon-upload"><icon-upload /></icon-base>
                                                        </span>
                                                        <strong class="text">30k</strong>
                                                    </span>
                                                </span>
                                            </div>
                                        </header>
                                    </div>
                                    <div class="common-accordion__body accordion__body">
                                        <div class="task-card link">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-data">
                                <p class="no-data__text">참여중인 리그가 없습니다.
                                    <br />
                                    AI CONNECT에서 제공하는 데이터와 베이스라인 코드로 다양한 AI/ML 경진대회에 참여해보세요!
                                </p>
                                <div class="btn-inline btn-inline--group ">
                                    <a href="" class="btn btn-small btn-primary">AI League 참여하기</a>
                                </div>
                            </div>
                        </div-->
                    </article>

                    <!-- TODO : 3단계 이후 개발 -->
                    <!--article class="activities-info-box right-content">
                        <div class="content-group">
                            <header class="content-group__header content-group__header--both">
                                <h3 class="content-group__title">Activities
                                </h3>
                                <a href="/competition/profile/activities" class="btn-more btn-more__small">전체보기
                                    <span class="icon">
                                        <icon-base viewBox="0 0 24 24" width="12" height="12" iconColor="#101216"
                                            icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                                    </span>
                                </a>
                            </header>
                            <ul class="timeline">
                                <li class="timeline__item">
                                    <div class="timeline__badge">
                                        <span class="blind">포인트</span>
                                    </div>
                                    <div class="timeline__panel">
                                        <div class="timeline__header">
                                            <h4 class="timeline__title"><strong>폐암 병리 슬라이드 이미지
                                                    세그멘테이션</strong>에 참여하였습니다.
                                            </h4>
                                        </div>
                                        <div class="timeline__footer">
                                            <p class="text-right">2023-03-02 21:00:15</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </article-->
                </div>
            </div>
            <!-- Certification Area -->
            <div class="printWrapper" style="display: none">
                <div id="printArea">
                    <Certification v-if="!isLoading" @printCertification="printCertification" :host=host :subject=subject
                        :data=certiData />
                </div>
            </div>
        </section>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, onUpdated,
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';

// api 
import CompetitionApi from "@/lib/api/Competition";
import TaskApi from "@/lib/api/Task"
import LeagueApi from '@/lib/api/League'


import modalControler from '@/helper/modalControler';
import Certification from '@/components/global/Certification'

// import { Swiper as SwiperClass, Pagination, ScrollBar, Autoplay } from 'swiper/swiper.esm.js'
// import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// SwiperClass.use([Pagination, Navigation, ScrollBar])
// const { directive } = getAwesomeSwiper(SwiperClass)
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
    name: 'Profile',
    components: {
        SwiperSlide,
    },
    directive: {
        swiper: directive
    },
    props: {
        from: {
            type: String,
            required: false
        },
        passwordEditRouterName: {
            type: String,
            required: false
        },
        passwordChkouterName: {
            type: String,
            required: false
        },
    },
    components: {
        Certification,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const stateStorage = stateStore()
        const state = reactive({
            inviteTeam: [],
            competition: [],
            pastCompetition: [],
            leagueList: [],
            accountInfo: store.getAccountInfo,
            panel: [],
            openPrintDialog: false,
            certiData: {},
            isLoading: true,
            assignmentName: '',
            host: '',       // 주최
            subject: '',    // 주관
            currentStates: [3, 4, 5],
            swiperOption: {
                loop: false,
                slidesPerView: 'auto',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-btn__middle__right',
                    prevEl: '.swiper-btn__middle__left'
                },
            },
            clickedCompetitionIdx: null,
            clickedLeagueIdx: null,
        });

        onMounted(() => {
            getMyCompetitionList()
            // getLeagueList()
            stateStorage.removeGnbItems()       // gnb list 지운다
        });

        const openCompetition = (c, i) => {
            if (state.clickedCompetitionIdx === i)
                state.clickedCompetitionIdx = null
            else
                state.clickedCompetitionIdx = i
        }

        const openLeague = (l, i) => {
            if (state.clickedLeagueIdx === i)
                state.clickedLeagueIdx = null
            else
                state.clickedLeagueIdx = i
        }

        const getMyCompetitionList = () => {
            state.competition = []
            state.pastCompetition = []
            modalControler.ShowLoading()
            CompetitionApi.getMyCompetitionList().then(response => {
                if (response.data.http_status_code === 200) {
                    response.data.result.forEach(async (list, idx) => {
                        // list.invitedTeam = await getJoinTeamApprovalList(list.competitionId).then(response => response.data.result).catch(v => null)
                        // if (moment(list.todt).isAfter(moment()) === true) {

                        list.assignmentlist = list.assignmentlist.filter(task => {
                            if (task.ISJOIN === 1) return task
                        })

                        state.competition.push(list)

                        // Noti에서 눌렀을 때 대회 열리게 함
                        if (route.params.queryId) {
                            if (list.competitionId === Number(route.params.queryId)) {
                                state.panel.push(idx)
                            }
                        }



                        // if (moment(list.todt).isAfter(moment()) === false) {
                        // if (list.status === 6) {
                        //     state.pastCompetition.push(list)
                        // }

                    })
                    // last sort by competitionId (대회 생성일순)
                    sortCompetition(state.competition)
                }
            }).catch(err => {
                // TODO : error alert
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const sortCompetition = (array) => {
            array.sort((a, b) => {
                a = a.competitionId
                b = b.competitionId
                if (a > b) return -1
                if (a < b) return 1
            })


        }

        const getLeagueList = () => {
            LeagueApi.getAILeagueList()
                .then(response => {
                    state.leagueList = response.data.result

                })
                .catch(err => {

                })
        }

        const getJoinTeamApprovalList = (competitionId) => {
            state.inviteTeam = [];
            let param = {
                competitionId: competitionId
            }
            return CompetitionApi.getJoinTeamApprovalList(utils.makeParams(param));
        }

        const fromWhere = computed(() => {

        });

        const approveInvitation = (c, t) => {
            if (c.status === 4) {
                modalControler.ShowWarningToast('대회 진행 중에는 초대장을 수락 할 수 없습니다.')
                return
            }
            let params = {
                approvalId: t.approvalId,
                competitionId: c.competitionId,
                type: 'approve'
            }
            modalControler.ShowLoading()
            CompetitionApi.approvalJoinTeam(params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('초대를 수락했습니다.')
                    getMyCompetitionList()
                    // location.reload()
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }

            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const deleteInvitation = (c, t) => {
            if (c.status === 4) {
                modalControler.ShowWarningToast('대회 진행 중에는 초대장을 거절 할 수 없습니다.')
                return
            }
            let params = {
                approvalId: t.approvalId,
                competitionId: c.competitionId,
                type: 'reject'
            }
            modalControler.ShowLoading()
            CompetitionApi.approvalJoinTeam(params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('초대를 거절했습니다.')
                    getMyCompetitionList()

                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const openContainer = (IP, PORT) => {
            utils.openContainer(IP, PORT)
        }

        const dropTask = () => {
            let params = {
                competitionId: route.params.competitionId,
                assignmentId: route.params.taskId
            }
            modalControler.ShowLoading()
            TaskApi.dropAssignment(params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('과제 참여를 취소하셨습니다.')
                    location.reload()
                } else {
                    if (response.data.error_code === 7500) {
                        modalControler.ShowErrorToast(response.data.error_detail)
                    }
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const changePassword = () => {
            if (!state.accountInfo.passwordmodifydt) {
                router.push({ name: 'CompetitionListPasswordRegist' })
            } else {
                router.push({ name: 'CompetitionListPasswordEdit' })
            }
        }

        const changeProfile = () => {
            if (!state.accountInfo.passwordmodifydt) {
                router.push({ name: 'CompetitionListPasswordRegist' })
            } else {
                router.push({ name: 'CompetitionListPasswrodChk' })
            }
        }

        const printCertification = () => {
            const printHtml = document.getElementById('printArea').innerHTML
            let styleHtml = `
            <style>
                * {
                    print-color-adjust: exact !important;
                }

                .private-numb {
                    position: absolute;
                    top: 8%;
                    left: 10%;
                    z-index: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 80%;
                }

                .cert_connect_logo{
                    width: 180px;
                    object-fit: contain;
                }

                .cert_wrapper {
                    width: 297mm;
                    height: 210mm;
                    page-break-before: always;
                    overflow: hidden;
                    position: relative;
                }

                .cert_contents{
                    width: 99%;
                    height: 99%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .cert_bgimg{
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    position: relative;
                }
                .cert_bgimg_element{
                    width: 100%;
                    height: 98%;
                }

                .cert_bgimg_watermark{
                    position: absolute;
                    top: 30%;
                    left: 25%;
                    z-index: 50;
                    opacity: 0.1;
                    width: 50%;
                }

                .cert_txt{
                    position: absolute;
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    top: 15%;
                    gap: 1rem;
                    font-size: 22px;
                }

                .cert_txt h1{
                    font-size: 40px;
                    margin: 0 auto;
                }

                .cert_txt_header{
                    display: flex;
                    width: 80%;
                    flex-direction: row;
                    justify-content: space-between;
                    font-weight: 700;
                }

                .cert_txt_article{
                    max-width: 70%;
                    text-align: center;
                    margin-top: 1rem;
                }

                .cert_txt_sign {
                    margin-left: 130px;
                }

                .cert_txt_sign_img{
                    left: -40px;
                    z-index: -2;
                    position: relative;
                    width: 20%;
                    top: 25px;
                }


                @media print {

                    * {		
                        -webkit-print-color-adjust: exact;
                        print-color-adjust: exact;
                    }

                    img {
                        visibility: visible;
                    }

                    @page{
                        size: A4 landscape;
                        margin: 0;
                    }

                    .private-numb {
                        position: absolute;
                        top: 8%;
                        left: 10%;
                        z-index: 1;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 80%;
                    }
                    
                    .cert_connect_logo{
                        width: 180px;
                        object-fit: contain;
                    }

                    .cert_wrapper {
                        width: 297mm;
                        height: 210mm;
                        page-break-before: always;
                        overflow: hidden;
                        position: relative;
                    }
                    
                    .cert_contents{
                        width: 99%;
                        height: 99%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .cert_bgimg_watermark{
                        position: absolute;
                        top: 30%;
                        left: 25%;
                        z-index: 50;
                        opacity: 0.3;
                        width: 50%;
                    }

                    .cert_bgimg_element {
                        width: 99%;
                        height: 90%;
                        display: list-item;
                        list-style-image: url(https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/certification/certificate.png);
                        list-style-position: inside;
                    }

                    .cert_txt{
                        position: absolute;
                        z-index: 5;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        top: 15%;
                        gap: 1rem;
                        font-size: 22px;
                    }
                    
                    .cert_txt h1{
                        font-size: 40px;
                        margin: 0 auto;
                    }
                    
                    .cert_txt_header{
                        display: flex;
                        width: 80%;
                        flex-direction: row;
                        justify-content: space-between;
                        font-weight: 700;
                    }
                    
                    .cert_txt_article{
                        max-width: 70%;
                        text-align: center;
                        margin-top: 1rem;
                    }


                    .cert_txt_sign {
                        margin-left: 130px;
                    }
                    
                    .cert_txt_sign_img{
                        left: -40px;
                        z-index: -2;
                        position: relative;
                        width: 20%;
                        top:25px;
                    }


                }

                
            </style>
            `
            const WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=1200,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`
                <!DOCTYPE html>
                <html>
                <head>
                    ${styleHtml}
                </head>
                <body>
                    ${printHtml}
                </body>
                </html>
            `);
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(function () {
                WinPrint.print();
                Winprint.close();
            }, 1000)

        }

        const goTaskPagesWithoutOpenStatusCode = (c, t) => {
            if (c.status === 2) {
                modalControler.ShowErrorToast("준비중입니다. 잠시만 기다려주세요!")
            } else {
                router.goTaskPages('TaskInfo', t.ASSIGNMENTID, c.competitionId)
            }
        }

        const openPrint = async (id) => {
            state.isLoading = true
            TaskApi.getCertificationData(id).then(response => {
                if (!response.data.error) {
                    state.certiData = response.data.result
                    if (response.data.result.competitionInfoList.length) {
                        state.host = ''
                        state.subject = ''
                        response.data.result.competitionInfoList.forEach(el => {
                            if (el.title === '주최') {
                                if (state.host === '') {
                                    state.host = state.host + el.content
                                } else {
                                    state.host = state.host + ', ' + el.content
                                }
                            } else if (el.title === '주관') {
                                if (state.subject === '') {
                                    state.subject += el.content
                                } else {
                                    state.subject = state.subject + ', ' + el.content
                                }
                            }
                        })
                    }
                    state.isLoading = false
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            })

        }

        const goCompetitionTaskList = (c) => {
            stateStorage.setCompetitionDetailTab('competitionTask')
            router.goCompetitionPages('CompetitionDetail', c.competitionId)
        }

        return {
            ...toRefs(state),
            store,
            router,
            approveInvitation,
            deleteInvitation,
            openContainer,
            dropTask,
            changeProfile,
            changePassword,
            openPrint,
            printCertification,
            goCompetitionTaskList,
            goTaskPagesWithoutOpenStatusCode,
            openCompetition,
            openLeague,
        }
    }

}
</script>
