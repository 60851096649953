<template>
    <section class="content-section--padding bg-gray" id="section-5">
        <div class="content-inner">
            <header class="content-section__header">
                <h2 class="content-section__title">상금 및 시상</h2>
            </header>
            <div ref="preview" class="ProseMirror">
                <div v-html="prizemoneyDescription"></div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';

export default {
    name: 'Prize',
    props: ['prizemoneyDesc'],
    setup(props, context) {
        const preview = ref(null)
        const state = reactive({
            prizemoneyDescription: null,
        })

        onMounted(async () => {
            if (props.prizemoneyDesc) {
                state.prizemoneyDescription = await utils.paragraphSeperator(props.prizemoneyDesc)
                katexRender()
            }
        })

        watch(() => props.prizemoneyDesc, async (nv, ov) => {
            state.prizemoneyDescription = await utils.paragraphSeperator(props.prizemoneyDesc)
            katexRender()
        })

        const katexRender = () => {
            const previewDiv = preview.value
            utils.katexRender(previewDiv)
        }


        return {
            ...toRefs(state),
            props,
            preview,
        }

    }
}

</script>

<style scoped lang="scss">

</style>