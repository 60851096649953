// pages
import TaskRootPage from './TaskRoot'
import TaskInfo from './TaskInfo'
import TaskLeaderboard from './TaskLeaderboard'
import TaskTeam from './TaskTeam'
import TaskData from './TaskData'
import TaskCodeShare from './TaskCodeShare'
import TaskSubmit from './TaskSubmit'
import TaskNotice from './TaskNotice'
import CompetitionNotice from './CompetitionNotice'
import CompetitionNoticeEdit from './edit/CompetitionNoticeEdit'
import TaskNoticeEdit from './edit/TaskNoticeEdit'
import TaskCodeShareEdit from './edit/TaskCodeShareEdit'
import CompetitionNoticeDetail from './detail/CompetitionNoticeDetail'
import TaskNoticeDetail from './detail/TaskNoticeDetail'
import TaskCodeShareDetail from './detail/TaskCodeShareDetail'
import MySpacePage from './MySpaceMobile'
import TaskProfilePage from '../profile/task/TaskProfilePage'
import TaskProfileEditPage from '../profile/task/TaskProfileEditPage'
import TaskPasswordEditPage from '../profile/task/TaskPasswordEditPage'
import TaskPasswordChkPage from '../profile/task/TaskPasswordChkPage'
import TeamBuild from './TeamBuild'
import TeamBuildDetail from './detail/TeamBuildDetail'
import TeamBuildEdit from './edit/TeamBuildEdit'

const taskSubRoutes = [
    {
        path: 'taskInfo',
        title: '과제 설명',
        name: TaskInfo.name,
        component: TaskInfo,
        meta: {
            anonymousCallable: true
        }
    },
    {
        path: 'leaderboard',
        title: '과제리더보드',
        name: TaskLeaderboard.name,
        component: TaskLeaderboard,
        meta: {
            anonymousCallable: true
        }
    },
    {
        path: 'team',
        title: '팀 구성',
        name: TaskTeam.name,
        component: TaskTeam,
        meta: {
            
        }
    },
    {
        path: 'data',
        title: '데이터 설명/다운로드',
        name: TaskData.name,
        component: TaskData
    },
    {
        path: 'submit',
        title: '과제 제출',
        name: TaskSubmit.name,
        component: TaskSubmit
    },
    {
        path: 'notice',
        title: '과제 공지/문의',
        name: TaskNotice.name,
        component: TaskNotice,
    },
    {
        path: 'notice/detail/:qnaId/edit',
        title: '과제 공지/문의 쓰기',
        name: TaskNoticeEdit.name,
        component: TaskNoticeEdit
    },
    {
        path: 'notice/detail/:qnaId',
        title: '과제 공지/문의',
        name: TaskNoticeDetail.name,
        component: TaskNoticeDetail
    },    
    {
        path: 'codeshare',
        title: '코드공유',
        name: TaskCodeShare.name,
        component: TaskCodeShare,
    },
    {
        path: 'codeshare/detail/:qnaId/edit',
        title: '코드공유 쓰기',
        name: TaskCodeShareEdit.name,
        component: TaskCodeShareEdit
    },
    {
        path: 'codeshare/detail/:qnaId',
        title: '코드공유',
        name: TaskCodeShareDetail.name,
        component: TaskCodeShareDetail
    },    
    {
        path: 'teambuild',
        title: '팀빌딩',
        name: TeamBuild.name,
        component: TeamBuild,
    },
    {
        path: 'teambuild/detail/:qnaId/',
        title: '팀빌딩',
        name: TeamBuildDetail.name,
        component: TeamBuildDetail
    },
    {
        path: 'teambuild/detail/:qnaId/edit',
        title: '팀빌딩 쓰기',
        name: TeamBuildEdit.name,
        component: TeamBuildEdit
    },
    {
        path: 'community',
        title: 'AI CONNECT 커뮤니티',
        name: CompetitionNotice.name,
        component: CompetitionNotice,
        meta: {
            anonymousCallable: true
        }
    },
    {
        path: 'community/edit',
        title: 'AI CONNECT 커뮤니티',
        name: CompetitionNoticeEdit.name,
        component: CompetitionNoticeEdit,
        meta: {
            anonymousCallable: false
        }
    },
    {
        path: 'community/detail/:postId',
        title: 'AI CONNECT 커뮤니티',
        name: CompetitionNoticeDetail.name,
        component: CompetitionNoticeDetail,
        meta: {
            anonymousCallable: true
        }
    },
    {
        path: 'myspace',
        title: 'My SPACE',
        name: MySpacePage.name,
        component: MySpacePage
    },
    {
        path: 'profile',
        title: '프로필',
        name: 'TaskProfile',
        component: TaskProfilePage,
    },
    {
        path: 'profile/password-edit',
        title: '비밀번호변경',
        name: 'TaskPasswordEdit',
        component: TaskPasswordEditPage,
    },
    {
        path: 'profile/password-chk',
        title: '비밀번호확인',
        name: 'TaskPasswrodChk',
        component: TaskPasswordChkPage,
    },
    {
        path: 'profile/edit',
        title: '프로필변경',
        name: 'TaskProfileEdit',
        component: TaskProfileEditPage,
    },

]

 export default {
    path: '/competition/detail/:competitionId/task/:taskId',
    title: '과제페이지',
    name: TaskRootPage.name,
    component: TaskRootPage,
    children: taskSubRoutes,
    redirect: {
        name: TaskInfo.name
    },
    meta: {
        GNBType: 'task',
        anonymousCallable: true        
    }
}
