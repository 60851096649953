<template>
    <div class="community-wrap contents-wrap">
        <div class="content-inner">
            <header class="page-header--line__section">
                <h2 class="page-header--line__title">Community</h2>
                <p class="page-header--line__sub-text">AI CONNECT는 정보를 공유하고 함께 성장하는 공동체입니다.<br />
                    AI 기술과 동향을 공유하고 토론하며 AI 지식과 기술을 발전시키고, 더 나은 미래를 만드는데 기여합니다.
                </p>
            </header>
            <BoardListView boardType='Community' @doSearch='getCommunity' @onlyMyQnA='getCommunity'
                :qnaTotalCount="qnaTotalCount" :qnaList="qnaList" />
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import BoardListView from '@/components/BoardListView'
import CommunityAPI from '@/lib/api/Community'
import modalControler from '@/helper/modalControler';
import utils from '@/helper/utils';


export default {
    name: 'CommunityList',
    components: {
        BoardListView,
    },
    setup(props, context) {
        const route = context.root.$route
        const state = reactive({
            qnaList: [],
            qnaTotalCount: 0,
            beforeSearchKeyword: null
        });

        const getCommunity = (options, searchKeyword, showNotice, clickedMoreBtn=false) => {
            if (searchKeyword !== '') {

                if (state.beforeSearchKeyword !== searchKeyword) {
                    state.qnaList = []
                    options.page = 1
                    options.itemsPerPage = 21
                    state.beforeSearchKeyword = searchKeyword
                } else {
                    state.qnaList = []
                    options.page = 1
                    options.itemsPerPage = 99999
                }

            } else {
                if (!clickedMoreBtn) {
                    state.beforeSearchKeyword = null
                    options.page = 1
                    options.itemsPerPage = 21
                    state.qnaList = []
                }
            }
            let keyword = searchKeyword ? utils.getPage(Object.assign(options, { searchText: searchKeyword, showNotice: showNotice })) :
                utils.getPage(Object.assign(options, { showNotice: showNotice }))
            modalControler.ShowLoading()
            CommunityAPI.getCommunityList(keyword).then(response => {
                if (!response.data.error) {
                    state.qnaList = state.qnaList.concat(response.data.data)
                    state.qnaTotalCount = response.data.totalCount
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        return {
            ...toRefs(state),
            getCommunity,
        }
    }
}
</script>