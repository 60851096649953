<template>
  <section class="main-banner__section">
    <div v-swiper:bannerSlider="swiperOption" class="swiper" ref="swiperRef">
      <div class="main-swiper-btns">
        <button type="button" class="swiper-btn-next" slot="button-next">
          <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#fff"
            icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
        </button>
        <button type="button" class="swiper-btn-prev" slot="button-prev">
          <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#fff"
            icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
        </button>
      </div>
      <div class="swiper-wrapper">
        <swiper-slide v-for="(c, idx) in mainBannerList" :key="idx">
          <div class="main-banner" v-bind:style="{ backgroundColor: c.backgroundColor }">
            <a v-bind:href="c.linkUrl" class="main-banner__link"
              v-bind:target="c.openLinkNewTab == true ? '_blank' : '_self'">
              <div class="main-banner__content">
                <h2 class="main-banner__title" v-html="c.title"></h2>
                <p class="main-banner__desc" v-html="c.description"></p>
              </div>
              <div class="main-banner__box">
                <img v-bind:src="c.imgUrl" class="main-banner__img" />
              </div>
            </a>
          </div>
        </swiper-slide>
      </div>
      <!--div class="swiper-pagination"></div-->
    </div>
  </section>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "@vue/composition-api";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'


export default {
  components: {
    SwiperSlide,
  },
  directive: {
    swiper: directive
  },
  props: [
    'mainBannerList',
  ],
  setup(props, context) {
    const swiperRef = ref(null);
    const state = reactive({
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 5000,     // bannder img slide time
          disableOnInteraction: false
        },
        initialSlide: 1,
      },
    })

    onMounted(() => {
      swiperRef.value.swiper.params.loopedSlides = props.mainBannerList.length
      swiperRef.value.swiper.update()
    })

    return {
      swiperRef,
      ...toRefs(state),
    }

  }
}
</script>