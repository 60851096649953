import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // progress
        loading: {
            circular: false,
            appBarLoading: false
        },
        // modal dialog
        dialog: {
            show: false,
            title: '',
            message: '',
            confirmButtonText: '',
            cancelButtonText: '',
            showTitle: false,
            showConfirmButton: false,
            showCancelButton: false,            
            didCloseDialog: null,
            isModal: false,
            maxWidth: '500px',
            maxHeight: '',
        },
        // modal alert
        alert: {
            show: false,
            title: '',
            message: '',
            color: '',
            icon: '',
            timeout: 8000
        },
        // right panel
        panel: {
            show: true,
        }
    },
    mutations: {
        SET_GLOBAL_LOADING(state, value) {
            state.loading.circular = value
        },
        SET_GLOBAL_DIALOG(state, params) {

        },
        SET_RIGHT_PANEL (state, value) {
            state.panel.show = value
        },
        SHOW_ALERT(state, params) {
            state.alert.show = true
            state.alert.title = params.title
            state.alert.message = params.message
            state.alert.timeout = params.timeout ? params.timeout : 5000;
            state.alert.color = params.color
            state.alert.icon = params.icon
        },
        SHOW_MODAL_DIALOG(state, params) {
            state.dialog.show = true
            state.dialog.title = params.title
            state.dialog.showTitle = !_.isEmpty(params.title)
            state.dialog.message = params.message
            state.dialog.confirmButtonText = _.isEmpty(params.confirmButtonText) ? 'Ok' : params.confirmButtonText
            state.dialog.cancelButtonText = _.isEmpty(params.cancelButtonText) ? 'Cancel' : params.cancelButtonText
            state.dialog.showConfirmButton = !_.isEmpty(params.confirmButtonText)
            state.dialog.showCancelButton = !_.isEmpty(params.cancelButtonText)
            state.dialog.didCloseDialog = params.didCloseDialog
            state.dialog.maxWidth = _.isEmpty(params.width) ? '500px' : params.width
            state.dialog.maxHeight = _.isEmpty(params.height) ? '' : params.height
        },
        DISMISS_MODAL_DIALOG(state, params) {
            state.dialog.show = false
            state.dialog.title = ''
            state.dialog.showTitle = false
            state.dialog.message = ''
            state.dialog.confirmButtonText = ''
            state.dialog.cancelButtonText = ''
            state.dialog.showConfirmButton = false
            state.dialog.showCancelButton = false
            state.dialog.didCloseDialog = null
            state.dialog.isModal = false
        },

    }
});


