<template>
    <div class="content-inner">
        <div class="content-group" v-if="isJoin >= 1">
            <header class="content-section__header">
                <h2 class="content-section__title">팀 구성</h2>
            </header>

            <ul class="list list__box list__box--line">
                <li class="list__item">팀 구성 시 참여한 모든 과제에서 같은 팀 구성원으로 적용됩니다. (각 과제별 다른 팀 구성 불가)
                </li>
                <li class="list__item">대회 상태가 접수중일 때만 팀을 구성할 수 있습니다. 대회가 진행 중일때는 팀원 수정이 불가합니다.
                </li>
                <li class="list__item">팀 리더가 팀 구성 완료 후 과제참여 버튼을 클릭해주시기 바랍니다.</li>
            </ul>
            <div class="team_name content-group">
                <header class="content-group__header">
                    <h4 class="content-group__title">팀명</h4>
                </header>
                <div class="team-textfield">
                    <div class="form-group">
                        <!-- <div class="form-group__title">팀명</div> -->
                        <v-text-field placeholder="팀명을 입력해주세요." persistent-hint class="common-form-control" outlined
                            hint="팀명은 결과제출 시 리더보드에 나타납니다." v-model="teamName" @blur="checkTeamName(teamName)"
                            @keyup="onKeyup($event, 'teamName')" :error-messages="errorStatus.teamNameErrMsg">
                            <template v-slot:append>
                                <div class="team-textfield__btn">
                                    <v-btn class="btn btn-small btn-primary" :disabled="errorStatus.teamNameErrMsg !== ''"
                                        @click.prevent="changeTeamName">팀명
                                        변경</v-btn>
                                </div>
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <div class="team-member">
                    <header class="content-group__header">
                        <h4 class="content-group__title">팀원
                            <span>
                                {{ teamMembersCnt }}</span>/{{ props.memberLimit }}
                        </h4>
                    </header>
                    <div class="team-member-list">
                        <div class="team-leader">
                            <div class="user-info">
                                <div class="profile-area">
                                    <div class="profile-img profile-img__size--300"
                                        v-tippy='{ arrow: true, arrowType: "round" }' :content="teamData.leader">
                                        <img :src="(teamData.leaderProfileImg) ? teamData.leaderProfileImg : require('@/assets/images/img-profile-gray.svg')"
                                            alt="" class="profile--img" />
                                    </div>
                                </div>
                                <div class="user">
                                    <div class="user__role">팀 리더</div>
                                    <div class="user__text">{{ teamData.leader }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="team-member-box" v-for="(m, idx) in teamData.teamuser" :key="'member-' + idx">
                            <div class="user-info">
                                <div class="profile-img profile-img__size--300"
                                    v-tippy='{ arrow: true, arrowType: "round" }' :content="m.NICKNAME">
                                    <img :src="(m.PROFILEIMG) ? m.PROFILEIMG : require('@/assets/images/img-profile-gray.svg')"
                                        alt="" class="profile--img" />
                                    <!--span class="tooltiptext">{{ team.leader }}</span-->
                                </div>
                                <div class="user">
                                    <div class="user__role">팀 멤버</div>
                                    <div class="user__text">{{ m.NICKNAME }}</div>
                                </div>
                            </div>
                            <div class="btn-group" v-if="store.accountInfo.nickname === teamData.leader">
                                <v-btn class="btn btn-secondary btn-xsmall" @click="toLeader(m)">리더로 변경</v-btn>
                                <v-btn class="btn btn-tertiary btn-xsmall" @click="kickTeamMember(m)">내보내기</v-btn>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="content-group">
                <header class="content-group__header">
                    <h4 class="content-group__title">다른 팀 초대하기</h4>
                </header>
                <div class="team-textfield">
                    <div class="form-group">
                        <v-text-field placeholder="닉네임, 이메일, 팀 이름, 이름 검색" persistent-hint hint="대회에 참여하지 않은 사용자는 검색되지 않습니다."
                            class="common-form-control" outlined v-model="teamSearchKeyword" @keydown.enter="searchTeam">
                            <template v-slot:append>
                                <div class="team-textfield__btn">
                                    <v-btn class="btn btn-small btn-primary" @click.prevent="searchTeam">검색</v-btn>
                                </div>
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <template v-if="teamList.length">
                    <div class="team-member-group" v-for="(team, idx) in teamList" :key="'team-' + idx">
                        <div class="user-info">
                            <div class="profile-img profile-img__size--300" v-tippy="{ arrow: true, arrowType: 'round' }"
                                :content="team.leader">
                                <img :src="(team.leaderProfileImg) ? team.leaderProfileImg : require('@/assets/images/img-profile-gray.svg')"
                                    alt="" />
                                <!--span class="tooltiptext">{{ team.leader }}</span-->
                            </div>
                            <div class="user">
                                <div class="user__role">{{ team.teamname }}</div>
                                <div class="user__text">{{ team.leader }}</div>
                                <div class="user__text" v-for="(m, idx) in team.teamuser" :key="'m-' + idx">{{
                                    m.NICKNAME
                                }}</div>
                            </div>
                        </div>
                        <div class="team-member-group__btn">
                            <v-btn class="btn btn-small btn-secondary" @click="requestJoinTeam(team)">초대 발송</v-btn>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="no-data" v-if="searched">
                        <p class="no-data__text">검색 대상이 없습니다.</p>
                    </div>
                </template>

            </div>
            <div class="team_member content-group">
                <header class="content-group__header">
                    <h4 class="content-group__title">내가 받은 초대</h4>
                </header>
                <template v-if="invites.length">
                    <div class="invite" v-for="(invite, idx) in invites" :key="'invite-' + idx">
                        <p><strong>{{ invite.teamname }}</strong> 팀에 초대받았습니다.</p>

                        <div class="btn-group" :key="idx">
                            <v-btn class="btn btn-tertiary btn-small" @click="deleteInvitation(invite)">거절</v-btn>
                            <v-btn class="btn btn-primary btn-small" @click="approveInvitation(invite)">수락</v-btn>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="no-data">
                        <div class="no-data__text">아직 받은 초대가 없습니다.</div>
                    </div>
                </template>

            </div>
        </div>
        <div v-else>
            <div class="no-data">
                <div class="no-data__text">참여중인 대회가 아닙니다.</div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import { debounce } from 'lodash'
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import validator from "@/config/formValidator"
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';

export default {
    name: 'TaskTeam',
    props: {
        status: {
            required: false,
            type: Number
        },
        memberLimit: {
            required: false,
            type: Number
        },
        isJoin: {
            required: false,
            type: Number
        },
    },
    components: {
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            searchFlag: false,
            teamName: '',
            teamData: {},
            teamSearchKeyword: '',
            teamMembersCnt: 0,
            maxTeamMembers: 6,
            teamId: 0,
            teamList: [],
            invites: [],
            showTeamModal: false,
            searched: false,
            errorStatus: {
                teamNameErrMsg: '',
            },
        });

        onMounted(() => {
            if (accountInfo.email) {
                getMyTeam()
                getJoinTeamInvites()
            }
        });

        const getMyTeam = () => {
            let params = {
                competitionId: route.params.competitionId
            }
            modalControler.ShowLoading()
            CompetitionApi.getMyTeam(utils.makeParams(params)).then(response => {
                if (!response.data.error) {
                    state.teamId = response.data.result.teamId
                    state.teamData = response.data.result
                    state.teamMembersCnt = response.data.result.teamuser.length + 1     // +1 은 리더
                    state.teamName = response.data.result.teamname
                } else {
                    // modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                // modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const checkTeamName = (teamName) => {
            state.errorStatus.teamNameErrMsg = validator.teamNameValidator(teamName)
            if (state.errorStatus.teamNameErrMsg !== '') return false
            else return true
        }

        const onKeyup = (e, type) => {
            if (type === 'teamName') {
                if (checkTeamName(state.teamName)) {
                    if (e.keyCode === 13) {
                        changeTeamName()
                    }
                } else {
                    return false
                }

            }
        }

        const getJoinTeamInvites = () => {
            let params = {
                competitionId: route.params.competitionId
            }
            CompetitionApi.getJoinTeamApprovalList(utils.makeParams(params)).then(response => {
                if (!response.data.error) {
                    state.invites = Object.assign(response.data.result, state.invites)
                }
            }).catch(err => {
                modalControler.ShowErrorToast('초대를 불러올 수 없습니다.')
            })
        }

        const changeTeamName = () => {
            if (props.isJoin === 0) {
                modalControler.ShowWarningToast('대회에 먼저 참여해주시기 바랍니다.')
                return
            }

            if (!checkTeamName(state.teamName)) return

            state.teamName = state.teamName.trim()
            if (!state.teamName || state.teamName.length === 0) {
                return modalControler.ShowWarningToast('팀명을 입력해주세요.')
            }

            let params = {
                competitionId: route.params.competitionId,
                changeTeamName: state.teamName
            }
            modalControler.ShowLoading()
            CompetitionApi.changeTeamName(params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('팀명 변경 완료')
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                modalControler.ShowErrorToast('팀명 변경에 실패했습니다.')
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const searchTeam = (e) => {
            if (props.isJoin === 0) {
                modalControler.ShowWarningToast('대회에 먼저 참여해주시기 바랍니다.')
                return
            }

            if (state.teamSearchKeyword.trim().length < 2) {
                modalControler.ShowWarningToast('두 글자 이상 검색해주세요.')
                return
            }
            modalControler.ShowLoading()
            state.searchFlag = true
            let params = {
                competitionId: Number(route.params.competitionId),
                searchText: state.teamSearchKeyword
            }
            CompetitionApi.searchTeam(utils.makeParams(params)).then(response => {
                if (!response.data.error) {
                    state.teamList = response.data.result
                    state.searched = true
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const toLeader = (member) => {
            modalControler.ShowModalDialog('리더 변경', '팀 리더를 변경하시겠습니까? \
                리더 권한을 잃게 되면 팀명 수정, 팀원 변경이 불가합니다.', '취소', '변경하기', (val) => {
                if (val) {
                    let params = {
                        competitionId: route.params.competitionId,
                        memberId: member.ID
                    }
                    CompetitionApi.switchTeamLeader(params).then(response => {
                        if (!response.data.error) {
                            modalControler.ShowSuccessToast('리더 변경 완료')
                            location.reload()
                        } else {
                            modalControler.ShowErrorToast(response.data.error_detail)
                        }
                    }).catch(err => {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    })
                }
            })
        }

        const kickTeamMember = (member) => {
            if (props.status !== 3) {
                modalControler.ShowWarningToast('대회 진행중에는 내보내기가 불가합니다.')
                return
            }

            modalControler.ShowModalDialog('팀원 변경', '해당 사용자를 팀에서 내보내시겠습니까?', '취소', '내보내기', (val) => {
                if (val) {
                    let params = {
                        competitionId: route.params.competitionId,
                        teamId: state.teamData.teamId,
                        memberId: member.ID
                    }
                    modalControler.ShowLoading()
                    CompetitionApi.kickTeamMember(params).then(response => {
                        if (!response.data.error) {
                            modalControler.ShowSuccessToast('팀원 내보내기 완료')
                            getMyTeam()
                        } else {
                            modalControler.ShowErrorToast(response.data.error_detail)
                        }
                    }).catch(err => {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                }
            })
        }

        const requestJoinTeam = (team) => {
            modalControler.ShowModalDialog('팀 병합', '초대장을 발송하시겠습니까?', '취소', '발송하기', (val) => {
                if (val) {
                    let params = {
                        competitionId: route.params.competitionId,
                        requestTeamId: team.teamId
                    }
                    // 팀 인원 초과 validation
                    if (state.teamMembersCnt >= props.memberLimit) {
                        modalControler.ShowWarningToast('대회 최대 팀 초대 인원수를 초과하였습니다.')
                        return
                    }
                    modalControler.ShowLoading()
                    CompetitionApi.requestJoinTeam(params).then(response => {
                        if (!response.data.error) {
                            state.teamList = state.teamList.filter(list => list.teamId !== team.teamId)
                            modalControler.ShowSuccessToast('성공적으로 초대를 보냈습니다.')
                            return
                        } else {
                            if (response.data.error_code === 7500) {
                                modalControler.ShowErrorToast(response.data.error_detail)
                            }
                        }
                    }).catch(err => {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                }
            })
        }

        const approveInvitation = (team) => {
            // 대회 진행중엔 수락 불가
            if (props.status === 4) {
                modalControler.ShowWarningToast('초대장 발송은 대회가 접수중일 때만 가능합니다.')
                return
            }

            modalControler.ShowModalDialog('팀 병합', '초대장을 수락하시겠습니까?', '취소', '수락하기', (val) => {
                if (val) {
                    let params = {
                        approvalId: team.approvalId,
                        competitionId: route.params.competitionId,
                        type: 'approve'
                    }
                    modalControler.ShowLoading()
                    CompetitionApi.approvalJoinTeam(params).then(response => {
                        if (!response.data.error) {
                            modalControler.ShowSuccessToast('초대를 수락했습니다.')
                            location.reload()
                        } else {
                            modalControler.ShowErrorToast(response.data.error_detail)
                        }
                        state.invites = state.invites.filter(list => list.approvalId !== team.approvalId)
                    }).catch(err => {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                }
            })
        }

        const deleteInvitation = (team) => {
            // 대회 진행중엔 거절 불가
            if (props.status === 4) {
                modalControler.ShowWarningToast('대회 진행 중에는 초대장을 거절 할 수 없습니다.')
                return
            }

            modalControler.ShowModalDialog('팀 병합', '초대장을 거절하시겠습니까?', '취소', '거절하기', (val) => {
                if (val) {
                    let params = {
                        approvalId: team.approvalId,
                        competitionId: route.params.competitionId,
                        type: 'reject'
                    }
                    modalControler.ShowLoading()
                    CompetitionApi.approvalJoinTeam(params).then(response => {
                        if (!response.data.error) {
                            modalControler.ShowSuccessToast('초대를 거절했습니다.')
                        } else {
                            modalControler.ShowErrorToast(response.data.error_detail)
                        }
                        state.invites = state.invites.filter(list => list.approvalId !== team.approvalId)
                    }).catch(err => {
                        modalControler.ShowErrorToast(err.response.data.error_detail)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                }
            })

        }

        const openTeamModal = () => {
            state.showTeamModal = true
        }


        return {
            ...toRefs(state),
            props,
            store,
            searchTeam,
            checkTeamName,
            changeTeamName,
            toLeader,
            kickTeamMember,
            requestJoinTeam,
            approveInvitation,
            deleteInvitation,
            openTeamModal,
            onKeyup,
        }
    }
}
</script>
<style scoped lang="scss"></style>


