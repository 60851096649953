import httpClient from './httpClient';

export default {

    // 연습과제 리스트
    getPracticeAssignmentList(param) {
        return httpClient.get(`/assignment/getAssignmentList${param}`)
    },

    // 과제 참여
    joinAssignment(param) {
        return httpClient.post(`/assignment/joinAssignment`, param);
    },
    // 과제 취소
    dropAssignment(param) {
        return httpClient.post(`/assignment/drop`, param);
    },    

    // 과제 목록
    getAssignmentList(param) {
        return httpClient.get(`/competition/getAssignmentList?competitionId=${param}`)
    },
    getAssignmentMyList(param) {
        return httpClient.get(`/assignment/getAssignmentMyList${param}`);
    },

    // 과제 상세 정보
    getAssignmentDetailInfo(param) {
        return httpClient.get(`/assignment/getAssignmentDetailInfo${param}`)
    },

    // 과제 리더보드 (public)
    getAssignmentLeaderboard(id, boardType, param) {
        return httpClient.get(`/assignment/${id}/leaderboards/${boardType}${param}`)
    },
    // 과제 공지/문의 
    getAssignmentQnaList(id, params) {
        return httpClient.get(`/assignment/${id}/getAssignmentQnaList${params}`);
    },

    // 코드 공유
    getCodeshareList(id, param) {
        return httpClient.get(`/assignment/${id}/getCodeshareList${param}`);
    },

    /**
     * 
     *   결과제출 관련
     */

    // 파일 형식
    getFileExt() {
        return httpClient.get(`/assignment/submit/file-ext`)
    },

    resultSubmit(file) {
        return httpClient.post(`/assignment/resultSubmit`, file, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    getResultSubmitList(param) {
        return httpClient.get(`/assignment/results${param}`);
    },
    lastSubmit(id, param) {
        return httpClient.patch(`/assignment/${id}/results/last`, param);
    },    

    // 결과제출 : 대용량 파일 업데이트
    multipartSubmitCreate(param) {
        return httpClient.post(`/assignment/results/multipart/create`, param)
    },
    multipartSubmitPart(param) {
        return httpClient.post(`/assignment/results/multipart/part`, param )
    },
    multipartSubmitComplete(param) {
        return httpClient.post(`/assignment/results/multipart/complete`, param)
    },

    // 제출 삭제 
    deleteSubmit(submitId, id) {
        return httpClient.delete(`/assignment/results/${submitId}?assignmentId=${id}`)
    },

    updateSubmitDesc(submitId, id, param) {
        return httpClient.put(`/assignment/results/${submitId}/description?assignmentId=${id}`, param, { headers: {'Content-Type': 'multipart/form-data'} })
    },

    /*
        과제 공지/문의 관련
    */ 
    getAssignmentQnaDetail(param) {
        return httpClient.get(`/assignment/getAssignmentQnADetail${param}`);
    },
    writeQnA(param) {
        return httpClient.post(`/assignment/writeQnA`, param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    updateQnA(param) {
        return httpClient.put('/assignment/updateQnA', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    deleteQnA(param) {
        return httpClient.put('/assignment/deleteQnA', param);
    },
    writeQnAReply(param) {
        return httpClient.post('/assignment/writeQnAReply', param);
    },
    updateQnAReply(param) {
        return httpClient.put('/assignment/updateQnAReply', param);
    },
    deleteQnAReply(param) {
        return httpClient.put('/assignment/deleteQnAReply', param);
    },    

    /*
        코드 공유 게시글 관련
    */ 
    getCodeshareDetail(param) {
        return httpClient.get(`/assignment/getCodeshareDetail${param}`);
    },
    writeCodeshare(param) {
        return httpClient.post(`/assignment/writeCodeshare`, param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    updateCodeshare(param) {
        return httpClient.put('/assignment/updateCodeshare', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    deleteCodeshare(param) {
        return httpClient.put('/assignment/deleteCodeshare', param);
    },
    writeCodeshareReply(param) {
        return httpClient.post(`/assignment/writeCodeshareReply`, param);
    },
    updateCodeshareReply(param) {
        return httpClient.put('/assignment/updateCodeshareReply', param);
    },
    deleteCodeshareReply(param) {
        return httpClient.put('/assignment/deleteCodeshareReply', param);
    },

    // 과제 데이터 
    getAssignmentData(id) {
        return httpClient.get(`/assignment/getAssignmentData${id}`);
    },

    // 과제 인증서
    getCertificationData(id) {
        return httpClient.get(`/assignment/${id}/certificate`);
    },


}
