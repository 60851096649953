<template>
    <article class="section-2__content">
        <BoardDetailView 
            detailType="TaskNotice" 
            @updateQnA="updateQnA"
            @deleteQnA="deleteQnA"
            @writeReply="writeQnaReply"
            @updateReply="updateQnaReply"
            @deleteReply="deleteQnaReply"
            @fetchMoreReply="fetchMoreReply"
            :replyList="replyList"
            :qnaData="qnaData"            
            />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';
import BoardDetailView from '@/components/BoardDetailView'

export default {
    name: 'TaskNoticeDetail',
    components: {
        BoardDetailView
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            // 댓글 더보기
            options: {
                page: 1,
                itemsPerPage: 0
            },            
            qnaData: {},
            replyList: [],            
        });      

        onMounted(() => {
            getTaskQnaDetail()
        });


        const getTaskQnaDetail = () => {
            let params = {
                Info: route.params.qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage
            }
            modalControler.ShowLoading()
            TaskApi.getAssignmentQnaDetail(utils.getPage(params)).then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    // state.replyList = response.data.result.replyList
                    state.replyList = []
                    response.data.result.replyList.forEach(el => {
                        state.replyList.push(el)
                    })                    
                } else {
                    modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                    router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
                }
            }).catch(err => {
                modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = () => {
            router.push({
                name: 'TaskNoticeEdit',
                params: {qnaId: route.params.qnaId, competitionId: route.params.competitionId, taskId: route.params.taskId},
                query: {type: 'update'}
            })
        }

        const deleteQnA = () => {
            let params = {
                assignmentId: route.params.taskId,
                assignmentQnaId: route.params.qnaId
            }
            modalControler.ShowLoading()
            TaskApi.deleteQnA(params).then(response => {
                router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnaReply = (content) => {
            let params = {
                assignmentId: route.params.taskId,
                assignmentQnaId: route.params.qnaId,
                content: content
            }
            TaskApi.writeQnAReply(params).then(response => {
                getTaskQnaDetail({Info: route.params.qnaId})
            }).catch(err => {

            })
        }        

        const updateQnaReply = (replyId, content) => {
            let params = {
                assignmentId: route.params.taskId,
                assignmentQnaId: route.params.qnaId,
                assignmentQnaReplyId: replyId,
                content: content                
            }
            TaskApi.updateQnAReply(params).then(response => {
                getTaskQnaDetail({Info: route.params.qnaId})

            }).catch(err => {

            })
        }

        const deleteQnaReply = (replyId) => {
            let params = {
                assignmentId: route.params.taskId,
                assignmentQnaId: route.params.qnaId,
                assignmentQnaReplyId: replyId,
            }
            TaskApi.deleteQnAReply(params).then(response => {
                getTaskQnaDetail({Info: route.params.qnaId})
            }).catch(err => {
                
            })
        }

        const fetchMoreReply = () => {
            if (state.qnaData.replycount > (state.options.page * state.options.itemsPerPage)) {
                ++state.options.page
                let params = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getTaskQnaDetail(params)
            }
        }        


        return {
            ...toRefs(state),
            getTaskQnaDetail,
            updateQnA,
            deleteQnA,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            fetchMoreReply,
        }

    }
}
</script>

<style scoped lang="scss">

.data_file_download{
    display: flex;
    padding: 2rem 0;
}

</style>