<template>
    <article class="section-2__content">
        <BoardEditView 
          v-if="!isLoading"
          editorTitle="과제문의" 
          :mode="mode" 
          :contentsTitle="contentsTitle"
          :contents="contents"
          :attachment="attachment"
          :isNotice="isNotice"
          :isOpen="isOpen"
          @writeQnA="writeQnA"
          @updateQnA="updateQnA"        
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from "@/lib/api/Task";
import modalControler from '@/helper/modalControler';
import BoardEditView from "@/components/BoardEditView"



export default {
    name: 'TaskNoticeEdit',
    components: {
        BoardEditView
    },
    setup(props, context) {
      const route = context.root.$route
      const router = context.root.$router
      const state = reactive({
        contentsTitle: '',
        contents: '',
        attachment: [],
        instances: [],
        qnaData: {},
        options: {
            page: 1,
            itemsPerPage: 10
        },     
        isNotice: false,
        isOpen: true,
        mode: 'write',
        isLoading: true,     
      });

      onMounted(async () => {
        if (route.query.type === 'update') {
          state.isLoading = true
          state.mode = 'update'
          await getTaskQnaDetail()
        } else {
          state.isLoading = false
        }
      });

      const getTaskQnaDetail = () => {
        let params = {
          Info: route.params.qnaId,
          // page: state.options.page,
          // itemsPerPage: state.options.itemsPerPage
        }
        modalControler.ShowLoading()
        TaskApi.getAssignmentQnaDetail(utils.makeParams(params)).then(response => {
          if (!response.data.error) {
            state.qnaData = response.data.result.data
            // 첨부파일 넣기 
            if (state.qnaData.files) {
              state.qnaData.files.forEach(file => {
                getBlobFromUrl(file.url, file.name).then(file => {
                  state.attachment.push(file)
                })
              })
            }
            /* 
              for QnA update
            */
            state.contentsTitle = state.qnaData.title
            state.contents = state.qnaData.content
            state.isNotice = state.qnaData.isnotice
            state.isopen = (state.qnaData.isopen === 1) ? true : false
            state.isLoading = false
          }          
        }).catch(err => {

        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const writeQnA = (params, files) => {
        const formData = new FormData()
        formData.append('qnaInfo', JSON.stringify(params))
        formData.append('fileList', files)
        modalControler.ShowLoading()
        TaskApi.writeQnA(formData).then(response => {
          router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
          // TODO : success alarm
        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const updateQnA = (params, files) => {
        const formData = new FormData()
        formData.append('qnaInfo', JSON.stringify(params))
        if (files && files.length && files.length >= 1) {
          formData.append('fileList', files[0])
        } else {
          formData.append('fileList', files)
        }
        modalControler.ShowLoading()
        TaskApi.updateQnA(formData).then(response => {
          // TODO : success alarm
          router.goTaskPages('TaskNotice', route.params.taskId, route.params.competitionId)
        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const getBlobFromUrl = async (url, name) => {
        const response = await fetch(url)
        const data = await response.blob()
        const file = new File([data], name, { type: name.split('.').pop() });
        processFile(file).then((dataURL) => {
          state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
        });

        return file
      }      

      const processFile = async (file) => {
        return await new Promise((resolve) => {
          let reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })
      }      

      return {
        ...toRefs(state),
        writeQnA,
        updateQnA
      }


    }
}
</script>

<style scoped lang="scss">

.app-g-btn-group {
  float: right;

  .app-g-btn {
    margin-left: 10px;
  }
}
</style>