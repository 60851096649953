import CommunityRootPage from './CommunityRoot'
import CommunityListPage from './CommunityList'
import CommunityDetailPage from './CommunityDetail'
import CommunityEditPage from './CommunityEdit'

const communitySubRoutes = [
    {
        path: 'list',
        title: '커뮤니티',
        name: CommunityListPage.name,
        component: CommunityListPage,
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'detail/:postId',
        title: '커뮤니티 글 상세',
        name: CommunityDetailPage.name,
        component: CommunityDetailPage,
        meta: {
            anonymousCallable: true,
        }
    },
    {
        path: 'edit',
        title: '커뮤니티 글 수정',
        name: CommunityEditPage.name,
        component: CommunityEditPage,
        meta: {
            anonymousCallable: false,
        }
    },
]

export default {
    path: '/community',
    title: '커뮤니티',
    name: CommunityRootPage.name,
    component: CommunityRootPage,
    children: communitySubRoutes,
    redirect: {
        name: CommunityListPage.name
    },
    meta: {
        anonymousCallable: true,
    }
}