<template>
    <section class="content-section--padding bg-gray" id="section-3" v-if="mappedScheduleList.length">
        <div class="content-inner">
            <header class="content-section__header content-section--both">
                <h2 class="content-section__title">일정</h2>
            </header>
            <div class="schedule-group">
                <template v-for="(s, idx) in mappedScheduleList">
                    <div class="process" :key="idx">
                        <div class="process-info" :class="{ 'process-info--on': isActiveDate(status, idx) }">
                            <span class="process-info__desc">{{ s.desc }}</span>
                            <span class="process-info__date">{{ s.date }}</span>
                        </div>
                        <div class="process__item">
                            <div class="process__point" :class="{ 'process__point--on': isActiveDate(status, idx) }">
                                <p></p>
                            </div>
                            <div class="process__divider process__divider--left"></div>
                            <div class="process__divider process__divider--right"></div>
                        </div>
                    </div>
                </template>
            </div>
            <!--div class="common-accordion accordion" id="accordionExample">
                <template>
                    <div class="common-accordion__item  accordion-item" v-for="(notice, idx) in showingList" :key="idx">
                        <div class="common-accordion__header accordion-header" :id="'heading-' + idx">
                            <button
                                class="common-accordion__button accordion-button  collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + idx"
                                aria-expanded="true"
                                :aria-controls="'collapse-' + idx"
                            >
                                <span class="accordion-text">{{ notice.title }} <span class="accordion-date">{{ notice.createdtm }}</span></span>
                            
                            </button>
                        </div>
                        <div
                            :id="'collapse-' + idx"
                            class="accordion-collapse collapse"
                            :aria-labelledby="'heading-' + idx"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="common-accordion__body accordion-body"  :key="'content-' + idx">
                                <div class="ProseMirror" v-html="props.scheduleDesc"></div>
                            </div>
                                
                        </div>
                    </div>
                </template>
            </div-->
            <details class="detail-shcedule">
                <summary class="detail-shcedule__title">경진대회 세부 일정</summary>
                <div ref="preview" class="detail-shcedule__content">
                    <div class="ProseMirror" v-html="scheduleDescription"></div>
                </div>
            </details>

        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import moment from 'moment';
import modalControler from '@/helper/modalControler';


export default {
    name: 'Shcedule',
    components: {

    },
    props: ['scheduleList', 'scheduleDesc', 'status'],
    setup(props, context) {
        const preview = ref(null)
        const state = reactive({
            mappedScheduleList: props.scheduleList.filter(v => v.expose === 1).map(v => ({
                desc: v.gubn,
                state: Math.sign(moment(v.scheduledt).diff(Date.now(), 'minutes')) === -1,
                date: moment(v.scheduledt).format('MM/DD HH:mm').replace(/00:00/g, ''),
                // time: moment(v.scheduledt).format('HH:mm').replace(/00:00/g, '-'),
                container: null,
                items: null,
                leftDivider: null,
                rightDivider: null,
                number: null,
                descWrapper: null,
                descNumber: null,
                descDate: null,
                activeIndex: 0,
                showingList: [],
            })),
            scheduleDescription: null,

        });

        onMounted(async () => {

            state.container = document.querySelectorAll(".process__item");
            state.items = document.querySelectorAll(".proc__point");
            state.leftDivider = document.querySelectorAll(".process__divider--left");
            state.rightDivider = document.querySelectorAll(".process__divider--right");
            state.number = document.querySelectorAll(".process__point");
            state.descWrapper = document.querySelectorAll(".process-info");
            state.descNumber = document.querySelectorAll(".process-info__desc");
            state.descDate = document.querySelectorAll(".process-info__date");
            // state.descWrapperAfter = document.querySelectorAll(".process-info::after")

            renderShceduleList(state.activeIndex)

            if (props.scheduleDesc) {
                state.scheduleDescription = await utils.paragraphSeperator(props.scheduleDesc)
                katexRender()
            }

        });

        watch(() => props.scheduleDesc, async (nv, ov) => {
            state.scheduleDescription = await utils.paragraphSeperator(props.scheduleDesc)
            katexRender()
        })

        // 일정에서 acive(pulse) 표시 
        const isActiveDate = (status, idx) => {
            if (status === 6) return false      // 대회가 FINALIZED 일때는 pulse 표시 x

            let isActive = false
            const lastIdx = state.mappedScheduleList.length - 1
            if (idx < lastIdx && idx > 0) {
                if (state.mappedScheduleList[idx - 1].state === true && state.mappedScheduleList[idx].state === false) {
                    state.activeIndex = idx
                    isActive = true
                } else if (state.mappedScheduleList[lastIdx].state === true) {
                    state.activeIndex = idx
                    isActive = false
                } else {
                    isActive = false
                }
            } else if (idx === lastIdx) {
                if (state.mappedScheduleList[idx].state === true) {
                    state.activeIndex = idx
                    isActive = true
                } else {
                    isActive = false
                }
            } else if (idx === 0) {
                if (state.mappedScheduleList[0].state === false) {
                    state.activeIndex = idx
                    isActive = true
                } else {
                    isActive = false
                }
            } else {
                isActive = false
            }
            return isActive
        }

        const katexRender = () => {
            const previewDiv = preview.value
            utils.katexRender(previewDiv)
        }


        const renderShceduleList = (idx) => {
            const lastIdx = state.mappedScheduleList.length - 1
            // Initialize
            try {
                state.leftDivider[0].style.display = "none"
                state.rightDivider[lastIdx].style.display = "none"
            } catch {

            }

            // Change colors
            for (var i = 0; i < state.mappedScheduleList.length; i++) {
                if (i > idx) {
                    state.leftDivider[i].style.borderColor = "rgba(209, 209, 209, 0.4)";
                    state.number[i].style.backgroundColor = "#d1d1d1";
                    state.number[i].style.width = "8px";
                    state.number[i].style.height = "8px";
                    state.number[i].style.color = "white";
                    state.rightDivider[i - 1].style.borderColor = "rgba(209, 209, 209, 0.4)";
                    state.descNumber[i].style.color = "#555";
                    state.descDate[i].style.color = "#555";
                    state.descWrapper[i].style.backgroundColor = "#efefef";

                } else if (i === idx) {
                    state.descNumber[i].style.color = "#fff";
                    state.descDate[i].style.color = "#fff";
                    state.descWrapper[i].style.borderTop = "#fff";
                } else {
                    state.descNumber[i].style.color = "rgba(0, 155, 250, 1)";
                    state.descDate[i].style.color = "rgba(0, 155, 250, 1)";
                    state.descWrapper
                }
            }
        }

        const dd = () => {

        }

        return {
            ...toRefs(state),
            isActiveDate,
            props,
            preview,
        }

    }

}
</script>
<style scoped lang="scss"></style>