<template>
    <div></div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, onUpdated,
} from '@vue/composition-api';
import { tokenStore } from '@/stores/auth';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'SSO',
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()

        onMounted(() => {
            SSOAuth(route.query.key, route.query.user_id, route.query.provider)
        })

        const SSOAuth = (key, user_id, provider) => {
            modalControler.ShowLoading()
            let params = {
                key: key,
                userId: user_id,
                provider: provider
            }
            Authenticate.SSOLogin(params).then(response => {
                if (!response.data.error) {
                    
                } else {
                    modalControler.ShowModalDialog('사용자 인증 실패', response.data.error_message, '', '확인', (val) => {})
                    router.goHome()
                    modalControler.HideLoading()
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }
    }
}

</script>