<template>
  <div class="dropdown">
    <div class="dropdown__box" :class="{ 'drop-down-box': this.fileList.length === 0 }" @dragover="dragover"
      @dragleave="dragleave" @drop="drop">
      <input type="file" multiple id="assetsFieldHandle" @change="onChange" ref="file" />
      <label for="assetsFieldHandle" class="dropdown__label">
        <span class="dropdown__label--icon">
          <icon-base viewBox="0 0 24 24" width="32" height="32" iconColor="#101216"
            icon-name="icon-plus"><icon-plus /></icon-base>
        </span>
        <p class="dropdown__label--text">
          파일을 드래그하거나 클릭하여 업로드해주세요.
        </p>
      </label>
    </div>
    <ul v-if="this.fileList.length" v-cloak class="upload-list">
      <li v-for="(file, index) in fileList" :key="index" class="upload-list__item">
        <div class="file">
          <span class="file-icon">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
              icon-name="icon-file"><icon-file /></icon-base>
          </span>
          <span class="file__name">{{ file.name }}</span>
          <span class="file__size">{{ calcSize(file.size) }}</span>
        </div>
        <button type="button" @click="remove(fileList.indexOf(file))" title="Remove file" class="remove_file_btn">
          <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
            icon-name="icon-closed"><icon-closed /></icon-base>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
// import CommonUIControl from "@/helper/CommonUIControl";
import { BIcon } from 'bootstrap-vue'
import modalControler from '@/helper/modalControler';

export default {
  name: 'FileInput',
  components: {

  },
  watch: {
    fileList: function () {
      this.emitChangeFileList();
    }
  },
  data() {
    return {
      fileList: [] //uploaded files
    }
  },
  methods: {
    onChange() {
      this.fileList = [...this.$refs.file.files];
    },
    remove(i) {
      this.fileList.splice(i, 1);
      this.$refs.file.value = null;
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-primary')) {
        event.currentTarget.classList.remove('bg-gray-9');
        event.currentTarget.classList.add('bg-primary');
      }
    },
    dragleave(event) {
      // Clean up
      this.cleanUp(event);
    },
    drop(event) {
      // let fileExtension = ['.csv', '.tsv', '.ftr', '.json', '.xml', '.txt', '.zip']
      event.preventDefault();
      let fileList = event.dataTransfer.files;

      // fileList = Array.from(fileList).filter(file => {
      //   return fileExtension.includes('.' + file.name.split('.').pop());
      // });
      // let NotAllow = event.dataTransfer.files;
      // NotAllow = Array.from(NotAllow).filter(file => {
      //   return !fileExtension.includes('.' + file.name.split('.').pop());
      // });
      // if(NotAllow.length !== 0) {
      //   modalControler.ShowWarningToast('해당 파일 형식은 제출하실 수 없습니다');
      // }
      this.$refs.file.files = this.fileListItems(fileList);
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      this.cleanUp(event);
    },
    fileListItems(files) {
      const b = new ClipboardEvent('').clipboardData || new DataTransfer();
      for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i])
      return b.files
    },
    cleanUp(event) {
      event.currentTarget.classList.add('bg-gray-9');
      event.currentTarget.classList.remove('bg-primary');
    },
    calcSize(value) {
      return Math.floor(value / 1024) + 'KB';
    },
    emitChangeFileList() {
      this.$emit('change', this.fileList);
    },
    removeAll() {
      this.fileList = [];
      this.$refs.file.value = null;
    }
  }
}
</script>

