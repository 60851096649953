<template>
    <v-app>
        <main class="login">
            <div class="login__inner vertical-align">
                <div class="login__forms">
                    <header class="login__header">
                        <button type="button" class="header-logo" @click="$router.push({ path: '/' })">
                            <img src="@/assets/images/img-logo.svg" alt="ai connect" />
                        </button>
                    </header>
                    <div class="login_form">
                        <div class="form-group">
                            <div class="form-group__title">이메일</div>
                            <v-text-field class="common-form-control" outlined placeholder="example@email.com" hide-details
                                clearable autofocus v-model="email" @keypress="onKeyPress($event, 'email')"></v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">비밀번호</div>
                            <v-text-field ref="passwordForm" class="common-form-control" outlined placeholder="password"
                                type="password" clearable hint="영문, 숫자, 특수문자 포함 최소 8자~20자" v-model="password"
                                @keypress="onKeyPress($event, 'password')"></v-text-field>
                        </div>
                        <div class="btn-inline btn-inline--group">
                            <v-btn class="btn-primary btn-large btn-full" @click="clickLOGIN"><strong>로그인</strong></v-btn>
                        </div>
                        <div class="login-link">
                            <div class="login-link__item">
                                <button class="btn-text--gray" type="button" @click.prevent="forgotPassword">
                                    비밀번호 찾기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="company-btns">
                    <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showMNC">
                        회사소개
                    </button>
                    <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showServicePolicy">
                        이용약관
                    </button>
                    <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showPrivacyPolicy">
                        개인정보처리방침
                    </button>
                </div>
            </div>
            <ResetPassword @dialogClose="resetPasswordVisible = false" :visible="resetPasswordVisible" />

        </main>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import { createPinia } from 'pinia';
import { tokenStore } from '@/stores/auth'
import { stateStore } from '@/stores/state';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import errorHandler from '@/helper/errorHandler'
import ResetPassword from '@/components/dialog/ResetPassword';

export default {
    name: 'SignInPage',
    components: {
        ResetPassword
    },
    setup(props, context) {
        const router = context.root.$router
        const store = tokenStore()
        const store2 = stateStore()
        const state = reactive({
            email: '',
            password: '',
            errorStatus: {
                emailErrormsg: '',
                passwordErrormsg: '',
            },
            resetPasswordVisible: false,
        });

        onMounted(() => {

        });

        const clickSignUp = () => {
            router.push({ name: 'SignupRoot' })
        }

        const clickLOGIN = async () => {

            if (state.email === '' || state.password === '') {
                modalControler.ShowWarningToast('이메일과 비밀번호를 입력해주세요.')
                return
            }

            modalControler.ShowLoading()
            Authenticate.getAuthToken(state.email, state.password).then(response => {
                if (response.data.success) {
                    store.storeAuthToken(response.data.result.access_token)
                    if (store2.savedURL.startsWith('/auth') || store2.savedURL.startsWith('/login') || !store2.savedURL) {
                        router.push({ name: 'CompetitionList' })
                    } else {
                        router.push({ path: store2.savedURL })
                    }
                    modalControler.HideLoading()
                } else {
                    modalControler.HideLoading()
                }
            }).catch(err => {
                // const msg = ``
                // modalControler.ShowModalDialog('로그인 실패', msg, '', '확인')
            }).finally(() => {
                modalControler.HideLoading();
            });
        }

        const onKeyPress = (e, type) => {
            if (e.keyCode === 13) {
                if (type === 'email') {
                    vm.refs.passwordForm.focus()
                } else if (type === 'password') {
                    clickLOGIN()
                }
            }
        }

        const showMNC = () => {
            window.open('http://mnc.ai', '_blank');
            // window.open('https://www.nipa.kr/', '_blank')
        }

        const showServicePolicy = () => {
            // router.push({ name: 'Service' })
            let routeData = router.resolve({ name: 'Service' })
            window.open(routeData.href, '_blank')
        }

        const showPrivacyPolicy = () => {
            // router.push({ name: 'Privacy' })
            let routeData = router.resolve({ name: 'Privacy' })
            window.open(routeData.href, '_blank')
        }

        const forgotPassword = () => {
            state.resetPasswordVisible = true
        }

        return {
            ...toRefs(state),
            onKeyPress,
            clickLOGIN,
            showMNC,
            showServicePolicy,
            showPrivacyPolicy,
            forgotPassword,
        }



    }
}
</script>

