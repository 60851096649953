<template>
    <div>
        <GNBCompetition />
        <Index v-if="$route.fullPath === '/'" />
        <router-view
            v-else
            :key="$route.fullPath"
        ></router-view>
        <Footer />
    </div>
</template>

<script>
import Index from '@/pages/main/Index';
import GNBCompetition from '@/components/gnb/GNBCompetition';
import Footer from '@/components/global/Footer';
export default {
    name: 'PlatformRoot',
    components: {
        GNBCompetition,
        Index,
        Footer,
    },

}
</script>