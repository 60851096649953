import httpClient from './httpClient';

export default {
    socialAuth(action) {
        return httpClient.get(`/auth/oauth/login?action=${action}`);
    },
    socialAuthLink(param) {
        return httpClient.post('/auth/oauth/link', param);
    },
    socialAuthSignUp(param) {
        return httpClient.post('/auth/oauth/signup', param);
    },
    socialAuthUnlink(param) {
        return httpClient.post('/auth/oauth/unlink', param);
    },
    socialAgreeRetry() {
        var action = 'signin'
        var type = 'retry'
        return httpClient.get(`/auth/oauth/login?action=${action}&type=${type}`);
    }
}