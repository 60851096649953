<template>
    <section class="large-content-section bg-blue">
        <div class="content-inner">
            <header class="large-content-section__header">
                <span class="large-content-section__sub-title">NO.1 AI CONNECT NEWS</span>
                <h2 class="large-content-section__title">
                    AI CONNECT가 궁금하신가요?</h2>
            </header>
            <div class="swiper__banner hover-btn swiper-unset">
                <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
                    <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                        icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                </button>
                <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                    <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                        icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                </button>
                <div v-swiper:bannerSlider="swiperOption" class="swiper">
                    <div class="swiper-wrapper">
                        <swiper-slide v-for="(n, i) in newsList" :key="'news-' + i">
                            <a @click.prevent.stop="$router.push({ name: 'CompetitionNewsDetail', params: { newsId: n.id } })"
                                class="card-thumb-box__link">
                                <div class="card-thumb">
                                    <div class="card-thumb__img">
                                        <img :src="(n.image.url) ?
                                                n.image.url : '../../assets/images/img-news-dummy-03.jpg'" alt="">
                                    </div>
                                </div>
                                <div class="card-thumb__content">
                                    <h3 class="card-thumb__title ellipsis-2">
                                        {{ n.subject }}
                                    </h3>
                                    <span class="card-thumb__text">
                                        {{ utils.sanitizeHtml(n.content, sanitizeOptions) }}
                                    </span>
                                </div>
                            </a>
                        </swiper-slide>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div class="large-content-section__btn-area">
                <a href="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/files/MNC-AI+Connect+Jul+2023_F.pdf"
                    target="_blank" class="btn btn-primary btn-large" download><strong>AI CONNECT
                        소개서</strong> <span class="icon-right">
                        <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                            icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                    </span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import {
    reactive,
    ref,
    toRefs,
} from "@vue/composition-api";
import { tokenStore } from "@/stores/auth";
import utils from '@/helper/utils';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { Pagination } from 'swiper';

export default {
    name: "NewsSwiper",
    components: {
        SwiperSlide,
    },
    directive: {
        swiper: directive
    },
    props: [
        'newsList',
    ],
    setup(props, context) {

        const route = context.root.$route;
        const router = context.root.$router;
        const store = tokenStore();
        const swiper = ref(null);
        const state = reactive({
            swiperOption: {
                loop: true,
                // observer: true,
                // observeParents: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                breakpoints: {

                    '768': {
                        slidesPerView: 1,
                    },
                    '1024': {
                        slidesPerView: 2,
                    },
                },
                navigation: {
                    nextEl: '.swiper-btn__middle__right',
                    prevEl: '.swiper-btn__middle__left'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            interval: null,
            sanitizeOptions: {
                allowedTags: [],
                allowedAttributes: {}
            },
        });

        return {
            utils,
            ...toRefs(state),
        }
    },
};
</script>

