<template>
    <div class="contents-wrap">
        <div class="content-inner">
            <BoardDetailView 
                detailType="CompetitionQuestion"
                @updateQnA="updateQnA"
                @deleteQnA="deleteQnA"
                @writeReply="writeQnaReply"
                @updateReply="updateQnaReply"
                @deleteReply="deleteQnaReply"
                @fetchMoreReply="fetchMoreReply"
                :replyList="replyList"
                :qnaData="qnaData"
                />    
        </div>
    </div>
</template>


<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import BoardDetailView from '@/components/BoardDetailView'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';


export default {
    name: 'CompetitionQuestionDetail',
    components: {
        BoardDetailView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const state = reactive({
            // 댓글 더보기
            options: {
                page: 1,
                itemsPerPage: 0
            },            
            qnaData: {},
            replyList: [],
        });

        onMounted(() => {
            getCompetitionQnaDetail()
        });

        const getCompetitionQnaDetail = () => {
            let params = {
                Info: route.params.qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage
            }
            modalControler.ShowLoading()
            CompetitionApi.getCompetitionQnaDetail(utils.getPage(params) + '&isview').then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    // state.replyList = response.data.result.replyList
                    state.replyList = []
                    response.data.result.replyList.forEach(el => {
                        state.replyList.push(el)
                    })
                } else {
                    modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                    router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
                }
            }).catch(err => {
                modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
                
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = () => {
            router.push({
                name: 'CompetitionQuestionEdit',
                params: {qnaId: route.params.qnaId},
                query: {type: 'update'}
            })
        }

        const deleteQnA = () => {
            let params = {
                competitionId: route.params.competitionId,
                competitionQnaId: route.params.qnaId
            }
            modalControler.ShowLoading()
            CompetitionApi.deleteQnA(params).then(response => {
                router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnaReply = (content) => {
            let params = {
                competitionId: route.params.competitionId,
                competitionQnaId: route.params.qnaId,
                content: content
            }
            CompetitionApi.writeQnAReply(params).then(response => {
                getCompetitionQnaDetail({Info: route.params.qnaId})
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 작성에 실패했습니다.')
            })
        }

        const updateQnaReply = (replyId, content) => {
            let params = {
                competitionId: route.params.competitionId,
                competitionQnaId: route.params.qnaId,
                competitionQnaReplyId: replyId,
                content: content                
            }
            CompetitionApi.updateQnAReply(params).then(response => {
                modalControler.ShowSuccessToast('댓글을 수정했습니다.')
                getCompetitionQnaDetail({Info: route.params.qnaId})

            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 수정에 실패했습니다.')
            })
        }

        const deleteQnaReply = (replyId) => {
            let params = {
                competitionId: route.params.competitionId,
                competitionQnaId: route.params.qnaId,
                competitionQnaReplyId: replyId,
            }
            CompetitionApi.deleteQnAReply(params).then(response => {
                getCompetitionQnaDetail({Info: route.params.qnaId})
            }).catch(err => {
                
            })
        }

        const fetchMoreReply = () => {
            if (state.qnaData.replycount > (state.options.page * state.options.itemsPerPage)) {
                ++state.options.page
                let params = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getCompetitionQnaDetail(params)
            }
        }


        return {
            ...toRefs(state),
            getCompetitionQnaDetail,
            updateQnA,
            deleteQnA,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            fetchMoreReply,
        }


    }

}
</script>
