import httpClient from './httpClient';

export default {
  getAllNewsList(page) {
    return httpClient.get(`/news/list${page}`);
  },
  getNewsDetail(id) {
    return httpClient.get(`/news/detail${id}`);
  },
}
