<template>
    <article class="section-2__content">
        <BoardListView 
            boardType='TaskNotice' 
            @onlyMyQnA='getTaskQnA'
            @doSearch='getTaskQnA'
            :qnaTotalCount="qnaTotalCount"
            :qnaList="qnaList"/>
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';
import BoardListView from '@/components/BoardListView'


export default {
    name: 'TaskNotice',
    components: {
        BoardListView
    },
    setup(props, context) {
        const route = context.root.$route
        const state = reactive({
            qnaList: [],
            qnaTotalCount: 0,               
        });

        const getTaskQnA = (options, searchKeyword, showNotice) => {
            let keyword = searchKeyword ? utils.getPage(Object.assign(options, { searchText: searchKeyword, showNotice: showNotice })) :
                                            utils.getPage(Object.assign(options,{ showNotice: showNotice }))
            modalControler.ShowLoading()
            // TaskApi.getAssignmentQnaList(route.params.taskId, keyword).then(response => {
            //     if (!response.data.error) {
            //         state.qnaList = response.data.result.data
            //         state.qnaTotalCount = response.data.result.totalcount
            //     }
            // }).catch(err => {
            //     modalControler.ShowErrorToast(err.response.error_detail)
            // }).finally(() => {
            //     modalControler.HideLoading()
            // })
        }


        return {
            ...toRefs(state),
            getTaskQnA,
        }
    }    
}
</script>

<style scoped lang="scss">


</style>