import _ from 'lodash';
import { defineStore } from 'pinia'
import Authenticate from '@/lib/api/Authenticate'

export const tokenStore = defineStore('authStore', {
    state: () => ({
        authToken: null,
        accountInfo: {
            userrole: 0,
        },
        deviceToken: null,
        isFcmReady: false,
    }),
    getters: {
        getAuthToken(state) {
            // 새로 고침 될때마다 store 가 날라감으로 localStorage 값 저장
            if (_.isEmpty(state.authToken)) {
                state.authToken = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_STORE_KEY)
            }
            return state.authToken
        },
        getAccountInfo(state) {
            return state.accountInfo
        },
        isAuthenticated(state) {
            if (_.isEmpty(state.authToken)) {
                state.authToken = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_STORE_KEY)
            }
            return !_.isEmpty(state.authToken)
        },

    },
    actions: {
        // 사용자 인증 (api 통신)
        async userAuthenticate() {
            try {
                const response = await Authenticate.getUserInfo()
                this.accountInfo = response.data.result
            } catch (error) {
                // do nothing
            }
        },
        storeAuthToken(token) {
            localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_STORE_KEY, token)
            this.authToken = token
        },
        removeAuthToken() {
            localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_STORE_KEY)
        },
    },
});