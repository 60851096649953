<template>
    <v-app>
        <section class="password-section">
            <div class="medium-content-inner">
                <header class="page-header--line--left__section">
                    <h2 class="page-header--line__title">비밀번호 입력</h2>
                    <ul class="list list__box list__box--line">
                        <li class="list__item">개인정보 수정을 위해 본인 확인이 필요합니다.</li>
                        <li class="list__item">비밀번호를 다시 한 번 입력해 주세요.</li>
                    </ul>
                </header>
                <form>
                    <div class="form-group">
                        <div class="form-group__title">E-mail</div>
                        <v-text-field class="common-form-control" placeholder="example@email.com" outlined hide-details
                            readonly v-model="email">
                        </v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">비밀번호</div>
                        <v-text-field class="common-form-control" ref="passwordEl" :type="pwShow1 ? 'text' : 'password'"
                            :append-icon="pwShow1 ? 'mdi-eye-off' : 'mdi-eye'" @click:append="pwShow1 = !pwShow1"
                            v-model="password" @keypress.enter="passwordSubmit" @blur="checkPassword(password)"
                            :error-messages="errorStatus.passwordErrMsg" placeholder="비밀번호를 입력해주세요." outlined>
                        </v-text-field>
                    </div>
                    <div class="content-group__btn-area">
                        <div class="btn-inline btn-inline--center">
                            <v-btn @click="$router.push({ name: 'CompetitionListProfile' })"
                                class="btn btn-tertiary btn-large "><strong>취소</strong></v-btn>
                            <v-btn @click="passwordSubmit" class="btn btn-large btn-primary"><strong>확인</strong></v-btn>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import validator from "@/config/formValidator"
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'PasswordChk',
    components: {

    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const state = reactive({
            email: '',
            password: '',
            pwShow1: false,
            errorStatus: {
                passwordErrMsg: '',
            },
            routeName: ''
        });

        onMounted(() => {
            state.email = store.getAccountInfo.email

            if (route.meta.GNBType === 'competition') {
                state.routeName = "CompetitionListProfileEdit"
            } else {
                state.routeName = "CompetitionDetailProfileEdit"
            }
        });

        const checkPassword = (passwd) => {
            state.errorStatus.passwordErrMsg = validator.passwordValidator(passwd)
        }

        const passwordSubmit = () => {
            modalControler.ShowLoading()
            Authenticate.getAuthToken(state.email, state.password).then(response => {
                if (response.data.success === true) {
                    modalControler.HideLoading()
                    router.push({ name: state.routeName, params: { pwcheck: true } })
                } else {
                    if (response.data.error_code !== 2106) {
                        modalControler.ShowErrorToast(response.data.error_detail)

                    }
                    state.password = ''
                    modalControler.HideLoading()
                }
            })
        }

        const profileEditPageName = computed(() => {

        });


        return {
            ...toRefs(state),
            checkPassword,
            passwordSubmit,
            profileEditPageName,
        }
    }
}

</script>

<style scoped lang="scss">
.font-04 {
    font-size: 14px;
}

.contents-title {
    font-size: var(--font-22);
    letter-spacing: -0.22px;
    display: block;
    width: 100%;
    padding: 80px 0 10px;
}

.setting-wrapper {
    overflow: hidden;
    border-radius: 4px;
    background-color: var(--white);
    padding: 30px 40px;
}

.form-wrapper {
    padding: 40px 20px;

    .app-g-form {
        width: 100%;
    }
}

.app-g-form {
    strong {
        font-size: var(--font-04);
        color: var(--gray4);
    }

    .login_form-label {
        font-size: 16px;
        color: var(--v2-font-title);
        font-weight: 700;
        margin-bottom: 20px;
    }
}
</style>