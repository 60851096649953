<template>

  <div id="Content">
    <header class="main_header">
      <div class="header_content" style="height: 60px;">
        <div class="header_title" @click="$router.push({ name: 'CompetitionList' })">
          <img src="@/assets/images/landing/aiconnect/logo.svg" alt="AIConnect" />
        </div>
        <div class="header_wrapper">
          <div class="header_menu_list">
            <button class="header_button" type="button" @click="$router.push({ name: 'CompetitionList' })">
              <span class="header_button_content">경진대회</span>
            </button>
          </div>
          <div class="header_menu_list">
            <button class="header_button" type="button" onclick="main()">
              <span class="header_button_content">과제</span>
            </button>
          </div>
          <div class="header_menu_list">
            <button class="header_button" type="button" @click="$router.push({ name: 'CompetitionNews' })">
              <span class="header_button_content">News</span>
            </button>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="header_status_wrapper">
          <button class="header_status_button" type="button" @click="$router.push({name: 'SignIn' })">
            <span class="header_button_content">
              <span class="header_button_content">
                <span class="header_button_content_txt">로그인</span>
              </span>
            </span>
          </button>
        </div>
      </div>
    </header>
    
    <!-- [S]intro-->
    <section class="section section_intro pb0">
        <div class="contents tac">
            <div class="wrap_text">
                <h1>No.1 AI Competition Platform</h1>
                <p class="intro_desc">인공지능 문제해결에 참여해보세요.</p>
                <ul class="list_intro">
                    <li>AI 전문 문제출제</li>
                    <li>맞춤형 운영</li>
                    <li>편리한 기능</li>
                    <li>철저한 보안</li>
                </ul>
                <div class="wrap_btn">
                    <button class="btn btn-lg btn-primary" @click="$router.push({ name: 'CompetitionList' })">홈페이지 접속하기</button>
                </div>
            </div>
            <div class="wrap_img">
                <img class="img img_computer" src="@/assets/images/landing/aiconnect/img_computer.png" alt="컴퓨터이미지">
                <img class="img img_cloud_l" src="@/assets/images/landing/aiconnect/img_cloud_l.png" alt="구름이미지">
                <img class="img img_cloud_r" src="@/assets/images/landing/aiconnect/img_cloud_r.png" alt="구름이미지">
                <img class="img img_srch" src="@/assets/images/landing/aiconnect/img_srch.png" alt="검색이미지">
                <img class="img img_thumb" src="@/assets/images/landing/aiconnect/img_thumb.png" alt="엄지척이미지">
                <img class="img img_dart" src="@/assets/images/landing/aiconnect/img_dart.png" alt="다트이미지">
                <img class="img img_ring" src="@/assets/images/landing/aiconnect/img_ring.png" alt="링이미지">
                <img class="img img_graph" src="@/assets/images/landing/aiconnect/img_grah.png" alt="그래프이미지">
            </div>
        </div>
    </section>
    <!-- [E]intro -->

    <!-- [S]arrange-->
    <section class="section section_arrange">
        <div class="contents tac"  data-aos="fade-up" data-aos-duration="1000">
            <h2>AI CONNECT</h2>
            <p class="section_desc">AI CONNECT는 인공지능 전문가들이 만든 경진대회 전용 온라인 플랫폼입니다.<br>문제 출제부터 데이터셋 구축, GPU 제공, 채점 서버 운영 등 경진대회의 End-to-End 서비스를 제공하고 있습니다.</p>
            <ul class="list_desc">
                <li>
                    <img src="@/assets/images/landing/aiconnect/bg_sect2-1.png" alt="우승컵 이미지">
                    국내 대규모<br>AI 경진대회 개최 이력
                </li>
                <li>
                    <img src="@/assets/images/landing/aiconnect/bg_sect2-2.png" alt="다양한 그래프 이미지">
                    폐쇄망 GPU 최다 보유로<br>데이터 유출없는 안전한<br>대회 운영 지원
                </li>
                <li>
                    <img src="@/assets/images/landing/aiconnect/bg_sect2-3.png" alt="두손이 악수하는 이미지">
                    수요-공급 매칭을 통한<br>기업 문제 해결
                </li>
            </ul>
        </div>
    </section>
    <!-- [E]arrange -->

    <!-- [S]list_type1-->
    <section class="section section_list_type1" style="background-color:#E9E9F6;overflow: hidden;">
        <div class="contents"  data-aos="fade-up" data-aos-duration="1000">
            <h2>Competitions</h2>
            <h5 class="pb20">사업화 지원금 총 60억원 이상의 대규모 대회 개최 이력으로 국내 유일무이 신뢰할 수 있는 AI 경진대회 플랫폼</h5>
            <p class="section_desc pb40">중소기업 사업화 지원, 해커톤, 네트워킹 행사 등 <br>다양한 유형의 경진대회를 아우르며 인공지능 전문 인력 육성 및 생태계 활성화를 촉진하고 있습니다.<br>각 대회 특성에 맞게 문제를 출제하고, 전문가를 섭외하여 평가 지표를 만듭니다.</p>
            <ul class="list_tag pb60">
                <li>#사회문제해결</li>
                <li>#기업 AI 혁신</li>
                <li>#네트워킹</li>
                <li>#사업화지원</li>
                <li>#홍보</li>
            </ul>
            <div class="wrap_list">
                <div class="list_data">
                    <dl>
                        <dd>{{ totalInfo.competitioncount }}</dd>
                        <dt>대회 개최 수</dt>
                    </dl>
                    <dl>
                        <dd>{{ totalInfo.totaluser }}</dd>
                        <dt>대회 참여 인원</dt>
                    </dl>
                    <dl>
                        <dd>{{ prizemoneyFormatter(totalInfo.totalprizemoney) }}</dd>
                        <dt>사업화 지원금 및 상금 규모</dt>
                    </dl>
                    <button class="btn btn-primary d_sm_none" onclick="main()">경진대회 모두보기</button>
                </div>
                <Slick ref="slick" :options="compSlickOptions"></Slick>
                <div class="slick-slider horizn_wrap">
                    <ul class="list_card slick-list" id="compSlide">
                      <template v-for="(item, index) in competitionList">
                        <li class="list_item slick-item" :key=index>
                          <a class="anchor_list" :href=" 'https://www.aiconnect.kr/main/competition/detail/'+ item.id +'/competitionInfo' ">
                            <div class="list_top">
                              <img alt="대회이미지" :src="item.imgurl" />
                            </div>
                            <div class="list_bottom">
                              <h5 class="ellipsis">{{ item.name }}</h5>
                              <p class="txt_info">{{ item.fromdt | competitionTime }} ~ {{ item.todt | competitionTime }}</p>
                              <ul class="list_tag_type2">
                                <li class="tag">{{ item.type }}</li>
                                <li class="tag">{{ item.host }}</li>
                                <li class="tag">{{ item.hostcompany }}</li>
                              </ul>
                            </div>
                          </a>
                        </li>
                      </template>
                    </ul>

                </div>
            </div>
            <div class="horizn_btn">
                <button class="btn_arrow" id="compePrev">
                    <i class="ico ico_prev"></i>
                </button>
                <button class="btn_arrow" id="compeNext">
                    <i class="ico ico_next"></i>
                </button>
            </div>
            <v-btn class="btn btn-primary d_lg_none" onclick="">경진대회 모두보기</v-btn>
        </div>
    </section>
    <!-- [E]list_type1 -->  

    <!-- [S]list_type2 -->
    <section class="section section_list_type2" style="padding: 80px 40px;">
        <div class="contents" data-aos="fade-up" data-aos-duration="1000">
            <h2>Tasks</h2>
            <h5 class="pb20">실제 기업 현장의 문제를 해결할 수 있는 문제 출제와, 상용화 할 수 있는 모델 개발을 연결하는 장</h5>
            <p class="section_desc pb40">인공지능을 활용하여 지역사회 및 기업 다방면의 문제를 해결할 수 있는 문제를 출제하고 있습니다.<br>이를 통해 공공 및 인간 분야의 데이터를 활용할 기회를 제공하며, 혁신적인 인공지능 기술을 확보합니다.<br>또한 경진대회에서 개발된 모델을 실제 기업 현장에 내재화할 수 있도록 지원합니다.</p>
            <div class="radio_type1 pb30 d_sm_none">
                <!-- <template v-for="(item, index) in taskCategoryList">
                    <label :key="index">
                        <input type="radio" :value="item.value" name="task_category" v-model="taskCategoryChecked">
                        <span>{{ item.title }}</span>
                    </label>
                </template> -->
                <label><input @change="taskCategoryChange($event)" type="radio" value="전체" name="task_category" checked><span>전체</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="금융" name="task_category"><span>금융</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="의료" name="task_category"><span>의료</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="제조" name="task_category"><span>제조</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="유통" name="task_category"><span>유통</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="환경" name="task_category"><span>환경</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="안전" name="task_category"><span>안전</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="교통" name="task_category"><span>교통</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="제조" name="task_category"><span>제조</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="에너지" name="task_category"><span>에너지</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="미디어" name="task_category"><span>미디어</span></label>
            </div>
            <div class="radio_type1 pb30 d_sm_none">
                <label><input @change="taskCategoryChange($event)" type="radio" value="패션" name="task_category"><span>패션</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="교육" name="task_category"><span>교육</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="언론" name="task_category"><span>언론</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="복지" name="task_category"><span>복지</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="재난" name="task_category"><span>재난</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="방역" name="task_category"><span>방역</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="농업" name="task_category"><span>농업</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="축산업" name="task_category"><span>축산업</span></label>
                <label><input @change="taskCategoryChange($event)" type="radio" value="임업" name="task_category"><span>임업</span></label>

            </div>
            <div class="wrap_select d_lg_none">
                <select class="ellipsis" @change="taskCategoryChange($event)">
                    <option>전체</option>
                    <option>금융</option>
                    <option>의료</option>
                    <option>제조</option>
                    <option>유통</option>
                    <option>횐경</option>
                    <option>안전</option>
                    <option>교통</option>
                    <option>제조</option>
                    <option>에너지</option>
                    <option>미디어</option>
                    <option>패션</option>
                    <option>교육</option>
                    <option>언론</option>
                    <option>복지</option>
                    <option>재난</option>
                    <option>방역</option>
                    <option>농업</option>
                    <option>축산업</option>
                    <option>임업</option>
                </select>
            </div>
            <Slick ref="slick2" :options="taskSlickOptions"></Slick>
            <div class="wrap_list_nav slick-slider">
                <ul class="list_text_card slick-list" id="taskList" style="padding-bottom: 15px;">
                    <template v-for="(item, index) in taskList">
                        <li :key="index" >
                            <a>
                                <i class="ico" :class="item.img"></i>
                                <h6 class="tit">{{ item.title }}</h6>
                                <p class="txt_info ellipsis">{{ item.company }}</p>
                            </a>
                        </li>
                    </template>
                </ul>
                <div class="horizn_btn">
                    <button class="btn_arrow" id="taskPrev">
                        <i class="ico ico_prev"></i>
                    </button>
                    <button class="btn_arrow" id="taskNext">
                        <i class="ico ico_next"></i>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <!-- [E]list_type2 -->  

    <!-- [S]side -->
    <section class="section section_side" style="background-color:#F7F2F5">
        <div class="contents" data-aos="fade-up" data-aos-duration="1000">
            <div class="warp">
                <h2>Service</h2>
                <h5 class="pb20">개방형, 폐쇄형, GPU 제공형 등 고객 니즈에 맞는 대회 운영 방식 채택 가능</h5>
                <p class="section_desc pb40">국내 최대 규모의 자체 GPU 서버를 보유하고 있습니다.<br>각 참가자들에게 1인 1서버를 제공하여 안전성을 보장하고 공정한 환경에서 대회를 진행할 수 있습니다.</p>
            </div>
            <div class="wrap">
                <img src="@/assets/images/landing/aiconnect/img_service.png">
            </div>
        </div>
    </section>
    <!-- [E]side -->  

    <!-- [S]leaderboard -->
    <section class="section section_leaderboard" v-if="publicLeaderBoards.length">
        <div class="contents tac" data-aos="fade-up" data-aos-duration="1000">
            <!-- <template v-for="(item, index) in publicLeaderBoards">

            </template> -->

            <h2 class="leaderboard_tit">Leaderboard</h2>
            <div class="horizn_btn d_sm_none">
                <button class="btn_arrow" id="boardPrev">
                    <i class="ico ico_prev"></i>
                </button>
                <button class="btn_arrow" id="boardNext">
                    <i class="ico ico_next"></i>
                </button>
            </div>

            <Slick ref="slick3" :options="leaderBoardOptions"></Slick>
            <div class="slick-slider" id="leaderBoardList">
                <template v-for="(leaderboard, index) in publicLeaderBoards">
                    <div :key="index">
                        <div class="tbl_task_tit">
                            <p>{{ leaderboard.assignmentName }}</p>
                        </div>
                        <table class="tbl_type1" :key="'leaderboard-' + index">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td class="tal">팀원</td>
                                    <td class="tal">팀명/점수</td>
                                    <td class="tar">제출수</td>
                                    <td>최종제출</td>
                                    <td>제출시간</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(record, index2) in leaderboard.leaderboard ">
                                    <tr :key="'leaderboard-' + index + '-' + index2">
                                        <td class="num emph">{{ record.ranking }}</td>
                                        <td class="data_img tal">
                                            <template v-for="(user, index3) in record.teamuser">
                                                <span v-if="user.PROFILEIMG === ''" class="img_user" :key="'user-' + index3 + user"></span>
                                                <span class="img_user" v-else :key="'user-' + index3 + user">
                                                    <img class="" style="border-radius: 50%"  :src="user.PROFILEIMG" width="40" height="40" />
                                                </span>
                                            </template>
                                        </td>
                                        <td class="tal tit">
                                            <p class="tit_team ellipsis">{{ record.name | teamNameEllipsis }}</p>
                                            <span class="score">{{ record.score }}</span>
                                        </td>
                                        <td class="bold tar d_sm_none">{{ record.submitcount }}</td>
                                        <td class="d_sm_none">
                                            <span class="flag">{{ record.createdtm | beforeDate }}</span>
                                        </td>
                                        <td class="d_sm_none">{{ record.createdtm }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
    </section>
    <!-- [E]leaderboard -->      
    
    <!-- [S]link --> 
    <section class="section section_array">
        <div class="contents" data-aos="fade-up" data-aos-duration="1000">
            <ul class="list_array">
                <li onclick="news()">
                    <a href="#">
                        <h4>News<i class="ico ico_arrow_horizon"></i></h4>
                        <p>AI CONNECT 의 새로운 소식</p>
                    </a>
                </li>
                <li onclick="faq()">
                    <a href="#">
                        <h4>Manual<i class="ico ico_arrow_diagonal"></i></h4>
                        <p>대회 참여방법 및 메뉴얼</p>
                    </a>
                </li>
                <li onclick="faq()">
                    <a href="#">
                        <h4>FAQ<i class="ico ico_arrow_diagonal"></i></h4>
                        <p>자주 묻는 질문</p>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <!-- [E]link --> 

    <section class="section section_marquee">
        <ul class="list_marquee">
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_1.png" alt="정보통신산업진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_2.png" alt="중소벤처기업진흥공단"/>
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_3.png" alt="한국지능정보사회진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_4.png" alt="경기도경제과학진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_5.jpg" alt="정보통신기획평가원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_6.png" alt="서울대학교병원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_7.png" alt="삼성서울병원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_8.png" alt="아주대학교병원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_9.png" alt="didim365" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_10.png" alt="hunet" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_11.png" alt="KAIST_AI" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_12.png" alt="mindsLAB" /> 
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_13.png" alt="naver_cloud" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_14.png" alt="한국축산데이터" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_15.png" alt="인공지능연구원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/company/co_16.png" alt="창업진흥원" />
            </li>
            <!-- <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee1.png" alt="정보통신산업진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee2.png" alt="중소벤처기업진흥공단" />
            </li> -->
            <!-- <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee3.png" alt="한국지능정보사회진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee4.png" alt="경기도" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee1.png" alt="정보통신산업진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee2.png" alt="중소벤처기업진흥공단" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee3.png" alt="한국지능정보사회진흥원" />
            </li>
            <li>
                <img src="@/assets/images/landing/aiconnect/img_marquee4.png" alt="경기도" />
            </li> -->
        </ul>
    </section>    
    <section class="footer" style="text-align:left">
        <div class="contents">
            <div class="top">
                <div class="left">
                    <a href="#"><img class="logo" src="@/assets/images/landing/aiconnect/logo.png" alt="AI CONNECT"></a>
                </div>
                <div class="right">
                    <a href="#">회사소개</a>
                    <a href="#">이용약관</a>
                    <a href="#">개인정보처리방침</a>
                </div>
            </div>
            <div class="bottom">
                (주)마인즈앤컴퍼니<br>
                대표 전상현,고석태  |  사업자번호 763-81-01040  |  서울시 서초구 서초대로77길 17, 13층 스파크플러스 1308호
                <p>© 2017-2021 MINDS AND COMPANY. All Rights Reserved</p>
            </div>
        </div>
    </section>    
  </div>
</template>

<script>
import api from '@/lib/api/Landingpage';
import utils from '@/helper/utils';
import Slick from 'vue-slick';
import moment from 'moment';
import $ from 'jquery';
import { landingPageTaskTitles, landingPageTaskList } from '@/config/vars';
// import GNBCompetition from '@/components/gnb/GNBCompetition';
var timer;


export default {
  name: 'LandingPage',
  components: { Slick},
  props: {
    msg: String
  },
  data() {
    return {
      competitionList: [],
      publicLeaderBoards: [],
      taskCategoryChecked: "전체",
      taskCategoryList : landingPageTaskTitles,
      taskList: landingPageTaskList,
      compSlickOptions: {
        slidesToShow: 0,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: null,
        prevArrow: null,
      },
      taskSlickOptions: {
        slidesToShow: 0,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        nextArrow: null,
        prevArrow: null,
        responsive: [
          {
            breakpoint: 880,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      },
      leaderBoardOptions: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false
      },
      totalInfo: {
          competitioncount: 0,
          totalprizemoney: 0,
          totalteam: 0,
          totaluser: 0
      }
    }
  },
  beforeCreate() {
    // window.addEventListener('load', this.a)
        window.onload = function() {
        var bannerLeft=0;
        var first=1;
        var last;
        var imgCnt=0;
        var $img = $(".list_marquee li");
        var $first;
        var $last;

        $img.each(function() {  
            $(this).css("left",bannerLeft);
            bannerLeft += $(this).width()+100;
            $(this).attr("id", "banner"+(++imgCnt));  
        });

        if( imgCnt > 7) { 
            last = imgCnt;
            timer = setInterval(function() {
                $img.each(function(){
                    $(this).css("left", $(this).position().left-1); // 1px씩 왼쪽으로 이동
                });
                $first = $("#banner"+first);
                $last = $("#banner"+last);
                if($first.position().left < -300) {    // 제일 앞에 배너 제일 뒤로 옮김
                    $first.css("left", $last.position().left + $last.width()+100 );
                    first++;
                    last++;
                    if(last > imgCnt) { last=1; }   
                    if(first > imgCnt) { first=1; }
                }
            }, 20);   
        }
      };  
  },
  created() {
    //   window.onload = function() {
    //     var bannerLeft=0;
    //     var first=1;
    //     var last;
    //     var imgCnt=0;
    //     var $img = $(".list_marquee li");
    //     var $first;
    //     var $last;

    //     $img.each(function() {  
    //         $(this).css("left",bannerLeft);
    //         bannerLeft += $(this).width()+100;
    //         $(this).attr("id", "banner"+(++imgCnt));  
    //     });

    //     if( imgCnt > 7) { 
    //         last = imgCnt;
    //         this.timer = setInterval(function() {
    //             $img.each(function(){
    //                 $(this).css("left", $(this).position().left-1); // 1px씩 왼쪽으로 이동
    //             });
    //             $first = $("#banner"+first);
    //             $last = $("#banner"+last);
    //             if($first.position().left < -300) {    // 제일 앞에 배너 제일 뒤로 옮김
    //                 $first.css("left", $last.position().left + $last.width()+100 );
    //                 first++;
    //                 last++;
    //                 if(last > imgCnt) { last=1; }   
    //                 if(first > imgCnt) { first=1; }
    //             }
    //         }, 20);   
    //     }
    //   };      
    this.a()
    this.fetchData()
  },
  beforeDestroy() {
      window.onload = null
      clearInterval(timer)
  },
  destroyed() {
    // window.removeEventListener('load', this.a)
    window.onload = null
    // clearInterval(timer)

  },
  beforeUpdate() {
    //   if (this.$refs.slick) {
    //       this.$refs.slick.destroy();
    //   }
      if (this.$refs.slick2) {
          this.$refs.slick2.destroy();
      }
    //   if (this.$refs.slick3) {
    //       this.$refs.slick3.destroy();
    //   }
  },
  updated() {
      this.$nextTick(() => {
          if (this.$refs.slick) {
              //   this.$refs.slick.create(this.compSlickOptions)
              $('#compSlide').slick({
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 4000,
                  nextArrow:$('#compeNext'),
                  prevArrow:$('#compePrev'),
                  responsive: [
                      {
                          breakpoint: 880,
                          settings: {
                              slidesToShow: 1,
                              touchThreshold: 22,
                              slidesToScroll: 1,
                              autoplaySpeed: 10000,
                              speed: 600,
                          }
                      }
                  ]
              });
          }
          if (this.$refs.slick2) {
              this.$refs.slick2.create(this.taskSlickOptions)
              $('#taskList').slick({
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  autoplay: false,
                  autoplaySpeed: 4000,
                  nextArrow:$('#taskNext'),
                  prevArrow:$('#taskPrev'),
                  responsive: [
                      {
                          breakpoint: 880,
                          settings: {
                              slidesToShow: 1
                          }
                      }
                  ]
              });
          }
          if (this.$refs.slick3) {
            //   this.$refs.slick3.create(this.leaderBoardOptions)
              $('#leaderBoardList').slick({
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 10000,
                  nextArrow:$('#boardNext'),
                  prevArrow:$('#boardPrev'),
                  responsive: [
                      {
                          breakpoint: 880,
                          settings: {
                              slidesToShow: 1
                          }
                      }
                  ]
              });
          }
        AOS.init();

      })
  },
  methods: {
    a() {
        var bannerLeft=0;
        var first=1;
        var last;
        var imgCnt=0;
        var $img = $(".list_marquee li");
        var $first;
        var $last;

        $img.each(function() {  
            $(this).css("left",bannerLeft);
            bannerLeft += $(this).width()+100;
            $(this).attr("id", "banner"+(++imgCnt));  
        });

        if( imgCnt > 7) { 
            last = imgCnt;
            setInterval(function() {
                $img.each(function(){
                    $(this).css("left", $(this).position().left-1); // 1px씩 왼쪽으로 이동
                });
                $first = $("#banner"+first);
                $last = $("#banner"+last);
                if($first.position().left < -300) {    // 제일 앞에 배너 제일 뒤로 옮김
                    $first.css("left", $last.position().left + $last.width()+100 );
                    first++;
                    last++;
                    if(last > imgCnt) { last=1; }   
                    if(first > imgCnt) { first=1; }
                }
            }, 20);   
        }
    },      
    taskCategoryChange(event) {
        $('#compSlide').slick('destroy');
        $('#taskList').slick('destroy');
        $('#leaderBoardList').slick('destroy');
        var selected = event.target.value
        if (selected === '전체') {
            this.taskList = landingPageTaskList
        } else {
            this.taskList = landingPageTaskList.filter(task => {
                if (selected === task.category) {
                    return task
                }
            })
        }
        this.$nextTick(() => {
            $('#compSlide').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                nextArrow:$('#compeNext'),
                prevArrow:$('#compePrev'),
                responsive: [
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 1,
                            touchThreshold: 22,
                            slidesToScroll: 1,
                            autoplaySpeed: 10000,
                            speed: 600,
                        }
                    }
                ]
            });
            $('#taskList').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 4000,
                nextArrow:$('#taskNext'),
                prevArrow:$('#taskPrev'),
                responsive: [
                    {
                        breakpoint: 880,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
              $('#leaderBoardList').slick({
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 10000,
                  nextArrow:$('#boardNext'),
                  prevArrow:$('#boardPrev'),
                  responsive: [
                      {
                          breakpoint: 880,
                          settings: {
                              slidesToShow: 1
                          }
                      }
                  ]
              });
        })

    },
    async fetchData() {
        let param_1 = {
            pageSize: 0
        }
        const competitionSummary = api.getCompetitionSummary();
        const competitionList = api.getCompetitionList(utils.makeParams(param_1));
        const leaderboardList = api.getLeaderboards();
        Promise.all([competitionSummary, competitionList, leaderboardList]).then((values) => {
            var res = values[0]
            var res1 = values[1]
            var res2 = values[2]

            if (res.data.http_status_code === 200) {
                this.totalInfo = res.data.result
            }

            if (res1.data.http_status_code === 200) {
                this.competitionList = res1.data.result.data
            }

            if (res2.data.http_status_code === 200) {
                this.publicLeaderBoards = res2.data.result
            }
        })
    },
    goToCompetition(competitionId) {
        window.open(`https://www.aiconnect.kr/main/competition/detail/${competitionId}/competitionInfo`).focus();
    },
    prizemoneyFormatter(str) {
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  filters: {
      beforeDate(date) {
          var now = moment()
          var date = moment(date)
          var before = now.diff(date, 'days')
          if (before >= 60) {
              return now.diff(date, 'months') + '개월 전'
          } else {
              return now.diff(date, 'days') + '일 전'
          }
      },
      competitionTime(date) {
          try {
            return date.split(" ")[0].replace(/-/g, '.')
          } catch {
              
          }
      },
      teamNameEllipsis(teamName) {
          if (window.innerWidth > 996) {
              return teamName
          }
          if (teamName.length > 6)
            return teamName.slice(0, 6) + '...'
          else return teamName
      },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-g-header {
    background: #383e45;
}
.tbl_task_tit {
    margin-bottom: 15px;
}

</style>
