<template>
    <section class="task-data-section">
        <article class="content-blocks">
            <header class="content-blocks__header">
                <h3 div class="content-blocks__title">데이터 설명</h3>
            </header>
            <div ref="dataPreview" class="content-blocks__decs ProseMirror" v-if="description">
                <div class="font-small" v-html="description"></div>
            </div>
            <div v-if="!description" class="no-data">
                <p class="no-data__text">작성된 데이터 설명이 없습니다.</p>
            </div>
        </article>
        <article class="content-blocks">
            <header class="content-blocks__header">
                <h3 div class="content-blocks__title">데이터 다운로드</h3>
            </header>
            <div class="list__box--area">
                <ul class="list list__box list__box--blue" v-if="sampleFile && sampleFile.url">
                    <li class="list__item"> Sample Data <a class="text-link" :href="sampleFile.url">{{
                        sampleFile.name
                    }}</a></li>
                </ul>
            </div>
            <div class="list__box--area">
                <ul class="list list__box list__box--red" v-if="!enableDownload">
                    <li class="list__text--red">
                        Test & Train 데이터는 대회가 진행 중일때만 다운로드할 수 있습니다.
                    </li>
                </ul>
            </div>
            <template v-if="enableDownload">
                <div class="list__box--area">
                    <ul class="list list__box list__box--blue" v-if="testFile && testFile.url">
                        <li class="list__item"> Test Data <a class="text-link" :href="testFile.url">{{ testFile.name
                        }}</a></li>
                    </ul>
                </div>
                <div class="list__box--area">
                    <ul class="list list__box list__box--blue" v-if="trainFile && trainFile.url">
                        <li class="list__item">Train Data <a class="text-link" :href="trainFile.url">{{ trainFile.name
                        }}</a></li>
                    </ul>
                </div>
            </template>
        </article>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import utils from '@/helper/utils'
import TaskApi from '@/lib/api/Task';
import { tokenStore } from '@/stores/auth';
import modalControler from '@/helper/modalControler';


export default {
    name: 'TaskData',

    props: {
        status: {
            type: Number,
            required: false,
        },
        isJoin: {
            type: Number,
            required: false,
        },
        agreed: {
            type: Boolean,
            required: false,
        },
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const dataPreview = ref(null)
        const state = reactive({
            // enableDownload: false,
            description: null,
            sampleFile: null,
            testFile: null,
            trainFile: null,
        });

        watch(() => state.description, (nv, ov) => {
            katexRender()
        })

        onMounted(() => {

            getTaskData()
        });

        const katexRender = () => {
            const previewDiv = dataPreview.value
            utils.katexRender(previewDiv)
        }

        const enableDownload = computed(() => {
            if (props.status === 4) {
                return true
            } else if (props.status === 6 && props.agreed) {
                return true
            }
            else return false
        });

        const getTaskData = () => {
            let params = {
                assignmentId: route.params.taskId
            }
            modalControler.ShowLoading()
            TaskApi.getAssignmentData(utils.makeParams(params)).then(response => {
                if (!response.data.error) {
                    state.description = response.data.result.data
                    state.sampleFile = response.data.result.samplefile
                    state.testFile = response.data.result.testfile
                    state.trainFile = response.data.result.trainfile
                } else {
                    modalConteroler.ShowErrorToast(response.data.message)
                }
            }).catch(err => {
                // TODO : error alert
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        return {
            props,
            ...toRefs(state),
            enableDownload,
            dataPreview,
        }
    }
}
</script>

<style scoped lang="scss"></style>