<template>
    <div class="rule-wrap contents-wrap">
        <div class="medium-content-inner">
            <section class="rule-section">
                <header class="content-section__header content-section--center">
                    <h2 class="content-section__title">규정 및 서약서 동의</h2>
                    <p class="content-section__text">
                        데이터 다운로드 및 제출을 위해 규정에 동의해주세요.
                    </p>
                </header>
                <form>
                    <div class="content-group">
                        <template v-for="(r, idx) in ruleList">
                            <div class="form-group" :key="idx">
                                <div class="form-group__title">{{ r.title }}</div>
                                <!-- <div class="rule__content" v-html="r.content"></div> -->
                                <div class="common-form">
                                    <div ref="preview" class="ProseMirror">
                                        <div v-html="r.content"></div>
                                    </div>
                                </div>
                                <div class="agree">
                                    <div class="agree__item">
                                        <v-checkbox class="common-checkbox" hide-details color="#009bfa" v-model="r.check"
                                            :label="r.title + '에 동의합니다.'" @change="onChangeCheckbox"></v-checkbox>
                                        <span class="essential">*</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="content-group">
                        <div class="btn-inline btn-inline--section btn-inline--center">
                            <v-btn class="btn btn-large btn-tertiary"
                                @click="$router.goCompetitionPages('CompetitionDetail', competitionId)">
                                <strong>취소</strong>
                            </v-btn>
                            <v-btn class="btn btn-large btn-primary" :disabled="enableSubmit" @click="submit">
                                <strong>동의</strong>
                            </v-btn>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import {
    computed,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    reactive,
    toRefs,
    nextTick,
    watch,
    ref,
} from "@vue/composition-api";
import utils from "@/helper/utils";
import { tokenStore } from '@/stores/auth';
import { stateStore } from "@/stores/state";
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';

export default {
    name: 'CompetitionAgreement',
    components: {

    },
    props: {},
    setup(props, context) {
        const router = context.root.$router;
        const route = context.root.$route;
        const store = tokenStore()
        const stateStorage = stateStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            description: '',
            isLoading: false,
            ruleList: [],
            competitionId: route.params.competitionId,

        })

        onMounted(() => {
            getCompetitionRule()
        })

        const getCompetitionRule = () => {
            modalControler.ShowLoading()
            CompetitionApi.getAgreement(state.competitionId).then(response => {
                if (response.data.http_status_code === 200) {
                    state.ruleList = response.data.result.filter(v => v.expose === 1).map(v => {
                        v.check = false
                        return v
                    })
                    modalControler.HideLoading()
                }
            }).catch(err => {
                modalControler.ShowErrorToast('대회규정을 불러올 수 없습니다.')
            })
        }


        const onChangeCheckbox = () => {
            // state.ruleList = state.ruleList.concat()
        }


        const enableSubmit = computed(() => {
            return !(
                checkRuleAgreements()
            )
        })


        const checkRuleAgreements = () => {
            let bool = true
            state.ruleList.forEach(r => {
                if (!r.check) bool = false
            })
            return bool
        }

        const submit = () => {
            modalControler.ShowLoading()
            CompetitionApi.agreePracticeSubmit(state.competitionId).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('대회 규정에 동의했습니다.')
                    if (route.params.taskId) {
                        router.push({
                            name: 'TaskInfo',
                            params: {
                                competitionId: route.params.competitionId,
                                taskId: route.params.taskId
                            }
                        })
                    } else {
                        router.push({
                            name: 'CompetitionDetail',
                            params: {
                                competitionId: route.params.competitionId,
                            }
                        })

                    }
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }


        return {
            ...toRefs(state),
            submit,
            enableSubmit,
            onChangeCheckbox,

        }
    }
}
</script>