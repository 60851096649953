<template>
    <div class="apply-wrap contents-wrap">
        <div class="medium-content-inner">
            <section class="apply-section">
                <header class="page-header--line__section">
                    <h2 class="page-header--line__title">대회참여 신청</h2>
                </header>
                <form>
                    <article class="content-group" v-if="registForms.length">
                        <header class="content-group__header">
                            <h3 class="content-group__title">참여신청서</h3>
                            <ul class="list list__box list__box--line">
                                <li class="list__item">해당 대회는 별도의 대회 참여신청서를 제출해야 대회 참여가 완료됩니다. </li>
                                <li class="list__item">양식을 다운로드 받아 작성 후 제출해주시기
                                    바랍니다. </li>
                            </ul>
                        </header>
                        <div class="file-list">
                            <template v-for="(form, idx) in registForms">
                                <span :key="idx">
                                    <a :href="form.url" class="file-list__link">
                                        <font-awesome-icon :icon="['fas', 'download']" />

                                        {{ getFileName(form.url) }}
                                    </a>
                                </span>
                            </template>
                        </div>
                        <div>
                            <FileInput ref="file" v-on:change="onChangeFile" />
                        </div>
                    </article>
                    <template v-for="(r, idx) in ruleList">
                        <article class="form-group" :key="idx">
                            <div class="form-group__title">{{ r.title }}</div>
                            <!-- <div class="rule__content" v-html="r.content"></div> -->
                            <div class="common-form">
                                <div class="font-small ProseMirror" v-html="r.content"></div>
                            </div>
                            <div class="agree">
                                <div class="agree__item">
                                    <v-checkbox class="common-checkbox" hide-details color="#009bfa" v-model="r.check"
                                        :label="r.title + '에 동의합니다.'" @change="onChangeCheckbox"></v-checkbox>
                                    <span class="essential">*</span>
                                </div>
                            </div>
                        </article>
                    </template>
                    <article class="content-group">
                        <header class="content-group__header">
                            <h3 class="content-group__title">개인정보 입력</h3>
                            <ul class="list list__box list__box--line">
                                <li class="list__item">대회 참가자들에게 관련 정보를 문자 및 메일로 안내드리고 있습니다. </li>
                                <li class="list__item">반드시 확인 가능한 연락처를
                                    입력해 주세요. </li>
                            </ul>
                        </header>
                        <v-app>
                            <div class="form-group">
                                <div class="form-group__title">
                                    이름
                                </div>
                                <v-text-field v-model="form.name" class="common-form-control" outlined hide-details readonly
                                    disabled placeholder="이름을 입력해주세요."></v-text-field>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">
                                    이메일
                                </div>
                                <v-text-field v-model="form.email" placeholder="email@example.com"
                                    class="common-form-control" outlined hide-details readonly disabled></v-text-field>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">휴대폰 번호</div>
                                <v-text-field v-model="form.mobile" placeholder="본인 인증이 필요합니다." class="common-form-control"
                                    outlined hide-details readonly disabled>
                                    <template v-slot:append>
                                        <v-btn class="btn-small btn-primary" @click="niceCertificate">
                                            {{ btnName }}
                                        </v-btn>
                                        <form name="form_chk" method="post">
                                            <input type="hidden" name="m" value="checkplusService">
                                            <!-- 필수 데이타로, 누락하시면 안됩니다. -->
                                            <input type="hidden" name="EncodeData" :value="sEncData">
                                            <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
                                        </form>
                                    </template>
                                </v-text-field>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">소속기관</div>
                                <v-select v-model="selectedBelong" :items="belongs" class="common-form-control" outlined
                                    hide-details single-line label="소속기관 유형을 선택해주세요."></v-select>
                            </div>
                            <div class="form-group form-group__detail">
                                <div class="form-group__title">소속기관명</div>
                                <v-text-field v-model="form.belongname" class="common-form-control" outlined single-line
                                    placeholder="소속기관 이름을 입력해주세요." @keyup="onKeyup($event, 'belongname')"
                                    @blur="checkBelongName(form.belongname)"
                                    :error-messages="errorStatus.belongNameErrMsg"></v-text-field>
                            </div>
                            <div class="form-group">
                                <div class="form-group__title">생년월일</div>
                                <v-menu :close-on-content-click="false" offset-y transition="scale-transition"
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="form.birth" readonly single-line class="common-form-control"
                                            outlined hide-details :nudge-right="40" v-bind="attrs" v-on="on"
                                            prepend-inner-icon="mdi-calendar" disabled></v-text-field>
                                    </template>
                                    <!-- <v-date-picker
                                                    locale="ko"
                                                ></v-date-picker> -->
                                </v-menu>
                            </div>
                            <div class="content-group__btn-area">
                                <div class="btn-inline btn-inline--center">
                                    <v-btn class="btn btn-large btn-tertiary"
                                        @click="$router.goCompetitionPages('CompetitionDetail', competitionId)">
                                        <strong>취소</strong>
                                    </v-btn>
                                    <v-btn class="btn btn-large btn-primary" :disabled="enableSubmit" @click="submitApply">
                                        <strong>대회참여</strong>
                                    </v-btn>
                                </div>
                            </div>
                        </v-app>
                    </article>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, provide
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';
import jwtDecode from 'jwt-decode';
import { BIcon } from 'bootstrap-vue'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import httpClient from '@/lib/api/httpClient';
import Authenticate from '@/lib/api/Authenticate';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import validator from "@/config/formValidator";
import FileInput from "@/components/competition/FileInput";

export default {
    name: 'CompetitionApply',
    components: {
        FileInput,
    },
    setup(props, context) {

        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const stateStorage = stateStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            sanitizeOptions: {      // 모든 tag 제거
                allowedTags: [],
                allowedAttributes: {}
            },
            competitionId: route.params.competitionId,
            selectedBelong: accountInfo.belong,
            date: "",
            ruleList: [],
            sEncData: null,
            isCertified: false,
            niceCertified: accountInfo.iscertified,
            form: {
                name: accountInfo.name,
                email: accountInfo.email,
                mobile: accountInfo.mobile,
                belong: accountInfo.belong,
                belongname: accountInfo.belongname,
                birth: accountInfo.dateofbirth,
                agreeUsageAccountInfo: false,
                agreeMarketing: false,
            },
            errorStatus: {
                nameErrMsg: '',
                emailErrMsg: '',
                belongNameErrMsg: '',
            },
            checkAllAgreement: false,
            profile: {},
            registForms: [],
            registForm: null,
        });

        const belongs = computed(() => {
            return [
                "중소기업",
                "대학교",
                "대기업",
                "개인사용자",
                "정부/공공기관",
                "연구기관(민간)",
                "기타"
            ]
        });

        onMounted(() => {
            getCompetitionRegistForms()
            getCompetitionRule()
            getNicesEncData()
            makeFunction()
            stateStorage.removeGnbItems()       // gnb list 지운다

        });

        const btnName = computed(() => {
            return (state.niceCertified) ? "재인증" : "본인인증"
        });

        const checkName = (name) => {
            state.errorStatus.nameErrMsg = validator.nameValidator(name)
        }

        const checkBelongName = (belongname) => {
            state.errorStatus.belongNameErrMsg = validator.belongNameValidator(belongname)
        }

        const onKeyup = (e, type) => {
            if (type === 'name') {
                checkName(state.form.name)
            } else if (type === 'belongname') {
                checkBelongName(state.form.belongname)
            }

        }

        const enableSubmit = computed(() => {

            return !(
                checkRuleAgreements() &&
                state.form.name &&
                state.form.email &&
                state.form.mobile &&
                state.form.birth &&
                state.selectedBelong &&
                state.form.belongname &&
                state.niceCertified
            )
        });

        const submitApply = () => {

            if (state.registForms.length) {
                if (!state.registForm || state.registForm.length === 0) {
                    modalControler.ShowWarningToast('대회 참가 신청서가 업로드 되지 않았습니다.')
                    return
                }
            }
            modalControler.ShowLoading()
            let params = {
                agreeinfoterms: state.form.agreeMarketing
            }
            const formdata = new FormData()
            formdata.append('joinInfo', new Blob([JSON.stringify(params)], { type: 'application/json' }))
            if (state.registForms.length) {
                formdata.append('applyFile', state.registForm[0])
            }

            CompetitionApi.joinCompetition(state.competitionId, formdata).then(response => {
                if (!response.data.error) {
                    state.profile = store.getAccountInfo;
                    state.profile.info = {};
                    state.profile.info.nickname = accountInfo.nickname;
                    state.profile.info.belong = (state.selectedBelong) ? state.selectedBelong : accountInfo.belong;
                    state.profile.info.belongname = (state.form.belongname) ? state.form.belongname : accountInfo.belongname;
                    state.profile.info.email = state.form.email;
                    state.profile.info.mobile = state.form.mobile;
                    state.profile.info.dateofbirth = state.form.birth
                    state.profile.info.name = state.form.name;
                    state.profile.info.iscertified = false;

                    let formData = new FormData()
                    const param = window.btoa(unescape(encodeURIComponent(JSON.stringify(state.profile))));
                    formData.append('data', param)

                    Authenticate.updateProfile(formData).then(response => {
                        if (!response.data.error) {
                            modalControler.ShowSuccessToast('대회 참여 완료! 참여할 과제를 선택해주세요.')
                            router.goCompetitionPages('CompetitionDetail', route.params.competitionId)
                        } else {
                            modalControler.ShowErrorToast('프로필 업데이트에 실패했습니다.')
                        }
                    }).catch(err => {
                        modalControler.ShowErrorToast(err)
                    }).finally(() => {
                        modalControler.HideLoading()
                    })
                } else {
                    modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                modalControler.ShowErrorToast('대회 참가에 실패했습니다.')
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const getCompetitionRegistForms = () => {
            CompetitionApi.getCompetitionRegistForms(state.competitionId).then(response => {
                if (!response.data.error) {
                    state.registForms = response.data.result
                }
            }).catch(err => {
                modalControler.ShowErrorToast('참가신청서를 불러올 수 없습니다.')
            })
        }

        const getFileName = (url) => {
            return decodeURI(new URL(url).pathname.replace(/^.*[\\\\/]/, ''))
        }

        const getCompetitionRule = () => {
            modalControler.ShowLoading()
            CompetitionApi.getAgreement(state.competitionId).then(response => {
                if (response.data.http_status_code === 200) {
                    state.ruleList = response.data.result.filter(v => v.expose === 1).map(v => {
                        v.check = false
                        return v
                    })
                    modalControler.HideLoading()
                }
            }).catch(err => {
                modalControler.ShowErrorToast('대회규정을 불러올 수 없습니다.')
            })
        }

        const onChangeCheckbox = () => {
            // state.ruleList = state.ruleList.concat()
        }

        const checkAll = () => {
            state.checkAllAgreement ? (state.form.agreeUsageAccountInfo = true, state.form.agreeMarketing = true) :
                (state.form.agreeUsageAccountInfo = false, state.form.agreeMarketing = false)
        }

        const checkTerms = () => {
            if (state.form.agreeUsageAccountInfo && state.form.agreeMarketing) state.checkAllAgreement = true
            else state.checkAllAgreement = false
        }

        const checkRuleAgreements = () => {
            let bool = true
            state.ruleList.forEach(r => {
                if (!r.check) bool = false
            })
            return bool
        }

        const onChangeFile = ($event) => {
            state.registForm = $event
            // TODO : 임시로 2개이상 막음
            if (state.registForm.length > 1) {
                modalControler.ShowWarningToast('1개의 파일만 업로드 할 수 있습니다.')
                state.registForm.splice(0, 1)
            }
        }

        // nice 필수 데이터 호출
        const getNicesEncData = () => {
            httpClient.get('/auth/nice').then(({ data } = response) => {
                state.sEncData = data.result
            });
        }

        // nice 본인인증 팝업창
        const niceCertificate = () => {
            window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            document.form_chk.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
            document.form_chk.target = 'popupChk';
            document.form_chk.submit();
        }


        return {
            utils,
            ...toRefs(state),
            belongs,
            niceCertificate,
            onChangeCheckbox,
            checkAll,
            checkTerms,
            enableSubmit,
            btnName,
            submitApply,
            checkName,
            checkBelongName,
            onKeyup,
            getFileName,
            onChangeFile,
        }

        // nice 본인인증 response 함수
        function makeFunction() {
            window.niceCertificate = async (token) => {
                const query = jwtDecode(token);
                if (moment().diff(moment(query.birthdate).format('YYYY-MM-DD'), 'years') < 14) {
                    modalControler.ShowWarningToast('14세 미만은 참여 하실 수 없습니다.')
                    return
                }

                state.profile = store.getAccountInfo;
                state.profile.info = {};
                state.profile.info.nickname = accountInfo.nickname;
                state.profile.info.belong = (state.selectedBelong) ? state.selectedBelong : accountInfo.belong;
                state.profile.info.belongname = (state.form.belongname) ? state.form.belongname : accountInfo.belongname;
                state.profile.info.token = token;
                state.profile.info.email = accountInfo.email;
                state.profile.info.mobile = utils.formatPhoneNumber(query.mobile);
                state.form.mobile = utils.formatPhoneNumber(query.mobile)
                state.profile.info.dateofbirth = moment(query.birthdate).format('YYYY-MM-DD');
                state.form.birth = moment(query.birthdate).format('YYYY-MM-DD')
                state.profile.info.name = query.name;
                state.form.name = query.name
                state.profile.info.iscertified = true;

                let formData = new FormData()
                const param = window.btoa(unescape(encodeURIComponent(JSON.stringify(state.profile))));
                formData.append('data', param)

                Authenticate.updateProfile(formData).then(response => {
                    if (!response.data.error) {
                        state.niceCertified = true
                        modalControler.ShowSuccessToast('본인인증이 완료되었습니다.')
                    } else {
                        modalControler.ShowErrorToast('본인인증에 실패했습니다. 다시 시도해주세요.')
                    }
                }).catch(err => {
                    modalControler.ShowErrorToast('본인인증 요청 실패')
                });
            }

        }
    }
}
</script>

<style scoped lang="scss">
.file-list {
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #009bfa;
}
</style>