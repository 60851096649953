<template>
    <div class="board-wrap contents-wrap">
        <div class="content-inner">
            <BoardEditView 
            v-if="!isLoading"
            editorTitle="게시글 작성"
            :boardType="'CompetitionQuestion'"
            :mode="mode" 
            :contentsTitle="contentsTitle"
            :contents="contents"
            :attachment="attachment"
            :isNotice="isNotice"
            :isOpen="isOpen"
            @writeQnA="writeQnA"
            @updateQnA="updateQnA"
            />    
        </div>
    </div>

</template>


<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import BoardEditView from "@/components/BoardEditView"

export default {
    name: 'CompetitionQuestionEdit',
    components: {
        BoardEditView
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            contentsTitle: '',
            contents: '',
            attachment: [],
            instances: [],
            qnaData: {},
            options: {
                page: 1,
                itemsPerPage: 10
            },
            isNotice: false,
            isOpen: true,
            mode: 'write',
            isLoading: true,
        });

        onMounted(async () => {
            if (route.query.type === 'update') {
                state.isLoading = true
                state.mode = 'update'
                await getCompetitionQnaDetail()
            } else {
                state.isLoading = false
            }
        });

        const getCompetitionQnaDetail = () => {
            let params = {
                Info: route.params.qnaId,
                page: state.options.page,
                itemsPerPage: state.options.itemsPerPage
            }
            modalControler.ShowLoading()
            CompetitionApi.getCompetitionQnaDetail(utils.getPage(params)).then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    // 첨부파일 넣기
                    if (state.qnaData.files) {
                        state.qnaData.files.forEach(file => {
                            getBlobFromUrl(file.url, file.name).then(file => {
                                state.attachment.push(file)
                            })
                        })
                    }
                    /* 
                      for QnA update
                    */
                    state.contentsTitle = response.data.result.data.title
                    state.contents = state.qnaData.content
                    state.isNotice = state.qnaData.isnotice
                    state.isopen = (state.qnaData.isopen === 1) ? true : false
                    state.isLoading = false

                }
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnA = (params, files) => {
            const formData = new FormData()
            formData.append('qnaInfo', JSON.stringify(params))
            formData.append('fileList', files)
            modalControler.ShowLoading()
            CompetitionApi.writeQnA(formData).then(response => {
                modalControler.ShowSuccessToast('게시글이 등록되었습니다.')
                router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = (params, files) => {
            const formData = new FormData()
            formData.append('qnaInfo', JSON.stringify(params))
            if (files && files.length && files.length >= 1) {
                formData.append('fileList', files[0])
            } else {
                formData.append('fileList', files)
            }
            modalControler.ShowLoading()
            CompetitionApi.updateQnA(formData).then(response => {
                modalControler.ShowSuccessToast('글을 수정했습니다.')
                router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const getBlobFromUrl = async (url, name) => {
            const response = await fetch(url)
            const data = await response.blob()
            const file = new File([data], name, { type: name.split('.').pop() });
            processFile(file).then((dataURL) => {
                state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
            });

            return file
        }

        const processFile = async (file) => {
            return await new Promise((resolve) => {
                let reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.readAsDataURL(file)
            })
        }


        return {
            ...toRefs(state),
            writeQnA,
            updateQnA,
        }

    }
}
</script>

