<template>
  <div class="main-wrap bg-contents-wrap">
    <header class="page-header__section pattern-section">
      <div class="page-header__inner">
        <div class="page-header__title-box">
          <h2 class="page-header__title">Competition</h2>
          <p class="page-header__sub-text">실제 데이터로 다양한 Real-World 문제 해결에 함께 참여해보세요.
          </p>
        </div>
        <!-- <img src="../../assets/images/img-header-competition.png" class="bg-img" /> -->
      </div>
    </header>
    <section v-if="runningCompetitionList.length" class="competition-list-section content-section--padding">
      <div class="content-inner">
        <header class="content-section__header">
          <h2 class="content-section__title">진행 중인 경진대회</h2>
        </header>
        <div v-for="(c, i) in runningCompetitionList" :key="'running-competition-' + i"
          :class="{ 'on': i === clickedRunningCompetitionIdx }" class="common-competition competition-list__group">
          <div class="competition-list__inner">
            <a @click="$router.goCompetitionPages('CompetitionDetail', c.id)"
              class="common-competition__box competition-list__box thumb-square thumb-square--m">
              <img :src="(c.imgurl) ? c.imgurl : '../../assets/images/img-main-dummy.png'" />
            </a>
            <div class="common-competition__header competition-list__header">
              <a @click="$router.goCompetitionPages('CompetitionDetail', c.id)" class="competition-list__content">
                <div class="common-competition__status-badge">
                  <div v-if="c.status === 3" class="status-badge status-badge--black">접수중</div>
                  <div v-else-if="c.status === 4" class="status-badge status-badge--primary">진행중</div>
                  <div v-else-if="c.status === 5" class="status-badge status-badge--white">점검중</div>
                </div>
                <div class="title">{{ c.name }}</div>
                <p class="dec"> {{ c.desc }}
                </p>
                <div class="info--group">
                  <div class="item">
                    <span class="item__icon">
                      <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                        icon-name="icon-user"><icon-user /></icon-base>
                    </span>
                    <span class="item__text">{{ c.joinusercnt }}명 / {{ c.jointeamcnt }}팀</span>
                  </div>
                  <div class="item" v-if="c.fromdt">
                    <span class="item__icon">
                      <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                        icon-name="icon-calendar"><icon-calendar /></icon-base>
                    </span>
                    <span class="item__text"> {{ c.fromdt.split(" ")[0] }} ~ {{ c.todt.split(" ")[0] }}</span>
                  </div>
                  <div class="prize" v-if="c.prizemoney > 0">
                    <span class="prize__icon icon icon__size--300 icon--prize">
                      <span class="blind">상금</span>
                    </span>
                    <div class="prize__text">
                      <span class="money font-num-bold">{{ utils.numberToKorean(c.prizemoney) }}</span> 만원
                    </div>
                  </div>
                </div>
              </a>
              <button @click="openRunningCompetition(c, i)" class="btn-arrow" type="button">
                <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#000"
                  icon-name="icon-arrow-up-circle"><icon-arrow-up-circle /></icon-base>
              </button>
            </div>
          </div>
          <div class="common-competition__body competition-list__body">
            <section class="swiper__banner swiper-unset">
              <template v-if="c.assignmentList.length > 2">
                <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
                  <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                    icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                </button>
                <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                  <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                    icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                </button>
              </template>
              <div v-swiper:[i]="swiperOption" class="swiper">
                <div class="swiper-wrapper">
                  <swiper-slide v-for="(t, ti) in c.assignmentList" :key="'task-' + ti">
                    <a @click="$router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)" class="task-list__item">
                      <h4 class="title">{{ t.name }}</h4>
                      <div class="tags__group">
                        <span class="tag" v-for="(t, tti) in t.keywords" :key="'keyword-' + tti">
                          {{ t.name }}
                        </span>
                      </div>
                      <div class="person">
                        <span class="icon">
                          <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-user"><icon-user /></icon-base>
                        </span>
                        <span class="text">{{ t.joinusercnt }}명 / {{ t.jointeamcnt }}팀</span>
                      </div>
                    </a>
                  </swiper-slide>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
    </section>
    <AllCompetition :competitionList="competitionList" />
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "@vue/composition-api";
import moment from "moment";
import utils from "@/helper/utils";
import { tokenStore } from "@/stores/auth";
import { stateStore } from "@/stores/state";
import CompetitionApi from "@/lib/api/Competition";
import BannerApi from "@/lib/api/Banner";
import modalControler from "@/helper/modalControler";
import Carousels from "@/components/competition/Carousels";
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import AllCompetition from "./AllCompetition"

export default {
  name: "CompetitionList",
  components: {
    SwiperSlide,
    AllCompetition
  },
  directive: {
    swiper: directive
  },
  setup(props, context) {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };

    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const swiper = ref(null);
    const state = reactive({
      competitionId: Number(route.params.competitionId),
      categories: [],
      myCompetition: [],
      runningCompetitionList: [],
      competitionList: [],
      mainBannerList: [],
      doing: false,
      showingStatus: [1, 2, 3, 4, 5, 6], // OPEN : 2, APPLYING : 3, RUNNING : 4 , HOLDING: 5
      currentStatus: [3, 4, 5],
      competition: 1,
      study: 2,
      recruitment: 3,
      options: {
        page: 1,
        itemsPerPage: 0,
        categoryId: 0,
      },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-btn__middle__right',
          prevEl: '.swiper-btn__middle__left'
        }

      },
      interval: null,
      loadBannerList: false,
      clickedRunningCompetitionIdx: 0,
      clickedCompetitionIdx: null,
    });

    onMounted(() => {
      getCompetitionList();
    });

    onUnmounted(() => {
      clearInterval(state.interval);
    });

    const getCompetitionList = () => {
      modalControler.ShowLoading()
      CompetitionApi.getCompetitionList(utils.getPage(state.options))
        .then((response) => {
          const data = response.data.result;
          state.competitionList = data.data
          state.competitionList = state.competitionList
            .sort((a, b) => b.id - a.id)
            .filter(c => state.showingStatus.includes(c.status))
            .map(c => {
              c.isClicked = false
              return c
            })
          state.runningCompetitionList = state.competitionList.filter(c => state.currentStatus.includes(c.status))
          state.runningCompetitionList.map(c => {
            c.isClicked = true
            return c
          })
          state.clickedRunningCompetitionIdx = 0
          modalControler.HideLoading()
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const openRunningCompetition = (c, i) => {
      if (state.clickedRunningCompetitionIdx === i)
        state.clickedRunningCompetitionIdx = null
      else
        state.clickedRunningCompetitionIdx = i
      // state.runningCompetitionList[i].isClicked = (state.runningCompetitionList[i].isClicked) ? false : true
    }

    const goToCompetition = (c) => {
      if (c.status === 2) {
        modalControler.ShowWarningToast('준비중입니다. 잠시만 기다려주세요!')
        return
      }
      router.goCompetitionPages('CompetitionDetail', c.id)
    }

    const openCompetition = (c, i) => {
      if (c.status === 2) {
        modalControler.ShowWarningToast('준비중입니다. 잠시만 기다려주세요!')
        return
      }    // OPEN

      if (state.clickedCompetitionIdx === i)
        state.clickedCompetitionIdx = null
      else
        state.clickedCompetitionIdx = i
    }

    const sortCompetition = (array) => {
      /*
        4(RUNNING) -> 2(OPEN) -> 5(HOLDING) -> 3(APPLYING) -> 6(FINALIZED) -> 1(CREATED)
      */
      // step 1. 4(RUNNING), 2(OPEN) 순으로
      array.sort((a, b) => {
        a = a.status
        b = b.status
        if (a > b) return -1
        if (a === b) return 0
        if (a < b) return 1
      })
      let tmp = []
      let sorted = []
      let finalized = []

      // step 2. 1(CREATED), 3(APPLYING), 5(HOLDING), 6(FINALIZED) 따로 뺀다
      sorted = array.filter(el => {
        if (el.status === 3 || el.status === 5) {
          tmp.push(el)
        } else if (el.status === 1 || el.status === 6) {     // created / finalzed
          finalized.push(el)
        } else {
          return el
        }
      })

      sorted = sorted.concat(tmp)    // sorting 된 상태로 merge
      sorted = sorted.concat(finalized)
      return sorted

    }

    const showOnlyDoing = () => { };

    const showKOSSA = () => {
      window.open("https://maicon.kr", "_blank");
    };
    const showAIPLAYGROUND = () => {
      window.open("https://ai-korea.kr/main/main.do", "_blank");
    };

    const next = () => {
      swiper.value.$swiper.slideNext();
    };

    const show403ErrorToast = (status) => {
      if (status === 2) {
        modalControler.ShowWarningToast("준비중입니다. 잠시만 기다려주세요!");
      }
      else {
        modalControler.ShowErrorToast("접근권한이 없는 페이지입니다.");
      }
    };

    return {
      swiper,
      onSwiper,
      onSlideChange,
      ...toRefs(state),
      utils,
      showOnlyDoing,
      moment,
      showKOSSA,
      showAIPLAYGROUND,
      show403ErrorToast,
      openCompetition,
      openRunningCompetition,
      goToCompetition,
    };
  },
};
</script>
