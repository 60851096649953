<template>
    <v-dialog content-class="event-dialog dialog" v-model="dialogOpen" width="380px" :fullscreen="getWindowWidthSize < 768"
        @keydown.esc="onClickClose" @input="(v) => v || onClickClose()">
        <v-card class="dialog-section center-type">
            <div class="event-dialog__img">
                <img src="../../assets/images/img-event-popup.png" />
            </div>
            <header class="dialog-section__header">
                <h3 class="dialog-section__title ">{{ accountInfo.nickname }} 님,
                    축하드립니다!</h3>
            </header>
            <div class="dialog-section__body">
                <p class="dialog-section__text">
                    도전하고 끊임없이 배우며, 열정과 인내심으로 달려가는 <strong>{{ accountInfo.nickname }}</strong>님이 미래를 바꿀 것입니다.
                </p>
                <div class="dialog-section__btns">
                    <v-btn @click="toLeaderboard" class="btn btn-medium btn-primary">
                        <strong>리더보드 인증하고 경품받기</strong>
                        <span class="icon-right">
                            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                                icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                        </span>
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialogOpen" width="380px" :fullscreen="getWindowWidthSize < 768" @keydown.esc="onClickClose"
        @input="(v) => v || onClickClose()">
        <v-card class="dialog-section">

            <div class="dialog-section__body">
                <img src="../../assets/images/img-event-popup.png" />
                <p class="dialog-section__text">
                    <strong class="dialog-section__text--strong ">축하드립니다!</strong>
                    도전하고 끊임없이 배우며, 열정과 인내심으로 달려가는 <strong>{{ accountInfo.nickname }}</strong>님이 미래를 바꿀 것입니다.
                </p>
                <div class="dialog-section__btns">
                    <v-btn @click="toLeaderboard" class="btn btn-medium btn-primary">
                        <strong>리더보드 인증하고 경품받기</strong>
                        <span class="icon-right">
                            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                                icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                        </span>
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog> -->
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';

export default {
    name: 'EventPopup',
    props: ['visible'],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            dialogOpen: false,
            deleteUser: null,
        });

        watch(() => props.visible, (nv, ov) => {
            state.dialogOpen = nv
        });

        const validate = computed(() => {
            return state.deleteUser !== '탈퇴요청'
        })


        const getWindowWidthSize = computed(() => {
            return context.root.$vuetify.breakpoint.width
        });

        const onClickClose = () => {
            context.emit('dialogClose')
            state.deleteUser = null
        }

        const onClickSubmit = () => {
            context.emit('submit')
            state.deleteUser = null
        }

        const toLeaderboard = () => {
            router.push({
                name: 'TaskLeaderboard',
                params: {
                    taskId: route.params.taskId,
                    competitionId: route.params.competitionId
                }
            })
        }


        return {
            ...toRefs(state),
            validate,
            onClickClose,
            onClickSubmit,
            getWindowWidthSize,
            accountInfo,
            toLeaderboard,
        }


    }

}


</script>

<style scoped lang="scss"></style>

