export const landingPageTaskTitles = [
    {
        value: '전체',
        title: '전체'
    },
    {
        value: '금융',
        title: '금융'
    },
    {
        value: '의료',
        title: '의료'
    },
    {
        value: '제조',
        title: '제조'
    },
    {
        value: '유통',
        title: '유통'
    }
]

export const landingPageTaskList = [
    {
        category: '금융',
        img: 'ico_graph',
        domain: '정형',
        title: 'KB차차차 중고차 매물 판매기간 예측 모델 개발',
        company: 'KB 캐피탈' 
    },
    {
        category: '금융',
        img: 'ico_img',
        domain: '이미지',
        title: '건축물의 화재 리스크 판별을 위한 건축물 구조 분류',
        company: 'KB 손해보험'
    },
    {
        category: '금융',
        img: 'ico_graph',
        domain: '정형',
        title: '지역 내 특정 업종별 매출 예측 (코로나 이후 변화 적용)',
        company: '하나카드'
    },
    {
        category: '금융',
        img: 'ico_graph',
        domain: '정형',
        title: '모바일 결제환경에서의 이상거래 탐지',
        company: '페이레터'
    },
    {
        category: '금융',
        img: 'ico_img',
        domain: '이미지',
        title: '고객의 손글씨 내용 탐지를 위한 모델 개발',
        company: ''
    },
    {
        category: '금융',
        img: 'ico_nlp',
        domain: '자연어',
        title: '모바일 결제환경에서의 이상거래 탐지',
        company: ''
    },
    {
        category: '의료',
        img: 'ico_img',
        domain: '이미지',
        title: '병원 영수증 인식을 위한 OCR 알고리즘 개발',
        company: '마인즈앤컴퍼니'
    },
    {
        category: '의료',
        img: 'ico_img',
        domain: '이미지',
        title: '흉부 CT 이미지 기반 코로나 감염 여부 탐지 모델 개발',
        company: ''
    },
    {
        category: '의료',
        img: 'ico_img',
        domain: '이미지',
        title: '사람 인체 자세 3D 데이터 기반 자세 추정 모델',
        company: ''
    },
    {
        category: '의료',
        img: 'ico_graph',
        domain: '정형',
        title: '치매 예방을 위한 라이프로그 치매 분류 모델',
        company: ''
    },
    {
        category: '보안',
        img: 'ico_img',
        domain: '이미지',
        title: '얼굴 합성 사이버범죄 방지를 위한 탐지모델 개발',
        company: ''
    },
    {
        category: '보안',
        img: 'ico_img',
        domain: '이미지',
        title: '야간 CCTV내 범죄자 얼굴인식을 위한 조도 밝기 조정 모델 개발',
        company: ''
    },
    {
        category: '보안',
        img: 'ico_img',
        domain: '이미지',
        title: '범죄자 얼굴 다각도 인식 및 조회 서비스 개발',
        company: ''
    },
    {
        category: '보안',
        img: 'ico_img',
        domain: '이미지',
        title: '액세서리 착용 범죄자에 대한 인식 및 조회 모델 개발',
        company: ''
    },
    {
        category: '통신',
        img: 'ico_nlp',
        domain: '자연어',
        title: '국민생각 알리미(긍정 및 불만 여론 탐지)',
        company: '육군'
    },
    {
        category: '통신',
        img: 'ico_img',
        domain: '이미지',
        title: '얼굴 합성 사이버범죄 방지를 위한 탐지모델 개발',
        company: '마인즈앤컴퍼니'
    },
    {
        category: '통신',
        img: 'ico_nlp',
        domain: '자연어',
        title: '인터넷 악성 댓글 분류를 위한 모델 개발',
        company: ''
    },
    {
        category: '통신',
        img: 'ico_img',
        domain: '이미지',
        title: '우주 전파 재난 대비를 위한 예측 모델 개발',
        company: ''
    },
    {
        category: '유통',
        img: 'ico_nlp',
        domain: '자연어',
        title: '소상공인 QnA 카테고리 분류',
        company: ''
    },
    {
        category: '유통',
        img: 'ico_img',
        domain: '이미지',
        title: 'CCTV 데이터 내 사람 추적 모델 개발',
        company: '인터마인즈'
    },
    {
        category: '환경',
        img: 'ico_graph',
        domain: '정형',
        title: '서초구 어린이집 실내 공기질 예측',
        company: '서초구청'
    },
    {
        category: '환경',
        img: 'ico_graph',
        domain: '정형',
        title: '서울시 특정지역 실외 공기 오염 예측',
        company: '케이웨더'
    },
    {
        category: '안전',
        img: 'ico_img',
        domain: '이미지',
        title: '보행자 장애물 인식을 위한 탐지 모델 개발',
        company: ''
    },
    {
        category: '안전',
        img: 'ico_img',
        domain: '이미지',
        title: '안전 보행을 위한 노면 상태 확인 모델 개발',
        company: ''
    },
    {
        category: '안전',
        img: 'ico_img',
        domain: '이미지',
        title: '운전 사고 예방을 위한 운전자 부주의 행동 검출 모델',
        company: ''
    },
    {
        category: '안전',
        img: 'ico_img',
        domain: '이미지',
        title: '아동 및 교통약자 보호를 위한 어린이 도로보행 위험행동 분류 모델',
        company: ''
    },
    {
        category: '교통',
        img: 'ico_graph',
        domain: '정형',
        title: '도로별 혼잡도 예측',
        company: ''
    },
    {
        category: '교통',
        img: 'ico_img',
        domain: '이미지',
        title: '드론 이미지를 활용하여 도로내 차량 인식 모델',
        company: ''
    },
    {
        category: '제조',
        img: 'ico_graph',
        domain: '정형',
        title: '독일 지역의 Winter 타이어 수요 예측',
        company: '한국타이어'
    },
    {
        category: '제조',
        img: 'ico_img',
        domain: '이미지',
        title: '타일 이미지 내 미세 Crack 검출 모델',
        company: '태영세라믹'
    },
    {
        category: '에너지',
        img: 'ico_graph',
        domain: '정형',
        title: '전력설비 고장 대응을 위한 전력품질 분류 모델',
        company: ''
    },
    {
        category: '에너지',
        img: 'ico_graph',
        domain: '정형',
        title: '기계시설물 센서 데이터 기반 이상징후 탐지 모델',
        company: ''
    },
    {
        category: '미디어',
        img: 'ico_nlp',
        domain: '자연어',
        title: 'SBS 고릴라 앱 실시간 청취자 댓글 분류 모델 구축',
        company: 'SBS'
    },
    {
        category: '패션',
        img: 'ico_img',
        domain: '이미지',
        title: '한국인 헤어스타일 세그먼테이션 모델',
        company: ''
    },
    {
        category: '교육',
        img: 'ico_nlp',
        domain: '자연어',
        title: '일상 환경에서 언택트 교육을 위한 음성 인식 모델 개발',
        company: ''
    },
    {
        category: '교육',
        img: 'ico_nlp',
        domain: '자연어',
        title: '어린이 음성데이터 기반 음성 모델 개발',
        company: ''
    },
    {
        category: '언론',
        img: 'ico_nlp',
        domain: '자연어',
        title: '법률 문서 요약 모델',
        company: ''
    },
    {
        category: '복지',
        img: 'ico_nlp',
        domain: '자연어',
        title: '노년층 대상 감성 분류 모델',
        company: ''
    },
    {
        category: '복지',
        img: 'ico_nlp',
        domain: '자연어',
        title: '시각장애인 시스템 개발을 위한 VQA 모델',
        company: ''
    },
    {
        category: '재난',
        img: 'ico_img',
        domain: '이미지',
        title: '태양흑점 이미지 자료를 통한 태양흑점폭발 여부 예측',
        company: '우주전파센터'
    },
    {
        category: '방역',
        img: 'ico_img',
        domain: '이미지',
        title: '코로나 방역을 위한 마스크 착용 여부 분류',
        company: ''
    },
    {
        category: '농업',
        img: 'ico_img',
        domain: '이미지',
        title: '토마토 잎사귀 이미지 기반 병충해 예측 문제',
        company: ''
    },
    {
        category: '농업',
        img: 'ico_img',
        domain: '이미지',
        title: '과수(사과, 배) 화상병 진단 문제',
        company: ''
    },
    {
        category: '농업',
        img: 'ico_img',
        domain: '이미지',
        title: '작물 내 해충 객체 검출 문제',
        company: ''
    },
    {
        category: '농업',
        img: 'ico_img',
        domain: '이미지',
        title: '특정 작물의 병해충 감염 방지를 위한 탐지 모델 개발',
        company: ''
    },
    {
        category: '축산업',
        img: 'ico_img',
        domain: '이미지',
        title: '축산업 활용을 위한 소 관절 좌표 추정 모델 (정확도)',
        company: ''
    },
    {
        category: '임업',
        img: 'ico_img',
        domain: '이미지',
        title: '위성 이미지 활용 산림 데이터 분석 모델 구축',
        company: '한국임업진흥원'
    },
]