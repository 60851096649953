<template>
  <div class="main-wrap bg-contents-wrap">
    <!--fixed main banner /-->
    <Banners v-if="!bannerIsReady" :mainBannerList="mainBannerList" />

    <Summary v-if="summaryIsReady" :summaryData="summaryData" />

    <CompetitionSwiper v-if="competitionIsReady" :competitionList="competitionList" />

    <Practice :practiceList="assignmentList" />

    <AILeague v-if="leagueIsReady" :leagueList="leagueList" />

    <NewsSwiper v-if="newsIsReady" :newsList="newsList" />

    <Notice v-if="noticeIsReady" :noticeList="noticeList" />

  </div>
</template>
<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "@vue/composition-api"

import moment from "moment"

import utils from "@/helper/utils"
import modalControler from "@/helper/modalControler"


//stores
import { tokenStore } from "@/stores/auth"
import { stateStore } from "@/stores/state"
// api
import LandingApi from "@/lib/api/Landingpage"
import CompetitionApi from "@/lib/api/Competition"
import TaskApi from '@/lib/api/Task'
import LeagueApi from '@/lib/api/League'
import BannerApi from "@/lib/api/Banner"
import CommunityApi from "@/lib/api/Community"
import NewsApi from "@/lib/api/News"
// components
import Banners from "@/components/index/Banners"
import NewsSwiper from "@/components/index/NewsSwiper";
import CompetitionSwiper from "@/components/index/CompetitionSwiper"
import AILeague from "@/components/index/League"
import Notice from "@/components/index/Notice"
import Practice from "@/components/index/PracticeList"
import Summary from "@/components/index/Summary"


export default {
  name: "index",
  components: {
    Banners,
    NewsSwiper,
    CompetitionSwiper,
    AILeague,
    Notice,
    Practice,
    Summary,
  },
  setup(props, context) {

    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const state = reactive({
      mainBannerList: [],
      summaryData: {},
      competitionList: [],
      assignmentList: [],
      leagueList: [],
      newsList: [],
      noticeList: [],
      doing: false,
      showingStatus: [3, 4, 5], // APPLYING : 3, RUNNING : 4 , HOLDING: 5
      competition: 1,
      study: 2,
      recruitment: 3,
      options: {
        page: 1,
        itemsPerPage: 0,

      },
      bannerIsReady: false,
      competitionIsReady: null,
      summaryIsReady: null,
      leagueIsReady: null,
      newsIsReady: true,
      noticeIsReady: true,

    });

    onMounted(async () => {
      // modalControler.ShowLoading()
      getMainBannerList()
      getSummary()
      getCompetitionList()
      getAssignmentList()
      getAILeagueList()
      getNewsList()
      getPlatformNotices()
      // modalControler.HideLoading()
    });


    const getMainBannerList = () => {
      state.bannerIsReady = true
      state.mainBannerList = [];
      BannerApi.getMainBannerList().then((response) => {
        if (!response.data.error) {
          state.mainBannerList = response.data.result
          state.mainBannerList.forEach((currentElement, index, array) => {
            currentElement.backgroundColor =
              currentElement.backgroundColor != '' ? '#' + currentElement.backgroundColor : '';
          });
          state.bannerIsReady = false
        }
      });
    }

    const getSummary = () => {
      state.summaryIsReady = false
      LandingApi.getCompetitionSummary()
        .then(response => {
          state.summaryData = response.data.result
          state.summaryIsReady = true

        })
    }

    const getCompetitionList = () => {
      state.competitionIsReady = false
      CompetitionApi.getCompetitionList(utils.getPage(state.options))
        .then(response => {
          let data = response.data.result
          state.competitionList = data.data.filter(c => state.showingStatus.includes(c.status))
          state.competitionIsReady = true
        })
        .catch(err => {

        })
    }

    const getAssignmentList = () => {
      let params = {
        practice: true,
        status: 6,
        sortBy: 'joinusercnt',
        sortDesc: 'desc',
        pageSize: 100
      }
      TaskApi.getPracticeAssignmentList(utils.makeParams(params))
        .then(response => {
          const data = response.data.result
          state.assignmentList = data.data
        })
        .catch(err => {

        })
    }

    const getAILeagueList = () => {
      state.leagueIsReady = false
      LeagueApi.getAILeagueList()
        .then(response => {
          state.leagueList = response.data.result
          state.leagueIsReady = true

        })
        .catch(err => {

        })
    }

    const getNewsList = () => {
      let params = {
        page: 1,
        itemsPerPage: 6,
      }
      state.newsIsReady = false
      NewsApi.getAllNewsList(utils.getPage(params))
        .then(response => {
          state.newsList = response.data.result.data
          state.newsIsReady = true
        })
        .catch(err => {

        })
    }

    const getPlatformNotices = () => {
      CommunityApi.getCommunityList(utils.getPage(Object.assign(state.options, { showNotice: true })))
        .then(response => {
          let notice = response.data.data.filter(post => post.notice).splice(0, 5)
          state.noticeList = notice
        })
        .catch(err => {

        })
    }

    return {
      ...toRefs(state),
      utils,
      moment,

    };
  },
};
</script>
