<template>
  <section class="large-content-section">
    <div class="content-inner">
      <header class="large-content-section__header">
        <h2 class="large-content-section__title">공지 사항</h2>
      </header>
      <div class="box-list-items">
        <a @click.prevent.stop="$router.push({ name: 'CommunityDetail', params: { postId: n.id } })" class="box-list-item"
          v-for="(n, i) in noticeList" :key="'notice-' + i">
          <div>
            <h3 class="box-list-item__title">{{ n.title }}</h3>
            <span class="box-list-item__date">{{ n.createDtm }}</span>
          </div>
          <span class="icon">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </a>
      </div>
      <div class="large-content-section__btn-area">
        <button @click="$router.push({
            name: 'CommunityRoot'
          })" class="btn btn-secondary btn-large" type="button"><strong>공지 사항
            전체보기</strong>
          <span class="icon-right">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </button>
        <!-- <button @click="$router.push({
          name: 'CommunityRoot'
        })" class="btn btn-secondary btn-large" type="button">더보기</button> -->
      </div>
    </div>
  </section>
</template>

<script>


export default {
  props: [
    'noticeList',
  ],
  setup(props, context) {

  }
}
</script>