<template>
    <div class="profile-wrap contents-wrap">
        <ProfileEdit />
    </div>
</template>

<script>

import ProfileEdit from '@/components/user/ProfileEdit'

export default {
    components: {
        ProfileEdit,
    }
}
</script>