<template>
    <div class="password-wrap contents-wrap">
        <PasswordRegister />
    </div>
</template>

<script>

import PasswordRegister from '@/components/user/PasswordRegister'

export default {
    components: {
        PasswordRegister,
    }
}
</script>