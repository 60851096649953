<template>
    <div class="policy-wrap contents-wrap">
        <header class="page-header--line__section">
            <h2 class="page-header--line__title">개인정보처리방침</h2>
        </header>
        <div class="content-inner">
            <iframe height="100%" width="100%" class="iframe"
                src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/terms/personal.html"></iframe>
        </div>
    </div>
</template>

<script>
import {
    reactive
} from '@vue/composition-api';



export default {
    name: 'Privacy',

    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({

        });



    }
}
</script>

<style scoped lang="scss">

</style>