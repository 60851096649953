
<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>


export default {
    name: 'SignupRoot',
    components: {
    },
    data() {
        return {
                        
        }
    }
}
</script>

<style scoped lang="scss">

</style>