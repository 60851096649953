<template>
  <section class="large-content-section pattern-section">
    <div class="content-inner">
      <header class="large-content-section__header mb-0">
        <span class="large-content-section__sub-title"> AI League</span>
        <h2 class="large-content-section__title large-content-section__title--white">모의경진대회로 실력을 키워보세요.</h2>

        <p class="large-content-section__text large-content-section__text--white">
          AI League 에서 수준별로 문제를 풀어보고 자신의 순위를 확인해보세요.</p>
        <div class="code-editor__group">
          <lottie :options="defaultOptions" :height="'100%'
          " :width="'100%'" v-on:animCreated="handleAnimation" />
        </div>
      </header>

      <div class="card-list__section">
        <div class="card-box card-box--black" v-for="(l, i) in leagueList" :key="'league-' + i">
          <div class="card-box__content">
            <a @click="$router.goLeaguePages('LeagueDetailRoot', l.id)" class="card-box__link">
              <div class="card-box--both">
                <div class="card-box__thumb thumb-square thumb-square--l">
                  <img :src="(l.thumbnailUrl) ? l.thumbnailUrl : '../../assets/images/img-main-dummy.png'" />
                </div>
                <div class="card-box__text-group">
                  <h3 class="card-box__title-large card-box__title-large--black ellipsis-1">{{ l.name }}</h3>
                  <p class="card-box__text card-box__text--black ">{{ l.description }}</p>
                  <div class="card-box__tags">
                    <template v-if="l.keywords.length && l.keywords[0] !== '' ">
                      <span class="tag  tag--black" v-for="(k, i) in l.keywords" :key="'keyword-' + i">{{ k }}</span>
                    </template>
                    <div class="mt--12">
                      <ul class="inline-list">
                        <li class="inline-list__item">
                          <span class="inline-list__title inline-list__title--black">Level</span>
                          <span class="inline-list__content inline-list__content--black">
                            <span v-if="l.level === '입문'" class="status-sub-badge status-sub-badge--yellow">입문</span>
                            <span v-if="l.level === '중급'" class="status-sub-badge status-sub-badge--orange">중급</span>
                            <span v-if="l.level === '실전'" class="status-sub-badge status-sub-badge--red">실전</span>
                          </span>
                        </li>
                        <li class="inline-list__item">
                          <span class="inline-list__title inline-list__title--black">Task</span>
                          <span class="inline-list__content inline-list__content--black">{{ l.task }}</span>
                        </li>
                        <li class="inline-list__item">
                          <span class="inline-list__title inline-list__title--black">Metric</span>
                          <span class="inline-list__content inline-list__content--black">{{ l.metric }}</span>
                        </li>
                        <li class="inline-list__item">
                          <span class="inline-list__title inline-list__title--black">Data Type</span>
                          <span class="inline-list__content inline-list__content--black">{{ l.dataType }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="card-box__option card-box__option--both card-box__option--between">
                      <div class="card-box__option--both">
                        <span class="icon-info">
                          <span class="icon">
                            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                              icon-name="icon-eye"><icon-eye /></icon-base>
                          </span>
                          <strong class="text">{{ l.viewCount }}</strong>
                        </span>
                        <span class="icon-info">
                          <span class="icon">
                            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                              icon-name="icon-user"><icon-user /></icon-base>
                          </span>
                          <strong class="text">{{ l.userCount }}</strong>
                        </span>
                        <span class="icon-info">
                          <span class="icon">
                            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                              icon-name="icon-upload"><icon-upload /></icon-base>
                          </span>
                          <strong class="text">{{ l.submitCount }}</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

      </div>
      <div class="large-content-section__btn-area">
        <button @click="$router.push({ name: 'LeagueRoot' })" class="btn btn-tertiary btn-large" type="button"><strong> AI
            League 전체보기</strong>
          <span class="icon-right">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import utils from '@/helper/utils';
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref,
} from '@vue/composition-api';

// lottie
import lottie from "@/components/global/Lottie"
import animationData from '@/assets/json/header-graph.json';

export default {
  components: {
    lottie,     // lottie
  },

  props: [
    'leagueList',
  ],
  setup(props, context) {
    const route = context.root.$route;
    const router = context.root.$router;
    const state = reactive({
      defaultOptions: { animationData: animationData },   // lottie option
    });

    // lottie
    const handleAnimation = (anim) => {
      // this.anim = anim;
    }
    return {
      ...toRefs(state),
      handleAnimation,    // lottie
    };
  },
}
</script>

