import router from '@/plugins/router'
import { tokenStore } from '@/stores/auth'
import modalControler from '@/helper/modalControler';
import SocialAPI from '@/lib/api/social';
import utils from '@/helper/utils';

export default {

    windowFuncs: () => {
        const store = tokenStore()

        window.socialSignin = async (token) => {

            // store.commit('auth/SET_AUTH_TOKEN', token);
            // store.dispatch('auth/GET_ACCOUNT_INFO');
            await store.storeAuthToken(token)
            await store.userAuthenticate()
            router.goHome();
        };

        window.signup = () => {
            router.push({ name: 'SignupSocial', params: { type: 'social' } })
        };

        window.socialSignup = (token) => {
            router.push({ name: 'SignupSocial', params: {type: 'social', token: token} })
        };

        window.alreadySignedUp = () => {
            modalControler.ShowModalDialog('', '이미 가입되어있습니다.<br> 로그인해주세요.', '', '확인', null);
        };        

        window.alreadyLinked = (msg) => {
            var mailInfo = atob(msg).split('_');
            var linkedMail = mailInfo[1]
            modalControler.ShowModalDialog('', `이미 ${linkedMail}로 연동되어 있는 계정입니다.`, '확인', null);
        };

        window.disagreeProfile = () => {
            var msg = "필수 제공 항목에 모두 동의하셔야 회원가입 할 수 있습니다."
            modalControler.ShowModalDialog('', msg, '동의하기', '취소' ,(val) => {
                if (val) {
                    SocialAPI.socialAgreeRetry().then(response => {
                        window.open(response.data.result.naver, 'popUpOpen', 'width=500, height=900, top=200, left=200, \
                                    toolbar=no, menubar=no, scrollbars=no, resizable=yes');
                    });
                }
            });
        };

        window.cannotSignUp = () => {
            modalControler.ShowModalDialog('', '탈퇴한 계정입니다.', '확인', null, null);
        };

        window.googleLink = (token) => {
            modalControler.ShowModalDialog('', '이미 가입된 회원입니다. SNS 계정과 연동하시겠습니까? ', '취소', '확인', (val) => {
                if (val) {
                    const param = {
                        token: token
                    }
                    SocialAPI.socialAuthLink(param).then(async (response) => {
                        if (response.data.error !== true) {
                            modalControler.ShowSuccessToast('연동에 성공하였습니다.');
                            await store.storeAuthToken(response.data.access_token)
                            await store.userAuthenticate()
                            // this.googleLinked = true
                            router.goHome();

                        } else {
                            if (response.data.error_code === 7500) {
                                modalControler.ShowModalDialog('비활성화된 계정', '활성화되지 않은 계정입니다.<br>이메일 내의 링크를 눌러 계정활성화를 진행해주세요', '', '확인')
                            } else {
                                modalControler.ShowErrorToast(response.data.error_detail);
                            }
                            router.push({ name: 'SignIn' })
                        }
                        // CommonUiControl.HideUIProgress();
                    });
                } else {
                    router.goHome();
                }
            });
        };


        window.naverLink = (token) => {
            modalControler.ShowModalDialog('', '이미 가입된 회원입니다. SNS 계정과 연동하시겠습니까? ', '취소', '확인', (val) => {
                if (val) {
                    const param = {
                        token: token
                    }
                    SocialAPI.socialAuthLink(param).then(async (response) => {
                        if (response.data.error !== true) {
                            modalControler.ShowSuccessToast('연동에 성공하였습니다.');
                            await store.storeAuthToken(response.data.access_token)
                            await store.userAuthenticate()
                            router.goHome();

                        } else {
                            if (response.data.error_code === 7500) {
                                modalControler.ShowModalDialog('비활성화된 계정', '활성화되지 않은 계정입니다.<br>이메일 내의 링크를 눌러 계정활성화를 진행해주세요', '', '확인')
                            } else {
                                modalControler.ShowErrorToast(response.data.error_detail);
                            }
                            router.push({ name: 'SignIn' })
                        }
                    });
                } else {
                    router.goHome();
                }
            });      
        };        

    }
};