<template>
    <article class="section-2__content">
        <Profile 
            passwordEditRouterName="TaskPasswordEdit"
            passwordChkouterName="TaskPasswrodChk"
        />    
    </article>
</template>

<script>
import Profile from '@/components/user/Profile'

export default {
    components: {
        Profile
    }
}
</script>

<style scoped lang="scss">

</style>