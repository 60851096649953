<template>
    <section class="header-section mobile" :class="{ 'active': mobileActive }">
        <header class="header">
            <div class="header__content">
                <div>
                    <button type="button" class="btn-menu" @click="openMobileMenu">
                        <span>메뉴 닫기</span>
                    </button>
                    <img class="header__logo" @click="$router.goHome()" style="cursor:pointer;"
                        src="@/assets/images/img-logo.svg" alt="ai connect" />
                </div>
                <ul class="nav-list">
                    <li class="nav-list__item"
                        :class="{ 'nav-list__item--active': currentActive === 'CompetitionList' ? true : false }"
                        @click.prevent.stop="$router.push({ name: 'CompetitionList' })">
                        <a class="nav-list__link">AI Competition</a>
                        <div class="sub-nav-list__group">
                            <div class="content-inner">
                                <ul class="sub-nav-list">
                                    <li class="sub-nav-list__item"
                                        @click.prevent.stop="$router.push({ name: 'CompetitionList' })">
                                        <a>Competition</a>
                                    </li>
                                    <li @click.prevent.stop="$router.push({ name: 'CompetitionTaskList' })"
                                        class="sub-nav-list__item">
                                        <a>Task</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li class="nav-list__item"
                        :class="{ 'nav-list__item--active': currentActive === 'LeagueList' ? true : false }"
                        @click.prevent.stop="$router.push({ name: 'LeagueList' })">
                        <a class="nav-list__link">AI League
                            <!-- <span class="new-text new-text--small new-text--right ml--2">Event</span> -->
                        </a>
                    </li>
                    <li class="nav-list__item"
                        :class="{ 'nav-list__item--active': currentActive === 'CodingTest' ? true : false }"
                        @click.prevent.stop="$router.push({ name: 'CodingTest' })">
                        <a class="nav-list__link">AI-bility Test
                        </a>
                    </li>
                    <li class="nav-list__item"
                        :class="{ 'nav-list__item--active': currentActive === 'CommunityList' ? true : false }"
                        @click.prevent="$router.push({ name: 'CommunityList' })">
                        <a class="nav-list__link">Community</a>
                    </li>
                    <li class="nav-list__item"
                        :class="{ 'nav-list__item--active': currentActive === 'CompetitionNews' ? true : false }"
                        @click.prevent="$router.push({ name: 'CompetitionNews' })">
                        <a class="nav-list__link">News</a>
                    </li>
                </ul>
                <div class="user-btn">
                    <template v-if="!store.isAuthenticated">
                        <a class="header-btn header-btn__login" @click.prevent="$router.signin()">로그인</a>
                    </template>
                    <template v-else>
                        <div class="i-list">
                            <Notification :notiVisible="notiVisible" />
                            <UserMenu :visible="visible" />
                        </div>
                    </template>
                </div>
            </div>
        </header>
        <div class="mobile-menu-group">
            <div class="mobile-menu">
                <div class="mobile-menu__header">
                    <img class="mobile-menu__header--logo" @click="$router.goHome()" style="cursor:pointer;"
                        src="@/assets/images/img-logo.svg" alt="ai connect" />
                    <button type="button" class="btn-closed" @click="openMobileMenu">
                        <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#fff"
                            icon-name="icon-closed"><icon-closed /></icon-base>
                    </button>
                </div>
                <div class="side-menu">
                    <ul class="side-menu__items">
                        <li :class="{ 'on': mobileMenuOpen }">
                            <span class="side-menu__item" @click.prevent.stop="openCompetitionMobile"> AI Competition
                                <span class="side-menu__item--icon">
                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                                        icon-name="icon-chevron-bottom"><icon-chevron-bottom /></icon-base>
                                </span>
                            </span>
                            <ul class="sub-side-menu__items">
                                <li class="sub-side-menu__item" :class="{
                                    'on': currentActive === 'CompetitionList' ? true : false,
                                }" @click.prevent.stop="$router.push({ name: 'CompetitionList' })">
                                    <a>Competition</a>
                                </li>
                                <li class="sub-side-menu__item" :class="{
                                    'on': currentActive === 'CompetitionTaskList' ? true : false
                                }" @click.prevent.stop="$router.push({ name: 'CompetitionTaskList' })">
                                    <a>Task</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a :class="{ 'side-menu__item--active': currentActive === 'LeagueList' ? true : false }"
                                @click.prevent.stop="$router.push({ name: 'LeagueList' })" class="side-menu__item">AI League
                                <!-- <span class="new-text new-text--small ml--2">Event</span> -->
                            </a>
                        </li>
                        <li>
                            <a :class="{ 'side-menu__item--active': currentActive === 'CodingTest' ? true : false }"
                                @click.prevent.stop="$router.push({ name: 'CodingTest' })" class="side-menu__item"
                                href="/league/coding-test">AI-bility Test
                            </a>
                        </li>
                        <li class="side-menu__item"
                            :class="{ 'side-menu__item--active': currentActive === 'CommunityList' ? true : false }"
                            @click.prevent="$router.push({ name: 'CommunityList' })">
                            Community</li>
                        <li class="side-menu__item"
                            :class="{ 'side-menu__item--active': currentActive === 'CompetitionNews' ? true : false }"
                            @click.prevent="$router.push({ name: 'CompetitionNews' })">
                            News</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import UserMenu from '@/components/global/UserMenu';
import Notification from '@/components/global/Notification';

export default {
    name: 'GNBCompetition',
    components: {
        UserMenu,
        Notification,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const store2 = stateStore()
        const state = reactive({
            visible: false,
            notiVisible: false,
            currentActive: router.currentRoute.name,
            mobileActive: false,
            mobileMenuOpen: false
        })

        if (router.currentRoute.name === 'TaskInfo') state.currentActive = 'CompetitionList'

        watch(() => store2.competitionActiveRoute, (nv, ov) => {
            state.currentActive = store2.competitionActiveRoute
        })

        onUnmounted(() => {
            state.mobileActive = false
            state.mobileMenuOpen = false
        })

        const openMobileMenu = () => {
            state.mobileActive = state.mobileActive ? false : true
        }

        const goToCompetitionList = () => {
            openMobileMenu()
            router.push({ name: 'CompetitionList' })
        }

        const goToCommunity = () => {
            openMobileMenu()
            router.push({ name: 'CommunityList' })
        }

        const goToNews = () => {
            openMobileMenu()
            router.push({ name: 'CompetitionNews' })
        }

        const openCompetitionMobile = () => {
            state.mobileMenuOpen = state.mobileMenuOpen ? false : true
        }

        return {
            ...toRefs(state),
            store,
            router,
            openMobileMenu,
            goToCompetitionList,
            goToNews,
            goToCommunity,
            openCompetitionMobile,
        }
    }
}
</script>
