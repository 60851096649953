import _ from 'lodash';
import { defineStore } from 'pinia'

export const stateStore = defineStore('stateStore', {
    state: () => ({
        gnbListItems: [
            {
                section: '#section-1',
                title: '대회개요'
            },
            {
                section: '#section-2',
                title: '과제목록',
            },
            {
                section: '#section-3',
                title: '일정',
            },
            {
                section: '#section-4',
                title: '규정',
            },
            {
                section: '#section-5',
                title: '상금 및 시상',
            },
            {
                section: '#section-6',
                title: '리더보드',
            },
            {
                section: '#section-7',
                title: '공지사항',
            },                                                            
        ],
        gnbCompetitionDetailList: [],
        taskActiveMenu: '',
        taskActiveRoute: '',
        competitionActiveMenu: '',
        competitionActiveRoute: '',
        competitionActiveTab: 'competitionDetail',
        savedURL: null,
        practiceTooltipOpen: true,
    }),
    getters: {
        getSavedURL(state) {
            if (_.isEmpty(state.savedURL)) {
                state.savedURL = localStorage.getItem('temp-path')
            }
            return !_.isEmpty(state.savedURL)
        }
    },
    actions: {
        closePracticeTooltip() {
            this.practiceTooltipOpen = false
        },
        setGnbItems() {
            this.gnbCompetitionDetailList = this.gnbListItems
        },
        removeGnbItems() {
            this.gnbCompetitionDetailList = []
        },
        setCompetitionDetailTab(tab) {
            this.competitionActiveTab = tab
            
        },
        setTaskActiveRoute(routeName) {
            this.taskActiveRoute = routeName
            switch(routeName) {
                case 'TaskInfo':
                    this.taskActiveMenu = '과제 설명'
                    break
                case 'TaskLeaderboard':
                    this.taskActiveMenu = '리더보드'
                    break
                case 'TaskTeam': 
                    this.taskActiveMenu = '팀 구성'
                    break
                case 'TeamBuild': 
                    this.taskActiveMenu = '팀 빌딩'
                    break
                case 'CompetitionNotice':
                    this.taskActiveMenu = '대회 공지/문의'
                    break    
                case 'TaskData':
                    this.taskActiveMenu = '데이터 설명/다운로드'
                    break
                case 'TaskCodeShare':
                    this.taskActiveMenu = '코드공유'
                    break                
                case 'TaskSubmit':
                    this.taskActiveMenu = '결과제출'
                    break                
                case 'TaskNotice':
                    this.taskActiveMenu = '과제 공지/문의'
                    break
                case 'MySpaceMobile':
                    this.taskActiveMenu = 'MY SPACE'
                    break
                case 'LeagueInfo':
                    break
                case 'LeagueData':
                    this.taskActiveMenu = '데이터'
                    break

                case 'LeagueLeaderboard':
                    break
                case 'LeagueSubmit':
                    break
                case 'LeagueCommunity':
                    break
                case 'LeagueRule':
                    break
            }
        },
        setCompetitionActiveRoute(routeName) {
            this.competitionActiveRoute = routeName
            switch(routeName) {
                case 'CompetitionList':
                    this.taskActiveMenu = '경진대회'
                    break
                case 'CommunityList':
                    this.taskActiveMenu = '커뮤니티'
                    break
                case 'CompetitionNews':
                    this.taskActiveMenu = '대회뉴스'
                    break
            }
        },
        setBeforeURL(currentPath) {
            var savedPath
            if (currentPath) {
                savedPath = currentPath
            } else {
                savedPath = '/'
            }
            this.savedURL = savedPath
            localStorage.setItem('temp-path', savedPath)
        },
        clearBeforeURL() {
            localStorage.removeItem('temp-path')
        },
    },

});