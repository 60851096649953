import axios from "axios";
import _ from 'lodash';
import { tokenStore } from '@/stores/auth';
import router from '@/plugins/router';
import utils from "@/helper/utils";
import errorHandler from '@/helper/errorHandler'

class HttpClient {
    static instance;
    axiosInstance
    constructor() {
        this.axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_API_HOST_URL,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest'                
            }
        });
        this.axiosInstance.interceptors.request.use(request => this.requestHandler(request));
        this.axiosInstance.interceptors.response.use(response => this.responseHandler(response),
        async error => {
            if (error.response) {
                let errorCode = error.response.data.error_code
                // errorHandler(Number(errorCode), utils.getRequestAPI(error.response.request.responseURL))
                errorHandler(error.response)

                if (error.response.status === 401) {
                    error.response.message = '로그인이 필요합니다.'
                    // router.push({ path: '/login' })
                } else if (error.response.status === 403 ){
                    error.response.message = '접근 권한이 없는 페이지입니다.'
                    router.push({ path : '/' })
                } else if (error.response.status === 404) {
                    error.response.message = '찾을 수 없는 페이지입니다.'
                    router.push({ path: '/404' })

                } else if (error.response.status === 500) {
                    error.response.message = '알 수 없는 오류가 발생했습니다.'

                } else if (error.response.status === 503) {
                    error.response.message = '서버오류 입니다.'
                }
            }
            throw error;
        });        
    }
    requestHandler = async (request) => {
        const store = tokenStore()
        let authToken = store.getAuthToken
        if (!_.isEmpty(authToken)) {
            request.headers[process.env.VUE_APP_AUTH_TOKEN_HEADER_KEY] = authToken
        }
        return request;
    };
    responseHandler = async (response) => {
        if (response.data.error) {
            errorHandler(response)
        }
        return response;
    };
    static getInstance = () => {
        if ((HttpClient.instance === null) || (HttpClient.instance === undefined) || !(HttpClient.instance instanceof HttpClient)) {
          HttpClient.instance = new HttpClient();
        }
        return HttpClient.instance.axiosInstance;
    }    
}

export default HttpClient.getInstance();

