<template>
  <section class="large-content-section bg-gray">
    <div class="content-inner">
      <header class="large-content-section__header">
        <span class="large-content-section__sub-title">Task</span>
        <h2 class="large-content-section__title">실제 출제된 다양한 문제를 해결해보세요.</h2>
        <!-- <div class="large-content-section__btn">
            <a href="" class="btn-more"><span>전체보기</span>
              <span class="icon">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                  icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
              </span>
            </a>
          </div> -->
        <p class="large-content-section__text">
          역대 AI CONNECT에서 개최된 대회의 과제와 베이스라인 코드를 공개합니다.</p>
      </header>
      <div class="card-list card-list--3">
        <div class="card-box" v-for="(d, idx) in practiceList" :key="idx">
          <div class="card-box__content">
            <a class="card-box__link" @click.prevent="$router.push({
              name: 'TaskRoot',
              params: {
                competitionId: d.competitionId,
                taskId: d.assignmentId
              }
            })">
              <h3 class="card-box__title ellipsis-1">{{ d.assignmentName }}</h3>
              <!--p class="card-box__text" v-if="c.desc">{{ c.desc }}</p-->
              <div class="card-box__tags">
                <span class="tag" v-for="(k, idx2) in d.keywords" :key="'tag-' + idx2">
                  {{ k.name }}
                </span>
              </div>
              <div class="card-box__option">
                <div class="card-box__sub-text">{{ d.competitionName }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="large-content-section__btn-area">
        <button @click="$router.push({ name: 'CompetitionTaskList' })" class="btn-secondary btn-large"
          type="button"><strong> Task 전체보기</strong>
          <span class="icon-right">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </button>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  props: [
    'practiceList',
  ],
  setup(props, context) {

  }
}
</script>