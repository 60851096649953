<template>
    <div class="error-wrap">
        <div class="error-content">
            <img src="@/assets/images/img-404.png" alt="404" />
            <div>
                <v-btn @click="$router.push('/')" class="btn btn-large btn-primary"><strong>홈으로</strong></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, nextTick
} from '@vue/composition-api';


export default {
    name: 'Page404',
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({

        })

        onMounted(() => {

        });

        return {

        }

    }
}
</script>
