import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'

// styles
import '@/styles/App.scss';

// plugins
import moment from 'moment'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

// store
import { createPinia, PiniaVuePlugin } from 'pinia';
import { tokenStore } from '@/stores/auth'

import store from '@/stores/modal'

// Bootstrap 
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

//awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
  fas,
  far,
  fab
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// svg icon
import IconBase from '@/components/IconBase.vue'
Vue.component('icon-base', IconBase)
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
Vue.component('icon-arrow-left', IconArrowLeft)
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
Vue.component('icon-arrow-right', IconArrowRight)
import IconArrowUp from '@/components/icons/IconArrowUp.vue'
Vue.component('icon-arrow-up', IconArrowUp)
import IconArrowBottom from '@/components/icons/IconArrowBottom.vue'
Vue.component('icon-arrow-bottom', IconArrowBottom)
import IconArrowUpCircle from '@/components/icons/IconArrowUpCircle.vue'
Vue.component('icon-arrow-up-circle', IconArrowUpCircle)
import IconArrowBottomCircle from '@/components/icons/IconArrowBottomCircle.vue'
Vue.component('icon-arrow-bottom-circle', IconArrowBottomCircle)
import IconArrowRightCircle from '@/components/icons/IconArrowRightCircle.vue'
Vue.component('icon-arrow-right-circle', IconArrowRightCircle)
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'
Vue.component('icon-chevron-left', IconChevronLeft)
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
Vue.component('icon-chevron-right', IconChevronRight)
import IconChevronUp from '@/components/icons/IconChevronUp.vue'
Vue.component('icon-chevron-up', IconChevronUp)
import IconChevronBottom from '@/components/icons/IconChevronBottom.vue'
Vue.component('icon-chevron-bottom', IconChevronBottom)
import IconAward from '@/components/icons/IconAward.vue'
Vue.component('icon-award', IconAward)
import IconCalendar from '@/components/icons/IconCalendar.vue'
Vue.component('icon-calendar', IconCalendar)
import IconClosed from '@/components/icons/IconClosed.vue'
Vue.component('icon-closed', IconClosed)
import IconEye from '@/components/icons/IconEye.vue'
Vue.component('icon-eye', IconEye)
import IconFile from '@/components/icons/IconFile.vue'
Vue.component('icon-file', IconFile)
import IconFileText from '@/components/icons/IconFileText.vue'
Vue.component('icon-file-text', IconFileText)
import IconInfo from '@/components/icons/IconInfo.vue'
Vue.component('icon-info', IconInfo)
import IconMinus from '@/components/icons/IconMinus.vue'
Vue.component('icon-minus', IconMinus)
import IconPen from '@/components/icons/IconPen.vue'
Vue.component('icon-pen', IconPen)
import IconPlus from '@/components/icons/IconPlus.vue'
Vue.component('icon-plus', IconPlus)
import IconRotate from '@/components/icons/IconRotate.vue'
Vue.component('icon-rotate', IconRotate)
import IconThumbsUp from '@/components/icons/IconThumbsUp.vue'
Vue.component('icon-thumbs-up', IconThumbsUp)
import IconUpload from '@/components/icons/IconUpload.vue'
Vue.component('icon-upload', IconUpload)
import IconUser from '@/components/icons/IconUser.vue'
Vue.component('icon-user', IconUser)
import IconCopy from '@/components/icons/IconCopy.vue'
Vue.component('icon-copy', IconCopy)
import IconMessage from '@/components/icons/IconMessage.vue'
Vue.component('icon-message', IconMessage)
import IconMore from '@/components/icons/IconMore.vue'
Vue.component('icon-more', IconMore)
import IconCheck from '@/components/icons/IconCheck.vue'
Vue.component('icon-check', IconCheck)
import IconDownload from '@/components/icons/IconDownload.vue'
Vue.component('icon-download', IconDownload)

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper.min.css'
import 'swiper/css/swiper.css'

import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import VBodyScrollLock from 'v-body-scroll-lock'
Vue.use(VBodyScrollLock)

Vue.config.productionTip = false

const pinia = createPinia()
const modalStore = tokenStore(pinia)

import windowFunc from '@/lib/windowFuncs'
windowFunc.windowFuncs();

Vue.use(PiniaVuePlugin)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueToast)
Vue.use(VueAwesomeSwiper)

Vue.prototype.$mobileWidth = 996

new Vue({
  moment,
  router,
  vuetify,
  pinia,
  created() {
    // AOS.init();
  },
  render: h => h(App),
  store: store,
}).$mount('#app')
