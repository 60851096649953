<template>
    <article class="section-2__content">
        <ProfileEdit />
    </article>
</template>

<script>
import ProfileEdit from '@/components/user/ProfileEdit'

export default {
    components: {
        ProfileEdit
    }
}
</script>

<style scoped lang="scss">

</style>