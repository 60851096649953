<template>
    <div class="contents-wrap">

        <div class="content-inner">
            <BoardListView boardType='CompetitionQuestion' @onlyMyQnA="getCompetitionQnA" @doSearch='getCompetitionQnA'
                :qnaTotalCount="qnaTotalCount" :qnaList="qnaList" />
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import _ from "lodash";
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import BoardListView from '@/components/BoardListView'
import { stateStore } from '@/stores/state';


export default {
    name: 'CompetitionQuestion',
    components: {
        BoardListView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const stateStorage = stateStore()

        const state = reactive({
            qnaList: [],
            qnaTotalCount: 0,
            competitionId: route.params.competitionId,
        });

        onMounted(() => {
            stateStorage.removeGnbItems()       // gnb list 지운다

        })

        const getCompetitionQnA = (options, searchKeyword, showNotice) => {
            // let keyword = searchKeyword ? utils.getPage(Object.assign(options, { searchText: searchKeyword, showNotice: showNotice })) :
            //                                 utils.getPage(Object.assign(options,{ showNotice: showNotice }))
            // modalControler.ShowLoading()
            // CompetitionApi.getCompetitionQnaList(route.params.competitionId, keyword).then(response => {
            //     if (response.data.http_status_code === 200) {
            //         state.qnaList = response.data.result.data
            //         state.qnaTotalCount = response.data.result.totalcount
            //     }
            // }).catch(err => {
            //     console.log(err)
            // }).finally(() => {
            //     modalControler.HideLoading()
            // })
        }

        return {
            ...toRefs(state),
            getCompetitionQnA,

        }
    }
}

</script>
