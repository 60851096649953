<template>
    <div class="password-wrap contents-wrap">
        <PasswordChk />
    </div>
</template>

<script>

import PasswordChk from '@/components/user/PasswordChk'

export default {
    components: {
        PasswordChk,
    }
}
</script>