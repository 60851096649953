<template>
  <table class="table-line">
    <!-- Table Header -->
    <colgroup>
      <col>
      <col>
      <col>
      <col>
    </colgroup>
    <thead class="table-line__thead">
      <tr>
        <template v-for="(header, idx) in tableHeader">
          <th :key="'aic-tb-header-' + idx">
            <template v-if="header.sortable">

              <button @click="clickHeader(header, idx)" class="btn-sort" :class="{ 'on': clickedHeaderIndex === idx }">
                {{ header.title }}
                <span class="icon" v-if="header.sortBy === 'asc'">
                  <font-awesome-icon :icon="['fas', 'arrow-up']" />

                </span>
                <span class="icon" v-if="header.sortBy === 'desc'">
                  <font-awesome-icon :icon="['fas', 'arrow-down']" />
                </span>
              </button>
            </template>
            <template v-else>
              {{ header.title }}
            </template>
          </th>
        </template>
      </tr>
    </thead>
    <!-- Table Contents -->
    <tbody class="table-line__body">
      <template v-if="data && data.length">
        <template v-for="(row, idx) in data.slice(0, maxShow)">
          <tr :key="idx" :class="{ 'practice__submit': row.practice ? true : false }">
            <template v-for="(header, idx2) in tableHeader">
              <td :key="'column-' + idx2" :data-label="header.title">
                <div class="flex-shrink">
                  <template v-if="header.submitScore">
                    <template v-for="(param, paramIdx) in evaluationParams">
                      <template v-if="
                        header.value === 'scoreList-' + paramIdx &&
                        row['scoreList-' + paramIdx]
                      ">

                        <div class="team__submit" :key="'publicScore-' + paramIdx"
                          v-if="row['scoreList-' + paramIdx].publicScore >= 0">
                          <span class="team__submit__title">Public Score</span>
                          <strong class="team__submit__text">
                            {{ row["scoreList-" + paramIdx].publicScore.toFixed(4) }}
                          </strong>
                        </div>
                        <div class="team__submit" :key="'privateScore-' + paramIdx" v-if="
                          isPrivateExpose &&
                          row['scoreList-' + paramIdx].privateScore >= 0
                        ">
                          <span class="team__submit__title">Private Score</span>
                          <strong class="team__submit__text">
                            {{ row["scoreList-" + paramIdx].privateScore.toFixed(4) }}
                          </strong>
                        </div>
                        <div class="team__submit" :key="'finalScore-' + paramIdx" v-if="
                          isFinalExpose &&
                          row['scoreList-' + paramIdx].finalScore >= 0
                        ">
                          <span class="team__submit__title">
                            Final Score
                          </span>
                          <strong class="team__submit__text">
                            {{ row["scoreList-" + paramIdx].finalScore.toFixed(4) }}
                          </strong>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <slot :name="header.value" :row="row">
                      <template v-if="typeof(row[header.value]) === 'number' && header.value != 'index' && header.value != 'id' && header.value != 'idx'">
                        {{ row[header.value].toFixed(4) }}
                      </template>
                      <template v-else>
                        {{ row[header.value] }}
                      </template>
                    </slot>
                  </template>
                </div>
              </td>
            </template>
          </tr>
        </template>
      </template>
      <template v-else>
        <template v-if="boardType === 'leaderboard'">
          <tr>
            <td :colspan="tableHeader.length" class="no-data">
              <p class="no-data__text">결과 제출 시 리더보드를 확인할 수 있습니다.</p>
            </td>
          </tr>
        </template>
        <template v-else-if="boardType === 'submitTable'">
          <td :colspan="tableHeader.length" class="no-data">
            <p class="no-data__text">제출이력이 없습니다.</p>
          </td>
        </template>
      </template>
    </tbody>
  </table>
</template>


<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  ref,
} from "@vue/composition-api";
import { EventBus } from '@/lib/event-bus';
import moment from 'moment'

export default {
  components: {},
  props: [
    "boardType",
    "isPrivateExpose",
    "isFinalExpose",
    "defaultClickedHeaderIdx",
    "tableHeader",
    "data",
    "evaluationParams", // evaluation param list
    "practiceData",
    "maxShow",
  ],
  setup(props, context) {
    const state = reactive({
      // minColumnWidth: 100, // px
      columnStyle: {},
      clickedHeaderIndex: props.defaultClickedHeaderIdx
        ? props.defaultClickedHeaderIdx
        : 0,
    });

    onMounted(() => {
      EventBus.$on('changeClickedHeaderIdx', changeClickedHeaderIdx)
      if (!props.data.length) state.clickedHeaderIndex = null;
      initSort(
        props.tableHeader[state.clickedHeaderIndex],
        state.clickedHeaderIndex
      );
      insertRank(props.data)
    });

    const clickHeader = (headerObj, headerIndex) => {
      state.clickedHeaderIndex = headerIndex;
      sort(headerObj, headerIndex);
      insertRank(props.data);
      EventBus.$emit('clickedTableHeader', headerIndex)
    };

    const initSort = (headerObj, headerIndex) => {
      try {
        if (headerObj.sortBy === "desc") {          // 내림차순
          props.data.sort((i, j) => {
            const a = i[headerObj.value];
            const b = j[headerObj.value];
            if (a < b) return 1;
            if (a > b) return -1;
            // 동점일 경우
            if (i.createDtm) {
              const c = i.createDtm
              const d = j.createDtm
              if (moment(c).isAfter(d)) return 1
              else return -1
            }
          });
        } else {
          props.data.sort((i, j) => {               // 오름차순
            const a = i[headerObj.value];
            const b = j[headerObj.value];
            if (a < b) return -1;
            if (a > b) return 1;
            // 동점일 경우
            if (i.createDtm) {
              const c = i.createDtm
              const d = j.createDtm
              if (moment(c).isAfter(d)) return 1
              else return -1
            }
          });
        }
      } catch {
        // do nothing
      }
    };

    const insertRank = (array) => {
      var currentRank = 0
      array.map((el, idx) => {
        if (!el.isPractice) {
          if (idx === 0) {
            el.ranking = 1
          } else {
            el.ranking = currentRank + 1
          }
          currentRank = el.ranking
        }
      })
    }

    const sort = (headerObj, headerIndex) => {
      try {
        if (headerObj.sortBy === "desc") {          // 내림차순
          props.data.sort((i, j) => {
            const a = i[headerObj.value];
            const b = j[headerObj.value];
            if (a < b) return 1;
            if (a > b) return -1;
            // 동점일 경우
            if (i.createDtm) {
              const c = i.createDtm
              const d = j.createDtm
              if (moment(c).isAfter(d)) return 1
              else return -1
            }
          });
          // props.tableHeader[headerIndex].sortBy = 'asc'
        } else {
          props.data.sort((i, j) => {               // 오름차순
            const a = i[headerObj.value];
            const b = j[headerObj.value];
            if (a < b) return -1;
            if (a > b) return 1;
            // 동점일 경우
            if (i.createDtm) {
              const c = i.createDtm
              const d = j.createDtm
              if (moment(c).isAfter(d)) return 1
              else return -1
            }
          });
          // props.tableHeader[headerIndex].sortBy = 'desc'
        }

      } catch {
        // do nothing
      }
    };

    const getHeaderWidth = computed(() => {
      let width = 0;
      props.tableHeader.forEach(header => {
        if (header.width) {
          width = width + header.width
        } else {
          width = width + state.minColumnWidth
        }
      })
      return width
    })



    const changeClickedHeaderIdx = (idx) => {
      state.clickedHeaderIndex = idx
      if (idx === 0)
        insertRank(props.data);
    }

    return {
      ...toRefs(state),
      clickHeader,
      sort,
      getHeaderWidth,
    };
  },
};
</script>




