<template>
    <v-menu class="notice" :min-width="300" :max-width="320" :max-height="280" offset-y nudge-bottom="5" nudge-left="260"
        :close-on-content-click="false" :content-class="'header-menu-list'" v-model="dialogOpen"
        v-body-scroll-lock="dialogOpen" attach="">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="draw-status" icon v-bind="attrs" v-on="on">
                <v-badge overlap dot color="red" class="header_noti_new" v-if="notiCount > 0">
                    <v-icon>notifications_none</v-icon>
                </v-badge>
                <v-badge overlap dot class="header_noti" v-else>
                    <v-icon>notifications_none</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <div class="notice__inner">
            <div class="notice__header">
                <div>
                    <h3 class="notice__title">새로운 알림 {{ notiCount }}</h3>
                </div>
                <div>
                    <v-btn class="btn-secondary btn-xsmall" @click="onClickNotificationReadAll">
                        모두 읽음
                    </v-btn>
                    <button type="button" class="btn-closed" @click="dialogOpen = !dialogOpen">
                        <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#616366"
                            icon-name="icon-closed"><icon-closed /></icon-base>
                    </button>
                </div>
            </div>
            <div class="notice__body">
                <div class="notice__item" v-for="(content, idx) in notiList" :key="idx"
                    @click="onClickNotificationRead(content)">
                    <div class="notice__item--point">
                        <span v-if="!content.isread" class="point"></span>
                        <span v-else></span>
                    </div>
                    <!-- 가입 축하 알림 -->
                    <template v-if="content.noticode === 1">
                        <button type="button" class="notice__item--text">
                            <span class="title">
                                가입을 축하드립니다!
                            </span>
                        </button>
                    </template>

                    <!-- 대회 참가 알림 -->
                    <template v-if="content.noticode === 2 && content.issingleassignment === 1">
                        <button type="button" class="notice__item--text">
                            <span class="message">참여 신청되었습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.competitionname }}</span>
                            </span>
                        </button>
                    </template>
                    <template v-else-if="content.noticode === 2 && content.issingleassignment === 0">
                        <button type="button" class="notice__item--text">
                            <span class="message">참여할 과제를 선택해 주세요.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.competitionname }}</span>
                            </span>
                        </button>
                    </template>

                    <!-- 공지 알림 -->
                    <template v-if="content.noticode === 3">
                        <button type="button" class="notice__item--text">
                            <span class="message">새로운 공지가 있습니다.</span>
                            <span class="title">{{ content.competitionname }}</span>
                        </button>
                    </template>

                    <!-- 팀 참여 알림 -->
                    <template v-if="content.noticode === 4">
                        <button type="button" class="notice__item--text">
                            <span class="message">팀원이 되었습니다.</span>
                            <span class="title">{{ content.competitionname }}</span>
                        </button>
                    </template>

                    <!-- 대회 문의 댓글 알림 -->
                    <template v-if="content.noticode === 5">
                        <button type="button" class="notice__item--text">
                            <span class="message"
                                v-if="content.notitriggeruserrole === 0 && content.notitriggercompetitionuserrole !== 2">내가
                                쓴 글에 새로운 댓글이 등록되었습니다.</span>
                            <span class="message" v-else> 내가 쓴 글에 답변이 등록되었습니다.</span>
                            <div class="noti-content-target"></div>
                            <span class="title">{{ titleEllipsis(content.competitionqnatitle) }} </span>
                        </button>
                    </template>

                    <!-- 과제 문의 댓글 알림 -->
                    <template v-if="content.noticode === 6">
                        <button type="button" class="notice__item--text">
                            <span class="message"
                                v-if="content.notitriggeruserrole === 0 && content.notitriggercompetitionuserrole !== 2">내가
                                쓴 글에 새로운 댓글이 등록되었습니다.</span>
                            <span class="message" v-else>내가 쓴 글에 답변이 등록되었습니다.</span>
                            <div class="noti-content-target"></div>
                            <span class="title">{{ titleEllipsis(content.assignmentqnatitle) }}</span>
                        </button>
                    </template>

                    <!-- 코드 공유 댓글 알림 -->
                    <template v-if="content.noticode === 7">
                        <button type="button" class="notice__item--text">
                            <span class="message">내가 쓴 글에 새로운 댓글이 있습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span></span>
                            </span>
                        </button>
                    </template>


                    <!-- 팀 초대 알림 -->
                    <template v-if="content.noticode === 8">
                        <button type="button" class="notice__item--text">
                            <span class="message"> 팀원으로 초대받았습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.teamname }} | {{ content.notitriggernickname }}</span>
                            </span>
                        </button>
                    </template>
                    <!-- 팀 초대 수락 알림 -->
                    <template v-if="content.noticode === 9"> <!-- 초대 수락 -->
                        <button type="button" class="notice__item--text">
                            <span class="message">새로운 팀원이 있습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.teamname }} | {{ content.notitriggernickname }}</span>
                            </span>
                        </button>
                    </template>

                    <!-- 팀 초대 거절 알림 -->
                    <template v-if="content.noticode === 10"> <!-- 초대 거절 -->
                        <button type="button" class="notice__item--text">
                            <span class="message"> 초대장이 거절당했습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.teamname }} | {{ content.notitriggernickname }}</span>
                            </span>
                        </button>
                    </template>
                    <!-- 팀 승인 알림 -->
                    <template v-if="content.noticode === 11">
                        <button type="button" class="notice__item--text">
                            <span class="message"> 팀이 승인되었습니다.</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.teamname }}</span>
                            </span>
                        </button>
                    </template>

                    <!-- 신규 notification 일괄 처리. 위에 남은 것들은 추후 삭제 필요. (지금 제거하면 backward compatibility 문제 발생함) -->
                    <template v-if="content.noticode > 1000 && content.noticode < 2000">
                        <button type="button" class="notice__item--text">
                            <span class="message">{{ content.title }}</span>
                            <span class="title">
                                <div class="noti-content-target"></div>
                                <span>{{ content.content }}</span>
                            </span>
                        </button>
                    </template>

                </div>

                <!-- 알림이 없을 때 -->
                <div v-if="notiList.length === 0">
                    <div class="no-data">
                        <p class="no-data__text">새로운 알림이 없습니다.</p>
                    </div>
                </div>

            </div>
        </div>

    </v-menu>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import moment from 'moment'
import utils from '@/helper/utils';
import Notification from '@/lib/api/Notification'
import modalControler from '@/helper/modalControler';
import { stateStore } from '@/stores/state';
import { EventBus } from '@/lib/event-bus';


export default {
    name: 'Notification',
    components: {
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const stateStorage = stateStore()
        const state = reactive({
            dialogOpen: false,
            notiList: [],
            notiCount: 0,
            maxTitleLength: 54,
            minWidth: 400,
            maxWidth: 450,
            maxHeight: 600,
            showTeamModal: false,
        })

        onMounted(() => {
            getNotificationCount()
            getNotification()

            if (utils.isMobile()) {
                // state.minWidth = 300
                // state.maxWidth = 330
                // state.maxHeight = 600
            }
        })

        onUnmounted(() => {
            // state.dialogOpen = false
        })

        const getNotificationCount = () => {
            Notification.getNotificationCount().then(response => {
                if (!response.data.error) {
                    state.notiCount = response.data.result
                } else {
                    // do nothing
                }
            }).catch(err => {
                console.log(err)
            })
        }

        const getNotification = () => {
            Notification.getNotification().then(response => {
                if (!response.data.error) {
                    response.data.result.sort(notiSort)
                    state.notiList = response.data.result
                } else {
                    modalControler.ShowErrorToast('알람을 불러올 수 없습니다.')
                }
            }).catch(err => {
                console.log(err)
            })
        }

        const notiSort = (a, b) => {
            return moment.utc(b.createdtm).diff(moment.utc(a.createdtm))
        }

        const titleEllipsis = (title) => {
            var titleLen = title.length
            var sliced
            if (titleLen > state.maxTitleLength) {
                sliced = title.slice(state.maxTitleLength)
                return sliced + '…'
            }
            return title
        }

        const onClickNotificationRead = (value) => {
            let param = {
                id: value.id
            }
            Notification.updateNotificationRead(param).then(response => {
                if (!response.data.error) {
                    // state.dialogOpen = false
                    getNotificationCount()
                    getNotification();
                    onClickNotificationMove(value)
                    // context.emit('closeNoti')
                } else {
                    // do nothing
                }
            }).catch(err => {
                console.log(err)
            })
        }

        const onClickNotificationReadAll = () => {
            let param = {}
            Notification.updateNotificationRead(param).then(response => {
                if (!response.data.error) {
                    getNotificationCount()
                    getNotification();
                } else {
                    // do nothing
                }
            }).catch(err => {
                console.log(err)
            })
        }

        const onClickNotificationMove = (value) => {
            // 대회 참가 알림
            // state.dialogOpen = false
            if (value.noticode === 2) {
                // router.push({
                //     name: 'TaskList',
                //     params: { competitionId: value.competitionid }
                // })
                stateStorage.setCompetitionDetailTab('competitionTask')
                router.goCompetitionPages('CompetitionDetail', value.competitionid)
            }
            // 공지 알림
            if (value.noticode === 3) {
                // this.$router.push({})
                if (value.competitionqnaid !== 0 && value.assignmentid == 0) {
                    router.push({
                        path: `/competition/detail/${value.competitionid}/qna/${value.competitionqnaid}`,
                    })
                } else if (value.competitionid !== 0 && value.assignmentid !== 0) {
                    router.push({
                        path: `/competition/detail/${value.competitionid}/task/${value.assignmentid}/notice/detail/${value.assignmentqnaid}`,
                    })
                }
            }
            // 대회 문의 댓글
            if (value.noticode === 5) {
                router.push({
                    // path: `/competition/detail/${value.competitionid}/task/${process.env.VUE_APP_NIPA_TASK_ID}/competition_notice/detail/${value.competitionqnaid}`,
                    path: `/competition/detail/${value.competitionid}/qna/${value.competitionqnaid}`,
                })
            }
            // 과제 문의 댓글
            if (value.noticode === 6) {
                EventBus
                router.push({
                    path: `/competition/detail/${value.competitionid}/task/${value.assignmentid}/notice/detail/${value.assignmentqnaid}`,
                })
            }
            // 코드 공유 댓글
            // TODO : response 수정 필요
            if (value.noticode === 7) {
                router.push({
                    path: `/competition/detail/${value.competitionid}/task/${value.assignmentid}/codeshare/detail/${value.codeshareid}`,
                })
                EventBus.$emit('summaryUpdate')
            }
            // 팀 초대 알림, 팀 초대 수락 알림 , 팀 초대 거절 알림
            if ((value.noticode === 4) || (value.noticode === 8) || (value.noticode === 9) || (value.noticode === 10) || (value.noticode === 11)) {

                stateStorage.setCompetitionDetailTab('competitionTeam')
                router.goCompetitionPages('CompetitionDetail', value.competitionid)
                // router.goTaskPages('TaskTeam', process.env.VUE_APP_NIPA_TASK_ID, process.env.VUE_APP_NIPA_ID)
                // router.push({
                //     // path: `/competition/detail/${value.competitionid}/task/${value.assignmentid}/team`,
                //     // path: `/competition/detail/${value.competitionid}/profile`,
                //     name: 'CompetitionDetailProfile',
                //     params: {
                //         competitionId: value.competitionid,
                //         queryId: value.competitionid
                //     }
                // })
            }

            // 신규 notification 일괄 처리. 위에 남은 것들은 추후 삭제 필요. (지금 제거하면 backward compatibility 문제 발생함)
            if (value.noticode > 1000 && value.noticode < 2000) {
                router.push({
                    path: value.link
                })
            }

            // Tab 이동
            if (value.noticode === 1101) {
                stateStorage.setCompetitionDetailTab('competitionTask')
            }
            if (value.noticode === 1103 || value.noticode === 1104 || value.noticode === 1105 || value.noticode === 1106 || value.noticode === 1107) {
                stateStorage.setCompetitionDetailTab('competitionTeam')
            }

            state.dialogOpen = false;
        }

        const openTeamModal = () => {
            state.showTeamModal = true
        }

        return {
            ...toRefs(state),
            titleEllipsis,
            onClickNotificationMove,
            onClickNotificationRead,
            onClickNotificationReadAll,
        }
    }
}
</script>

<style scoped lang="scss">
.isread {
    height: 100%;
    width: 1.2%;
    margin-left: -20px;
}

.v-menu__content {
    overflow-x: unset;
    overflow-y: unset;
}
</style>