<template>
    <div class="profile-wrap bg-contents-wrap">
        <Profile v-if="$route.meta.GNBType === 'competition'" passwordEditRouterName="CompetitionListPasswordEdit"
            passwordChkouterName="CompetitionListPasswrodChk" />
        <Profile v-else passwordEditRouterName="CompetitionDetailPasswordEdit"
            passwordChkouterName="CompetitionDetailPasswordChk" />
    </div>
</template>

<script>

import Profile from '@/components/user/Profile'

export default {
    components: {
        Profile,
    },
}

</script>

<style scoped lang="scss"></style>