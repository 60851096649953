<template>
    <v-app>
        <section class="prfile-edit-section">
            <div class="medium-content-inner">
                <header class="page-header--line--left__section">
                    <h2 class="page-header--line__title">프로필 설정</h2>
                </header>
                <v-form ref="form" lazy-validation>
                    <div class="form-group form-group__detail text-center">
                        <div class="profile-img profile-img__size--600 mb--24">
                            <img :src="(previewURL) ? previewURL : require('@/assets/images/img-profile-blue.svg')"
                                alt="프로필 이미지" />
                        </div>
                        <v-file-input class="common-form-control" outlined prepend-inner-icon="mdi-paperclip"
                            :prepend-icon="null" accept="image/*" placeholder="파일첨부 최대 5MB" truncate-length="30"
                            ref="profileEl" type="file" v-model="profile.profileimg"
                            :error-messages="errorStatus.profileErrorMessage"
                            @change="validate($event, profile.profileimg)"></v-file-input>
                    </div>
                    <div class="content-group">
                        <header class="content-group__header">
                            <div class="content-group__title">기본정보</div>
                        </header>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">닉네임<span class="essential">*</span></div>
                            <v-text-field placeholder="특수문자, 공백없이 최소 4~20자" class="common-form-control" outlined
                                v-model="profile.info.nickname" @keyup="onKeyup($event, 'nickname')"
                                @blur="checkNickName(profile.info.nickname)"
                                :error-messages="errorStatus.nicknameErrorMessage">
                                <template v-slot:append>
                                    <v-btn v-if="isNickNameChecked === false" class="btn btn-small btn-secondary"
                                        :disabled="(errorStatus.nicknameErrorMessage !== '') ? true : false"
                                        @click="nicknameDuplicationCheck(profile.info.nickname)">중복체크</v-btn>
                                    <span v-else class="confirm-chk">
                                        <b-icon icon="check-circle-fill"></b-icon>
                                    </span>
                                </template>
                            </v-text-field>
                        </div>

                        <div class="form-group form-group__detail">
                            <div class="form-group__title">이름<span class="essential">*</span></div>
                            <v-text-field class="common-form-control" outlined placeholder="특수문자, 공백없이 최소 2~20자"
                                v-model="profile.info.name" @blur="checkName(profile.info.name)"
                                @keyup="onKeyup($event, 'name')" :error-messages="errorStatus.nameErrorMessage">
                            </v-text-field>
                        </div>

                        <div class="form-group">
                            <div class="form-group__title">이메일<span class="essential">*</span></div>
                            <v-text-field class="common-form-control" outlined hide-details readonly disabled
                                v-model="profile.email">
                            </v-text-field>
                        </div>
                    </div>
                    <div class="content-group">
                        <header class="content-group__header">
                            <h3 class="content-group__title">추가정보</h3>
                        </header>

                        <div class="form-group form-group__detail">
                            <div class="form-group__header form-group__both">
                                <div class="form-group__title mb-0">휴대전화</div>
                                <template>
                                    <div>
                                        <v-btn @click="niceCertificate" class="btn-small btn-secondary">
                                            {{ btnName }}
                                        </v-btn>
                                        <!-- NICE본인인증 -->
                                        <form name="form_chk" method="post">
                                            <input type="hidden" name="m" value="checkplusService">
                                            <!-- 필수 데이타로, 누락하시면 안됩니다. -->
                                            <input type="hidden" name="EncodeData" :value="sEncData">
                                            <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
                                        </form>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="list__box--area">
                                <div class="list list__box list__box--red"
                                    v-if="!profile.info.mobile || profile.info.mobile === ''">
                                    <p class="list__text--red">등록된 정보가 없습니다. 휴대폰 번호 인증을 통해 등록해주세요. </p>
                                </div>
                            </div>
                            <v-text-field class="common-form-control" outlined disabled readonly
                                :label="profile.info.mobile">
                            </v-text-field>
                        </div>

                        <div class="form-group form-group__detail">
                            <div class="form-group__title">생년월일</div>
                            <v-menu class="common-form-control" outlined :nudge-right="31" :close-on-content-click="false"
                                transition="scale-transition" min-width="auto" ref="menu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="common-form-control" outlined prepend-inner-icon="mdi-calendar"
                                        readonly disabled v-on="on" v-bind="attrs"
                                        v-model="profile.info.dateofbirth"></v-text-field>
                                </template>

                            </v-menu>
                        </div>

                        <div class="form-group">
                            <div class="form-group__title">소속기관</div>
                            <v-select v-model="profile.info.belong" :items="belongs" single-line hide-details
                                label="소속기관 유형을 선택해주세요." class="common-form-control" outlined></v-select>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">소속기관명</div>
                            <v-text-field class="common-form-control" outlined single-line placeholder="소속기관 이름을 입력해주세요."
                                v-model="profile.info.belongname" @keyup="onKeyup($event, 'belongname')"
                                @blur="checkBelongName(profile.info.belongname)"
                                :error-messages="errorStatus.belongNameMessage"></v-text-field>
                        </div>

                    </div>

                    <!-- SNS Login -->
                    <div class="content-group">
                        <header class="content-group__header">
                            <h3 class="content-group__title">SNS 로그인 관리</h3>
                        </header>
                        <div class="form-group">
                            <div class="form-group__header form-group__both">
                                <div class="form-group__title mb-0">네이버 로그인</div>
                                <div id="linkedContainer" class="btn-inline btn-inline--right">
                                    <template v-if="!naverLinked">
                                        <v-btn class="btn-small btn-secondary" @click="naverLink">연동하기</v-btn>
                                    </template>
                                    <template v-else>
                                        <span class="info-text">{{ linkedtmFormatter(naverLinkedtm) }} 연동완료</span>
                                        <v-btn class="btn-small btn-negative" @click="unLinkSocial('naver')">연동해제</v-btn>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-group__header form-group__both">
                                <div class="form-group__title mb-0">구글 로그인</div>
                                <div id="linkedContainer" class="btn-inline btn-inline--right ">
                                    <template v-if="!googleLinked">
                                        <v-btn class="btn-small btn-secondary" @click="googleLink">연동하기</v-btn>
                                    </template>
                                    <template v-else>
                                        <span class="info-text">{{ linkedtmFormatter(googleLinkedtm) }} 연동완료</span>
                                        <v-btn class="btn-small btn-secondary" @click="unLinkSocial('google')">연동해제</v-btn>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="btn-inline btn-inline--section">
                            <div class="btn-inline--left">
                                <v-btn class="btn-secondary btn-small" @click="openUserDelete">탈퇴하기</v-btn>
                            </div>

                            <div class="btn-inline--center">
                                <v-btn class="btn btn-large btn-tertiary"
                                    @click="$router.push({ name: 'CompetitionListProfile' })"><strong>취소</strong></v-btn>
                                <v-btn class="btn btn-large btn-primary" @click="updateProfile"><strong>저장</strong></v-btn>
                            </div>
                        </div>
                    </div>
                </v-form>
            </div>
            <!-- 탈퇴 모달  -->
            <UserWithdrawal @submit="onUserDelete" @dialogClose="userWithdrawalVisible = false"
                :visible="userWithdrawalVisible" />
        </section>
    </v-app>
</template>

<script>
import moment from 'moment'
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref,
} from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import jwtDecode from 'jwt-decode';
import { JSEncrypt } from 'jsencrypt';
import utils from '@/helper/utils';
import { BIcon } from 'bootstrap-vue'
import { tokenStore } from '@/stores/auth';
import validator from "@/config/formValidator";
import httpClient from '@/lib/api/httpClient';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import UserWithdrawal from '@/components/dialog/UserWithdrawal'
import SocialAPI from '@/lib/api/social';


export default {
    name: 'ProfileEdit',
    components: {
        BIcon,
        UserWithdrawal,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const profileEl = ref(null);
        // if (!route.params.pwcheck) {
        //     // TODO : error alert
        //     alert('비밀번호 인증이 필요한 페이지입니다.')
        //     // router.push({ name: 'CompetitionDetailPasswordChk', params: {} })
        //     router.goCompetitionPages('CompetitionDetailPasswordChk', route.params.competitionId)
        // }

        const store = tokenStore()
        const accountInfo = store.accountInfo
        const state = reactive({
            sEncData: null,
            isCertified: false,
            niceCertified: accountInfo.iscertified,
            errorStatus: {
                profileErrorMessage: '',
                repasswordErrorMessage: '',
                passwordErrorMessage: '',
                nicknameErrorMessage: '',
                nameErrorMessage: '',
                belongNameMessage: '',
            },
            naverLinkedtm: '',
            googleLinkedtm: '',
            naverLinked: false,
            googleLinked: false,
            previewURL: (accountInfo.profileimg) ? accountInfo.profileimg : '',
            profile: {
                type: 'email',
                email: accountInfo.email,
                info: {
                    name: accountInfo.name,
                    nickname: accountInfo.nickname,
                    password: '',
                    newpassword: '',
                    dateofbirth: accountInfo.dateofbirth,
                    belong: accountInfo.belong,
                    belongname: accountInfo.belongname,
                    mobile: accountInfo.mobile,
                    iscertified: false,
                    token: null,
                },
                profileimg: null
            },
            fileName: null,
            isNickNameChecked: (accountInfo.nickname) ? true : false,
            checkedNickName: (accountInfo.nickname) ? accountInfo.nickname : '',
            profile_rules: [
                value => !value || value.size < 5000000 || '프로필 이미지는 최대 5MB까지 업로드할 수 있습니다.',
            ],
            profileImgMaxSize: 5000000, // 5MB
            formProfile: {},
            dialog: {
                deleteDialog: false,
            },
            userWithdrawalVisible: false
        });

        onMounted(() => {
            getNicesEncData()
            makeFunction()

            if (accountInfo.oauth) {
                if (accountInfo.oauth['google']) {
                    state.googleLinkedtm = accountInfo.oauth['google'].createdtm
                    state.googleLinked = true
                }
                if (accountInfo.oauth['naver']) {
                    state.naverLinkedtm = accountInfo.oauth['naver'].createdtm
                    state.naverLinked = true
                }
            }

            window.naverAuthLink = (token) => {
                const param = {
                    token: token
                }
                SocialAPI.socialAuthLink(param).then((response) => {
                    if (!response.data.error) {
                        modalControler.ShowSuccessToast('연동에 성공하였습니다.');
                        state.naverLinked = true
                        state.naverLinkedtm = moment().format('yyyy-MM-DD HH:mm')
                    } else {
                        modalControler.ShowErrorToast('연동에 실패했습니다.');
                    }

                    // CommonUiControl.HideUIProgress();
                })
            };

            window.googleAuthLink = (token) => {
                const param = {
                    token: token
                }
                SocialAPI.socialAuthLink(param).then((response) => {
                    if (!response.data.error) {
                        modalControler.ShowSuccessToast('연동에 성공하였습니다.');
                        state.googleLinked = true
                        state.googleLinkedtm = moment().format('yyyy-MM-DD HH:mm')
                    } else {
                        modalControler.ShowErrorToast('연동에 실패했습니다.');
                    }
                });
            };

        });

        const belongs = computed(() => {
            return [
                "중소기업",
                "대학교",
                "대기업",
                "개인사용자",
                "정부/공공기관",
                "연구기관(민간)",
                "기타"
            ]
        });

        const btnName = computed(() => {
            return (state.niceCertified) ? "재인증" : "본인인증"
        })

        // blur 시 validation
        const checkNickName = (nickname) => {
            state.errorStatus.nicknameErrorMessage = validator.nicknameValidator(nickname)
        }

        const checkName = (name) => {
            state.errorStatus.nameErrorMessage = validator.nameValidator(name)
        }

        const checkBelongName = (belongname) => {
            state.errorStatus.belongNameMessage = validator.belongNameValidator(belongname)
        }


        // 닉네임 중복체크
        const nicknameDuplicationCheck = (nickname) => {
            Authenticate.checkNickname({ nickname: nickname }).then(response => {
                if (response.data.result) {
                    modalControler.ShowWarningToast('이미 사용 중인 닉네임입니다.')
                } else {
                    modalControler.ShowSuccessToast('사용 가능한 닉네임입니다.')
                    state.isNickNameChecked = true
                    state.checkedNickName = state.profile.info.nickname
                }
            }).catch(err => err);
        }

        const onKeyup = (e, type) => {
            if (state.isNickNameChecked === true && (state.checkedNickName !== state.profile.info.nickname)) {
                state.isNickNameChecked = false
            }

            if (state.checkedNickName === state.profile.info.nickname) {        // 기존 닉네임 입력시 중복체크 불필요
                state.isNickNameChecked = true
            }

            if (type === 'nickname') {
                checkNickName(state.profile.info.nickname)
            } else if (type === 'name') {
                checkName(state.profile.name)
            } else if (type === 'belongname') {
                checkBelongName(state.profile.info.belongname)
            }

        }

        const validate = (e, data) => {
            if (data && data.size > state.profileImgMaxSize) {
                profileEl.value.reset()
                state.errorStatus.profileErrorMessage = '프로필 이미지는 최대 5MB까지 업로드할 수 있습니다.'
                modalControler.ShowErrorToast('프로필 이미지는 최대 5MB까지 업로드할 수 있습니다.')
            } else {
                state.errorStatus.profileErrorMessage = ''
                state.previewURL = URL.createObjectURL(e)
            }
        }

        const getBlobFromUrl = async (url) => {
            state.fileName = new URL(url).pathname.replace(/^.*[\\\\/]/, '')
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
            });
            const data = await response.blob()
            return new File([data], state.fileName, { type: 'image/' + state.fileName.split('.').pop() });
        }

        // nice 필수 데이터 호출
        const getNicesEncData = () => {
            httpClient.get('/auth/nice').then(({ data } = response) => {
                state.sEncData = data.result
            });
        }

        // nice 본인인증 팝업창
        const niceCertificate = () => {
            window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            document.form_chk.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
            document.form_chk.target = 'popupChk';
            document.form_chk.submit();
        }

        const updateProfile = () => {
            if (!state.isNickNameChecked) {
                modalControler.ShowErrorToast('닉네임 중복체크를 해주세요.');
                return;
            }

            if (state.errorStatus.nameErrorMessage) {
                modalControler.ShowErrorToast('이름이 잘못되었습니다.')
                return
            }

            if (state.errorStatus.belongNameMessage) {
                modalControler.ShowErrorToast('소속기관 이름이 잘못되었습니다.')
                return
            }


            modalControler.ShowLoading()
            let formData = new FormData()
            let profile = cloneDeep(state.profile)
            profile.dateofbirth = state.profile.info.dateofbirth
            if (state.profile.profileimg !== '') {
                formData.append('files', state.profile.profileimg)
            }
            const param = window.btoa(unescape(encodeURIComponent(JSON.stringify(profile))));
            formData.append('data', param);
            Authenticate.updateProfile(formData).then(response => {
                if (response.data.error && response.data.error_code === 7500) {
                    modalControler.ShowErrorToast('이미 사용 중인 닉네임입니다.');
                }

                if (!response.data.error) {
                    modalControler.ShowSuccessToast('프로필 업데이트 완료')
                    modalControler.HideLoading()
                    router.push({ name: 'CompetitionListProfile' })
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.message)
            })
        }

        const openUserDelete = () => {
            state.userWithdrawalVisible = true
        }

        const onUserDelete = () => {
            Authenticate.withdrawal().then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('회원탈퇴 되었습니다.')
                    state.userWithdrawalVisible = false
                    router.signout()
                } else {
                    if (response.data.error_code === 7500) {
                        modalControler.ShowErrorToast(response.data.error_detail)
                    }
                }
            })
        }

        const naverLink = () => {
            // CommonUiControl.ShowUIProgress();
            SocialAPI.socialAuth('link').then((response) => {
                if (!response.data.error) {
                    window.open(response.data.result.naver, 'popUpOpen', 'width=500, height=900, top=200, left=200, \
                                    toolbar=no, menubar=no, scrollbars=no, resizable=yes');
                    // location.href = response.data.result.naver
                } else {
                    modalControler.ShowErrorToast('연동할 수 없습니다.');
                    // CommonUiControl.HideUIProgress();
                }
            })
        }

        const googleLink = () => {
            // CommonUiControl.ShowUIProgress();
            SocialAPI.socialAuth('link').then((response) => {
                if (!response.data.error) {
                    window.open(response.data.result.google, 'popUpOpen', 'width=500, height=900, top=200, left=200, \
                                toolbar=no, menubar=no, scrollbars=no, resizable=yes');
                    // location.href = response.data.result.google
                } else {
                    modalControler.ShowErrorToast('연동할 수 없습니다.');
                    // CommonUiControl.HideUIProgress();
                }
            })
        }

        const isLinked = (provider) => {
            if (accountInfo && (accountInfo.oauth[provider])) {
                return
            }
        }

        const unLinkSocial = (provider) => {
            var msg
            if (accountInfo.passwordmodifydt) {
                msg = "SNS 로그인 연동을 해제하시겠습니까?\n연동 해제하더라도 서비스에 탈퇴되지 않으며,\n이메일 주소를 통해 로그인할 수 있습니다.";
                modalControler.ShowModalDialog('개인정보 변경', msg, '취소', '확인', (val) => {
                    if (val) {
                        let param = {
                            provider: provider
                        }
                        SocialAPI.socialAuthUnlink(param).then((response) => {
                            if (!response.data.error) {
                                if (provider === 'google') {
                                    state.googleLinkedtm = ''
                                    state.googleLinked = false
                                } else if (provider === 'naver') {
                                    state.naverLinkedtm = ''
                                    state.naverLinked = false
                                }
                                modalControler.ShowSuccessToast('연동해제 되었습니다.');
                            } else {
                                modalControler.ShowErrorToast(response.data.error_detail)
                            }
                        })
                    }
                });
            } else {
                msg = "최소 1개 이상의 로그인 수단을 유지해야 합니다.\n연동 해제를 원하시면 이메일로 회원가입 해주시기 바랍니다.";
                modalControler.ShowErrorToast(msg);
            }
        }

        const linkedtmFormatter = (dtm) => {
            return moment(dtm).format('yyyy-MM-DD HH:mm')
        }


        return {
            ...toRefs(state),
            belongs,
            btnName,
            onKeyup,
            profileEl,
            validate,
            checkName,
            checkNickName,
            checkBelongName,
            niceCertificate,
            nicknameDuplicationCheck,
            updateProfile,
            onUserDelete,
            openUserDelete,
            isLinked,
            naverLink,
            googleLink,
            unLinkSocial,
            linkedtmFormatter,
        }

        // nice 본인인증 response 함수
        function makeFunction() {
            window.niceCertificate = async (token) => {
                const query = jwtDecode(token);
                if (moment().diff(moment(query.birthdate).format('YYYY-MM-DD'), 'years') < 14) {
                    modalControler.ShowWarningToast('14세 미만은 참여 하실 수 없습니다.')
                    return
                }

                state.profile = store.getAccountInfo;
                state.profile.info = {};
                state.profile.info.nickname = accountInfo.nickname;
                state.profile.info.belong = accountInfo.belong;
                state.profile.info.belongname = accountInfo.belongname;
                state.profile.info.token = token;
                state.profile.info.email = accountInfo.email;
                state.profile.info.mobile = utils.formatPhoneNumber(query.mobile);
                state.profile.info.dateofbirth = moment(query.birthdate).format('YYYY-MM-DD');
                state.profile.info.name = query.name;
                state.profile.info.iscertified = true;
                state.profile.profileimg = [];

                let formData = new FormData()
                const param = window.btoa(unescape(encodeURIComponent(JSON.stringify(state.profile))));
                formData.append('data', param)

                Authenticate.updateProfile(formData).then(response => {
                    if (!response.data.error) {
                        state.niceCertified = true
                        modalControler.ShowSuccessToast('본인인증이 완료되었습니다.')
                    } else {
                        modalControler.ShowErrorToast('본인인증에 실패했습니다. 다시 시도해주세요.')
                    }
                }).catch(err => {
                    modalControler.ShowErrorToast('본인인증 요청 실패')
                });
            }

        }


    }

}
</script>

<style scoped lang="scss">
.profile {
    position: relative;
    overflow: hidden;
    width: 120px;
    border-radius: 60px;
    margin: 0 auto 24px;

    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &--img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        max-width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}
</style>
