<template>
  <section class="manual-section content-section bg-gray">
    <div class="content-inner">
      <header class="content-section__header">
        <h2 class="content-section__title">AI CONNECT 이용 매뉴얼</h2>
      </header>
      <section class="manual-banner swiper__banner">
        <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
          <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
            icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
        </button>
        <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
          <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
            icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
        </button>
        <div v-swiper:slideSwiper="swiperOption">
          <div class="swiper-wrapper " ref="swiperWrapper">
            <swiper-slide v-for="(manual, idx) in manualList" :key="'category-' + idx">
              <!-- <div class="manual-box" :class="getBoxColor(manual)"> -->
              <div class="manual-box">
                <div class="manual-box__header">
                  <h3 class="manual-box__title">{{ manual.categoryName }}</h3>
                </div>
                <div class="manual-box__body">
                  <ul class="manual-list__items">
                    <li v-for="(m, idx) in manual.manualList" :key="'manual-' + idx" class="manual-list__item"
                      @click.prevent.stop="toDetailPage(m.id)"><a href="">{{ m.title }} <span class="icon">
                          <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#fff"
                            icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                        </span></a></li>
                  </ul>
                  <div class="manual-box__icon">
                    <img v-if="manual.categoryName === '평가'" src="../../assets/images/icon-faq-01.png" alt="평가" />
                    <img v-else-if="manual.categoryName === '데이터'" src="../../assets/images/icon-faq-02.png" alt="데이터" />
                    <img v-else-if="manual.categoryName === '모델개발'" src="../../assets/images/icon-faq-03.png"
                      alt="모델개발" />
                    <img v-else-if="manual.categoryName === '결과제출'" src="../../assets/images/icon-faq-04.png"
                      alt="결과제출" />
                    <img v-else-if="manual.categoryName === 'GPU 서버 환경 설정'" src="../../assets/images/icon-faq-05.png"
                      alt="'GPU 서버 환경 설정" />
                    <img v-else-if="manual.categoryName === '대회참여'" src="../../assets/images/icon-faq-06.png"
                      alt="대회참여" />
                    <img v-else-if="manual.categoryName === '사이트 이용방법'" src="../../assets/images/icon-faq-07.png"
                      alt="사이트 이용방법" />

                    <!-- <img v-else-if="manual.categoryName === '시스템 오류 및 장애'" src="../../assets/images/icon-faq-08.svg"
                                    alt="" />
                                  <img v-else-if="manual.categoryName === '개선 의견 접수'" src="../../assets/images/icon-faq-09.svg"
                                    alt="" />
                                  <img v-else-if="manual.categoryName === '기타'" src="../../assets/images/icon-faq-10.svg" alt="" /> -->
                  </div>
                </div>
              </div>
            </swiper-slide>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>


<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import SupportApi from '@/lib/api/Support.js'

// import "swiper/swiper-bundle.css";
import 'swiper/css/swiper.css'

// // import { Swiper as SwiperClass, Pagination, Navigation, ScrollBar } from 'swiper/swiper.esm.js'
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// SwiperClass.use([Pagination, Navigation, ScrollBar])
// const { directive } = getAwesomeSwiper(SwiperClass)
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  name: 'Manual',
  components: {
    // SwiperSlide
  },
  directive: {
    // swiper: directive
  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = tokenStore()
    const store2 = stateStore()
    const swiperWrapper = ref(null)
    const state = reactive({
      swiperOption: {
        autoHeight: true,
        spaceBetween: 24,
        breakpoints: {
          1023: { slidesPerView: 3 },
          768: { slidesPerView: 2 },
          0: { slidesPerView: 1.2 },
        },
        pagination: {
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-btn__middle__right',
          prevEl: '.swiper-btn__middle__left',
        }
      },
      manualList: [],

    })

    onMounted(() => {
      getManualList()
    })

    const getManualList = () => {
      let params = {
        pageNo: 1,
        pageSize: 10
      }
      SupportApi.getManualList(utils.makeParams(params)).then(response => {
        if (response.data.code === 0) {
          state.manualList = getCategory(response.data.result)
        }
      })
    }

    const toDetailPage = (manualId) => {
      router.push({
        name: 'HelpDetail',
        params: {
          manualId: manualId,
        }
      })
    }

    const getCategory = (manualList) => {
      let tmpList = []
      let categoryList = []
      tmpList = manualList.filter((v, i, self) => i === self.findIndex((t) => (t.categoryName === v.categoryName)))
      tmpList.forEach(v => {
        categoryList.push({ categoryName: v.categoryName, categoryId: v.categoryId, manualList: [] })
      })
      for (var i = 0; i < manualList.length; i++) {
        for (var j = 0; j < categoryList.length; j++) {
          if (manualList[i].categoryName === categoryList[j].categoryName) {
            categoryList[j].manualList.push(manualList[i])
          }
        }
      }
      return categoryList
    }

    const getBoxColor = (manual) => {
      const categoryName = manual.categoryName
      // switch (categoryName) {
      //   case '사이트 이용방법': return 'manual-box--blue'
      //   case '대회 문의/일정': return 'manual-box--green'
      //   case '데이터': return 'manual-box--purple'
      //   case '모델개발': return 'manual-box--pink'
      //   case '결과제출': return 'manual-box--yellow'
      //   case '평가': return 'manual-box--blue'
      //   case 'GPU 서버 환경 설정': return 'manual-box--green'
      //   case '시스템 오류 및 장애': return 'manual-box--purple'
      //   case '개선 의견 접수': return 'manual-box--pink'
      //   case '기타': return 'manual-box--yellow'
      // }
    }

    return {
      utils,
      ...toRefs(state),
      swiperWrapper,
      toDetailPage,
      getBoxColor,
    }
  }

}
</script>
