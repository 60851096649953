import httpClient from './httpClient';

export default {
    // league List
    getAILeagueList() {
        return httpClient.get(`/league/leagues`)
    },
    // league detail
    getLeagueDetail(id) {
        return httpClient.get(`/league/leagues/${id}`)
    },
    // get league data
    getLeagueData(id) {
        return httpClient.get(`/league/leagues/${id}/data`)
    },
    // get league leaderboard
    getLeagueLeaderboard(id) {
        return httpClient.get(`/league/leagues/${id}/leaderboard`)
    },
    getLeagueSubmitList(id) {
        return httpClient.get(`/league/leagues/${id}/submitLogs`);
    },    
    // do league agree
    agreeLeagueRule(id) {
        return httpClient.put(`/league/leagues/${id}/agree-rule-user`)
    },
    // increase leaguue view count
    increaseLeagueViewcount(id) {
        return httpClient.put(`/league/leagues/${id}/view`)
    },

    /**
     *  League Submit apis
     * 
     */
    multipartSubmitCreate(id, param) {
        return httpClient.post(`/league/leagues/${id}/submit/create`, param)
    },
    multipartSubmitPart(id, param) {
        return httpClient.post(`/league/leagues/${id}/submit/part`, param )
    },
    multipartSubmitComplete(id, param) {
        return httpClient.post(`/league/leagues/${id}/submit/complete`, param)
    },
    updateSubmitDesc(submitId, param) {
        return httpClient.put(`/league/leagues/submit/${submitId}/description`, param, { headers: {'Content-Type': 'multipart/form-data'} })
    },

    /**
     * DCT Demo API
     */

    submitDemoForm(param) {
        return httpClient.post(`/support/demo/submit`, param)
    }

}