import utils from "@/helper/utils";
import router from '@/plugins/router';
import modalControler from '@/helper/modalControler';


/*
    [ NOTE ]
    - case 12002 는 과제참여 호출 단에서 처리

*/

const definedErrors = (errorResponse) => {
    let errorCode = errorResponse.data.error_code ? errorResponse.data.error_code : null
    let requestURL = errorResponse.request.responseURL ? utils.getRequestAPI(errorResponse.request.responseURL) : null
    let msg
    switch (errorCode) {
        // case 2002:
        //     msg = "로그인이 필요합니다."
        //     if (router.currentRoute.name !== 'SignIn')
        //         router.push({ path: '/login' })

        //     break

        // 로그인 후 화면에서 7일간 활동이 없을 경우 로그인 토큰 만료 되었을 때
        case 2003:
            // msg = `토큰이 만료되었습니다. 다시 로그인해주세요.`
            // modalControler.ShowModalDialog('', msg, '', '확인', (val) => {
            //     // localStorage.setItem("reSignIn", true)
            //     // router.signin()
            //     if (val) {
            //     } else {
            //     }
            // })
            router.signout()
            break

        case 2101:
            // ‘비밀번호 초기화 링크 요청’ 버튼 클릭 시, 가입된 이메일이 아닐 때 
            if (requestURL === '/auth/requestResetPassword') {
                msg = "사용자를 찾을 수 없습니다. 메일 주소를 확인해 주세요."
                modalControler.ShowErrorToast(msg)
            }
            // 가입하지 않은 이메일로 로그인
            else {
                msg = "가입되지 않은 이메일입니다. 회원가입 하시겠습니까?"
                modalControler.ShowModalDialog('', msg, '취소', '확인', (val) => {
                    if (val) {
                        router.push({ name: 'SignupRoot' })
                    }
                })
            }
            break

        case 2102:
            // ‘비밀번호 초기화 링크 요청’ 버튼 클릭 시, 가입 후 이메일 인증을 아직 하지 않은 계정일 때
            if (requestURL === '/auth/requestResetPassword') {
                msg = "활성화되지 않은 계정입니다. 이메일 내의 링크를 클릭하여 인증해주시기 바랍니다."
                modalControler.ShowErrorToast(msg)
            }
            // 인증되지 않은 이메일로 로그인
            else {
                msg = `활성화되지 않은 계정입니다. 이메일 내의 링크를 클릭하여 인증해주시기 바랍니다.`
                modalControler.ShowErrorToast(msg)
            }
            break

        // 60일 이상 미사용 계정으로 로그인
        case 2103:
            msg = `장기간 미접속하여 계정이 비활성화 되었습니다. 이메일을 다시 인증해주시기 바랍니다.`
            modalControler.ShowModalDialog('', msg, '', '확인', (val) => {
                if (val) {
                    // do nothing
                }
            })
            break

        case 2104:
            // ‘비밀번호 초기화 링크 요청’ 버튼 클릭 시, SNS 를 통한 가입 계정일 때
            if (requestURL === '/auth/requestResetPassword') {
                msg = `'네이버 또는 구글 계정으로 가입한 계정입니다. SNS 로그인 버튼을 통해 로그인해주시기 바랍니다.`
                modalControler.ShowErrorToast(msg)
            }
            // SNS 을 통한가입으로 비밀번호 미등록 상태에서 이메일/비밀번호로 로그인
            else {
                msg = `네이버 (또는 구글) 계정으로 회원가입하여 비밀번호가 등록되지 않았습니다. `
                modalControler.ShowModalDialog('', msg, '', '확인', (val) => {
                    if (val) {
                        // do nothing
                    }
                })
            }
            break

        case 2105:
            // ‘비밀번호 초기화 링크 요청’ 버튼 클릭 시, SSO 계정 - 플랫폼을 통한 가입 계정이 아닐 때
            if (requestURL === '/auth/requestResetPassword') {
                msg = `비밀번호를 변경할 수 없습니다. 가입한 사이트에서 비밀번호를 변경해주세요.`
                modalControler.ShowErrorToast(msg)
            }
            else {
                // SSO 계정으로 플랫폼에서 직접 로그인
                msg = `로그인 할 수 없습니다. 관리자에게 문의해주세요.`
                modalControler.ShowErrorToast(msg)
            }
            break

        // 잘못된 비밀번호 입력 후 로그인
        case 2106:
            msg = `이메일 또는 비밀번호를 확인해주세요.`
            modalControler.ShowErrorToast(msg)
            break

        // 이메일에서 “비밀번호 초기화” 버튼 클릭시, 만료된 재설정 링크일 경우(이미 초기화 버튼을 클릭하였을 경우)
        case 2107:
            msg = `유효하지 않은 링크입니다.`
            modalControler.ShowErrorToast(msg)
            break

        // 대회참여 - 참여기간이 아닐 경우
        case 10001:
            msg = `참여 기간이 아닙니다.`
            modalControler.ShowErrorToast(msg)
            break

        // 대회참여 - 본인인증을 하지 않았을 경우
        case 10002:
            msg = `본인인증이 완료되지 않았습니다.`
            modalControler.ShowErrorToast(msg)
            break

        // 대회참여 - 이미 참여중인 대회일 경우
        case 11001:
            msg = `이미 참여중인 대회입니다.`
            modalControler.ShowErrorToast(msg)
            break

        // 과제 참여 버튼: 팀 승인 대기 상태
        case 11002:
            msg = `팀이 승인된 이후에 과제에 참여할 수 있습니다. <br/>관리자에게 문의해주세요!`
            modalControler.ShowModalDialog('', msg, '', '확인', (val) => { })
            break

        case 12002:
            modalControler.ShowErrorToast(errorResponse.data.error_detail)
            break

        // 결과제출 > 최대 제출 개수 초과
        case 12101:
            msg = `제출파일 개수를 다시 확인해주세요.`
            modalControler.ShowErrorToast(msg)
            break

        // 결과제출 > 일 단위 제출횟수 제한 초과 시
        case 12102:
            modalControler.ShowErrorToast(errorResponse.data.error_detail)
            break

        // 결과제출 > 제출 시간 초과, 제출 횟수 초과
        case 12103:
            modalControler.ShowErrorToast(errorResponse.data.error_detail)
            break

        // 결과제출 > File chunk size 쪼갤 때 오류
        case 51002:
            msg = `업로드 도중 에러가 발생했습니다. 파일 크기를 확인해주세요.`
            modalControler.ShowModalDialog('업로드 실패', msg, '', '확인', (val) => { })


        // General Error ...
        // case 7500:
        //     modalControler.ShowErrorToast(errorResponse.data.error_detail)
        // NOTE: case 12002 는 과제참여 호출 단에서 처리
    }
}


export default definedErrors
