<template>
    <v-app>
        <div class="help-wrap contents-wrap">
            <section class="help-section">
                <header class="page-header--line__section">
                    <span class="page-header--line__sub-title">1:1 문의 내역</span>
                    <h2 class="page-header--line__title">{{ detail.title }}</h2>
                </header>
                <div class="board">
                    <div class="medium-content-inner">
                        <div class="board__detail">
                            <ul class="board__detail--list">
                                <li><span class="content-title">카테고리</span>
                                    <span class="content">{{ detail.categoryParentName }}
                                        <template v-if="detail.categoryName"> / {{ detail.categoryName }}</template>
                                    </span>
                                </li>

                                <li>
                                    <span class="content-title">답변여부</span>
                                    <span class="content">
                                        <span class="board-status">
                                            <span v-if="detail.solved">
                                                <span class="status-sub-badge status-sub-badge--red">답변완료</span>
                                                <span>{{ detail.replyCreateDtm }}</span>
                                            </span>
                                            <span v-else class="status-sub-badge status-sub-badge--default">미답변</span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span class="content-title">대회명/과제명</span>
                                    <span class="content">{{ detail.competitionName }}
                                        <template v-if="detail.assignmentName"> / {{
                                            detail.assignmentName
                                        }}</template>
                                    </span>
                                </li>
                                <li>
                                    <span class="content-title">작성일시</span>
                                    <span class="content">{{ detail.createDtm }}</span>
                                </li>

                            </ul>
                        </div>
                        <div class="board-text">
                            <div class="ProseMirror" v-html="detail.content"></div>
                        </div>

                        <!-- 첨부파일 란 -->
                        <template v-if="detail.fileUrl">
                            <div class="btn-filedown-area">
                                <a class="btn-filedown" :href="detail.fileUrl">
                                    <font-awesome-icon :icon="['fas', 'paperclip']" /><strong
                                        class="file-name">첨부파일</strong>
                                </a>
                            </div>
                        </template>

                        <div class="reply">
                            <!-- 댓글 -->
                            <template v-if="detail.solved">
                                <div class="reply__header">
                                    <h4 class="reply__title">답변 내용</h4>
                                </div>
                                <div class="reply__body">
                                    <div class="reply__foot">
                                        <div class="reply__comment">
                                            <div v-html="detail.replyContent"></div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="btn-inline btn-inline--section btn-inline--center">
                            <v-btn class="btn btn-secondary btn-large" @click="toFAQList"><strong>목록</strong></v-btn>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </v-app>
</template>
<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import SupportApi from '@/lib/api/Support.js'

import 'highlight.js/styles/monokai-sublime.css'


export default {
    name: 'InquiryDetail',
    // components: {
    //     FileInput,
    // },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            detail: {},
        })

        onMounted(() => {
            getInquiryDetail(route.params.inquiryId)
        })

        const getInquiryDetail = (id) => {
            SupportApi.getInquiryDetail(id).then(response => {
                if (response.data.code === 0) {
                    state.detail = response.data.result
                    state.detail.replyContent.replace('\n', '<br/>')
                }
            })
        }

        const toFAQList = () => {
            router.push({
                name: 'faq'
            })
        }

        return {
            utils,
            ...toRefs(state),
            toFAQList,
        }
    }

}
</script>

