<template>
  <div class="competition-wrap contents-wrap">
    <div class="content-inner">
      <header class="content-header">
        <div class="content-header--both">
          <div class="content-header__thumb">
            <div class="thumb-square thumb-square--m">
              <img :src="(c_imgurl) ? c_imgurl : require('@/assets/images/img-main-dummy.png')"/>
            </div>
          </div>
          <div class="">
            <div class="content-header__status">
              <span v-if="c_status === 2" class="status-badge status-badge--white">
                진행예정
              </span>
              <span v-else-if="c_status === 3" class="status-badge status-badge--black">
                접수중 {{ c_ddate }}
              </span>
              <span v-else-if="c_status === 4" class="status-badge status-badge--primary">
                진행중 {{ c_ddate }}
              </span>
              <span v-else-if="c_status === 5" class="status-badge status-badge--white">
                점검중 {{ c_ddate }}
              </span>
              <span v-else-if="c_status === 6" class="status-badge status-badge--gray">
                종료
              </span>
            </div>
            <h2 class="content-header__title">{{ c_title }}</h2>
            <div class="content-info">
              <div class="content-info__items">
                <div class="content-info__item" v-if="c_prizemoney !== '0만원'">
                  <span class="item__icon">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-award"><icon-award /></icon-base>
                  </span>
                  <span class="item__text">
                    {{ c_prizemoney }}
                  </span>
                </div>
                <div class="content-info__item">
                  <span class="item__icon">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-calendar"><icon-calendar /></icon-base>
                  </span>
                  <span class="item__text">
                    {{ c_fromdt }} ~ {{ c_todt }}
                  </span>
                </div>
                <div class="content-info__item">
                  <span class="item__icon">
                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#101216"
                      icon-name="icon-user"><icon-user /></icon-base>
                  </span>
                  <span class="item__text">
                    {{ c_participants }}명
                  </span>
                </div>
              </div>
              <div class="content-info__btn">
                <div class="tooltip__group" ref="tipBox" v-if="c_isjoin === 1 || c_isjoin === 2">
                  <div class="tooltip__box tooltip__box--right">
                    <div class="tooltip__inner  tooltip__inner--primary">팀구성 후 과제에 참여해주세요.</div>
                    <button type="button" class="btn-closed" @click="closeTipbox">
                      <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                        icon-name="icon-closed"><icon-closed /></icon-base>
                    </button>
                  </div>
                </div>
                <v-btn v-if="c_status === 3 && c_isjoin === 0" class="btn btn-primary btn-large"
                  @click="competitionApply"><strong>대회참여</strong></v-btn>
                <v-btn v-if="c_isjoin === 1 || c_isjoin === 2 || c_isjoin === 3" class="btn btn-primary btn-large"
                  disabled><strong>대회참여중</strong></v-btn>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div>
      <div class="sticky-menu tab__menu">
        <div class="tab__menu__inner content-inner">
          <div class="tab__menu__btns">
            <a class="tab__menu__btn" :class="{ 'tab__menu__btn--on': activeTab === 'competitionDetail' }"
              @click.prevent="clickTab('competitionDetail')" href="">상세 내용 </a>
            <a class="tab__menu__btn" :class="{ 'tab__menu__btn--on': activeTab === 'competitionTask' }"
              @click.prevent="clickTab('competitionTask')" href="">과제 목록</a>
            <a class="tab__menu__btn" :class="{ 'tab__menu__btn--on': activeTab === 'competitionTeam' }"
              @click.prevent="clickTab('competitionTeam')" href="">팀 구성</a>
          </div>
        </div>
      </div>
      <div class="tab__pannel" :class="{ 'tab__pannel--on': activeTab === 'competitionDetail' }">
        <section class="content-group content-detail-section" id="section-1">
          <div class="medium-content-inner">
            <div ref="preview">
              <div class="ProseMirror" v-html="c_content"></div>
            </div>
            <div class="host" id="footer_host" v-if="c_infos.length">
              <dl class="host__list" v-for="(info, idx) in c_infos" :key="idx">
                <dt>
                  {{ info.title }}
                </dt>
                <dd>
                  {{ info.content }}
                </dd>
              </dl>
            </div>
            <div class="content-group__btn-area">
              <div class="btn-inline btn-inline--right">
                <v-btn class="btn btn-secondary btn-small" @click="toMenualFAQ">
                  FAQ 및 매뉴얼
                </v-btn>
                <v-btn class="btn btn-secondary btn-small" @click="toInquiryForm">
                  문의하기
                </v-btn>
              </div>
            </div>

          </div>
        </section>

        <!--일정-->
        <Schedule v-if="!isLoading" :scheduleList="c_schedule" :scheduleDesc="c_scheduleDesc" :status="c_status" />

        <!--대회 규정-->
        <Rule v-if="!isLoading" :competitionId="Number($route.params.competitionId)" :ruleDesc="c_ruleDesc" />

        <!--상금 및 시상-->
        <Prize v-if="!isLoading && c_prizeDesc" :prizemoneyDesc="c_prizeDesc" />

        <!--공지사항-->
        <Notice :competitionId="Number($route.params.competitionId)" />

      </div>
      <div class="tab__pannel" :class="{ 'tab__pannel--on': activeTab === 'competitionTask' }">
        <!-- 과제 목록 -->
        <TaskList v-if="!isLoading" :competitionId="Number($route.params.competitionId)" :maxAssignment="c_maxassignment"
          :joinedTask="joinedTask" :isJoin="c_isjoin" :competitionStatus="c_status" />
      </div>
      <div class="tab__pannel" :class="{ 'tab__pannel--on': activeTab === 'competitionTeam' }">
        <template>
          <Team :status="c_status" :memberLimit="c_memberlimit" :isJoin="c_isjoin" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  nextTick,
  watch,
  ref,
} from "@vue/composition-api";
import utils from "@/helper/utils";
import { stateStore } from "@/stores/state";
import CompetitionApi from "@/lib/api/Competition";
import modalControler from "@/helper/modalControler";
import TaskList from "@/components/competition/detail/TaskList";
import Schedule from "@/components/competition/detail/Schedule";
import Rule from "@/components/competition/detail/Rule";
import Prize from "@/components/competition/detail/Prize";
import Notice from "@/components/competition/detail/Notice";
import Team from "@/components/competition/Team"
// import ApplyHelper from "@/components/competition/detail/competitionHelp";


export default {
  name: "CompetitionDetail",
  components: {
    TaskList,
    Schedule,
    Rule,
    Prize,
    Notice,
    Team
    // ApplyHelper,
  },
  props: {},
  setup(props, context) {
    const router = context.root.$router;
    const route = context.root.$route;
    const stateStorage = stateStore();
    const tipBox = ref(null)
    const preview = ref(null)
    const state = reactive({
      competitionId: Number(route.params.competitionId),
      c_id: null,
      c_title: "",
      c_prizemoney: 0,
      c_participants: 0,
      c_abstract: "",
      c_schedule: [],
      c_status: 0,
      c_rule: "",
      c_bgimgURL: "",
      c_imgurl: "",
      c_host: "",
      c_hostcompany: "",
      c_content: "",
      c_ddate: "",
      c_ddateText: "",
      c_infos: [],
      c_isjoin: 0,
      isLoading: false,
      c_fromdt: "",
      c_todt: "",
      c_prizeDesc: "",
      c_scheduleDesc: "",
      c_ruleDesc: "",
      c_maxassignment: 0,
      c_memberlimit: 0,
      joinedTask: [],
      // Count Down
      targetDate: new Date("2022/06/03 15:00:00"),
      t_day: 0,
      t_hours: 0,
      t_minutes: 0,
      t_seconds: 0,
      interval: null,
      activeTab: 'competitionDetail',
    });

    onMounted(async () => {
      // detail page 동작에 필요
      // header();
      // try{
      //   // process();
      // } catch {}
      // base();

      // 대회 상세정보 불러오기
      state.isLoading = true;
      await getCompetitionDetailInfo(state.competitionId);

      stateStorage.setGnbItems(); // gnb list 생성

      // stateStorage.setCompetitionDetailTab(state.activeTab)
      state.activeTab = stateStorage.competitionActiveTab

      // count down
      // state.interval = setInterval(launchTimeClock, 1000)
    });

    watch(() => state.activeTab, (nv, ov) => {
      state.activeTab = stateStorage.competitionActiveTab
    })

    watch(() => stateStorage.competitionActiveTab, (nv, ov) => {
      state.activeTab = stateStorage.competitionActiveTab
    })

    onUnmounted(() => {
      // clearInterval(state.interval)
    });

    const katexRender = () => {
      const previewDiv = preview.value
      utils.katexRender(previewDiv)
    }

    const clickTab = (tab) => {
      state.activeTab = tab
      stateStorage.setCompetitionDetailTab(tab)
    }

    const competitionApply = () => {
      router.goCompetitionPages("CompetitionApply", state.c_id);
    };

    const getCompetitionDetailInfo = (competitionId) => {
      let params = {};
      modalControler.ShowLoading();
      CompetitionApi.getCompetitionDetailInfo(competitionId)
        .then((response) => {
          if (response.data.http_status_code === 200) {
            const data = response.data.result;
            state.c_id = data.id;
            state.c_title = data.name;
            state.c_prizemoney = utils.numberToKorean(data.prizemoney) + "만원";
            state.c_participants = data.joinusercnt;
            state.c_abstract = data.content;
            state.c_schedule = data.scheduleList;
            state.c_status = data.status;
            state.c_bgimgURL = data.bgimgurl;
            state.c_imgurl = data.imgurl;
            state.c_prizeDesc = data.prizemoneydesc;
            state.c_ruleDesc = data.ruledesc;
            state.c_scheduleDesc = data.scheduledesc;
            // state.c_host = data.host
            // state.c_hostcompany = data.hostcompany
            state.c_content = data.content;
            state.c_infos = data.infos;
            state.c_isjoin = data.isjoin;
            state.c_fromdt = data.fromdt.replace(/(\d*:*:)\w+/g, "");
            state.c_todt = data.todt.replace(/(\d*:*:)\w+/g, "");
            state.c_maxassignment = data.maxassignment;
            state.c_memberlimit = data.maxteamperson;
            state.joinedTask = data.joinedAssignmentIds;
            scheduleState();
            state.isLoading = false;
            katexRender()
          } else {
            modalControler.ShowErrorToast("대회정보를 불러오지 못했습니다.");
          }
          modalControler.HideLoading();
        })
        .catch((err) => {
          console.log(err);
          modalControler.HideLoading();
        });
    };

    const scheduleState = () => {
      if (state.c_id) {
        const { noDDay, DDay, DDayText } = utils.statusToScheduleTitle(
          state.c_status,
          state.c_schedule
        );
        if (!noDDay) {
          state.c_ddate = DDay;
          state.c_ddateText = DDayText;
        } else {
          // do nothing
        }
      }
    };

    const toInquiryForm = () => {
      // router.goTaskPages('CompetitionNotice', state.joinedTask[0], state.competitionId)
      router.push({ name: "InquiryForm" });
    };

    const toMenualFAQ = () => {
      // window.open(
      //   "https://mindsncom.notion.site/AI-CONNECT-1d619e6803a64972ab04505882ae8efb",
      //   "_blank"
      // );
      router.push({ name: 'faq' })
    };

    const showNIPA = () => {
      window.open("https://www.nipa.kr/", "_blank");
    };

    const showServicePolicy = () => {
      let msg = `
                    <iframe height="100%" width="100%"
                    src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/terms/rule.html"></iframe>
            `;
      modalControler.ShowModalDialog(
        "이용약관",
        msg,
        "",
        "확인",
        "",
        "1000px",
        "600px"
      );
    };

    const showPrivacyPolicy = () => {
      let msg = `
                    <iframe height="100%" width="100%"
                    src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/terms/personal.html"></iframe>
                    `;
      modalControler.ShowModalDialog(
        "개인정보처리방침 동의",
        msg,
        "",
        "확인",
        "",
        "1000px",
        "600px"
      );
    };

    const launchTimeClock = () => {
      // Get the current date
      const currentDate = new Date();

      // Find the difference between dates
      var diff = (currentDate - state.targetDate) / 1000;
      var diff = Math.abs(Math.floor(diff));

      // Check number of days until target
      state.t_day = Math.floor(diff / (24 * 60 * 60));
      state.t_seconds = diff - state.t_day * 24 * 60 * 60;

      // Check number of hours until target
      state.t_hours = Math.floor(state.t_seconds / (60 * 60));
      state.t_seconds = state.t_seconds - state.t_hours * 60 * 60;

      // Check number of minutes until target
      state.t_minutes = Math.floor(state.t_seconds / 60);
      state.t_seconds = state.t_seconds - state.t_minutes * 60;

      if (state.t_day < 10) state.t_day = "0" + state.t_day;
      if (state.t_hours < 10) state.t_hours = "0" + state.t_hours;
      if (state.t_minutes < 10) state.t_minutes = "0" + state.t_minutes;
      if (state.t_seconds < 10) state.t_seconds = "0" + state.t_seconds;
    };

    const closeTipbox = () => {
      tipBox.value.style.display = 'none'
    }

    return {
      utils,
      preview,
      ...toRefs(state),
      tipBox,
      clickTab,
      competitionApply,
      scheduleState,
      toMenualFAQ,
      showNIPA,
      showServicePolicy,
      showPrivacyPolicy,
      toInquiryForm,
      closeTipbox,
    };
  },
}
</script>
