<template>
  <section class="content-section search-result-section">
    <div class="content-inner">
      <header class="content-section__header content-section--both">
        <h2 class="content-section__title"><span class="color-primary">{{ searchText }}</span> 검색 결과 </h2>
        <span class="count-text">총 <strong>{{ totalResult }}</strong>건</span>
      </header>
    </div>
    <section class="content-group" v-if="manuals && manuals.length">
      <div class="content-inner">
        <header class="content-group__header">
          <h2 class="content-group__title">매뉴얼</h2>
        </header>
        <div class="box-list-items">
          <a @click.prevent.stop="toDetailPage(manual.id)" href="" class="box-list-item" v-for="(manual, idx) in manuals"
            :key="'manualSearch-' + idx">
            <h3 class="box-list-item__title">{{ manual.title }}</h3>
            <p class="box-list-item__text">{{ utils.sanitizeHtml(manual.content, sanitizeOptions) }}</p>
            <span class="icon">
              <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
            </span>
          </a>
        </div>
      </div>
    </section>

    <section class="content-group" v-if="faqs && faqs.length">
      <div class="content-inner">
        <header class="content-group__header">
          <h2 class="content-group__title">자주 묻는 질문</h2>
        </header>
        <div class="common-accordion accordion" id="accordionExample3">
          <template>
            <div class="common-accordion__item accordion-item" v-for="(faq, idx) in faqs" :key="'faq-' + idx">
              <header class="common-accordion__header accordion-header" :id="'faq-' + idx">
                <button class="common-accordion__button accordion-button  collapsed" type="button"
                  data-bs-toggle="collapse" :data-bs-target="'#collapse-' + idx" aria-expanded="true"
                  :aria-controls="'collapse-' + idx">
                  <span class="icon icon--q"><img src="../../assets/images/icon-q.svg" /></span>{{ faq.title }}
                </button>
              </header>
              <div class="accordion-collapse collapse" :id="'collapse-' + idx" :aria-labelledby="'heading-' + idx"
                data-bs-parent="#accordionExample3">
                <template>
                  <div class="common-accordion__body accordion-body">
                    <div class="common-accordion__content">
                      <div class="d-flex">
                        <span class="icon icon--a"><img src="../../assets/images/icon-a.svg" /></span><span>{{
                          faq.content
                        }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="content-section" v-if="!manuals.length && !faqs.length">
      <div class="content-inner">
        <div class="no-data">
          <p class="no-data__text">검색 결과가 없습니다.</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import SupportApi from '@/lib/api/Support.js'

export default {
  name: 'SearchResult',
  props: [
    'manuals',
    'faqs',
    'searchText',
  ],
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const state = reactive({
      sanitizeOptions: {      // 모든 tag 제거
        allowedTags: [],
        allowedAttributes: {}
      },

    })

    const totalResult = computed(() => {
      let total = 0
      total = total + props.manuals.length
      if (props.faqs) total = total + props.faqs.length
      return total
    })

    const toDetailPage = (manualId) => {
      router.push({
        name: 'HelpDetail',
        params: {
          manualId: manualId,
        },
      })
    }

    return {
      ...toRefs(state),
      totalResult,
      toDetailPage,
      utils,
    }
  }
}
</script>

