<template>
    <div class="content-header">
        <!-- <a @click.prevent.stop="$router.goLeaguePages('LeageDetailRoot', info.id)" class="content-header__link">{{
            info.name
        }}
            <span class="icon">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                    icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
            </span>
        </a> -->
        <h2 class="content-header__title">{{ info.name }}</h2>
        <p class="content-header__desc">
            {{ info.description }}
        </p>
        <div class="content-header__tags">
            <span class="tag" v-for="(k, ki) in info.keywords" :key="'keyword-' + ki">{{ k }}</span>
        </div>
        <div class="content-info content-info--both mb--0">
            <div class="content-info__items">
                <div class="content-info__item">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-eye"><icon-eye /></icon-base></span>
                    <span class="item__text">
                        {{ info.viewCount }}
                    </span>
                </div>
                <div class="content-info__item">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-user"><icon-user /></icon-base></span>
                    <span class="item__text">
                        {{ info.userCount }}명
                    </span>
                </div>
                <div class="content-info__item" v-if="info.submitLogs">
                    <span class="item__icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-upload"><icon-upload /></icon-base></span>
                    <span class="item__text">
                        {{ info.submitLogs.length }}
                    </span>
                </div>
            </div>
            <div class="content-info__btn" v-if="!agreedInfo.agreed">
                <template>
                    <v-btn @click="applyLeague" class="btn-primary btn-medium">참여하기</v-btn>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from "@/helper/modalControler";
import TaskApi from "@/lib/api/Task";
import { tokenStore } from '@/stores/auth';

export default {
    name: 'Summary',
    props: [
        'info',
        'competitionTitle',
        'taskTitle',
        'taskCategory',
        'container',
        'agreedInfo',
    ],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            c_status: 0,
            c_schedule: [],
            c_ddate: '',
            c_ddateText: '',
            leagueId: route.params.leagueId,
        });


        onMounted(() => {

        })


        const applyCompetition = () => {
            router.goCompetitionPages('CompetitionApply', route.params.competitionId)
        }


        const applyLeague = () => {
            router.push({
                name: 'LeagueAgreement',
                params: {
                    leagueId: state.leagueId
                }
            })
        }

        return {
            ...toRefs(state),
            router,
            props,
            utils,
            applyCompetition,
            applyLeague,
        }
    }

}
</script>
