<template>
    <v-app>
        <section class="password-section">
            <div class="medium-content-inner">
                <header class="page-header--line--left__section">
                    <h2 class="page-header--line__title">비밀번호 등록</h2>
                    <ul class="list list__box list__box--line">
                        <li class="list__item">8자~20자 영문, 숫자, 특수문자를 조합하여 주세요.</li>
                        <li class="list__item">동일한 문자 반복, 나란히 있는 문자열 등 추측하기 쉬운 비밀번호 사용은 피해주세요.</li>
                        <li class="list__item">타 사이트의 비밀번호와 같으면 도용되거나 유출될 위험이 있으니, 주기적으로 변경하여 사용해 주세요.</li>
                        <li class="list__item">비밀번호 변경 후 90일이 지나면 비밀번호를 변경해 주세요.</li>
                    </ul>
                </header>
                <div>
                    <form>
                        <div class="form-group">
                            <div class="form-group__title">비밀번호 이메일 주소</div>
                            <v-text-field class="common-form-control" outlined hide-details readonly
                                v-model="accountInfo.email">
                            </v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title ">비밀번호</div>
                            <v-text-field ref="passwordEl" :type="pwShow2 ? 'text' : 'password'"
                                :append-icon="pwShow2 ? 'mdi-eye-off' : 'mdi-eye'" @click:append="pwShow2 = !pwShow2"
                                class="common-form-control" outlined placeholder="비밀번호를 입력해주세요." v-model="newpassword"
                                @blur="checkPassword($event, newpassword)"
                                :error-messages="errorStatus.passwordErrorMessage"></v-text-field>
                        </div>
                        <div class="form-group form-group__detail">
                            <div class="form-group__title">비밀번호 재입력</div>
                            <v-text-field ref="passwordEl" :type="pwShow3 ? 'text' : 'password'"
                                :append-icon="pwShow3 ? 'mdi-eye-off' : 'mdi-eye'" @click:append="pwShow3 = !pwShow3"
                                placeholder="새 비밀번호를 다시 입력해주세요." class="common-form-control" outlined
                                v-model="newrepassword" @blur="checkRepassword($event, newpassword, newrepassword)"
                                :error-messages="errorStatus.repasswordErrorMessage"></v-text-field>
                        </div>

                    </form>
                    <div class="content-group__btn-area">
                        <div class="btn-inline btn-inline--center">
                            <v-btn class="btn btn-large btn-tertiary"
                                @click="$router.push({ name: 'CompetitionListProfile' })"><strong>취소</strong></v-btn>
                            <v-btn class="btn btn-large btn-primary" @click="changePassword"><strong>확인</strong></v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import { JSEncrypt } from "jsencrypt";
import { cloneDeep } from 'lodash'
import validator from "@/config/formValidator"
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'PasswordRegister',
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.accountInfo
        const state = reactive({
            pwShow1: false,
            pwShow2: false,
            pwShow3: false,
            newpassword: '',
            newrepassword: '',
            errorStatus: {
                passwordErrorMessage: '',
                repasswordErrorMessage: '',
            },
            data: {
                email: '',
                newPassword: '',
            }
        });

        onMounted(() => {

        });

        const checkPassword = (e, password) => {
            if (e.keyCode === 13 || e) {
                if (utils.checkPasswordFormat(password) === false) {
                    return state.errorStatus.passwordErrorMessage = '영문, 숫자, 특수문자 포함 / 최소 8자 최대 20자'
                } else {
                    state.errorStatus.passwordErrorMessage = ''
                }
                if (password.length > 20) {
                    return (state.newpassword = state.newpassword.substr(0, 20),
                        state.errorStatus.passwordErrorMessage = '최소 8자 최대 20자');
                }
                if (state.currentpassword === password) {
                    return state.errorStatus.passwordErrorMessage = '현재 비밀번호와 같습니다'
                }
            }
        }

        const checkRepassword = (e, password, repassword) => {
            if (e.keyCode === 13 || e) {
                if (password !== repassword) {
                    state.errorStatus.repasswordErrorMessage = '비밀번호가 일치하지 않습니다.'
                } else {
                    state.errorStatus.repasswordErrorMessage = ''
                }
            }
        }

        const changePassword = () => {
            // if (state.currentpassword === state.newpassword) {
            //   return state.errorStatus.passwordErrorMessage = '현재 비밀번호와 같습니다'
            // }
            if (state.newpassword !== state.newrepassword) {
                return state.errorStatus.repasswordErrorMessage = '비밀번호가 일치하지 않습니다'
            }
            if (!state.newpassword && !state.newrepassword) {
                return state.errorStatus.repasswordErrorMessage = '비밀번호를 입력해 주세요'
            }
            Authenticate.rsaKey().then(({ data } = response) => {
                const rsa = new JSEncrypt({ 'default_key_size': 2048 });
                rsa.setPublicKey(utils.createPublicKey(data.result.publicKey));
                state.data.email = accountInfo.email;
                let profile = cloneDeep(state.data)
                // profile.password = state.currentpassword;
                profile.newPassword = state.newpassword;
                // profile = rsa.encrypt(JSON.stringify(profile));
                Authenticate.changePassword(profile).then(({ data } = response) => {
                    if (!data.error) {
                        modalControler.ShowInfoToast('비밀번호가 변경되었습니다.')
                        router.push({ name: 'CompetitionListProfile' });
                        // Authenticate.signOut();
                    } else {
                        modalControler.ShowErrorToast(data.message);
                    }
                }).catch(err => {
                    modalControler.ShowErrorToast(err.error_message);
                })
            })
        }

        return {
            ...toRefs(state),
            props,
            store,
            accountInfo,
            checkPassword,
            checkRepassword,
            changePassword,
        }
    }
}

</script>