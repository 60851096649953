<template>
    <section class="cert_wrapper">
        <article class="cert_contents">
            <div class="cert_bgimg">
                <img src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/certification/certificate.png" class="cert_bgimg_element">
                <img src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/certification/logo_watermark.png" class="cert_bgimg_watermark">
            </div>
            
                <div class="private-numb">
                    <p>제{{ moment().format('YYYY') }}-{{ data.competitionId }}-{{ data.userId }}</p>
                    <img src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/certification/img-logo.svg" class="cert_connect_logo">
                </div>
            <div class="cert_txt">
                <h1>대회 참가 인증서</h1>
                <div class="cert_txt_header">
                    <ul>
                        <li>과제명: {{ data.assignmentName }}</li>
                        <li>등수/백분율: {{ data.teamRank }}/{{ data.teamPercent }}%</li>
                    </ul>
                    <ul>
                        <li>팀명: {{ data.teamName }}</li>
                        <li>이름: {{ data.userName }} </li>
                    </ul>
                </div>
                <div class="cert_txt_article">
                    귀하는 '{{ subject }}'에서 주관하고 '{{ host }}'이(가) 주최한 '{{ data.competitionName }}' 대회에 참가하여<br>위와 같은 성적을 거두었기에 본 인증서를 수여합니다. 
                </div>
                <div>인증서 발급 일자: {{ moment().format('YYYY') }}년 {{ moment().format('MM') }}월 {{ moment().format('DD') }}일</div>
                <div class="cert_txt_sign">
                    (주) 마인즈앤컴퍼니 
                    <img src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/certification/squre.jpg" class="cert_txt_sign_img">
                </div>
            </div>
        </article>
    </section>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, onUpdated,
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';

export default {
    name: 'Certification',
    props: ['data', 'host', 'subject'],
    setup(props, context) {

        onMounted(() => {
            context.emit('printCertification')
        })

        return {
            moment,
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/print/certification'
</style>
