<template>
    <article class="section-2__content">
        <BoardListView 
            boardType='TeamBuild'
            @onlyMyQnA="getTeamBuildList"
            @doSearch="getTeamBuildList"
            :qnaTotalCount="qnaTotalCount"
            :qnaList="qnaList"
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import _ from "lodash";
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import BoardListView from '@/components/BoardListView'

export default {
    name: 'TeamBuild',
    components: {
        BoardListView,
    },
    setup(props, context) {
        const route = context.root.$route
        const state = reactive({
            qnaList: [],
            qnaTotalCount: 0,
        });

        const getTeamBuildList = (options, searchKeyword, showNotice) => {
            let keyword = searchKeyword ? utils.getPage(Object.assign(options, { searchText: searchKeyword, showNotice: showNotice })) :
                                            utils.getPage(Object.assign(options,{ showNotice: showNotice }))
            modalControler.ShowLoading()
            CompetitionApi.getTeamBuildList(route.params.competitionId, keyword).then(response => {
                if (!response.data.error) {
                    state.qnaList = response.data.result.data
                    state.qnaTotalCount = response.data.result.totalcount
                }
            }).catch(err => {
                modalControler.ShowErrorToast(err.response.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }


        return {
            ...toRefs(state),
            getTeamBuildList,
        }
    }
}



</script>