<template>
    <div class="league-main-wrap bg-contents-wrap">
        <header class="page-header__section pattern-section">
            <div class="page-header__inner">
                <div class="page-header__title-box">
                    <h2 class="page-header__title">AI League
                        <!-- <span
                            class="new-text new-text--medium new-text--dark  ml--4">Event</span> -->
                        </h2>
                    <p class="page-header__sub-text">
                        AI League 란 언제든지 참여하여 실력을 확인하고 능력을 키울 수 있는 역량 강화 모의 경진대회입니다.<br />
                        실제 비즈니스 분야 태스크를 베이스라인 가이드를 바탕으로 데이터 로드부터 평가까지 전 과정을 경험해보세요!

                    </p>
                </div>
                <div class="code-editor__group">
                    <lottie :options="defaultOptions" :height="'100%'
                        " :width="'100%'" v-on:animCreated="handleAnimation" />
                </div>
            </div>
        </header>
        <section class="content-section">
            <div class="content-inner">
                <header class="content-section__header content-section--center ">
                    <h2 class="content-section__title">딥러닝을 경험하고
                        실력을 증명할 수 있는 AI League
                    </h2>
                </header>
                <div class="box__items">
                    <div class="box__item">
                        <div class="box__img-box">
                            <span class="icon icon__size--400 icon--compete"><span class="blind">컴퓨터</span></span>
                        </div>
                        <div class="box__text-box">
                            <strong class="box__title">
                                연습은 실전처럼!
                            </strong>
                            <p class="box__text">
                                기초적인 내용 점검을 위한 단순 문제 풀이가 아닌, 실전 경진대회와 유사한 수준의 문제에 도전하며 자신의 순위를 확인할 수 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="box__item">
                        <div class="box__img-box">
                            <span class="icon icon__size--400 icon--intelligent"><span class="blind">모델</span></span>
                        </div>
                        <div class="box__text-box">
                            <strong class="box__title">AI for Real World
                            </strong>
                            <p class="box__text">
                                실제 비즈니스 세계에서 활용되는 중요도 높은 태스크로 구성되어 있습니다.
                            </p>
                        </div>
                    </div>
                    <div class="box__item">
                        <div class="box__img-box">
                            <span class="icon icon__size--400 icon--ai-dev"><span class="blind">ai</span></span>
                        </div>
                        <div class="box__text-box">
                            <strong class="box__title">열려있는 경쟁의 장
                            </strong>
                            <p class="box__text">
                                누구나, 언제든지 참여할 수 있도록 상시적으로 열려있으며,
                                상세하고 친절한 베이스라인 코드를 제공합니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section--padding bg-gray">
            <div class="content-inner">
                <header class="content-section__header content-section--center">
                    <h2 class="content-section__title">지금 바로 도전해보세요!</h2>
                    <p class="content-section__text">
                        LLM (Large Language Model) 의 전성시대에 더욱 더 중요성이 커지고 있는 NLP 모델 개발에 관심이 있다면?<br />
                        어디에서도 찾기 힘든 NLP 전문가가 되는 가장 빠른 길, AI League 에서 실력을 키워보세요.
                    </p>
                </header>
                <div class="card-list--section">
                    <!-- <a class="league-banner" href="/league/1/info">
                        <img src="../../assets/images/banner-summer-mo.png" class="league-banner--mo" />
                        <img src="../../assets/images/banner-summer-pc.png" class="league-banner--pc" />
                    </a> -->
                    <div v-for="(l, i) in leagueList" :key="'league-' + i" class="card-box">
                        <div class="card-box__content">
                            <a @click="$router.goLeaguePages('LeagueDetailRoot', l.id)" class="card-box__link">
                                <div class="card-box--both">
                                    <div class="card-box__thumb thumb-square thumb-square--m">
                                        <img :src="(l.thumbnailUrl) ?
                                            l.thumbnailUrl : '../../assets/images/img-league-02.png'" alt="특허" />
                                    </div>
                                    <div class="card-box__text-group">
                                        <h3 class="card-box__title-large ellipsis-1">{{ l.name }}</h3>
                                        <p class="card-box__text min-height--auto">{{ l.description }}</p>
                                        <div class="card-box__tags" v-if="l.keywords.length && l.keywords[0] !== ''">
                                            <span class="tag" v-for="(k, ki) in l.keywords" :key="'keyword-' + ki">{{ k
                                            }}</span>
                                        </div>
                                        <div class="mt--12">
                                            <ul class="inline-list">
                                                <li class="inline-list__item">
                                                    <span class="inline-list__title">Level</span>
                                                    <span class="inline-list__content">
                                                        <span class="status-sub-badge status-sub-badge--yellow"
                                                            v-if="l.level == '입문'">입문</span>
                                                        <span class="status-sub-badge status-sub-badge--orange"
                                                            v-if="l.level == '중급'">중급</span>
                                                        <span class="status-sub-badge status-sub-badge--red"
                                                            v-if="l.level == '실전'">실전</span>
                                                    </span>
                                                </li>
                                                <li class="inline-list__item">
                                                    <span class="inline-list__title">Task</span>
                                                    <span class="inline-list__content">{{ l.task }}</span>
                                                </li>
                                                <li class="inline-list__item">
                                                    <span class="inline-list__title">Metric</span>
                                                    <span class="inline-list__content">{{ l.metric }}</span>
                                                </li>
                                                <li class="inline-list__item">
                                                    <span class="inline-list__title">Data Type</span>
                                                    <span class="inline-list__content">{{ l.dataType }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="card-box__option card-box__option--both card-box__option--between">
                                            <div class="card-box__option--both">
                                                <span class="icon-info">
                                                    <span class="icon">
                                                        <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                            iconColor="#101216"
                                                            icon-name="icon-eye"><icon-eye /></icon-base>
                                                    </span>
                                                    <strong class="text">{{ l.viewCount }}</strong>
                                                </span>
                                                <span class="icon-info">
                                                    <span class="icon">
                                                        <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                            iconColor="#101216"
                                                            icon-name="icon-user"><icon-user /></icon-base>
                                                    </span>
                                                    <strong class="text">{{ l.userCount }}</strong>
                                                </span>
                                                <span class="icon-info">
                                                    <span class="icon">
                                                        <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                            iconColor="#101216"
                                                            icon-name="icon-upload"><icon-upload /></icon-base>
                                                    </span>
                                                    <strong class="text">{{ l.submitCount }}</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section">
            <div class="content-inner">
                <header class="content-section__header content-section--center">
                    <h2 class="content-section__title">유의사항</h2>
                </header>
            </div>
            <div class="medium-content-inner">
                <ul>
                    <li class="list__item list__item--large ">AI League는 팀이 아닌 개인으로만 참여할 수 있습니다.</li>
                    <li class="list__item list__item--large "> 여러 개의 리그에 동시에 참여할 수 있습니다.</li>
                    <li class="list__item list__item--large "> AI League는 상시로 진행되어 별도의 마감 기간이 없지만, 운영사의 정책에 따라 운영이 종료될 수
                        있습니다.</li>
                    <li class="list__item list__item--large "> 사이트 이용 방법 및 시스템 오류에 대한 문의는 플랫폼 매뉴얼 및 FAQ, 1:1 문의를 이용해주세요.
                    </li>
                    <li class="list__item list__item--large ">리그 문제와 베이스라인 코드 등에 대한 질문, 의견 교류는 커뮤니티를 활용해주세요. 더 많은 사람들과
                        의견을 나누며 답을
                        얻어가실 수
                        있습니다.</li>


                </ul>
            </div>
        </section>
    </div>
</template>


<script>
import {
    computed,
    getCurrentInstance,
    onMounted,
    onBeforeMount,
    onUnmounted,
    reactive,
    toRefs,
    watch,
    ref,
    nextTick,

} from "@vue/composition-api";
import moment from "moment";
import utils from "@/helper/utils";
import { tokenStore } from "@/stores/auth";
import { stateStore } from "@/stores/state";

import LeagueApi from '@/lib/api/League'

import modalControler from "@/helper/modalControler";

// lottie
import lottie from "@/components/global/Lottie"
import animationData from '@/assets/json/header-graph.json';

export default {
    name: "LeagueList",
    components: {
        lottie,     // lottie
    },

    setup(props, context) {
        const route = context.root.$route;
        const router = context.root.$router;
        const store = tokenStore();
        const swiper = ref(null);
        const state = reactive({
            leagueList: [],
            defaultOptions: { animationData: animationData },   // lottie option

        });

        onMounted(() => {
            getAILeagueList()
        });

        onUnmounted(() => {
            clearInterval(state.interval);
        });

        const getAILeagueList = () => {
            LeagueApi.getAILeagueList()
                .then(response => {
                    state.leagueList = response.data.result

                })
                .catch(err => {

                })
        }


        // lottie
        const handleAnimation = (anim) => {
            // this.anim = anim;
        }
        return {
            swiper,
            ...toRefs(state),
            utils,
            moment,
            handleAnimation,
        };
    },
};
</script>
