<template>
  <v-dialog v-model="dialogOpen" content-class="dialog" width="380px" :fullscreen="getWindowWidthSize < 768"
    @input="(v) => v || onClickClose()">
    <v-card class="dialog-section">
      <header class="dialog-section__header center-type">
        <h3 class="dialog-section__title ">비밀번호 초기화</h3>
      </header>
      <div class="dialog-section__body">
        <p class="dialog-section__text--small">
          입력하신 메일로 초기화 링크를 보내드립니다.
        </p>
        <div class="form-box mt--16">
          <v-form>
            <v-text-field ref="emailEl" v-model="email" :error-messages="errorStatus.errorMessage"
              class="common-form-control" outlined hide-details placeholder="이메일을 입력해주세요."
              @keypress.13.prevent="onClickRequestResetPassword($event)"></v-text-field>
          </v-form>
        </div>
        <div class="dialog-section__btns">
          <v-btn class="btn btn-small btn-tertiary" @click="onClickClose()"> <strong>닫기</strong></v-btn>
          <v-btn class="btn btn-small btn-primary" :disabled="!isValidInput" @click="onClickRequestResetPassword($event)">
            <strong>비밀번호 초기화 링크 요청</strong></v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { BIcon } from 'bootstrap-vue'
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';


export default {
  name: 'ResetPassword',
  components: {
  },
  props: ['visible'],
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const state = reactive({
      dialogOpen: false,
      isValidInput: true,
      email: '',
      errorStatus: {
        email: false,
        errorMessage: ''
      },
      dialogVisible: false,
    });

    watch(() => props.visible, (nv, ov) => {
      state.dialogOpen = nv
    });

    const getWindowWidthSize = computed(() => {
      return context.root.$vuetify.breakpoint.width
    })

    const onClickClose = () => {
      context.emit('dialogClose')
    }

    const onClickRequestResetPassword = (e) => {
      if (e.keyCode === 13 || e) {
        Authenticate.requestResetPassword(btoa(state.email)).then(response => {
          if (response.data.success === true) {
            modalControler.ShowModalDialog('비밀번호 초기화', '입력한 메일로 초기화 링크가 전달되었습니다.<br>받으신 이메일 내용에 있는 초기화 링크를 클릭해 주세요.', '', '확인', () => {
              onClickClose()
              state.email = ''
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }

    return {
      ...toRefs(state),
      onClickClose,
      getWindowWidthSize,
      onClickRequestResetPassword,
    }

  }

}
</script>