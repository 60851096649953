import httpClient from './httpClient';
import {JSEncrypt} from 'jsencrypt'
import utils from '@/helper/utils'

/*

[ 권한 번호 ]
0: 사용자
1: 관리자
2: 모니터
3: 운영자

*/

export default {
    // user page authToken 발급
    getAuthToken(email, password) {
        return new Promise((resolve, reject) => {
          let params = {
            email: email,
            password: password
          };
          this.rsaKey().then(({data} = response) => {
            const rsa = new JSEncrypt({default_key_size: 2048});
            rsa.setPublicKey(utils.createPublicKey(data.result.publicKey));
            return {data: rsa.encrypt(JSON.stringify(params))};
          })
            .then(result => resolve(this.signIn(result)))
            .catch(reject);
        });
    },
    //
    rsaKey() {
      return httpClient.get('/auth/rsakey');
    },    
    // 
    getUserInfo() {
        return httpClient.get('/auth/account');
    },
    // login
    signIn(param) {
        return httpClient.post('/auth/signin', param);
    },
    // logout
    signOut() {

    },
    // 닉네임 중복 체크
    checkNickname(param) {    
      return httpClient.post('/auth/nicknamecheck', param);
    },
    // 이메일 중복 체크
    checkEmail(param) {     
      return httpClient.post('/auth/signupcheck', param);
    },
    // 회원 가입
    signUp(param) {
      return httpClient.post('/auth/signup', param);
    },    
    // 계정 활성화
    signInActivation(param) {
      return httpClient.put(`auth/signin/activation?id=${param}`);
    },    
    // 프로필 업데이트
    updateProfile(param) {
      return httpClient.post('/auth/updateProfile', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    // 비밀번호 변경
    changePassword(params){
      return httpClient.post(`/auth/changePassword`, params);
    },

    // 비밀번호 초기화
    requestResetPassword(param) {
      return httpClient.get(`/auth/requestResetPassword?id=${param}`);
    },    
    
    // 비밀번호 reset
    resetPassword(param) {
      return httpClient.put(`/auth/resetPassword?id=${param}`);
    },    

    // 회원탈퇴
    withdrawal() {
      return httpClient.put(`/auth/deleteUser`);
    },

    // SSO api
    SSOLogin(params) {
      return httpClient.post(`/auth/sso`, params);
    },
}