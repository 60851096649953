<template>
    <aside class="task-section-3 right-content">
        <div class="leaderboad-list content-group">
            <header class="content-group__header">
                <h3 class="content-group__title">리더보드</h3>
            </header>
            <ul class="leaderboad-list__items list-board" v-if="leaderboard.length">
                <li class="leaderboad-list__item list-board__item list-board--medium " v-for="(l, i) in leaderboard"
                    :key="'rank-' + i">
                    <div class="list-board__content">
                        <div class="ranking-box ranking-box--small pr-16">
                            <span v-if="l.ranking === 1" class="icon icon__size--300 icon--medal-01">
                                <span class="blind">1위</span>
                            </span>
                            <div v-else-if="l.ranking === 2" class="ranking-box ranking-box--small pr-16">
                                <span class="icon icon__size--300 icon--medal-02">
                                    <span class="blind">2위</span>
                                </span>
                            </div>
                            <div v-else-if="l.ranking === 3" class="ranking-box ranking-box--small pr-16">
                                <span class="icon icon__size--300 icon--medal-03">
                                    <span class="blind">3위</span>
                                </span>
                            </div>
                            <div v-else class="ranking-box ranking-box--small pr-16">
                                <span class="ranking-box__item">
                                    {{ l.ranking }}위
                                </span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="profile-img profile-img__size--200">
                                <img :src="
                                    (l.profileImg) ? l.profileImg : 
                                    require('@/assets/images/img-profile-gray.svg')" 
                                    alt="" />
                            </div>
                            <strong class="title pl--8">{{ l.nickname }}</strong>
                        </div>
                    </div>
                    <div class="small-text">
                        {{ l.scores[0].publicScore.toFixed(4) }}
                    </div>
                </li>
            </ul>
            <div v-if="!leaderboard.length" class="no-data">
                <p class="no-data__text">결과 제출 시 리더보드를 확인할 수 있습니다.</p>
            </div>
        </div>
    </aside>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, provide, watch
} from '@vue/composition-api';

export default {
    name: "LeaderboardPanel",
    props: [
        'leaderboard',
    ],
    setup(props, context) {
        onMounted(() => {
            // console.log(props.leaderboard)
        })

        return {
            props,
        }
    }


};
</script>
