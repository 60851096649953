import httpClient from './httpClient';


export default {

    // 메뉴얼 리스트
    getManualList(params) {
        return httpClient.get(`/support/manuals${params}`)
    },

    // 메뉴얼 상세
    getManualDetail(id) {
        return httpClient.get(`/support/manuals/${id}`)
    },
    
    // 문의 목록
    getInquiryList(params) {
        return httpClient.get(`/support/qna${params}`)
    },

    // 문의 상세
    getInquiryDetail(id) {
        return httpClient.get(`/support/qna/${id}`)
    },

    // 문의 등록
    RegistInquiry(params) {
        return httpClient.post(`/support/qna`, params, {headers: {'Content-Type': 'multipart/form-data'}})
    },

    // 1:1 문의 카테고리 목록
    getInquiryCategories() {
        return httpClient.get(`/support/qna/categories`)
    },

    // FAQ 리스트
    getFAQList(params) {
        return httpClient.get(`/support/faqs${params}`)
    },

    // Manual 카테고리 목록
    getManualCategories() {
        return httpClient.get(`/support/manuals/categories`)
    },

    // 메뉴얼/FAQ 검색
    searchManuals(searchText) {
        return httpClient.get(`/support/search?q=${encodeURIComponent(searchText)}`)
    },


}