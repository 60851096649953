<template>
    <div class="help-wrap contents-wrap">
        <section class="help-section">
            <header class="page-header--line__section">
                <span class="page-header--line__sub-title">AI CONNECT 이용 매뉴얼</span>
                <h2 class="page-header--line__title">{{ detail.title }}</h2>
            </header>
            <div class="medium-content-inner">
                <div class="ProseMirror" v-html="detail.content"></div>
                <template v-if="detail.fileUrl">
                    <div class="btn-filedown-area">
                        <a class="btn-filedown" :href="detail.fileUrl">
                            <font-awesome-icon :icon="['fas', 'paperclip']" /><span class="file-name">{{
                                utils.getFilenameFromURL(detail.fileUrl) }}</span>
                        </a>
                    </div>
                </template>
                <div class="btn-tags" v-if="keywords.length">
                    <v-btn class="btn btn-tag" @click="searchTag('#' + keyword)" v-for="(keyword, idx) in keywords"
                        :key="'keyword-' + idx">
                        <span class="icon-left"><img src="../../assets/images/icon-tag.svg" /></span>{{ keyword }}
                    </v-btn>
                </div>
            </div>
            <div class="medium-content-inner">
                <div class="content-group" v-if="filteredManuals.length">
                    <header class="content-group__header mt--32">
                        <h3 class="content-group__title"><span class="color-primary">{{
                            detail.categoryName
                        }}</span>의
                            다른 글
                        </h3>
                    </header>

                    <ul class="list-board">
                        <li class="list-board__item list-board--medium" v-for=" (m, idx) in filteredManuals"
                            :key="'m-' + idx">
                            <a @click.prevent.stop="toDetailPage(m.id)" class="list-board__link">{{ m.title }} <span
                                    class="icon">
                                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                                        icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                                </span></a>
                        </li>
                    </ul>
                </div>
                <div class="content-group__btn-area">
                    <div class="btn-inline btn-inline--center">
                        <button type="button" class="btn btn-secondary btn-large"
                            @click="toFAQList"><strong>목록</strong></button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import SupportApi from '@/lib/api/Support.js'

import 'highlight.js/styles/monokai-sublime.css'


export default {
    name: 'HelpDetail',
    // components: {
    //     FileInput,
    // },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            detail: {},
            keywords: [],
            manualList: [],
            filteredManuals: [],
        })

        onMounted(() => {
            getManualDetail(route.params.manualId)
        })

        const getManualList = () => {
            let params = {
                pageNo: 1,
                pageSize: 10
            }
            SupportApi.getManualList(utils.makeParams(params)).then(response => {
                if (response.data.code === 0) {
                    state.manualList = response.data.result
                    // 
                    state.filteredManuals = state.manualList.filter(m => ((m.categoryId === state.detail.categoryId) && (m.title !== state.detail.title)))
                }
            })
        }

        const getManualDetail = (id) => {
            SupportApi.getManualDetail(id).then(response => {
                if (response.data.code === 0) {
                    state.detail = response.data.result
                    state.keywords = getKeywords(response.data.result.keywords)
                    getManualList()
                } else {
                    modalControler.ShowErrorToast('해당 메뉴얼을 찾을 수 없습니다.')
                }
            })
                .catch(err => {
                    modalControler.ShowErrorToast(err)
                })
        }

        const toFAQList = () => {
            router.push({
                name: 'faq',
            })
        }

        const getKeywords = (keywordsArr) => {
            if (keywordsArr.length)
                return keywordsArr.split(' ')
            else
                return []
        }

        const toDetailPage = (manualId) => {
            router.push({
                name: 'HelpDetail',
                params: {
                    manualId: manualId,
                }
            })
        }

        const searchTag = (keyWord) => {
            router.push({
                name: 'manualSearch',
                query: {
                    searchText: keyWord,
                }
            })
        }

        return {
            utils,
            route,
            ...toRefs(state),
            toFAQList,
            toDetailPage,
            getKeywords,
            searchTag,
        }
    }

}
</script>
