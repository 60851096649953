<template>
  <div>
    <a class="back_btn" @click.prevent="$router.goCompetitionPages('CompetitionDetail', competitionId)">
      <!-- <img src="@/assets/images/arrow_back_black_24dp.svg"> -->
    </a>

    <div class="main_container_task">
      <section class="task_category section-2">
        <div class="section-2__container">
          <article class="section-2__article">
            <h1>과제목록</h1>
            <div class="league__container">
              <ul class="list_card league-list">
                <li class="list_item" v-for="(task, idx) in taskList" :key="idx">
                  <div class="card">
                    <a @click.prevent="toTaskDetail(task.assignmentId)" class="card-img"
                      :style="{ backgroundImage: 'url(' + task.imgurl + ')', backgroundSize: 'cover' }">
                      <div class="card-img__wrapper" v-if="task.prizemoney !== 0">
                        <!-- <img src="@/assets/images/cash-yellow.svg" /> -->
                        <p>{{ utils.numberToKorean(task.prizemoney) }}만원</p>
                      </div>

                    </a>
                    <div class="card-content">
                      <a class="card-top__wrapper" @click.prevent="toTaskDetail(task.assignmentId)">
                        <h3>{{ task.name }}</h3>
                        <p>{{ task.desc }}</p>
                      </a>
                      <div class="card-keywords">
                        <ul class="list_tag_type2">
                          <li v-if="task.type && task.type !== '' && task.type !== null" class='tag'>{{ task.type }}</li>
                          <template v-for="(k, idx) in task.keywords">
                            <li v-if="k.name !== ''" :key="'k-' + idx" class='tag'>{{ k.name }}</li>
                          </template>
                        </ul>
                      </div>
                      <div class="card-bottom__wrapper">
                        <v-btn v-if="isJoin === 1 || isJoin === 2" class="card-bottom__btn"
                          @click.prevent="taskApply(task)">참여하기</v-btn>
                        <p class="card-bottom__text">
                          <!-- <span><img src="@/assets/images/participants.svg" /></span> -->
                          {{ task.joinusercnt }}명 / {{ task.jointeamcnt }}팀
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--a
                          @click.prevent="$router.push({ name: 'TaskRoot', params: {taskId: task.assignmentId} })"
                          class="anchor_list">
                          <div class="list_top">
                          </div>
                          <div class="list_bottom">
                            <a>
                              <h5 class="ellipsis">{{ task.name }}</h5>
                              <p class="txt_info">{{ task.desc }}</p>
                            </a>
                              <ul class="list_tag_type2">
                                  <li v-if="task.type !== '' && task.type !== null" class="tag">{{ task.type }}</li>
                                  <template v-for="(k, idx) in task.keywords">
                                    <li :key="idx" class="tag">{{ k.name }}</li>
                                  </template>
                              </ul>
                          </div>
                        </a-->
                </li>
              </ul>
            </div>
          </article>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import CompetitionApi from '@/lib/api/Competition';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';


export default {
  name: 'TaskList',
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const stateStorage = stateStore()
    const state = reactive({
      competitionId: context.root.$route.params.competitionId,
      taskList: [],
      status: 0,
      isJoin: 0,
    });

    onMounted(() => {
      stateStorage.removeGnbItems()       // gnb list 지운다

      getTaskList();
      getCompetitionDetailInfo();
    });

    const getTaskList = () => {
      TaskApi.getAssignmentList(state.competitionId).then(response => {
        if (response.data.http_status_code === 200) {
          state.taskList = response.data.result

        }
      }).catch(err => {
        modalControler.ShowErrorToast("과제목록 호출 실패")
      });
    }

    const getCompetitionDetailInfo = () => {
      modalControler.ShowLoading()
      CompetitionApi.getCompetitionDetailInfo(route.params.competitionId).then(response => {
        if (!response.data.error) {
          const data = response.data.result
          state.status = data.status
          state.isJoin = data.isjoin
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

    const toTaskDetail = (taskId) => {
      router.goTaskPages('TaskInfo', taskId, route.params.competitionId)
    }

    const taskApply = (task) => {
      let params = {
        competitionId: route.params.competitionId,
        assignmentId: task.assignmentId
      }
      modalControler.ShowLoading()
      TaskApi.joinAssignment(params).then(response => {
        if (!response.data.error) {
          modalControler.HideLoading()
          router.goTaskPages('TaskInfo', task.assignmentId, route.params.competitionId)
          // location.reload()
        } else {
          if (response.data.error_code === 120002) {
            modalControler.ShowErrorToast(response.data.error_detail)
          }
        }
      }).catch(err => {
        // modalControler.ShowErrorToast(err.response.error_detail)
        console.log(err)
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

    return {
      ...toRefs(state),
      utils,
      toTaskDetail,
      taskApply,
    }

  }

}
</script>

<style scoped lang="scss">
.list_top {
  height: 45%;

  img {
    height: 100%;
  }
}

.list_card {

  .list_item {
    height: 476.34px;

    .card {
      height: 100%;
      width: 339px;

      .card-content {
        padding: 0.7rem;
      }
    }
  }

  .anchor_list {
    width: 341.25px;
  }

  .list_bottom {
    padding: 0.7rem;
    line-height: 1.2;
    width: 100%;
    height: 34%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    h5 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }



}
</style>