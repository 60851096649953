<template>
    <v-app>

        <main class="login">
            <div class="login__inner">
                <header class="login__header">
                    <button type="button" class="header-logo" @click="$router.push({ path: '/' })">
                        <img src="@/assets/images/img-logo.svg" alt="ai connect" />
                    </button>
                </header>
                <form>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">닉네임</div>
                        <v-text-field class="common-form-control" outlined placeholder="특수문자, 공백없이 최소 4~20자"
                            :error-messages="errorStatus.nickErrMsg" v-model="form.nickname"
                            @blur="checkNickName(form.nickname)" @keyup="onKeyup($event, 'nickname')">
                            <template v-slot:append>
                                <v-btn class="btn-small btn-primary" v-if="isNickNameChecked === false"
                                    @click="nicknameDuplicationCheck(form.nickname)"
                                    :disabled="(errorStatus.nickErrMsg !== '') ? true : false">
                                    중복체크
                                </v-btn>

                                <span v-else class="confirm-chk">
                                    <b-icon icon="check-circle-fill"></b-icon>
                                </span>
                            </template>
                        </v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">이메일</div>
                        <v-text-field class="common-form-control" outlined placeholder="example@email.com" filled readonly
                            disabled :error-messages="errorStatus.emailErrMsg" v-model="form.info.email"
                            @blur="checkEmail(form.info.email)" @keyup="onKeyup($event, 'email')">
                            <template v-slot:append>
                                <v-btn class="btn-small btn-primary" v-if="isEmailChecked === false"
                                    @click="emailDuplicationCheck(form.info.email)"
                                    :disabled="(errorStatus.emailErrMsg !== '') ? true : false">
                                    중복체크
                                </v-btn>
                                <span v-else class="confirm-chk">
                                    <b-icon icon="check-circle-fill"></b-icon>
                                </span>
                            </template>
                        </v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">이름</div>
                        <v-text-field class="common-form-control" outlined placeholder="특수문자, 공백없이 최소 2~20자"
                            :error-messages="errorStatus.nameErrMsg" v-model="form.name" @blur="checkName(form.name)"
                            @keyup="onKeyup($event, 'name')"></v-text-field>
                    </div>

                    <div class="agree">
                        <div class="agree__item agree__item--all">
                            <v-checkbox class="common-checkbox" label="전체동의" hide-details color="#009bfa"
                                v-model="checkAllAgreement" @change="checkAll">
                            </v-checkbox>
                        </div>
                        <div class="agree__item agree__item--both">
                            <v-checkbox class="common-checkbox" label="이용약관 동의" hide-details color="#009bfa"
                                v-model="form.agreePlatformTerms" @change="checkTerms">
                            </v-checkbox>
                            <button class="btn-text btn-xsmall" type="button" @click="showServicePolicy">약관보기</button>
                        </div>
                        <div class="agree__item agree__item--both">
                            <v-checkbox class="common-checkbox" label="개인정보처리방침 동의" hide-details color="#009bfa"
                                v-model="form.agreePlatformUseTerms" @change="checkTerms">
                            </v-checkbox>
                            <button class="btn-text btn-xsmall" type="button" @click="showPrivacyPolicy">약관보기</button>
                        </div>
                        <div class="btn-inline btn-inline--section">
                            <v-btn class="btn btn-primary btn-large btn-full" :disabled="enableSubmit" @click="signup">
                                <strong>회원가입</strong>
                            </v-btn>
                        </div>
                        <div class="login-link">
                            <div class="login-link__item">
                                <button class="btn-text" @click.prevent="$router.push({ name: 'SignIn' })">
                                    로그인하기
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="company-btns">
                        <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showServicePolicy">
                            이용약관
                        </button>
                        <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showPrivacyPolicy">
                            개인정보처리방침
                        </button>
                    </div>
                </form>
            </div>
        </main>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import { JSEncrypt } from 'jsencrypt'
import { BIcon } from 'bootstrap-vue'
import moment from 'moment'
import jwtDecode from 'jwt-decode';
import utils from '@/helper/utils'
import validator from "@/config/formValidator"
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import SocialAPI from '@/lib/api/social';


export default {
    name: 'SignupSocial',
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router

        const state = reactive({
            form: {
                type: '',
                token: null,
                provider: null,
                nickname: '',
                name: '',
                info: {
                    email: '',
                    password: ''
                },
                agreePlatformTerms: false,
                agreePlatformUseTerms: false,
                repassword: ''
            },
            errorStatus: {
                nickErrMsg: '',
                nameErrMsg: ''
            },
            checkAllAgreement: false,
            isNickNameChecked: false,
            checkedNickName: '',
            isEmailChecked: false,
            checkedEmail: '',
        });

        onMounted(() => {
            if ((route.params.type && route.params.type === 'social') && route.params.token) {
                const userInfo = jwtDecode(route.params.token)
                state.form.token = route.params.token
                state.form.provider = userInfo.provider
                state.form.info.email = userInfo.email
                utils.storeSocialSignUpData(state.form.provider, state.form.info.email, state.form.token)
            } else {
                const userData = JSON.parse(localStorage.getItem('aiconnect-social-signup'));
                if (userData && !route.params.token) {
                    state.form.provider = userData.provider
                    state.form.info.email = userData.email
                    state.form.token = userData.token
                }
            }
        })

        const enableSubmit = computed(() => {
            return !(
                state.form.nickname &&
                state.form.name &&
                state.form.info.email &&
                state.isNickNameChecked &&
                state.form.agreePlatformTerms &&
                state.form.agreePlatformUseTerms
            )
        })

        const checkNickName = (nickname) => {
            state.errorStatus.nickErrMsg = validator.nicknameValidator(nickname)
            if (state.errorStatus.nickErrMsg !== '') return false
            else return true
        }

        const checkName = (name) => {
            state.errorStatus.nameErrMsg = validator.nameValidator(name)
            if (state.errorStatus.nameErrMsg !== '') return false
            else return true
        }

        // 닉네임 중복체크
        const nicknameDuplicationCheck = (nickname) => {

            // validator 
            if (!checkNickName(nickname)) return

            Authenticate.checkNickname({ nickname: nickname }).then(response => {
                if (response.data.result) {
                    modalControler.ShowErrorToast('이미 사용중인 닉네임입니다.')
                } else {
                    modalControler.ShowSuccessToast('사용 가능한 닉네임입니다.')
                    state.isNickNameChecked = true
                    state.checkedNickName = state.form.nickname
                }
            }).catch(err => err);
        }

        // 타이핑 시 form validation
        const onKeyup = (e, type) => {
            if (state.isNickNameChecked === true && (state.checkedNickName !== state.form.nickname)) {
                state.isNickNameChecked = false
            }

            if (state.isEmailChecked === true && (state.checkedEmail !== state.form.info.email)) {
                state.isEmailChecked = false
            }

            if (type === 'nickname') {
                checkNickName(state.form.nickname)
            } else if (type === 'email') {
                checkEmail(state.form.info.email)
            } else if (type === 'name') {
                checkName(state.form.name)
            } else if (type === 'password') {
                checkPassword(state.form.info.password)
            } else if (type === 'repassword') {
                checkRePassword(state.form.repassword)
            }

        }

        const checkAll = () => {
            state.checkAllAgreement ? (state.form.agreePlatformTerms = true, state.form.agreePlatformUseTerms = true) :
                (state.form.agreePlatformTerms = false, state.form.agreePlatformUseTerms = false)
        }

        const checkTerms = () => {
            if (state.form.agreePlatformTerms && state.form.agreePlatformUseTerms) state.checkAllAgreement = true
            else state.checkAllAgreement = false
        }

        const signup = () => {
            let params = {
                token: state.form.token,
                nickname: state.form.nickname,
                email: state.form.info.email,
                name: state.form.name,
                agreeplatterms: state.form.agreePlatformTerms,
                agreeplatuseterms: state.form.agreePlatformUseTerms
            }
            SocialAPI.socialAuthSignUp(params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('가입되었습니다.')
                    utils.discardSocialSignUpData();
                    router.goHome()
                } else {
                    modalControler.ShowErrorToast('가입에 실패했습니다.')
                }
            }).catch(err => {
                modalControler.ShowErrorToast('가입에 실패했습니다.')
                console.log(err)
            })
        }

        const showServicePolicy = () => {
            // router.push({ name: 'Service' })
            let routeData = router.resolve({ name: 'Service' })
            window.open(routeData.href, '_blank')
        }

        const showPrivacyPolicy = () => {
            // router.push({ name: 'Privacy' })
            let routeData = router.resolve({ name: 'Privacy' })
            window.open(routeData.href, '_blank')
        }



        return {
            ...toRefs(state),
            validator,
            checkNickName,
            nicknameDuplicationCheck,
            onKeyup,
            checkAll,
            checkName,
            checkTerms,
            enableSubmit,
            signup,
            showServicePolicy,
            showPrivacyPolicy,
        }
    }
}
</script>

<style scoped lang="scss">
.confirm-chk {
    color: #4ade10;
    font-size: 20px;
}

@media screen and (max-width:480px) {
    .v-text-field ::v-deep input::placeholder {
        font-size: 14px;
    }
}
</style>