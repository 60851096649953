<template>
    <Swiper 
        ref="swiper" 
        class="swiper" 
        :options="swiperOption" 
        :mobules="modules"
    >
        <swiper-slide v-for="(slide, idx) in props.competitionList" :key="'bg-img' + idx">
            <section class="wrap_main_banner">
                <div class="main_banner"
                    :style="{ backgroundImage: 'url(' + slide.bgimgurl +')' }"
                >
                </div>
            </section>
        </swiper-slide>
    </Swiper>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import moment from 'moment';
import utils from '@/helper/utils';
import { Pagination, Navigation, AutoPlay } from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
// import 'swiper/swiper-bundle.css';
import 'swiper/css/swiper.css'

export default {
    name: 'Carousels',
    props: {
        competitionList: {
            required: false,
        }
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const vuetify = context.root.$vuetify
        const state = reactive({
            swiperOption: {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
                initialSlide: 1,
                autoplay: 4000,
            },
        })  
        const swiper = ref(null)
        onMounted(() => {
            swiper.slideTo(1, 1000, false)
        })

        const getWindowWidthSize = computed(() => {
            if (vuetify.breakpoint.width > 1080) {
                return 450
            }
            if (vuetify.breakpoint.width > 768) {
                return 380
            }
            return 240
        })


        return {
            ...toRefs(state),
            props,
            utils,
            getWindowWidthSize,
            modules: [ Pagination, Navigation, AutoPlay ]
        }      
    }
    
}

</script>