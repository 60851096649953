<template>
    <article class="section-2__content">
        <BoardEditView 
          v-if="!isLoading"
          editorTitle="리그 커뮤니티" 
          :boardType="'LeagueCommunity'"
          :mode="mode" 
          :contentsTitle="contentsTitle"
          :contents="contents"
          :attachment="attachment"
          :isNotice="isNotice"
          :isOpen="isOpen"
          @writeQnA="writeQnA"
          @updateQnA="updateQnA"        
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CommunityApi from "@/lib/api/Community";
import modalControler from '@/helper/modalControler';
import BoardEditView from "@/components/BoardEditView"



export default {
    name: 'LeagueCommunityEdit',
    components: {
        BoardEditView
    },
    setup(props, context) {
      const route = context.root.$route
      const router = context.root.$router
      const state = reactive({
        contentsTitle: '',
        contents: '',
        attachment: [],
        instances: [],
        qnaData: {},
        options: {
            page: 1,
            itemsPerPage: 10
        },     
        isNotice: false,
        isOpen: true,
        mode: 'write',
        isLoading: true,     
      });

      onMounted(async () => {
        if (route.query.type === 'update') {
          state.isLoading = true
          state.mode = 'update'
          await getTaskQnaDetail()
        } else {
          state.isLoading = false
        }
      });

      const getTaskQnaDetail = () => {
        modalControler.ShowLoading()
        CommunityApi.getLeagueCommunityDetail(route.params.postId).then(response => {
          if (!response.data.error) {
            state.qnaData = response.data.result
            // 첨부파일 넣기 
            // if (state.qnaData.files) {
            //   state.qnaData.files.forEach(file => {
            //     getBlobFromUrl(file.url, file.name).then(file => {
            //       state.attachment.push(file)
            //     })
            //   })
            // }
            /* 
              for QnA update
            */
            state.contentsTitle = state.qnaData.title
            state.contents = state.qnaData.content
            state.isNotice = state.qnaData.notice
            state.isopen = (state.qnaData.isopen === 1) ? true : false
            state.isLoading = false
          }          
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const writeQnA = (params, files) => {
        const formData = new FormData()
        formData.append('data', JSON.stringify(params))
        formData.append('fileList', files)
        modalControler.ShowLoading()
        CommunityApi.writeLeagueCommunityWrite(route.params.leagueId, formData).then(response => {
            modalControler.ShowSuccessToast('게시글이 등록되었습니다.')
            router.push({
                name: 'LeagueCommunityDetail',
                params: {
                    leagueId: route.params.leagueId,
                    postId: response.data.postId
                }
            })            
          // TODO : success alarm
        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const updateQnA = (params, files) => {
        const formData = new FormData()
        formData.append('data', JSON.stringify(params))
        if (files && files.length && files.length >= 1) {
          formData.append('fileList', files[0])
        } else {
          formData.append('fileList', files)
        }
        modalControler.ShowLoading()
        CommunityApi.updateLeagueCommunityWrite(
            route.params.leagueId,
            route.params.postId,
            formData).then(response => {
                modalControler.ShowSuccessToast('글을 수정했습니다.')
                router.push({
                    name: 'LeagueCommunityDetail',
                    params: {
                        postId: route.params.postId
                    }
                })
        }).catch(err => {
          // TODO : error alarm
        }).finally(() => {
          modalControler.HideLoading()
        })
      }

      const getBlobFromUrl = async (url, name) => {
        const response = await fetch(url)
        const data = await response.blob()
        const file = new File([data], name, { type: name.split('.').pop() });
        processFile(file).then((dataURL) => {
          state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
        });

        return file
      }      

      const processFile = async (file) => {
        return await new Promise((resolve) => {
          let reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })
      }      

      return {
        ...toRefs(state),
        writeQnA,
        updateQnA
      }


    }
}
</script>

<style scoped lang="scss">

.app-g-btn-group {
  float: right;

  .app-g-btn {
    margin-left: 10px;
  }
}
</style>