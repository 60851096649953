<template>
    <section class="large-content-section border-top-100" v-if="competitionList.length">
        <div class="content-inner">
            <header class="large-content-section__header">
                <span class="large-content-section__sub-title">Competition</span>
                <h2 class="large-content-section__title">AI 경진대회에 참여해보세요.</h2>
                <!-- <div class="large-content-section__btn">
            <a href="" class="btn-more"><span>전체보기</span>
              <span class="icon">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                  icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
              </span>
            </a>
          </div> -->
                <p class="large-content-section__text">
                    Compete and Complete!<br />
                    AI CONNECT에서 다양한 경진대회에 도전하여 당신의 실력을 증명하고 매력적인 Reward까지 얻어보세요.</p>
            </header>

            <!-- 대회 예정일때 <div class="competition-banner__group common-competition common-competition__disabled"> -->
            <div class="competition-banner__group common-competition" v-for="(c, i) in competitionList" :key="i">
                <a @click.prevent.stop="$router.goCompetitionPages('CompetitionDetail', c.id)"
                    class="common-competition__box competition-banner__box thumb-square thumb-square--l">
                    <img :src="(c.imgurl) ? c.imgurl : '../../assets/images/img-main-dummy.png'" />
                </a>
                <div class="competition-banner__content">
                    <div class="common-competition__header">
                        <div class="common-competition__status-badge">
                            <div v-if="c.status === 3" class="status-badge status-badge--black">접수중</div>
                            <div v-else-if="c.status === 4" class="status-badge status-badge--primary">진행중</div>
                            <div v-else-if="c.status === 5" class="status-badge status-badge--white">점검중</div>
                        </div>
                        <a @click.prevent.stop="$router.goCompetitionPages('CompetitionDetail', c.id)" class="title">{{
                            c.name }}</a>
                        <a @click.prevent.stop="$router.goCompetitionPages('CompetitionDetail', c.id)" class="dec">
                            {{ c.desc }}
                        </a>
                        <div class="info--group">
                            <div class="item">
                                <span class="item__icon" v-if="c.fromdt">
                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                                        icon-name="icon-calendar"><icon-calendar /></icon-base>
                                </span>
                                <span class="item__text"> {{ c.fromdt.split(" ")[0] }} ~ {{ c.todt.split(" ")[0] }}</span>
                            </div>
                            <div class="prize" v-if="c.prizemoney > 0">
                                <span class="prize__icon icon icon__size--300 icon--prize">
                                    <span class="blind">상금</span>
                                </span>
                                <div class="prize__text">
                                    <span class="money font-num-bold">{{ utils.numberToKorean(c.prizemoney) }}</span> 만원
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="common-competition__body">
                        <div class="task-list__items">
                            <section class="swiper__banner hover-btn swiper-unset">
                                <!--template v-if="c.assignmentList.length > 2">
                                    <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">
                                        <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                                            icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                                    </button>
                                    <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                                        <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                                            icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                                    </button>
                                </template-->

                                <div v-swiper:[i]="taskSwiperOption" class="swiper">
                                    <div class="swiper-wrapper">
                                        <swiper-slide v-for="(t, ti) in c.assignmentList" :key="'task-' + ti">
                                            <a @click="$router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)"
                                                class="task-list__item">
                                                <h4 class="title">{{ t.name }}</h4>
                                                <div class="tags__group">
                                                    <span class="tags__item tag" v-for="(k, ki) in t.keywords"
                                                        :key="'key-' + ki">
                                                        {{ k.name }}
                                                    </span>
                                                </div>
                                                <div class="person">
                                                    <span class="icon">
                                                        <icon-base viewBox="0 0 24 24" width="16" height="16"
                                                            iconColor="#101216"
                                                            icon-name="icon-user"><icon-user /></icon-base>
                                                    </span>
                                                    <span class="text">
                                                        {{ t.joinusercnt }}명 / {{ t.jointeamcnt }}팀
                                                    </span>
                                                </div>
                                            </a>
                                        </swiper-slide>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="large-content-section__btn-area">
                <button @click="goToCompetitionList" class="btn btn-secondary btn-large" type="button"><strong>AI 경진대회
                        전체보기</strong>
                    <span class="icon-right">
                        <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
                            icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
                    </span>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import {
    reactive,
    ref,
    toRefs,
    onMounted,
} from "@vue/composition-api";
import { tokenStore } from "@/stores/auth";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import utils from '@/helper/utils';

export default {
    name: "CompetitionSwiper",
    components: {
        SwiperSlide,
    },
    // directive: {
    // swiper: directive
    // },
    props: [
        'competitionList',
    ],
    setup(props, context) {

        const route = context.root.$route;
        const router = context.root.$router;
        const store = tokenStore();
        const swiper = ref(null);
        const state = reactive({
            taskSwiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 0,
                // observer: true,
                // observeParents: true,
                navigation: {
                    nextEl: '.swiper-btn__middle__right',
                    prevEl: '.swiper-btn__middle__left'
                }
            },
            interval: null,
            taskOptionList: [],

        });

        const goToCompetitionList = () => {
            router.push({
                name: 'CompetitionList'
            })
        }

        onMounted(() => {
            // makeTaskOptionArray()
            // console.log(state.taskOptionList)
        })

        // const makeTaskOptionArray = () => {
        //     props.competitionList.forEach((c, i) => {
        //         let taskSwiperOption = {
        //             initialSlide: 1,
        //             loop: true,
        //             slidesPerView: 3.5,
        //             breakpoints: {

        //                 '768': {
        //                     slidesPerView: 1.5,

        //                 },
        //                 '1024': {
        //                     slidesPerView: 2.5,
        //                 },
        //             },
        //             autoplay: {
        //                 delay: 2000,     // bannder img slide time
        //                 disableOnInteraction: false
        //             },
        //             pagination: {
        //                 el: '.swiper-pagination',
        //                 clickable: true,
        //             },                
        //         }
        //         if (c.assignmentList.length) {
        //             if (c.assignmentList.length < 3) {
        //                 taskSwiperOption.slidesPerView = 1.5
        //             }
        //         }
        //         state.taskOptionList.push(taskSwiperOption)
        //     })
        // }

        return {
            ...toRefs(state),
            goToCompetitionList,
            utils
        }
    },
};
</script>
