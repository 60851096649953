<template>
    <article>
        <article v-if="props.category === 'A003'" class="content-blocks">
            <header class="content-blocks__header">
                <h3 div class="content-blocks__title">연습 제출 안내</h3>
            </header>

            <div class="list__box--area">
                <ul class="list list__box list__box--line">
                    <li class="list__item">
                        대회에 참여하지 않더라도 데이터 다운로드 및 결과제출이 가능합니다.
                    </li>
                    <li class="list__item">
                        결과제출 시 Public Score, Private Score, Final Score를 확인할 수 있습니다.
                    </li>
                </ul>
            </div>
        </article>
        <!-- 결과제출 제한사항 -->
        <article class="content-blocks">
            <header class="content-blocks__header">
                <h3 div class="content-blocks__title">결과제출 제한사항</h3>
            </header>

            <div class="list__box--area">
                <ul class="list list__box list__box--line">
                    <li class="list__item">
                        <span>파일크기 : </span>
                        <span> 최대 <strong>{{ props.maxSubmitSize }}</strong>MB </span>
                    </li>

                    <template v-if="props.category === 'A001'">
                        <li class="list__item" v-if="props.maxSubmitTime !== 0">
                            <span>제출횟수 : </span>
                            <span> 하루 최대 <strong>{{ props.maxSubmitTime }}</strong>회 까지 제출 가능</span>
                        </li>
                        <li class="list__item" v-if="props.maxSubmitTerm !== 0">
                            <span>제출시간 : </span>
                            <span>제출 후 <strong>{{ props.maxSubmitTerm }}</strong>시간 이후 제출 가능</span>
                        </li>
                    </template>
                    <template v-else-if="props.category === 'A002'">
                        <template v-if="fileExtList.length">
                            <li class="list__item">
                                <span>파일형식 : </span>
                                <span v-for="(format, idx) in fileExtList" :key="idx">
                                    {{ format.name }}
                                    (<template v-for="(ext, idx2) in format.items">
                                        <strong :key="idx2">.{{ ext.ext }}</strong>
                                    </template>)
                                </span>
                            </li>
                        </template>
                        <template v-if="limitFileCount > 0">
                            <li class="list__item">
                                <span>파일개수 : </span>
                                <span><strong>{{ limitFileCount }}</strong>개</span>
                            </li>
                        </template>
                    </template>

                </ul>
            </div>
        </article>

        <!--GPU제공형-->
        <template v-if="props.isOpen === 0 && props.category === 'A001' && props.isJoin === 3">
            <article class="content-blocks" v-if="props.container">
                <header class="content-blocks__header">
                    <h3 class="content-blocks__title">제출명령어</h3>
                </header>
                <div class="list__box list__box--blue">
                    <div class="code-block">
                        <p style="word-break: break-word;">
                            {{ `curl -X POST '${apiURL}/assignment/${assignmentId}/results'` }}
                            {{ ` --header 'Authorization: Bearer ${authToken}'` }}
                            {{ ` --form 'file=@"FILE-PATH/FILE-NAME"'` }}
                        </p>
                        <div class="btn-copy-group">
                            <button type="button" class="btn-copy" @click="copySubmitCmd">
                                <span class="icon">
                                    <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#088ef5"
                                        icon-name="icon-copy"><icon-copy /></icon-base>
                                </span>
                                <span>클립보드 복사</span>
                            </button>
                        </div>
                    </div>
                </div>
                <ul class="list pt--8">
                    <li class="list__item">FILE-NAME에는 파일 확장자 까지 포함되어야 합니다.</li>
                </ul>

            </article>
            <article class="content-blocks">
                <header class="content-blocks__header">
                    <h3 class="content-blocks__title">결과제출</h3>
                </header>
                <template v-if="props.container">
                    <div class="task-card">
                        <header class="task-card__header">
                            <h4 div class="task-card__title">컨테이너 접속 정보</h4>
                        </header>
                        <figure class="table-task__box">
                            <figcaption class="table-task__title">SSH</figcaption>
                            <table class="table-task">
                                <tbody class="table-task__body">
                                    <tr>
                                        <th>IP</th>
                                        <td>{{ props.container.SSHIP }}</td>
                                    </tr>
                                    <tr>
                                        <th>PORT</th>
                                        <td>{{ props.container.SSHPORT }}</td>
                                    </tr>
                                    <tr>
                                        <th>ID</th>
                                        <td>{{ props.container.SSHID }}</td>
                                    </tr>
                                    <tr>
                                        <th>PW</th>
                                        <td>{{ props.container.SSHPW }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <figure class="table-task__box">
                            <figcaption class="table-task__title">JupyterLab</figcaption>
                            <table class="table-task">
                                <tbody class="table-task__body">
                                    <tr>
                                        <th>IP</th>
                                        <td>{{ props.container.JUPYTERIP }}</td>
                                    </tr>
                                    <tr>
                                        <th>PORT</th>
                                        <td>{{ props.container.JUPYTERPORT }}</td>
                                    </tr>

                                    <tr>
                                        <th>PW</th>
                                        <td>{{ props.container.JUPYTERPW }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <div class="btn-inline btn-inline__group btn-inline--right">
                            <v-btn class="btn btn-small btn-primary" @click="openContainer">접속하기</v-btn>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <ul class="list">
                        <li class="list__item">컨테이너가 할당되지 않았습니다.</li>
                    </ul>
                </template>
            </article>
            <article class="content-blocks" v-if="submitLimitDesc">
                <header class="content-blocks__header">
                    <h3 div class="content-blocks__title">제출 전 꼭 확인해주세요!</h3>
                </header>
                <div class="list list__box list__box--red">
                    <div v-html="submitLimitDesc"></div>
                </div>
            </article>
        </template>

        <!--개방형-->
        <template v-if="props.isOpen === 1 || props.category === 'A002'">
            <article class="content-group">

                <template v-if="props.category === 'A002'">
                    <div class="form-group">
                        <div class="form-group__title">제목</div>
                        <v-text-field class="common-form-control" outlined hide-details dense v-model="submitTitle"
                            placeholder="제목을 입력해주세요."></v-text-field>
                    </div>
                </template>
                <div class="form-group">
                    <div class="form-group__title">파일첨부</div>
                    <!--FileInput ref="file" v-on:change="onChangeFile" /-->
                    <v-file-input prepend-inner-icon="mdi mdi-paperclip" prepend-icon="" dense show-size
                        class="common-form-control" outlined hide-details clearable
                        :placeholder="`최대 ${props.maxSubmitSize}MB`" v-model="file" ref="resultFile" type="file"
                        @change="validateFile($event, file)"></v-file-input>
                </div>
                <div class="form-group">
                    <div class="form-group__title">제출설명</div>
                    <v-textarea id="textarea-no-resize" placeholder="제출 파일에 대한 설명을 작성하세요." rows="6" no-resize
                        class="common-textarea" outlined hide-details v-model="submitDesc"></v-textarea>
                </div>
                <div class="form-group" v-if="submitLimitDesc">
                    <h3 class="form-group__title">제출 전 꼭 확인해주세요!</h3>
                    <div class="list list__box list__box--red">
                        <div v-html="submitLimitDesc"></div>
                    </div>
                </div>
                <div class="content-group__btn-area">
                    <div class="btn-inline btn-inline--center">
                        <v-btn @click="submit" :disabled="!enableSubmit" class="btn btn-large btn-primary">
                            <strong>제출하기</strong>
                        </v-btn>
                    </div>
                </div>

            </article>
        </template>



        <!-- Progress bar -->
        <div>
            <!--v-progress-linear
                                                                                color="#343B94"
                                                                                v-model="progressPercentage"
                                                                                height="25"
                                                                                style="margin-bottom: 30px;"
                                                                                dark
                                                                            >
                                                                                <strong>{{ Math.ceil(progressPercentage) }}%</strong>
                                                                            </v-progress-linear -->
        </div>

        <article class="content-blocks">
            <header class="content-blocks__header content-blocks__header--both">
                <h3 div class="content-blocks__title">제출현황</h3>
                <span class="content-blocks__icon">
                    <button type="button" @click="refreshSubmit" class="btn-icon">
                        <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor=" #a9abae"
                            icon-name="icon-rotate"><icon-rotate /></icon-base>
                    </button>
                </span>
            </header>

            <div v-if="props.category === 'A001' || props.category === 'A003'" class="list list__box--area">
                <div class="list list__box list__box--line">
                    <ul class="list">
                        <li class="list__item">가장 높은 순위의 제출 파일이 자동으로 리더보드에 반영됩니다.</li>
                        <li class="list__item">Final 리더보드에 제출할 파일 1개를 선택할 수 있습니다. </li>
                        <li class="list__item">제출 파일을 선택하지 않으면 Public 리더보드 기준 순위가 가장 높은 제출 건이 Final 리더보드에 반영됩니다.</li>
                    </ul>
                </div>
            </div>


            <div class="content__container submit-table" v-if="props.category === 'A001' || props.category === 'A003'">
                <!-- New Table -->
                <Table v-if="!isLoading" :boardType="'submitTable'" :isPrivateExpose="privateExpose"
                    :isFinalExpose="finalExpose" :tableHeader="tableHeader" :data="tableData"
                    :evaluationParams="evaluationParameters">
                    <template v-slot:lastSubmit="{ row }">
                        <div class="tac">
                            <v-checkbox v-if="row.uploadState === 'uploaded' || row.errorCode"
                                :disabled="!row.success || status !== 4" v-model="row.lastSubmit" color="#009bfa"
                                @change="lastSubmit($event, row)"></v-checkbox>
                            <v-btn v-else class="btn btn-small btn-tertiary" :disabled="status !== 4"
                                @click="deleteSubmit(row.id)">취소</v-btn>
                        </div>
                    </template>
                    <template v-slot:filename="{ row }">
                        <div class="team">
                            <button type="button" class="team__name text-link" v-if="row.fileUrl" @click="submitDownload(row.fileUrl)">{{
                                row.filename
                            }}</button>
                            <span v-else class="team__name">
                                {{ row.filename }}
                            </span>
                            <div class="team__submit">
                                <div>
                                    <div class="edit" @click="showDescEditmodal(row)">
                                        <div class="ellipsis-1 edit-text">
                                            {{ row.content }}
                                        </div>
                                        <v-icon class="icon-edit">edit</v-icon>
                                    </div>
                                    <template>
                                        <div :id="'submit-' + tableData.indexOf(row)" class="task__submit--layer">
                                            <v-text-field v-model="row.content" label="Edit" single-line></v-text-field>
                                            <div class="btns-group">
                                                <v-btn class="btn btn-xsmall btn-tertiary"
                                                    @click="hideDescEditmodal(row)">취소</v-btn>
                                                <v-btn class="btn btn-xsmall btn-primary"
                                                    @click="saveDesc(row.id, row.content)">확인</v-btn>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                            </div>
                            <div class="team__submit">
                                <span class="team__submit__title">
                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                                        icon-name="icon-user"><icon-user /></icon-base>
                                </span>
                                <span class="team__submit__text">
                                    {{ row.nickname }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:index="{ row }">
                        <template v-if="row.practice">
                            <div></div>
                        </template>
                      <span v-if="!row.practice">{{ row.index }}</span>
                    </template>

                    <template v-slot:success="{ row }">
                        <div>
                            <div v-if="row.success" class="status status--success">
                                성공
                            </div>
                            <template v-if="!row.success && row.errorCode">
                                <div class="status--hover">
                                    <div class="status status--error">
                                        실패
                                        <div v-if="(row.errorCode === 100 || row.errorCode === 101)">
                                            <div class="tool-tip">
                                                {{ row.error }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- 채점중 -->
                            <template v-if="!row.success && !row.errorCode">
                                <div v-if="row.uploadState === 'uploading'" class="status status--ing">업로드중</div>
                                <div v-if="row.uploadState === 'uploaded'" class="status status--ing">채점중</div>
                            </template>
                            <!-- 기준미달 -->
                            <template v-if="!row.success && row.errorCode === 10">
                                <div class="status--hover">
                                    <div class="status status--warin">
                                        기준미달
                                        <div class="tool-tip">
                                            {{ row.error }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </Table>
                <!--div id="sample-table"></div-->
            </div>
            <!-- 아이디어 경진대회 -->
            <div class="content__container submit-table" v-else>

                <Table v-if="!isLoading" :boardType="'submitTable'" :tableHeader="tableHeader" :data="tableData">
                    <template v-slot:filename="{ row }">
                        <div class="submit-table-description">
                            <a class="team__name text-link" @click="submitDownload(row.fileUrl)">{{ row.filename }}</a>
                            <div class="team__submit__title">
                                <v-edit-dialog :return-value.sync="row.content" large @save="saveDesc(row.id, row.content)"
                                    @cancel="cancel" @open="open" @close="close">
                                    <div class="edit">
                                        <div class="ellipsis-1">
                                            {{ row.content }}
                                        </div>
                                        <v-icon small>edit</v-icon>
                                    </div>

                                    <template v-slot:input>
                                        <v-text-field v-model="row.content" label="Edit" single-line></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </div>
                            <div class="team__submit">
                                <span class="team__submit__title">
                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                                        icon-name="icon-user"><icon-user /></icon-base>
                                </span>
                                <span class="team__submit__text">
                                    {{ row.nickname }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:cancelSubmit="{ row }">
                        <v-btn class="btn-secondary btn-xsmall" :disabled="status !== 4"
                            @click="deleteSubmit(row.id)">삭제</v-btn>
                    </template>
                </Table>

            </div>
        </article>
        <EventPopup :visible="showEvent" @dialogClose="showEvent = false" />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import axios from "axios";
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import modalControler from '@/helper/modalControler';
import Table from '@/components/Table'
import FileInput from "@/components/competition/FileInput";

import EventPopup from '@/components/dialog/Event'


export default {
    name: 'TaskSubmit',
    components: {
        Table,
        EventPopup,
        // FileInput,
    },
    props: {
        status: {
            required: false,
        },
        isOpen: {
            required: false,
        },
        container: {
            required: false,
        },
        maxSubmitSize: {
            required: false,
        },
        maxSubmitTime: {
            required: false,
        },
        maxSubmitTerm: {
            required: false,
        },
        category: {
            required: false,
        },
        fileExtGroupIdList: {
            required: false
        },
        submitLimitDesc: {
            required: false
        },
        privateExpose: {
            type: Boolean,
            required: false,
        },
        finalExpose: {
            type: Boolean,
            required: false,
        },
        limitFileCount: {
            required: false
        },
        isJoin: {
            required: false
        },
        agreed: {
            type: Boolean,
            required: false
        },
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const resultFile = ref(null)
        const vTextField = ref([])
        const state = reactive({
            assignmentId: route.params.taskId,
            submitList: [],
            submitDesc: '',
            file: null,
            multipartToken: null,
            // chunkSize: 10 000000,              // byte (10MB)
            // chunkSize: 100000000,        // byte (100MB)
            chunkSize: 5000000000,          // byte (5GB)
            fileUploadToken: null,
            bulkUploadPartNumber: 0,
            bulkUploadParts: [],
            s3UploadParts: [],
            bulkUploadFailed: false,
            uploading: false,
            progressPercentage: 0,
            bulkStandardSize: 500000000,     // byte (500MB)
            maxAllowedFileSize: 0, //props.maxSubmitSize * 1048_576, // byte (MB to Byte)
            maxChunkSize: 5000000000,       // byte (5GB)
            maxPartNumbers: 10,             // 10 part (10 * 5GB => 50GB )
            interval: null,
            profile_rules: [
                value => !value || value.size < props.maxSubmitSize * 1048_576 || `제출 파일 크기는 ${props.maxSubmitSize}MB를 초과할 수 없습니다.`,
            ],
            refreshTime: 5000,      // 5 sec
            maxAllowedFileName: 100,
            maxAllowedSubmitTitle: 50,
            fileExtList: [],
            allowedExtList: [],
            submitTitle: '',
            evaluationParameters: [],
            tableData: [
                // {id:1, file:"Oli Bob", score1:"0.8393434", score2:"0.5112312", isSuccess: true, submitDate:"14/05/2022", lastSubmit: false},
                // {id:2, file:"Mary May", score1:"0.9302349", score2:"0.4323423", submitDate:"14/05/1982", lastSubmit: false},
                // {id:3, file:"Christine Lobowski", score1:"0.4738249", score2:"0.7623214", submitDate:"22/05/1982", lastSubmit: false},
                // {id:4, file:"Brendon Philips", score1:"0.6493249", score2:"0.8875482", submitDate:"01/08/1980", lastSubmit: false},
                // {id:5, file:"Margret Marmajuke", score1:"0.7695945", score2:"0.9948394", submitDate:"31/01/1999", lastSubmit: true},
            ],
            tableHeader: [
                { title: "#", value: 'index', },
                { title: "최종선택", value: 'lastSubmit', },
                { title: "제출 파일", value: 'filename', width: 300, },
                // {title: "Public Score", value: 'publicScore', width: 200, },
                // {title: "Private Score", value: 'privateScore', width: 200, },
                // {title: "Final Score", value: 'finalScore', width: 200, },
                { title: "성공여부", value: 'success', },
                { title: "제출시간", value: 'createDtm', width: '400', }
            ],
            insertTableIndex: 3,
            isLoading: false,
            showEvent: false,
        });

        onMounted(() => {
            // TODO : api success 안에 넣을 것
            if (props.category === 'A002') {
                getFileExt()
            }
            getResultSubmitList()
        });

        const apiURL = computed(() => {
            return process.env.VUE_APP_API_HOST_URL
        })

        const maxAllowedFileSize = computed(() => {
            state.maxAllowedFileSize = props.maxSubmitSize
            return props.maxSubmitSize * 1048_576
        })

        const maxAllowedFileName = computed(() => {
            return 100
        })

        const authToken = computed(() => {
            return localStorage.getItem('aiconnect-user-token')

        })

        onUnmounted(() => {
            clearInterval(state.interval)
        });

        const enableSubmit = computed(() => {
            if (state.submitDesc !== '' && state.file && state.file.length !== 0)
                return true
            else
                return false
        })

        const getFileExt = () => {
            state.allowedExtList = []
            TaskApi.getFileExt().then(response => {
                state.fileExtList = response.data.result.filter(v => {
                    if (props.fileExtGroupIdList.includes(v.id)) {
                        v.items.forEach(ext => {
                            state.allowedExtList.push('.' + ext.ext)
                        })
                        return v
                    }
                })
            })
        }

        const getResultSubmitList = () => {
            state.isLoading = true
            let params = {
                assignmentId: route.params.taskId
            }
            // modalControler.ShowLoading()
            TaskApi.getResultSubmitList(utils.makeParams(params)).then(response => {
                if (!response.data.error) {

                    state.submitList = response.data.result

                    if (props.category === 'A001' || props.category === 'A003') {
                        state.tableHeader = [
                            { title: "#", value: 'index', },
                            { title: "최종선택", value: 'lastSubmit', },
                            { title: "제출 파일", value: 'filename', width: 300, },
                            // {title: "Public Score", value: 'publicScore', width: 200, },
                            // {title: "Private Score", value: 'privateScore', width: 200, },
                            // {title: "Final Score", value: 'finalScore', width: 200, },
                            { title: "성공여부", value: 'success', },
                            { title: "제출시간", value: 'createDtm', width: 200, },
                        ]

                        state.evaluationParameters = response.data.metadata
                        for (var i = state.evaluationParameters.length - 1; i >= 0; i--) {
                            state.tableHeader.splice(state.insertTableIndex, 0, {
                                title: state.evaluationParameters[i].name,
                                value: 'scoreList-' + i,
                                width: 250,
                                submitScore: true,
                            })
                        }
                        if (state.submitList.length) {
                            // 평가지표 반영

                            state.submitList.sort((a, b) => b.index - a.index)
                            // intervalResultSubmit()
                            state.submitList.map(row => {
                                for (var i = 0; i < row.scores.length; i++) {
                                    row['scoreList-' + i] = row.scores[i]
                                }
                            })
                        }
                    } else if (props.category === 'A002') {
                        state.tableHeader = [
                            { title: "#", value: 'index', },
                            { title: "제출 파일", value: 'filename', width: 300, },
                            { title: "제목", value: 'title', width: 300, },
                            { title: "제출일시", value: 'createDtm', width: 200, },
                            { title: "제출취소", value: 'cancelSubmit', width: 200, },
                        ]
                    }


                    state.tableData = state.submitList
                    state.isLoading = false

                } else {
                    // modalControler.ShowErrorToast(response.data.error_detail)
                }
            }).catch(err => {
                console.log(err)
                // modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                // modalControler.HideLoading()
            })
        }

        const lastSubmit = ($event, cell) => {
            let item = cell
            if (!item.success) {
                modalControler.ShowWarningToast('성공한 제출만 최종선택이 가능합니다.')
                return
            }
            if (typeof (state.assignmentId) === 'undefined') {
                modalControler.ShowWarningToast('과제번호를 확인할 수 없습니다.')
                location.reload()
                return
            }

            let params = {
                submitId: item.id,
                isLast: $event
            }
            TaskApi.lastSubmit(state.assignmentId, params).then(response => {
                if (response.data.error_code === 7500) {
                    modalControler.ShowErrorToast(response.data.error_detail)
                } else {
                    if ($event) {
                        // cell.
                        modalControler.ShowSuccessToast('최종선택 되었습니다.')
                    }
                    else {
                        // cell.getRow()
                        modalControler.ShowSuccessToast('선택취소 되었습니다.')
                    }
                }
                getResultSubmitList()
            }).catch(err => {
                console.log(err)
                // modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                // modalControler.HideLoading()
            })

        }

        const submitDownload = (fileUrl) => {
            location.href = fileUrl
        }

        const openContainer = () => {
            if (props.container) {
                if (props.container.JUPYTERIP !== "" && props.container.JUPYTERPORT !== "") {
                    utils.openContainer(props.container.JUPYTERIP, props.container.JUPYTERPORT)
                } else if (props.container.JUPYTERIP === "" && props.container.JUPYTERPORT === "" && props.container.SSHIP !== "" && props.container.SSHPORT !== "") {
                    modalControler.ShowModalDialog('컨테이너 접속', `터미널에서 아래 명령어를 실행해주세요<br> ssh root@${props.container.SSHIP} -p ${props.container.SSHPORT}`, (val) => { });
                }
            } else {
                modalControler.ShowErrorToast('컨테이너가 할당되지 않았습니다.')
            }
        }

        const submit = async () => {
            if (props.category === 'A002') {
                if (state.submitTitle === '') {
                    modalControler.ShowWarningToast('파일제목을 입력해주세요.')
                    return
                }

                if (state.submitTitle.length > state.maxAllowedSubmitTitle) {
                    modalControler.ShowWarningToast(`제출 파일명은 최대 ${state.maxAllowedSubmitTitle}자를 초과할 수 없습니다.`)
                    return
                }
            }

            if ((props.status !== 4 || !(props.category === 'A003' && props.status === 6)) && !props.agreed) {
                modalControler.ShowErrorToast("대회가 진행중일 때만 결과를 제출할 수 있습니다.")
                return
            }

            if (state.file.length === 0) {
                modalControler.ShowWarningToast('파일을 첨부해주세요.')
                return
            }
            if (state.file.size > maxAllowedFileSize.value) {
                modalControler.ShowWarningToast('제출 파일사이즈 제한을 초과했습니다.')
                resultFile.value = null
                state.file = []
                // location.reload()
                return
            }
            await bulkUpload()
            getResultSubmitList()
            // intervalResultSubmit()

            // 제출 form 초기화
            state.submitDesc = ''
            resultFile.value = null
            // state.file = []
        }

        const validate = (item) => {
            if (!item.issuccess || item.errorcode !== 0) {
                return true
            }
        }

        const validateFile = (e, data) => {
            if (data && data.name.length > state.maxAllowedFileName) {
                state.file = null
                resultFile.value = null
                modalControler.ShowErrorToast(`제출 파일명은 최대 ${state.maxAllowedFileName}자를 초과할 수 없습니다.`)
                return
            }
            if (data && data.size > maxAllowedFileSize.value) {
                state.file = []
                resultFile.value = null
                modalControler.ShowErrorToast(`제출 파일 크기는 ${state.maxAllowedFileSize}MB를  초과할 수 없습니다.`)
                return
            }
            // 파일 형식 검사
            if (props.category === 'A002') {
                if (data && state.allowedExtList.length) {
                    let dataExt = data.name.match(/\.[0-9a-z]+$/i)[0]       // file name 에서 확장자 get
                    if (!state.allowedExtList.includes(dataExt)) {
                        state.file = null
                        resultFile.value = null
                        modalControler.ShowErrorToast(`제출파일 형식을 다시 확인해 주세요.`)
                        return
                    }
                }
            }

        }

        const intervalResultSubmit = () => {
            clearInterval(state.interval)
            state.interval = setInterval(() => {
                if (state.submitList.find(item => !item.issuccess && !item.errorcode)) {
                    getResultSubmitList()
                } else {
                    clearInterval(state.interval)
                }
            }, state.refreshTime)
        }

        const refreshSubmit = () => {
            getResultSubmitList()
        }

        const saveDesc = (submitId, content) => {
            const formdata = new FormData()
            formdata.append('content', new Blob([content], { type: 'application/json' }))
            TaskApi.updateSubmitDesc(submitId, state.assignmentId, formdata).then(response => {
                if (!response.data.error) {
                    getResultSubmitList()
                } else {
                    modalControler.ShowErrorToast('수정에 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
            })
        }

        const cancel = () => { }
        const open = () => { }
        const close = () => { }

        const bulkUpload = async () => {
            let resultFlag = false
            modalControler.ShowLoading()

            const partResult = makeMultipartFiles()
            if (!partResult) {
                modalControler.HideLoading();
                return;
            }

            let params1 = {
                assignmentId: state.assignmentId,
                filename: state.file.name,
                content: state.submitDesc,
                title: state.submitTitle
            }

            // step1. send 'create' request
            const createResponse = await TaskApi.multipartSubmitCreate(params1)
            if (!createResponse.data.error) {
                state.fileUploadToken = createResponse.data.result.token
            } else {
                // 업로드 error alert
                // modalControler.ShowErrorToast(`업로드 실패: ${createResponse.data.error_detail}`)
                if (createResponse.data.error_code === 7500) {
                    modalControler.ShowErrorToast(createResponse.data.error_detail)
                    // modalControler.ShowModalDialog('업로드 실패', `업로드 도중 에러가 발생했습니다. 다시 시도해주세요. 
                    //             <br/> 해당 에러가 계속되면 문의게시판에 접수해주시기 바랍니다.`, '', '확인', (val) => { })
                }
                modalControler.HideLoading()
                return
            }

            // step2. send 'part' request
            let partNum = 1
            state.s3UploadParts = []
            for await (const file of state.bulkUploadParts) {
                let params2 = {
                    token: state.fileUploadToken,
                    partNumber: partNum
                }
                const partResponse = await TaskApi.multipartSubmitPart(params2)
                if (!partResponse.data.error) {
                    const presignedURL = partResponse.data.result.uploadUrl
                    const s3Response = await s3Upload(presignedURL, file)
                    if (s3Response.status === 200) {
                        state.s3UploadParts.push({
                            partNumber: partNum,
                            etag: s3Response.headers.get('ETag').replace(/"/g, '')
                        })

                    }
                    ++partNum

                } else {
                    // error response 시, loop out / return
                    state.bulkUploadFailed = true
                    // modalControler.ShowErrorToast(`업로드 실패: ${partResponse.data.error_detail}`)
                    if (partResponse.data.error_code === 7500) {
                        modalControler.ShowModalDialog('업로드 실패', `업로드 도중 에러가 발생했습니다. 다시 시도해주세요. 
                                    <br/> 해당 에러가 계속되면 문의게시판에 접수해주시기 바랍니다.`, '', '확인', (val) => { })
                    }
                    break
                }
            }

            if (state.bulkUploadFailed) {
                modalControler.ShowModalDialog('업로드 실패', `업로드 도중 에러가 발생했습니다. 다시 시도해주세요. 
                            <br/> 해당 에러가 계속되면 문의게시판에 접수해주시기 바랍니다.`, '', '확인', (val) => { })
                modalControler.HideLoading()
                return
            }

            // step3. send 'complete' request
            let params3 = {
                token: state.fileUploadToken,
                parts: state.s3UploadParts
            }
            const completeResponse = await TaskApi.multipartSubmitComplete(params3)
            if (!completeResponse.data.error) {
                if (completeResponse.data.result.s3_uploaded === true) {
                    if (props.category === 'A001' || props.category === 'A003') {
                        if (completeResponse.data.result.score_server_executed) {
                            modalControler.ShowSuccessToast('제출이 완료되었습니다.')
                        } else {
                            modalControler.ShowErrorToast('채점에 실패했습니다.')
                        }
                    } else {
                        modalControler.ShowSuccessToast('제출이 완료되었습니다.')
                    }

                    // 연습제출 이벤트 팝업 창
                    if (props.category === 'A003') {
                        state.showEvent = true
                    }

                    resultFlag = true
                } else {
                    if (!completeResponse.data.result.s3_uploaded) {
                        modalControler.ShowModalDialog('업로드 실패', `업로드 도중 에러가 발생했습니다. 다시 시도해주세요. 
                                    <br/> 해당 에러가 계속되면 문의게시판에 접수해주시기 바랍니다.`, '', '확인', (val) => { })
                    }
                    // if (props.category === 'A001') {
                    //     if (!completeResponse.data.result.score_server_executed) {
                    //         modalControler.ShowErrorToast('채점에 실패했습니다.')
                    //     }
                    // } else {
                    //     modalControler.ShowErrorToast('제출에 실패했습니다.')
                    // }
                }
            } else {
                if (completeResponse.data.error_code === 7500) {
                    // modalControler.ShowErrorToast(`업로드 실패: ${completeResponse.data.error_detail}`)
                    modalControler.ShowModalDialog('업로드 실패', `업로드 도중 에러가 발생했습니다. 다시 시도해주세요. 
                                <br/> 해당 에러가 계속되면 문의게시판에 접수해주시기 바랍니다.`, '', '확인', (val) => { })
                }

            }
            modalControler.HideLoading()
            return resultFlag
        }

        // File을 chunk 단위로 쪼갠다
        const makeMultipartFiles = () => {
            state.bulkUploadParts = [];

            for (var i = 0; i < state.file.size; i += state.chunkSize) {
                const chunk = state.file.slice(i, i + state.chunkSize + 1)
                state.bulkUploadParts.push(chunk)
            }

            if (state.bulkUploadParts.length > state.maxPartNumbers) {
                // modalControler.ShowWarningToast('최대 허용 파트 수를 초과했습니다.')
                modalControler.ShowModalDialog('업로드 실패', '업로드 도중 에러가 발생했습니다. 파일 크기를 확인해주세요.', '', '확인', (val) => { })
                return false
            }

            return true;
        }

        // S3 bucket 에 업로드
        const s3Upload = async (presignedURL, slicedFile) => {
            const response = await fetch(
                new Request(presignedURL, {
                    method: 'PUT',
                    body: slicedFile,
                    headers: new Headers({
                        "Content-Type": "application/octet-stream"
                    }),
                })
            )

            return response
        }

        const deleteSubmit = (submitId) => {
            let msg = `해당 제출내역을 삭제하시겠습니까?`
            modalControler.ShowModalDialog('제출 삭제', msg, '취소', '확인', (val) => {
                if (val) {
                    TaskApi.deleteSubmit(submitId, state.assignmentId).then(response => {
                        modalControler.ShowSuccessToast('삭제 되었습니다.')
                        if (!response.data.error) {
                            getResultSubmitList();
                        }
                    }).catch(err => {
                        modalControler.ShowErrorToast('삭제에 실패했습니다.')
                    })
                }
            })
        }

        const copySubmitCmd = () => {
            try {
                const cmd = `curl -X POST '${apiURL.value}/assignment/${state.assignmentId}/results' --header 'Authorization: Bearer ${authToken.value}'  --form 'file=@"FILE-PATH/FILE-NAME"'`
                const $textarea = document.createElement('textarea')
                document.body.appendChild($textarea)
                $textarea.value = cmd
                $textarea.select()

                document.execCommand('copy')
                document.body.removeChild($textarea)
                modalControler.ShowSuccessToast('클립보드에 복사되었습니다.')
            } catch {
                modalControler.ShowErrorToast('복사에 실패했습니다.')
            }
        }

        const hideDescEditmodal = (row) => {
            document.getElementById('submit-' + state.tableData.indexOf(row)).style.display = 'none'
        }

        const showDescEditmodal = (row) => {
            document.getElementById('submit-' + state.tableData.indexOf(row)).style.display = 'block'
        }

        return {
            ...toRefs(state),
            route,
            router,
            props,
            submit,
            validate,
            resultFile,
            lastSubmit,
            enableSubmit,
            refreshSubmit,
            submitDownload,
            validateFile,
            maxAllowedFileSize,
            openContainer,
            deleteSubmit,
            cancel,
            open,
            close,
            saveDesc,
            authToken,
            apiURL,
            copySubmitCmd,
            vTextField,
            showDescEditmodal,
            hideDescEditmodal,
        }
    }
}
</script>

