// pages
import Index from '@/pages/main/Index'
import CompetitionTaskListPage from './CompetitionTaskList'
import CompetitionRootPage from './CompetitionRoot'
import CompetitionListPage from './CompetitionList'
import CompetitionDetailPage from './CompetitionDetail'
import CompetitionApplyPage from './CompetitionApply'
import CompetitionNews from './CompetitionNews'
import CompetitionNewsDetail from './CompetitionNewsDetail'
import CompetitionQuestion from './CompetitionQuestion'
import CompetitionQuestionDetail from './CompetitionQuestionDetail'
import CompetitionQuestionEdit from './CompetitionQuestionEdit'
import CompetitionTeam from './CompetitionTeam'
import CompetitionAgreement from './CompetitionAgreement'
import TaskListPage from './TaskList'
import ProfilePage from '../profile/competition/ProfilePage'
import ProfileEditPage from '../profile/competition/ProfileEditPage'
import PasswordEditPage from '../profile/competition/PasswordEditPage'
import PasswordChkPage from '../profile/competition/PasswordChkPage'
import PasswordRegisterPage from '../profile/competition/PasswordRegisterPage'
// 
import Service from '@/pages/policy/Service';
import Privacy from '@/pages/policy/Privacy';
import PrintPage from '@/pages/tmp/PrintPage';
import HelpIndex from '@/pages/help/HelpIndex';
import HelpDetail from '@/pages/help/HelpDetail';
import InquiryForm from '@/pages/help/InquiryForm';
import InquiryDetail from '@/pages/help/InquiryDetail';
import SearchResult from '@/pages/help/SearchResult';
import Activities from '../profile/competition/Activities';

const faqSubRoutes = [
    // {
    //     path: 'main',
    //     // name: 
    // },
    // {
    //     path: 'detail/:faqId',
    //     name: HelpDetail.name,
    //     components: { faq: HelpDetail },
    //     meta: {
    //         anonymousCallable: true,           
    //          GNBType: 'competition'
    //     }
    // },    
    // {
    //     path: 'inquiry',
    //     name: InquiryForm.name,
    //     component: InquiryForm,
    //     meta: {

    //         GNBType: 'competition'
    //     }
    // },
    // {
    //     path: 'inquiry/detail/:inquiryId',
    //     name: InquiryDetail.name,
    //     component: InquiryDetail,
    //     meta: {
    //         GNBType: 'competition'
    //     }
    // }    
]

const competitionSubRoutes = [
    {
        path: '/',
        title: '메인',
        name: Index.name,
        component: Index,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },

    {
        path: 'task/list',
        title: '과제리스트',
        name: CompetitionTaskListPage.name,
        component: CompetitionTaskListPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'list',
        title: '대회리스트',
        name: CompetitionListPage.name,
        component: CompetitionListPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId',
        title: '대회페이지',
        name: CompetitionDetailPage.name,
        component: CompetitionDetailPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId/apply',
        title: '대회참가신청',
        name: CompetitionApplyPage.name,
        component: CompetitionApplyPage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId/task_list',
        title: '과제목록',
        name: TaskListPage.name,
        component: TaskListPage,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId/qna',
        title: '대회문의',
        name: CompetitionQuestion.name,
        component: CompetitionQuestion,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId/qna/:qnaId',
        title: '대회문의 상세',
        name: CompetitionQuestionDetail.name,
        component: CompetitionQuestionDetail,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'detail/:competitionId/qna/:qnaId/edit',
        // path: 'detail/:competitionId/qna/edit',
        title: '대회문의 편집',
        name: CompetitionQuestionEdit.name,
        component: CompetitionQuestionEdit,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    // {
    //     path: 'detail/:competitionId/qna/:qnaId/update',
    //     title: '대회문의 편집',
    //     name: CompetitionQuestionEdit.name,
    //     component: CompetitionQuestionEdit,
    //     meta: {
    //         anonymousCallable: true,
    //         GNBType: 'competitionDetail'
    //     }        
    // },
    {
        path: 'detail/:competitionId/team',
        title: '팀 구성',
        name: CompetitionTeam.name,
        component: CompetitionTeam,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }

    },
    {
        path: 'detail/:competitionId/agreement',
        title: '대회 규정',
        name: 'CompetitionAgreement',
        component: CompetitionAgreement,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    // 프로필 관련

    {
        path: 'profile',
        title: '프로필',
        name: 'CompetitionListProfile',
        component: ProfilePage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'profile/password-edit',
        title: '비밀번호변경',
        name: 'CompetitionListPasswordEdit',
        component: PasswordEditPage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'profile/password-chk',
        title: '비밀번호확인',
        name: 'CompetitionListPasswrodChk',
        component: PasswordChkPage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'profile/password-regist',
        title: '비밀번호등록',
        name: 'CompetitionListPasswordRegist',
        component: PasswordRegisterPage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'profile/edit',
        title: '프로필변경',
        name: 'CompetitionListProfileEdit',
        component: ProfileEditPage,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
    {
        path: 'news',
        title: '뉴스',
        name: 'CompetitionNews',
        component: CompetitionNews,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'news/:newsId',
        title: '뉴스 상세',
        name: 'CompetitionNewsDetail',
        component: CompetitionNewsDetail,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'policy/service',
        name: Service.name,
        component: Service,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'policy/privacy',
        name: Privacy.name,
        component: Privacy,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'certification',
        name: PrintPage.name,
        component: PrintPage,
        meta: {
            anonymousCallable: false,
        }
    },
    // FAQ pages
    {
        path: 'faq',
        name: HelpIndex.name,
        component: HelpIndex,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'faq/detail/:manualId',
        name: HelpDetail.name,
        component: HelpDetail,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'inquiry',
        name: InquiryForm.name,
        component: InquiryForm,
        meta: {

            GNBType: 'competition'
        }
    },
    {
        path: 'inquiry/detail/:inquiryId',
        name: InquiryDetail.name,
        component: InquiryDetail,
        meta: {
            GNBType: 'competition'
        }
    },
    {
        path: 'faq/search',
        name: SearchResult.name,
        component: SearchResult,
        meta: {
            anonymousCallable: true,
            GNBType: 'competition'
        }
    },
    {
        path: 'profile/activities',
        title: 'Activities',
        name: 'ActivitiesPage',
        component: Activities,
        meta: {
            anonymousCallable: false,
            GNBType: 'competition'
        }
    },
]


export default {
    path: '/competition',
    title: '경진대회',
    name: CompetitionRootPage.name,
    component: CompetitionRootPage,
    children: competitionSubRoutes,
    redirect: {
        name: CompetitionListPage.name
    },
    meta: {
        anonymousCallable: true,
        GNBType: 'competition'
    }
}