<template>
  <div>


    <section class="content-section">
      <div class="content-inner">
        <header class="content-section__header">
          <h2 class="content-section__title">자주 묻는 질문</h2>
        </header>
        <div class="common-accordion accordion" id="accordionExample3">
          <template>
            <div class="common-accordion__item accordion-item" v-for="(faq, idx) in faqList" :key="'faq-' + idx">
              <header class="common-accordion__header accordion-header" :id="'faq-' + idx">
                <button class="common-accordion__button accordion-button  collapsed" type="button"
                  data-bs-toggle="collapse" :data-bs-target="'#collapse-' + idx" aria-expanded="true"
                  :aria-controls="'collapse-' + idx">
                  <span class="icon icon--q"><img src="../../assets/images/icon-q.svg" /></span>{{ faq.title }}
                </button>
              </header>
              <div class="accordion-collapse collapse" :id="'collapse-' + idx" :aria-labelledby="'heading-' + idx"
                data-bs-parent="#accordionExample3">
                <template>
                  <div class="common-accordion__body accordion-body">
                    <div class="common-accordion__content">
                      <div class="d-flex">
                        <span class="icon icon--a"><img src="../../assets/images/icon-a.svg" /></span><span>{{
                          faq.content
                        }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="content-section--padding bg--primary">
      <div class="content-inner">
        <div class="inquiry-box">
          <div class="icon">
            <img src="../../assets/images/icon-inquiry.png" />
          </div>
          <p class="text">원하는 내용을 찾지 못했습니까?</p>
          <div class="btn-group">
            <v-btn class="btn btn-medium btn-secondary" @click="toFAQWrite">
              <div class="icon-left">
                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                  icon-name="icon-pen"><icon-pen /></icon-base>
              </div>문의 작성
            </v-btn>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import SupportApi from '@/lib/api/Support.js'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';

export default {
  name: 'FAQ',
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const state = reactive({
      faqList: [],

    })

    onMounted(() => {
      getFAQList()
    })

    const getFAQList = () => {
      let params = {
        pageNo: 1,
        pageSize: 10
      }
      SupportApi.getFAQList(utils.makeParams(params)).then(response => {
        if (response.data.code === 0) {
          state.faqList = response.data.result
        }
      })
    }

    const toFAQWrite = () => {
      router.push({
        name: 'InquiryForm'
      })
    }

    return {
      utils,
      ...toRefs(state),
      toFAQWrite,
    }
  }
}
</script>