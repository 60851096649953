<template>
    <div class="password-wrap contents-wrap">
        <PasswordEdit />
    </div>
</template>

<script>

import PasswordEdit from '@/components/user/PasswordEdit'

export default {
    components: {
        PasswordEdit,
    }
}
</script>