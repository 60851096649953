<template>
  <article class="section-2__content">
    <BoardListView
        boardType='LeagueCommunity'
        @doSearch='getCommunity'
        @onlyMyQnA='getCommunity'
        :qnaTotalCount="qnaTotalCount"
        :qnaList="qnaList" />
  </article>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import BoardListView from '@/components/BoardListView'
import CommunityAPI from '@/lib/api/Community'
import modalControler from '@/helper/modalControler';
import utils from '@/helper/utils';


export default {
  name: 'LeagueCommunity',
  components: {
    BoardListView,
  },
  setup(props, context) {
    const route = context.root.$route
    const state = reactive({
      qnaList: [],
      qnaTotalCount: 0,
      beforeSearchKeyword: null
    });

    const getCommunity = (options, searchKeyword, showNotice, clickedMoreBtn=false) => {
      if (searchKeyword !== '') {

          if (state.beforeSearchKeyword !== searchKeyword) {
              state.qnaList = []
              options.page = 1
              options.itemsPerPage = 21
              state.beforeSearchKeyword = searchKeyword
          } else {
              state.qnaList = []
              options.page = 1
              options.itemsPerPage = 99999
          }

      } else {
          if (!clickedMoreBtn) {
              state.beforeSearchKeyword = null
              options.page = 1
              options.itemsPerPage = 21
              state.qnaList = []
          }
      }      
      let keyword = searchKeyword ? utils.getPage(Object.assign(options, { searchText: searchKeyword, showNotice: showNotice })) :
          utils.getPage(Object.assign(options, { showNotice: showNotice }))
      modalControler.ShowLoading()
      CommunityAPI.getLeagueCommunityList(route.params.leagueId, keyword).then(response => {
        if (!response.data.error) {
          state.qnaList = state.qnaList.concat(response.data.data)
          state.qnaTotalCount = response.data.totalCount
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

    return {
      ...toRefs(state),
      getCommunity,
    }
  }
}
</script>

<style scoped lang="scss">
</style>