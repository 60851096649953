<template>
    <v-app>
        <main class="login">
            <form>
                <div class="login__inner">
                    <header class="login__header">
                        <button type="button" class="header-logo" @click="$router.push({ path: '/' })">
                            <img src="@/assets/images/img-logo.svg" alt="ai connect" />
                        </button>
                    </header>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">닉네임</div>
                        <v-text-field class="common-form-control" outlined placeholder="특수문자, 공백없이 최소 4~20자" autofocus
                            :error-messages="errorStatus.nickErrMsg" v-model="form.nickname"
                            @blur="checkNickName(form.nickname)" @keyup="onKeyup($event, 'nickname')">
                            <template v-slot:append>
                                <v-btn class="btn-small btn-primary" v-if="isNickNameChecked === false"
                                    @click="nicknameDuplicationCheck(form.nickname)"
                                    :disabled="(errorStatus.nickErrMsg !== '') ? true : false">
                                    중복체크
                                </v-btn>
                                <span v-else class="confirm-chk">
                                    <b-icon icon="check-circle-fill"></b-icon>
                                </span>
                            </template>
                        </v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">이메일</div>
                        <v-text-field class="common-form-control" placeholder="example@email.com" outlined
                            :error-messages="errorStatus.emailErrMsg" v-model="form.info.email"
                            @blur="checkEmail(form.info.email)" @keyup="onKeyup($event, 'email')">

                            <template v-slot:append>
                                <v-btn class="btn-small btn-primary" v-if="isEmailChecked === false"
                                    @click="emailDuplicationCheck(form.info.email)"
                                    :disabled="(errorStatus.emailErrMsg !== '') ? true : false">
                                    중복체크
                                </v-btn>

                                <span v-else class="confirm-chk">
                                    <b-icon icon="check-circle-fill"></b-icon>
                                </span>
                            </template>
                        </v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">이름</div>
                        <v-text-field class="common-form-control" outlined placeholder="특수문자, 공백없이 최소 2~20자"
                            :error-messages="errorStatus.nameErrMsg" v-model="form.name" @blur="checkName(form.name)"
                            @keyup="onKeyup($event, 'name')"></v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">비밀번호</div>
                        <v-text-field class="common-form-control" outlined placeholder="영문, 숫자, 특수문자 포함 최소 8~20자"
                            :type="pwShow1 ? 'text' : 'password'" :append-icon="pwShow1 ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="pwShow1 = !pwShow1" :error-messages="errorStatus.passwordErrMsg"
                            v-model="form.info.password" @blur="checkPassword(form.info.password)"
                            @keyup="onKeyup($event, 'password')"></v-text-field>
                    </div>
                    <div class="form-group form-group__detail">
                        <div class="form-group__title">비밀번호 재입력</div>
                        <v-text-field class="common-form-control" outlined placeholder="영문, 숫자, 특수문자 포함 최소 8~20자"
                            :type="pwShow2 ? 'text' : 'password'" :append-icon="pwShow2 ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="pwShow2 = !pwShow2" :error-messages="errorStatus.repasswordErrMsg"
                            v-model="form.repassword" @blur="checkRePassword(form.repassword)"
                            @keyup="onKeyup($event, 'repassword')"></v-text-field>
                    </div>
                    <div class="agree">
                        <div class="agree__item agree__item--all">
                            <v-checkbox class="common-checkbox" label="전체동의" hide-details color="#009bfa"
                                v-model="checkAllAgreement" @change="checkAll">
                            </v-checkbox>
                        </div>
                        <div class="agree__item agree__item--both">
                            <v-checkbox class="common-checkbox" label="이용약관 동의" hide-details color="#009bfa"
                                v-model="form.agreePlatformTerms" @change="checkTerms">
                            </v-checkbox>
                            <button class="btn-text--gray btn-xsmall" type="button" @click="showServicePolicy">약관보기</button>
                        </div>
                        <div class="agree__item agree__item--both">
                            <v-checkbox class="common-checkbox" label="개인정보처리방침 동의" hide-details color="#009bfa"
                                v-model="form.agreePlatformUseTerms" @change="checkTerms">
                            </v-checkbox>
                            <button class="btn-text--gray btn-xsmall" type="button" @click="showPrivacyPolicy">약관보기</button>
                        </div>
                        <div class="btn-inline btn-inline--section">
                            <v-btn class="btn-primary btn-large btn-full" :disabled="enableSubmit" @click="signup">
                                <strong>회원가입</strong>
                            </v-btn>
                        </div>
                        <div class="login-link">
                            <div class="login-link__item">
                                <button type="button" class="btn-text--gray"
                                    @click.prevent="$router.push({ name: 'SignIn' })">
                                    로그인하기
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="company-btns">
                        <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showMNC">
                            회사소개
                        </button>
                        <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showServicePolicy">
                            이용약관
                        </button>
                        <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showPrivacyPolicy">
                            개인정보처리방침
                        </button>
                    </div>
                </div>
            </form>
        </main>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs,
} from '@vue/composition-api';
import moment from 'moment'
import { JSEncrypt } from 'jsencrypt'
import { BIcon } from 'bootstrap-vue'
import utils from '@/helper/utils'
import validator from "@/config/formValidator"
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import SocialAPI from '@/lib/api/social';


export default {
    name: 'SignupEmail',
    components: {
        BIcon,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            form: {
                nickname: '',
                name: '',
                info: {
                    email: '',
                    password: ''
                },
                agreePlatformTerms: false,
                agreePlatformUseTerms: false,
                repassword: ''
            },
            errorStatus: {
                nickErrMsg: '',
                nameErrMsg: '',
                emailErrMsg: '',
                passwordErrMsg: '',
                repasswordErrMsg: '',
            },
            options: {
                passwordShow: false,
                repasswordShow: false
            },
            dialog: {
                ruleDialog: false,

            },
            checkAllAgreement: false,
            isNickNameChecked: false,
            checkedNickName: '',
            isEmailChecked: false,
            checkedEmail: '',
            pwShow1: false,
            pwShow2: false,

        });

        // blur 시 validation
        const checkNickName = (nickname) => {
            state.errorStatus.nickErrMsg = validator.nicknameValidator(nickname)
            if (state.errorStatus.nickErrMsg !== '') return false
            else return true
        }

        const checkName = (name) => {
            state.errorStatus.nameErrMsg = validator.nameValidator(name)
            if (state.errorStatus.nameErrMsg !== '') return false
            else return true
        }

        const checkEmail = (email) => {
            state.errorStatus.emailErrMsg = validator.emailValidator(email)
            if (state.errorStatus.emailErrMsg !== '') return false
            else return true
        }

        const checkPassword = (passwd) => {
            state.errorStatus.passwordErrMsg = validator.passwordValidator(passwd)
            if (state.errorStatus.passwordErrMsg !== '') return false
            else return true
        }

        const checkRePassword = (repasswd) => {
            state.errorStatus.repasswordErrMsg = (state.form.repassword !== state.form.info.password) ? "비밀번호가 일치하지 않습니다." : ""
            if (state.errorStatus.repasswordErrMsg !== '') return false
            else return true
        }

        // 닉네임 중복체크
        const nicknameDuplicationCheck = (nickname) => {

            // validator 
            if (!checkNickName(nickname)) return

            Authenticate.checkNickname({ nickname: nickname }).then(response => {
                if (response.data.result) {
                    modalControler.ShowWarningToast('이미 사용중인 닉네임입니다.')
                } else {
                    modalControler.ShowSuccessToast('사용 가능한 닉네임입니다.')
                    state.isNickNameChecked = true
                    state.checkedNickName = state.form.nickname
                }
            }).catch(err => err);
        }

        // 이메일 중복체크
        const emailDuplicationCheck = (email) => {
            // validator
            if (!checkEmail(email)) return

            Authenticate.rsaKey().then(({ data } = response) => {
                const rsa = new JSEncrypt({ default_key_size: 2048 });
                rsa.setPublicKey(utils.createPublicKey(data.result.publicKey));
                Authenticate.checkEmail({ data: rsa.encrypt(JSON.stringify({ email: email })) }).then(({ data } = response) => {
                    if (data.result) {
                        modalControler.ShowWarningToast("이미 가입된 이메일 주소입니다.")
                    } else {
                        modalControler.ShowSuccessToast('사용 가능한 이메일입니다.')
                        state.isEmailChecked = true
                        state.checkedEmail = email
                    }
                }).catch(err => err)
            })
        }

        // 타이핑 시 form validation
        const onKeyup = (e, type) => {
            if (state.isNickNameChecked === true && (state.checkedNickName !== state.form.nickname)) {
                state.isNickNameChecked = false
            }

            if (state.isEmailChecked === true && (state.checkedEmail !== state.form.info.email)) {
                state.isEmailChecked = false
            }

            if (type === 'nickname') {
                checkNickName(state.form.nickname)
            } else if (type === 'email') {
                checkEmail(state.form.info.email)
            } else if (type === 'name') {
                checkName(state.form.name)
            } else if (type === 'password') {
                checkPassword(state.form.info.password)
            } else if (type === 'repassword') {
                checkRePassword(state.form.repassword)
            }

        }

        const enterKey = (e, type) => {
        }

        const checkAll = () => {
            state.checkAllAgreement ? (state.form.agreePlatformTerms = true, state.form.agreePlatformUseTerms = true) :
                (state.form.agreePlatformTerms = false, state.form.agreePlatformUseTerms = false)
        }

        const checkTerms = () => {
            if (state.form.agreePlatformTerms && state.form.agreePlatformUseTerms) state.checkAllAgreement = true
            else state.checkAllAgreement = false
        }

        const enableSubmit = computed(() => {
            return !(
                state.form.nickname &&
                state.form.info.email &&
                state.form.name &&
                state.form.info.password &&
                state.form.info.password === state.form.repassword &&
                state.isNickNameChecked &&
                checkPassword(state.form.info.password) &&
                state.isEmailChecked &&
                state.form.agreePlatformTerms &&
                state.form.agreePlatformUseTerms
            )
        });

        const showMNC = () => {
            window.open('http://mnc.ai', '_blank');
            // window.open('https://www.nipa.kr/', '_blank')
        }

        const showServicePolicy = () => {
            // router.push({ name: 'Service' })
            let routeData = router.resolve({ name: 'Service' })
            window.open(routeData.href, '_blank')
        }

        const showPrivacyPolicy = () => {
            // router.push({ name: 'Privacy' })
            let routeData = router.resolve({ name: 'Privacy' })
            window.open(routeData.href, '_blank')
        }

        const signup = () => {

            let params = {
                nickname: state.form.nickname,
                email: state.form.info.email,
                name: state.form.name,
                password: state.form.info.password,
                agreeplatterms: state.form.agreePlatformTerms,
                agreeplatuseterms: state.form.agreePlatformUseTerms
            }
            Authenticate.signUp(params).then(response => {
                if (response.data.error && response.data.error_code === 7500) {
                    const msg = response.data.error_detail
                    modalControler.ShowModalDialog('가입 실패', response.data.error_detail, '', '확인');
                    return;
                }

                if (response.data.http_status_code === 200) {
                    let msg
                    msg = `환영합니다! 이메일 인증 후 로그인할 수 있습니다.`
                    modalControler.ShowModalDialog('가입 완료', msg, '', '확인', (val) => {
                        router.push({ name: 'SignIn' })
                    })
                }
            }).catch(err => {
                // TODO : alert
                modalControler.ShowErrorToast(err)
                modalControler.HideLoading()
            })
        }

        const socialSignup = (provider) => {
            // router.push({ name: 'SignupSocial', params: { type: 'social' } })
            SocialAPI.socialAuth('signup').then(response => {
                if (!response.data.error) {
                    if (provider === 'google') {
                        location.href = response.data.result.google
                    } else if (provider === 'naver') {
                        location.href = response.data.result.naver
                    }
                }
            })

        }



        return {
            ...toRefs(state),
            validator,
            checkNickName,
            checkName,
            checkEmail,
            checkPassword,
            checkRePassword,
            nicknameDuplicationCheck,
            emailDuplicationCheck,
            onKeyup,
            enterKey,
            checkAll,
            checkTerms,
            enableSubmit,
            signup,
            showMNC,
            showServicePolicy,
            showPrivacyPolicy,
            socialSignup,
        }
    }
}

</script>
