<template>
    <div class="community-wrap contents-wrap">
        <div class="medium-content-inner">
            <BoardEditView v-if="!isLoading" editorTitle="게시글 작성" :boardType="'Community'" :mode="mode"
                :contentsTitle="contentsTitle" :contents="contents" :attachment="attachment" :isNotice="isNotice"
                :isOpen="isOpen" @writeQnA="writeQnA" @updateQnA="updateQnA" />
        </div>
    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api'
import utils from '@/helper/utils'
import CommunityAPI from '@/lib/api/Community'
import modalControler from '@/helper/modalControler'
import BoardEditView from "@/components/BoardEditView"


export default {
    name: 'CommunityEdit',
    components: {
        BoardEditView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            contentsTitle: '',
            contents: '',
            attachment: [],
            instances: [],
            postData: {},
            options: {
                page: 1,
                itemsPerPage: 10
            },
            isNotice: false,
            isOpen: true,
            mode: 'write',
            isLoading: true,
        })

        onMounted(async () => {
            if (route.query.type === 'update') {
                state.isLoading = true
                state.mode = 'update'
                await getCommunityDetail()
            } else {
                state.isLoading = false
            }
        })

        const getCommunityDetail = () => {
            modalControler.ShowLoading()
            CommunityAPI.getCommunityDetail(route.params.postId).then(response => {
                if (!response.data.error) {
                    state.postData = response.data.result
                    // if (state.postData.files) {
                    //     state.postData.files.forEach(file => {
                    //         getBlobFromUrl(file.url, file.name).then(file => {
                    //             state.attachement.push(file)
                    //         })
                    //     })
                    // }

                    state.contentsTitle = response.data.result.title
                    state.contents = state.postData.content
                    state.isNotice = state.postData.notice
                    state.isopen = (state.postData.isOpen === 1) ? true : false
                    state.isLoading = false
                } else {
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const getBlobFromUrl = async (url, name) => {
            const response = await fetch(url)
            const data = await response.blob()
            const file = new File([data], name, { type: name.split('.').pop() });
            processFile(file).then((dataURL) => {
                state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
            });

            return file
        }

        const processFile = async (file) => {
            return await new Promise((resolve) => {
                let reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.readAsDataURL(file)
            })
        }

        const writeQnA = (params, files) => {
            const formData = new FormData()
            formData.append('data', JSON.stringify(params))
            formData.append('fileList', files)
            modalControler.ShowLoading()
            CommunityAPI.writeCommunity(formData).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('게시글이 등록되었습니다.')
                    router.push({ name: 'CommunityDetail', params: { postId: response.data.postId } })
                } else {
                    modalControler.ShowErrorToast('게시글 등록에 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = (params, files) => {
            const formData = new FormData()
            formData.append('data', JSON.stringify(params))
            if (files && files.length && files.length >= 1) {
                formData.append('fileList', files[0])
            } else {
                formData.append('fileList', files)
            }
            modalControler.ShowLoading()
            CommunityAPI.updateCommunity(route.params.postId, formData).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('게시글을 수정했습니다.')
                    router.push({ name: 'CommunityDetail', params: { postId: route.params.postId } })
                } else {
                    modalControler.ShowErrorToast('게시글 수정에 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast(err.response.data.error_detail)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }


        return {
            ...toRefs(state),
            writeQnA,
            updateQnA,
        }
    }
}

</script>