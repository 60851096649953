<template>
  <section class="content-section">
    <div class="content-inner">
      <header class="content-section__header">
        <h2 class="content-section__title">1:1 문의 내역</h2>
      </header>
      <div v-if="items.length" class="card-list card-list--3">
        <div class="card-box" v-for="(q, i) in items" :key="'qna-' + i">
          <div class="card-box__content">
            <a @click="toDetailPage(q)" class="card-box__link">
              <span v-if="q.solved" class="status-sub-badge status-sub-badge--default mb--12">답변완료</span>
              <span v-else class="status-sub-badge status-sub-badge--red mb--12">미답변</span>
              <div class="card-box__sub-title"> {{ q.categoryName }} </div>
              <h3 class="card-box__title ellipsis-1">
                {{ q.title }}
              </h3>
              <div class="card-box__option">
                <div class="card-box__sub-text">{{ q.competitionName }} / {{ q.assignmentName }}</div>
                <div class="card-box__sub-text">{{ q.createDtm }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="totalCount > items.length" class="btn-inline btn-inline--section btn-inline--center">
        <v-btn @click="showMoreBtn" class="btn btn-secondary btn-large"><strong>더보기</strong></v-btn>
      </div>
      <div v-if="!items.length" class="no-data">
        <p class="no-data__text">1:1 문의 내역이 없습니다.</p>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import SupportApi from '@/lib/api/Support.js'


export default {
  name: 'MyInquiryList',
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = tokenStore()
    const store2 = stateStore()
    const state = reactive({
      tableHeader: [
        { text: '답변여부', value: 'solved', align: 'center', sortable: false, width: '100' },
        { text: '카테고리', value: 'categoryName', align: 'left', sortable: false, width: '120' },
        { text: '대회명 · 과제명', value: 'competitionName', align: 'left', sortable: false, width: '200' },
        { text: '제목', value: 'title', align: 'left', sortable: false },
        { text: '작성일시', value: 'createDtm', align: 'left', sortable: false, width: '180' }

      ],
      items: [],
      totalCount: 0,
      options: {
        page: 1,
        itemsPerPage: 9
      },
    })

    onMounted(() => {
      getMyQnaList()
    })

    watch(() => _.cloneDeep(state.options), async (cv, ov) => {
      await getMyQnaList()
    })

    const getMyQnaList = () => {
      SupportApi.getInquiryList(utils.getPage(state.options)).then(response => {
        if (response.data.code === 0) {
          state.items = state.items.concat(response.data.result)
          state.totalCount = response.data.totalCount
        }
      })
    }

    const toDetailPage = (item) => {
      router.push({
        name: 'InquiryDetail',
        params: {
          inquiryId: item.id,
        },
      })
    }

    const showMoreBtn = () => {
      state.options.page++
      // getMyQnaList()
    }

    return {
      utils,
      ...toRefs(state),
      toDetailPage,
      showMoreBtn,
    }
  }
}
</script>
