import moment from 'moment';
import _, {isUndefined} from 'lodash'
import sanitizeHtml from 'sanitize-html';
import katex from 'katex';


export default {
    createPublicKey(rsakey) {
      return '-----BEGIN PUBLIC KEY-----\n' + rsakey + '\n-----END PUBLIC KEY-----';
    },
    

    numberToKorean(number) {
      let splitUnit = 10000;        // 1만원 단위
      if (number === 0 || number < splitUnit) return 0    // 상금 1만원 미만시, 0만원
      let inputNumber = number < 0 ? false : number;
      let resultString = '';
      const divided = Math.floor(inputNumber / splitUnit)
      resultString = String(divided.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))  + resultString;

      return resultString;
    },    

    getFilenameFromURL(url) {
      const regex = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/
      return decodeURIComponent(url.match(regex)[0])
    },

    countDDay(date) {
      let day = moment(moment(new Date()).format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'days')
      if (day > 0) {
        return `D-${day}`
      }
      if (day === 0) {
        return `D-${day}`
      }
      return `D${day}`
    },

    beforeDate(date) {
      var now = moment()
      var date = moment(date)
      var before = now.diff(date, 'hours')
      if (before === 0) {
        return '1시간 전'
      }
      if (before <= 24 ) {
          return now.diff(date, 'hours') + '시간 전'
      } else if (before > 24) {
        before = now.diff(date, 'days')
        if (before === 0) {
          return '1일 전'
        }
        if (before > 31) {
          before = now.diff(date, 'months')
          if (before === 0) {
            return '1개월 전'
          }
          return before + '개월 전'
        } else {
          return before + '일 전'
        }
      }
    },

    statusToScheduleTitle(status, scheduleList) {
      let DDayText = ''
      let DDayDt = ''
      let noDDay = false
      var today = moment(); 
      var scheduleData = scheduleList.filter(el => {
        if ((status === el.status) && (el.expose === 1) && ( moment(el.scheduledt).diff(today) >= 0 )) {
          return el
        }
      })
      if ((typeof(scheduleData[0]) !== 'undefined')) {
        if (status === 3) {
          DDayText = '접수마감'
        } else if (status === 4) {
          DDayText = '진행중'
        } else if (status === 5) {
          DDayText = '결과발표'
        } else {
          noDDay = true
          // return [noDDay, null, DDayText]
          return {
            noDDay: noDDay,
            DDay: null,
            DDayText: DDayText
          }
        }
        DDayDt = this.countDDay(scheduleData[0].scheduledt)
        noDDay = false
        // return [noDDay, DDayDt, DDayText]
        return {
          noDDay: noDDay,
          DDay: DDayDt,
          DDayText: DDayText          
        }
      } else {
        noDDay = true
        // return [noDDay, null, DDayText]
        return {
          noDDay: noDDay,
          DDay: null,
          DDayText: DDayText          
        }        
      }
    },    

    makeParams(object) {
        return `?${Object.entries(object).map(([key, value]) => `${key}=${value}`).join('&')}`;
    },

    formatPhoneNumber(phoneNumberString) {
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      let match = null;
      if (cleaned.length === 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      }
      return [match[1], '-', match[2], '-', match[3]].join('');
    },

    getPage(options) {
        const {
            status, 
            page, 
            itemsPerPage, 
            competitionId, 
            assignmentId, 
            gubn, 
            type, 
            searchText, 
            showNotice, 
            Info, 
            rankSize, 
            categoryId,
            myPost,
        } = options;
        // let param = {pageSize: itemsPerPage, pageNo: page};
        let param = {};
        if (itemsPerPage) {
          param = Object.assign(param, {pageSize: itemsPerPage})
        }
        if (page) {
          param = Object.assign(param, {pageNo: page})
        }
        if (assignmentId) {
          param = Object.assign(param, {assignmentId});
        }
        if (competitionId) {
          param = Object.assign(param, {competitionId});
        }
        if (status) {
          param = Object.assign(param, {status});
        }
        if (type) {
          param = Object.assign(param, {type})
        }
        if (myPost) {
          param = Object.assign(param, { myPost: myPost })
        }
        if (gubn) {
          param = Object.assign(param, {gubn});
        }
        if (searchText) {
          param = Object.assign(param, {searchText});
        }
        if (showNotice === true || showNotice === false) {
          param = Object.assign(param, {showNotice: showNotice});
        }
        if (Info) {
          param = Object.assign(param, {Info});
        }
        if (rankSize >= 0) {
          param = Object.assign(param, {rankSize: rankSize});
        }
        if (categoryId) {
          param = Object.assign(param, { categoryId: categoryId });
        }
        return this.makeParams(param);
    },

    openContainer(IP, PORT) {
      window.open(`http://${IP}:${PORT}`, '_blank')
    },

    sendMessageNative(message) {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('android') !== -1) {
        return WebViewBridge.showMessage(message)
      } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
        return window.webkitURL.messageHandlers.webViewMessageHandler.postMessage(message)
      } else if (userAgent.indexOf('whale') !== -1) {
        return window.alert(message)
      } else {
        return alert(message)
      }

    },

    isMobile() {
      const mobileWidth = 996
      if (window.innerWidth < mobileWidth) return true
      else return false
    },

    formatNewLine(word) {
      try {
        return word.replace(/\n/g, '<br />');
      } catch (e) {
        return word
      }
    },
    // social signup 위한 사용자 정보 저장 
    storeSocialSignUpData(provider, email, token) {
      const userData = {
        provider: provider,
        email: email,
        token: token
      }
      localStorage.setItem('aiconnect-social-signup', JSON.stringify(userData))
    },

    discardSocialSignUpData() {
      localStorage.removeItem('aiconnect-social-signup');
    },
  
    checkPasswordFormat: (pw) => {
      //숫자,영문,특수문자 각 1자리씩 8~20자
      const pwRegex = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/
      return pwRegex.test(pw)
    },

    /**
     * 
     *  # Main userrole
     *  1: admin 관리자
     * 
     *  # Competition Userrole
     *  1: monitor 모니터 
     *  2: host 운영자
     * 
     */

    // 대회 모니터 권한 체크
    isMonitorAccount(competitionId, userData) {
      if (userData.hasCompetitionUserrole) {
        for (var i=0; i < userData.competitionuserrole.length; i++) {
          if (userData.competitionuserrole[i].competitionid === Number(competitionId)) {
            if (userData.competitionuserrole[i].userrole === 1) {
              return true
            }
          }
        }
      } else {
        return false
      }
    },
    // 대회 운영자 권한 체크
    isHostAccount(competitionId, userData) {
      if (userData.hasCompetitionUserrole && userData.competitionuserrole.length) {
        for (var i=0; i < userData.competitionuserrole.length; i++) {
          if (userData.competitionuserrole[i].competitionid === Number(competitionId)) {
            if (userData.competitionuserrole[i].userrole === 2) {
              return true
            }
          }
        }
        return false
      } else {
        return false
      }
    },

    getRequestAPI(requestURL) {
      // const regex = new RegExp("(?<=(\\b" + process.env.VUE_APP_API_HOST_URL + "\\b).*(?=\?)", 'g')
      // return requestURL.match(`//g`)
      let out
      out = requestURL.split(process.env.VUE_APP_API_HOST_URL)[1]
      if (out.match(/\?/g)) {
        return out.split('?')[0]
      } else {
        return out
      }
    },

    // Html text 에서 tag 제거 
    sanitizeHtml(html, options) {
      const parsed = sanitizeHtml(html, options)
      return parsed
    },

    katexRender(previewDiv) {
      setTimeout(() => {
        try {
          previewDiv.querySelectorAll('katex').forEach(el => {
            katex.render(el.getAttribute('content'), el, {
              throwOnError: false,
              strict: false,
              displayMode: true,
              maxSize: 300
            });
          })
          previewDiv.querySelectorAll('span[data-inline-katex]').forEach(el => {
            if (el.childElementCount > 0) {
              return false;
            }
            katex.render(el.innerText, el, {
              throwOnError: false,
              strict: false,
              displayMode: false,
              maxSize: 300
            });
          })

        } catch (err) {
          console.log('kartexRendering Error: ', err)
        }
      }, 1)
    },

    paragraphSeperator (content) {
      let out = content.replace(/<p><\/p>/g, '<p><br></p>')
      return out
    },

    

}  
  
  
  