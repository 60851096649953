<template>
    <v-app>
      <main>
        <input type="hidden" id="anPageName" name="page" value="login" />
          <div class="login login--both">
            <section class="login__left">
              <div class="login__inner">
                <header class="login__header">
                  <h2 class="login__title color-primary font-num-ebold">
                    Welcome Back!
                  </h2>
                  <p class="login__text">AI CONNECT 에서 Data Scientist로 함께 배우고 성장해요.</p>
                </header>  
                  <div class="img-box">
                     <img class="img" src="@/assets/images/img-login.svg" />
                  </div>
              </div>
            </section>
            <section class="login__right">
              <div class="login__inner">
                <div class="login__forms">

       
                <header class="login__header">
                  <button type="button" class="header-logo" 
                      @click="$router.push({ path: '/' })">
                      <img src="@/assets/images/img-logo.svg" alt="ai connect" />
                  </button>
                </header>
                <div class="social-login">
                    <button type="button" class="btn-login" @click="socialLogin('google')">
                      <img class="btn-login__icon" src="../../assets/images/img-google.svg"/>
                        <span class="btn-login__text">구글로 로그인</span>
                    </button>
                    <button type="button" class="btn-login" @click="socialLogin('naver')">
                      <img class="btn-login__icon" src="../../assets/images/img-naver.svg"/>
                      <span class="btn-login__text">네이버로 로그인</span>
                  </button>
                  <button type="button" class="btn-login" @click="clickLOGIN">
                    <img class="btn-login__icon" src="../../assets/images/img-email.svg"/>
                      <span class="btn-login__text">이메일로 로그인</span>
                  </button>
                </div>
                <div class="login-link">
                    <div class="login-link__item">
                      <button class="btn-text--gray" type="button" @click.prevent="clickSignUp">
                      이메일로 회원가입
                      </button>
                    </div>
                      <div class="login-link__item">
                        <button class="btn-text--gray" type="button" @click.prevent="forgotPassword">
                          비밀번호 찾기
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="company-btns">
                    <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showMNC">
                        회사소개
                      </button>
                      <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showServicePolicy">
                        이용약관
                      </button>
                      <button type="button" class="company-btn" data-id="anima-widget" @click.prevent="showPrivacyPolicy">
                        개인정보처리방침
                      </button>
                  </div>
                </div>
            </section>
          </div>
          <ResetPassword 
            @dialogClose="resetPasswordVisible = false"
            :visible="resetPasswordVisible" />

      </main>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import { createPinia } from 'pinia';
import { tokenStore } from '@/stores/auth'
import { stateStore } from '@/stores/state';
import SocialApi from '@/lib/api/social';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';
import errorHandler from '@/helper/errorHandler'
import ResetPassword from '@/components/dialog/ResetPassword';
import Vue from 'vue'


export default {
    name: 'SignIn',
    components: {
      ResetPassword,
    },
    setup(props, context) {
      const vm = getCurrentInstance()
      const router = context.root.$router
      const store = tokenStore()
      const store2 = stateStore()
      const state = reactive({
        email: '',
        password: '',
        errorStatus: {
          emailErrormsg: '',
          passwordErrormsg: '',
        },
        deviceToken: null,
        resetPasswordVisible: false,
      });

      onMounted(() => {
      })

      const clickLOGIN = () => {
        // form validation 
        router.push({ name: 'SignInPage' })
      }

      const clickSignUp = () => {
        router.push({ name: 'SignupRoot' })
      }

      const onKeyPress = (e, type) => {
        if (e.keyCode === 13) {
          if (type === 'email') {
            vm.refs.passwordForm.focus()
          } else if (type === 'password') {
            // clickLOGIN()
          }
        }
      }

      const socialLogin = (provider) => {
        SocialApi.socialAuth('signin').then(response => {
          if (!response.data.error) {
            if (provider === 'google') {
              location.href = response.data.result.google
            } else if (provider === 'naver') {
              location.href = response.data.result.naver
            }
          } else {
            // TODO : error alert
            // const msg = ''
            // modalControler.ShowModalDialog('로그인 실패', msg, '', '확인')
          }
        });
      }

      const forgotPassword = () => {
        state.resetPasswordVisible = true
      }

      const showMNC = () => {
          window.open('http://mnc.ai', '_black');
      }

      const showServicePolicy = () => {
          router.push({ name: 'Service' })

      }

      const showPrivacyPolicy = () => {
          router.push({ name: 'Privacy' })
      }      

      return {
        ...toRefs(state),
        clickLOGIN,
        clickSignUp,
        onKeyPress,
        socialLogin,
        forgotPassword,
        showMNC,
        showServicePolicy,
        showPrivacyPolicy,
      }
    }
}
</script>

<style scoped lang="scss">
</style>