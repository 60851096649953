<template>
    <v-tabs-items>
        <div class="content-group">
            <div class="content-group__header">
                <h2 class="content-group__title">게시글 작성</h2>
            </div>
            <div class="form-group">
                <v-text-field 
                    ref="titleRef"
                    outlined class="common-form-control" dense hide-details placeholder="제목을 입력해 주세요(최대 300자)"
                    v-model="editorContentsTitle"></v-text-field>
                <!-- 컨텐츠 에디터 -->
                <div class="editor-box">
                    <user-editor v-model="editorContents" />
                </div>
            </div>
            <div class="form-group">
                <div class="form-group__title">파일첨부</div>
                <!--FileInput ref="file" v-on:change="onChangeFile" /-->
                <v-file-input v-model="editorFiles" placeholder="파일첨부(최대 50MB)" outlined hide-details
                    class="common-form-control" show-size prepend-inner-icon="mdi mdi-paperclip" prepend-icon="" dense
                    type="file" :error-messages="errorStatus.attachmentErrMsg" ref="attachement"
                    @change="validate($event, editorFiles)"></v-file-input>
            </div>
            <div class="form-group"
                v-if="store.accountInfo.userrole === 1 || utils.isHostAccount($route.params.competitionId, store.accountInfo)">
                <div class="form-group__title">공지사항여부</div>
                <div class="checkes">
                    <v-radio-group hide-details row v-model="editorIsNotice">
                        <v-radio label="공지" :value="true" class="common-check" color="#009bfa"
                            off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-checkbox-marked-circle"></v-radio>
                        <v-radio label="일반글" :value="false" class="common-check" color="#009bfa"
                            off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-checkbox-marked-circle"></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="btn-inline btn-inline--section btn-inline--center">
                <v-btn class="btn btn-large btn-tertiary" @click="toQnaList"><strong>취소</strong></v-btn>
                <!-- <v-btn>수정하기</v-btn> -->
                <v-btn v-if="editorMode === 'write'" @click="writeQnA"
                    class="btn btn-large btn-primary"><strong>등록</strong></v-btn>
                <v-btn v-else @click="updateQnA" class="btn btn-large btn-primary"><strong>수정</strong></v-btn>
            </div>
        </div>
    </v-tabs-items>
</template>

<script>
import UserEditor from "@/components/editor/UserEditor"
// import {VueEditor} from "vue2-editor";
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import modalControler from '@/helper/modalControler';
import FileInput from "@/components/competition/FileInput";


export default {
    name: 'BoardEditView',
    props: [
        'editorTitle',
        'contentsTitle',
        'contents',
        'attachment',
        'isNotice',
        'isOpen',
        'mode',
        'boardType',
    ],
    // props: {
    //     editorTitle: {
    //         type: String,
    //         required: true
    //     },
    // },
    components: {
        // VueEditor,
        UserEditor,
        // FileInput,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const vuetify = context.root.$vuetify
        const store = tokenStore()
        const attachement = ref(null)
        const titleRef = ref(null)
        const state = reactive({
            summaryMobile: null,
            windowWidth: window.innerWidth,
            editorMode: props.mode,
            editorContentsTitle: props.contentsTitle,
            editorContents: props.contents,
            editorSetting: {
                modules: {
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },
            editorFiles: props.attachment,
            editorIsNotice: props.isNotice,
            editorIsOpen: props.isOpen,

            rules: [
                files => !files || !files.some(file => file.size >= 52_428_800) || '50MB 이하의 파일만 가능합니다.',
            ],
            maxAttachmentSize: 52_428_800,
            errorStatus: {
                attachmentErrMsg: ''
            },
            maxTitleLength: 300,
        });

        onMounted(() => {
            titleRef.value.focus()

        });

        onUnmounted(() => {

        });

        watch(() => state.windowWidth, (newValue, oldValue) => {
            if (state.windowWidth <= vuetify.breakpoint.width) {
                try {
                    // state.summaryMobile = document.querySelector(".section-2__header--m");
                    // state.summaryMobile.style.display = "none";
                } catch {
                    // Ignore errors
                }
            } else {
                try {
                    // state.summaryMobile.style.display = "flex";
                } catch {
                    // Ignore errors
                }
            }
        });

        const writeQnA = () => {
            // QnA validation
            if (!state.editorContentsTitle) {
                return modalControler.ShowWarningToast('제목을 입력해주세요.')
            }

            if (state.editorContentsTitle.length > 300) {
                return modalControler.ShowErrorToast('제목은 최대 300글자까지 작성할 수 있습니다.')
            }

            if (!state.editorContents) {
                return modalControler.ShowWarningToast('내용을 입력해 주세요.')
            }
            let params = {
                competitionId: (route.params.competitionId) ? route.params.competitionId : null,
                assignmentId: (route.params.taskId) ? route.params.taskId : null,
                title: state.editorContentsTitle,
                content: state.editorContents,
                isOpen: state.editorIsOpen,
                isNotice: state.editorIsNotice,
                // QQQ : 나중에 사용 ?
                categoryId: 0,
                extType: '',
                extId: 0,
            }
            context.emit('writeQnA', params, state.editorFiles)
        }

        const updateQnA = () => {
            // QnA validation
            if (!state.editorContentsTitle) {
                return modalControler.ShowWarningToast('제목을 입력해주세요.')
            }

            if (state.editorContentsTitle && state.editorContentsTitle.length > 300) {
                return modalControler.ShowErrorToast('제목은 최대 300글자까지 작성할 수 있습니다.')
            }

            if (!state.editorContents) {
                return modalControler.ShowWarningToast('내용을 입력해 주세요.')
            }

            let params = {
                competitionQnaId: route.params.qnaId,
                competitionId: route.params.competitionId,
                assignmentQnaId: route.params.qnaId,
                assignmentId: route.params.taskId,
                codeshareId: route.params.qnaId,
                title: state.editorContentsTitle,
                content: state.editorContents,
                isOpen: state.editorIsOpen,
                isNotice: state.editorIsNotice,
                // QQQ : 나중에 사용 ?
                categoryId: 0,
                extType: '',
                extId: 0,
            }
            // return
            context.emit('updateQnA', params, state.editorFiles)
        }

        const toQnaList = () => {
            if (props.boardType === 'CompetitionQuestion') {
                router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
                return
            } else if (props.boardType === 'Community') {
                router.push({ name: 'CommunityList' })
                return
            } else if (props.boardType === 'TaskCommunity') {
                router.push(
                    {
                        name: 'TaskCommunityList',
                        params: {
                            taskId: route.params.taskId,
                            competitionId: route.params.competitionId
                        }
                    }
                )
                return
            } else if (props.boardType === 'LeagueCommunity') (
                router.push(
                    {
                        name: 'LeagueCommunity',
                        params: {
                            leagueId: route.params.leagueId,
                        }
                    }
                )
            )

            if (props.mode === 'update') {
                if (props.editorTitle === '대회문의') {
                    router.goQnaDetails('CompetitionNoticeDetail', route.params.qnaId, route.params.competitionId, route.params.taskId)
                } else if (props.editorTitle === '과제문의') {
                    router.goQnaDetails('TaskNoticeDetail', route.params.qnaId, route.params.competitionId, route.params.taskId)
                } else if (props.editorTitle === '코드 공유') {
                    router.goQnaDetails('TaskCodeShareDetail', route.params.qnaId, route.params.competitionId, route.params.taskId)
                } else if (props.editorTitle === '리그 커뮤니티') {
                    router.push({
                        name: 'LeagueCommunityDetail',
                        params: { leagueId: route.params.leagueId }
                    })
                } else {
                    router.go(-1)
                }
            } else {
                router.goTaskPages(route.params.boardType, route.params.taskId, route.params.competitionId)
            }
        }

        const validate = (e, data) => {
            if (data && data.size > state.maxAttachmentSize) {
                attachement.value.reset()
                state.errorStatus.attachmentErrMsg = '첨부파일 크기는 50MB를 초과할 수 없습니다.'
                modalControler.ShowWarningToast('첨부파일 크기는 50MB를 초과할 수 없습니다.')
            } else {
                state.errorStatus.attachmentErrMsg = ''
            }
        }

        function onResize() {
            state.windowWidth = window.innerWidth
        }


        return {
            utils,
            store,
            titleRef,
            ...toRefs(state),
            attachement,
            writeQnA,
            updateQnA,
            toQnaList,
            validate,
        }
    }
}
</script>

