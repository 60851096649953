<template>
    <div>
        <div class="task-wrap contents-wrap">
            <section class="task-section">
                <div class="content-inner">
                    <div class="column-02">
                        <div class="task-contents left-content">
                            <Summary 
                                :agreedInfo="agreedInfo"
                                :info="leagueInfo" />
                            <LeagueNavbar 
                                v-if="!isLoading" 
                                :agreed="agreedInfo.agreed" 
                                :communityRecentNotice="communityRecentNotice"
                                />
                            <div class="task-content">

                                <template v-if="$route.name === 'LeagueInfo'">
                                    <router-view 
                                        v-if="!isLoading"
                                        name="leagueTab"
                                        :leagueInfo="leagueInfo"
                                        :key="$route.fullPath"></router-view>
                                </template>

                                <template v-else-if="$route.name === 'LeagueData'">
                                    <router-view
                                        v-if="!isLoading"
                                        name="leagueTab"
                                        :key="$route.fullPath"
                                        :agreed="agreedInfo.agreed"
                                        :description="leagueInfo.data"
                                    ></router-view>
                                </template>
                                
                                <template v-else-if="$route.name === 'LeagueLeaderboard'">
                                    <router-view
                                        v-if="!isLoading"
                                        name="leagueTab"
                                        :key="$route.fullPath"
                                        :scoreSortBy="scoreSort"
                                        :scoreMeta="scoreMeta"
                                    ></router-view>
                                </template>

                                <template v-else-if="$route.name === 'LeagueSubmit'">
                                    <router-view
                                        v-if="!isLoading"
                                        name="leagueTab"
                                        :category="category"
                                        :key="$route.fullPath"
                                        :submits="submits"
                                        :maxSubmitSize="maxSubmitSize"
                                        :maxSubmitTime="maxSubmitTime"
                                        :maxSubmitTerm="maxSubmitTerm"
                                        :submitLimitDesc="submitLimitDesc"
                                    ></router-view>
                                </template>

                                <template v-else-if="$route.name === 'LeagueCommunity'">
                                    <router-view
                                        name="leagueTab"
                                        :key="$route.fullPath"
                                    ></router-view>
                                </template>                                

                                <template v-else-if="$route.name === 'LeagueRule'">
                                    <router-view
                                        name="leagueTab"
                                        :key="$route.fullPath"
                                        :rules="rules"
                                        :agreedInfo="agreedInfo"
                                        :username="username"
                                    ></router-view>
                                </template>

                                <template v-else>
                                    <router-view 
                                        v-if="!isLoading"
                                        name="leagueTab"></router-view>
                                </template>

                            </div>
                        </div>
                        <LeaderboardPanel 
                            v-if="!isLoadingLeaderboard"
                            :leaderboard="leaderboard" />
                    </div>

                </div>
            </section>
        </div>

    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, provide, watch
} from '@vue/composition-api'
import utils from '@/helper/utils';

import { tokenStore } from '@/stores/auth'

import Summary from '@/components/league/Summary'
import LeagueNavbar from '@/components/league/LeagueNavbar'
import LeaderboardPanel from '@/components/league/LeaderboardPanel'

import CommunityApi from '@/lib/api/Community';
import LeagueApi from '@/lib/api/League'

import modalControler from '@/helper/modalControler';
import moment from 'moment'

export default {
    name: 'LeagueRoot',
    components: {
        Summary,
        LeagueNavbar,
        LeaderboardPanel,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const state = reactive({
            isLoading: false,
            isLoadingLeaderboard: false,
            leagueId: route.params.leagueId,
            leagueInfo: {},
            scoreSort: 'desc',
            scoreMeta: [],
            maxSubmitSize: 0,
            maxSubmitTime: 0,
            maxSubmitTerm: 0,
            submitLimitDesc: null,
            leaderboard: [],
            rules: [],
            agreedInfo: {
                agreed: null,
                agreedDtm: null,
            },
            username: store.getAccountInfo.nickname,
            submits: [],
            category: null,
            communityRecentNotice: [],
            noticeRange: 3,     // 3 days
            options: {
                page: 1,
                itemsPerPage: 9999,
                showNotice: 1,
            },            
        })        

        onMounted( async () => {
            getLeagueDetail()
            getLeagueLeaderboard()
            getCommunityRecent()
        })

        const getLeagueDetail = () => {
            state.isLoading = true
            LeagueApi.getLeagueDetail(state.leagueId)
                .then(res => {
                    const data = res.data.result
                    state.leagueInfo = data
                    state.scoreSort = data.scoreSort.toLowerCase()
                    state.scoreMeta = data.scoreMeta
                    state.maxSubmitSize = data.limitFileSize
                    state.maxSubmitTime = data.submitOfDay
                    state.maxSubmitTerm = data.submitDelay
                    state.submits = data.submitLogs
                    state.rules = data.rules
                    state.agreedInfo.agreed = data.agreed
                    state.agreedInfo.agreedDtm = data.agreedDtm
                    state.submitLimitDesc = data.submitDesc
                    state.isLoading = false
                    state.category = data.category
                    // League 조회수 증가
                    LeagueApi.increaseLeagueViewcount(state.leagueId)
                        .then(res => {})
                        .catch(err => {})
                })
                .catch(err => {

                })
        }

        const getLeagueLeaderboard = () => {
            state.isLoadingLeaderboard = true
            LeagueApi.getLeagueLeaderboard(state.leagueId)
                .then(res => {
                    let array = res.data.result
                    state.leaderboard = sortLeaderboard(array)
                    changeRank(state.leaderboard)
                    state.isLoadingLeaderboard = false

                })
                .catch(err => {
                    // console.log('leageu panel leaderboard err: ', err)
                })
        }

        const sortLeaderboard = (array) => {
            // QQQ : index 1개 기준
            if (state.scoreSort === 'desc') {
                array.sort((i, j) => {
                    const a = i.scores[0].publicScore
                    const b = j.scores[0].publicScore
                    if (a < b) return 1
                    if (a > b) return -1
                    if (i.createDtm) {
                        const c = i.createDtm
                        const d = j.createDtm
                        if (moment(c).isAfter(d)) return 1
                        else return -1
                    }
                })

            } else {
                array.sort((i, j) => {
                    const a = i.scores[0].publicScore
                    const b = j.scores[0].publicScore
                    if (a < b) return -1
                    if (a > b) return 1
                    if (i.createDtm) {
                        const c = i.createDtm
                        const d = j.createDtm
                        if (moment(c).isAfter(d)) return 1
                        else return -1
                    }
                })
            }

            return array

        }

        const changeRank = (array) => {
            array.map((el, idx) => {
                el.ranking = idx + 1
            })
        }

        const getCommunityRecent = () => {
            let searchOptions = utils.getPage(state.options)
            CommunityApi.getLeagueCommunityList(state.leagueId, searchOptions).then(response => {
                if (!response.data.error) {
                    const now = moment()
                    state.communityRecentNotice = response.data.data.filter(v => (now.diff(v.createDtm, 'days') <= state.noticeRange))
                }

            }).catch(err => {
                console.log(err)
            })
        }

        return {
            ...toRefs(state),

        }
    }
}
</script>