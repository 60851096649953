<template>
    <div class="contents-wrap">
        <div class="content-inner">
            <BoardDetailView 
                detailType="LeagueCommunity"
                @updateQnA="updateQnA"
                @deleteQnA="deleteQnA"
                @writeReply="writeQnaReply"
                @updateReply="updateQnaReply"
                @deleteReply="deleteQnaReply"
                @fetchMoreReply="fetchMoreReply"
                @updateLikePost="updateLikePost"
                :replyList="replyList"
                :qnaData="qnaData"
                :isLiked="isLiked"
                />    
        </div>
    </div>
</template>


<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CommunityApi from "@/lib/api/Community";
import modalControler from '@/helper/modalControler';
import BoardDetailView from '@/components/BoardDetailView'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';


export default {
    name: 'LeagueCommunityDetail',
    components: {
        BoardDetailView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const state = reactive({
            // 댓글 더보기
            options: {
                page: 1,
                itemsPerPage: 0
            },            
            qnaData: {},
            replyList: [],
            isLiked: false,            
        });

        onMounted(() => {
            getCompetitionQnaDetail()
        });

        const getCompetitionQnaDetail = () => {
            modalControler.ShowLoading()
            CommunityApi.getLeagueCommunityDetail(route.params.postId).then(response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result
                    state.isLiked = response.data.isLiked
                    // state.replyList = response.data.result.replyList
                    state.replyList = []
                    response.data.result.reply.forEach(el => {
                        state.replyList.push(el)
                    })
                } else {
                    modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                    // router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
                }
            }).catch(err => {
                modalControler.ShowWarningToast('관리자만 볼 수 있습니다.')
                // router.goCompetitionPages('CompetitionQuestion', route.params.competitionId)
                
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updateQnA = () => {
            router.push({
                name: 'LeagueCommunityEdit',
                params: { leagueId: route.params.leagueId, postId: route.params.postId },
                query: { type: 'update' }
            })
        }

        const deleteQnA = () => {
            modalControler.ShowLoading()
            CommunityApi.deleteLeagueCommunity(route.params.leagueId, route.params.postId).then(response => {
                modalControler.ShowSuccessToast('글을 삭제했습니다.')
                router.push({
                    name: 'LeagueCommunity',
                    params: {
                        leagueId: route.params.leagueId
                    }
                })
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnaReply = (content) => {
            let params = {
                content: content                
            }
            CommunityApi.writeLeagueCommunityReply(route.params.leagueId, route.params.postId, params).then(response => {
                getCompetitionQnaDetail()
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 작성에 실패했습니다.')
            })
        }

        const updateQnaReply = (replyId, content) => {
            let params = {
                content: content                
            }
            CommunityApi.updateLeagueCommunityReply(route.params.leagueId, route.params.postId, replyId, params).then(response => {
                modalControler.ShowSuccessToast('댓글을 수정했습니다.')
                getCompetitionQnaDetail()

            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 수정에 실패했습니다.')
            })
        }

        const deleteQnaReply = (replyId) => {
            CommunityApi.deleteLeagueCommunityReply(route.params.leagueId, route.params.postId, replyId).then(response => {
                modalControler.ShowSuccessToast('댓글을 삭제했습니다.')
                getCompetitionQnaDetail()
            }).catch(err => {
                
            })
        }

        const fetchMoreReply = () => {
            if (state.qnaData.replycount > (state.options.page * state.options.itemsPerPage)) {
                ++state.options.page
                let params = {
                    Info: route.params.qnaId,
                    page: state.options.page,
                    itemsPerPage: state.options.itemsPerPage
                }
                getCompetitionQnaDetail()
            }
        }

        const updateLikePost = () => {
            CommunityApi.updateLikePost(route.params.postId).then(response => {
            if (!response.data.error) {
                getCompetitionQnaDetail()
            } else {
                modalControler.ShowErrorToast('좋아요 누르기에 실패했습니다.')
            }
            }).catch(err => {
            modalControler.ShowErrorToast('좋아요 누르기에 실패했습니다.')
            })
        }            


        return {
            ...toRefs(state),
            getCompetitionQnaDetail,
            updateQnA,
            deleteQnA,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            fetchMoreReply,
            updateLikePost,
        }


    }

}
</script>
