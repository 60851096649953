<template>
    <article class="swiper-slide-group swiper-tab">
        <div class="swiper-tab__container">
            <!-- menu nav slide btn -->
            <div class="swiper-button-next" slot="button-next" @click="nextTab()"></div>
            <div class="swiper-button-prev" slot="button-prev" @click="prevTab()"></div>

            <!-- task tab menu -->
            <div v-swiper:slideSwiper="swiperOption">
                <div class="swiper-wrapper" ref="swiperWrapper">
                    <swiper-slide>
                        <button class="swiper-tab__btn" 
                            :class="{ 'active': currentActive === 'LeagueInfo' ? true : false }"
                            @click.prevent="clickEvent('LeagueInfo')">개요</button>
                    </swiper-slide>                

                    <swiper-slide>
                        <button class="swiper-tab__btn" 
                            :class="{ 'active': currentActive === 'LeagueData' ? true : false }"
                            @click.prevent="clickEvent('LeagueData')">데이터</button>
                    </swiper-slide>

                    <swiper-slide>
                        <button class="swiper-tab__btn" type="button"
                            :class="{ 'active': currentActive === 'LeagueLeaderboard' ? true : false }"
                            @click.prevent="clickEvent('LeagueLeaderboard')">리더보드</button>
                    </swiper-slide>


                    <swiper-slide v-if="agreed">
                        <button class="swiper-tab__btn" 
                            :class="{ 'active': currentActive === 'LeagueSubmit' ? true : false }"
                            @click.prevent="clickEvent('LeagueSubmit')">결과제출</button>
                    </swiper-slide>


                  <swiper-slide>
                    <button class="swiper-tab__btn" :class="{'active': currentActive === 'LeagueCommunity' ? true : false}" @click.prevent="clickEvent('LeagueCommunity')">
                      커뮤니티
                      <span class="icon-new" v-if="communityRecentNotice.length"><span class="new">N</span></span>
                    </button>
                  </swiper-slide>

                    <swiper-slide>
                        <button class="swiper-tab__btn" :class="{ 'active': currentActive === 'LeagueRule' ? true : false }"
                            @click.prevent="clickEvent('LeagueRule')">규정 및 서약서</button>
                    </swiper-slide>
                </div>

            </div>
        </div>
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import modalControler from '@/helper/modalControler';
// import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'

export default {
    name: 'LeagueNavBar',
    components: {

    },
    directive: {

    },
    props: [
        'agreed',
        'communityRecentNotice',
    ],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store2 = stateStore()
        const state = reactive({
            currentActive: router.currentRoute.name,
            currentIndex: 0,   
            tabs: [
                'LeagueInfo',
                'LeagueData',
                'LeagueLeaderboard',
                'LeagueSubmit',
                'LeagueCommunity',
                'LeagueRule',
            ],            
            swiperOption: {
                loop: false,
                // slidesPerView: 'auto',
                // freeMode: true,
                slidesPerView: 'auto',
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            }
        })

        onMounted(() => {
        state.currentActive = store2.taskActiveRoute
          state.currentIndex = state.tabs.indexOf(route.name)
        })

        const clickEvent = (routeName) => {
            state.currentIndex = state.tabs.indexOf(routeName)
            store2.setTaskActiveRoute(routeName)
            state.currentActive = routeName
            router.goLeaguePages(routeName, route.params.leagueId)
        }

        watch(() => store2.taskActiveRoute, (newValue, oldValue) => {
            state.currentActive = store2.taskActiveRoute
            // console.log(state.currentActive)
        })

        const nextTab = () => { }
        const prevTab = () => { }

        return {
            ...toRefs(state),
            clickEvent,
            nextTab,
            prevTab,
        }
    }
}

</script>