<template>
  <div>
    <!-- Main App -->
    <div id="app">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <!-- global progress -->
    <v-overlay :value="$store.state.loading.circular" style="z-index: 9999;">
      <v-progress-circular indeterminate size="48" color="#009bfa"></v-progress-circular>
    </v-overlay>

    <!-- global dialog modal component -->
    <v-app id="vApp">
      <v-dialog content-class="dialog" v-body-scroll-lock="$store.state.dialog.show" v-model="$store.state.dialog.show"
        scrollable :max-width="$store.state.dialog.maxWidth" width="420px" @click:outside="didClickDialogOutside">
        <v-card class="dialog-section center-type" :height="$store.state.dialog.maxHeight">
          <header class="dialog-section__header">
            <h3 class="dialog-section__title" v-if="$store.state.dialog.showTitle">{{
              $store.state.dialog.title
            }}</h3>
          </header>

          <div>
            <p class="dialog-section__text" v-html="$store.state.dialog.message"></p>
          </div>

          <div class="dialog-section__btns" v-if="$store.state.dialog.showConfirmButton ||
              $store.state.dialog.showCancelButton
              ">
            <v-btn class="btn btn-secondary btn-small" v-if="$store.state.dialog.showCancelButton" text color="#343B94"
              @click="didCloseModalDialog(false)">{{
                $store.state.dialog.cancelButtonText }}
            </v-btn>
            <v-btn class="btn btn-primary btn-small" v-if="$store.state.dialog.showConfirmButton" text color="#343B94"
              @click="didCloseModalDialog(true)">{{
                $store.state.dialog.confirmButtonText }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <!-- global alert -->
      <v-alert top elevation="12" type="error" transition="scale-transition" v-model="$store.state.alert.show"
        :timeout="$store.state.alert.timeout">
        {{ $store.state.alert.message }}
      </v-alert>
    </v-app>

    <!-- <v-snackbar 
      class=""
      v-model="$store.state.alert.show"
      :timeout="$store.state.alert.timeout"
      centered
    >
      <div>
        <div></div>
        <v-spacer></v-spacer>
        <div @click="$sstore.state.alert.show = false">
          <b-icon icon="x"></b-icon>
        </div>
      </div>
      <div>
        <v-icon 
          size="30"
          :color="$store.state.alert.color"
        >{{ $store.state.alert.icon }}
        </v-icon>
        <div v-if="$store.state.alert.title !== ''">
          <strong>{{ $store.state.alert.title }}</strong>
        </div>
        <div v-html="$store.state.alert.message"></div>
      </div>
    </v-snackbar> -->
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { BIcon } from "bootstrap-vue";
import { createPinia } from "pinia";
import { tokenStore } from "@/stores/auth";
import Authenticate from "@/lib/api/Authenticate";
import utils from "@/helper/utils";

export default {
  name: "App",
  components: {
    // BIcon,
  },

  setup(props, context) {
    const store = context.root.$store;
    const state = reactive({
      primary: "#343B94",
      tokenStore: null,
      deviceToken: null,
    });

    const didCloseModalDialog = (value) => {
      if (store.state.dialog.didCloseDialog) {
        store.state.dialog.didCloseDialog(value);
      }
      store.commit("DISMISS_MODAL_DIALOG");
    };

    const didClickDialogOutside = () => {
      if (!store.state.dialog.isModal) {
        didCloseModalDialog(false);
      }
    };

    onMounted(async () => {

    });
    return {
      ...toRefs(state),
      didCloseModalDialog,
      didClickDialogOutside,
    };
  },
};
</script>

<style>
/* Component transition animaction */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#vApp>.v-application--wrap {
  min-height: 0;
}
</style>
