<template>
    <article class="section-2__content">
        <BoardEditView 
          v-if="!isLoading"
          editorTitle="팀 빌딩" 
          :mode="mode" 
          :contentsTitle="contentsTitle"
          :contents="contents"
          :attachment="attachment"
          :isNotice="isNotice"
          :isOpen="isOpen"
          @writeQnA="writeQnA"
          @updateQnA="updateQnA" 
        />
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition"
import modalControler from '@/helper/modalControler';
import BoardEditView from "@/components/BoardEditView"

export default {
    name: 'TeamBuildEdit',
    components: {
        BoardEditView
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            contentsTitle: '',
            contents: '',
            attachment: [],
            instances: [],
            qnaData: {},
            options: {
                page: 1,
                itemsPerPage: 10
            },     
            isNotice: false,
            isOpen: true,
            mode: 'write',
            isLoading: true,
        })
    
        onMounted(async () => {
            if (route.query.type === 'update') {
                state.isLoading = true
                state.mode = 'update'
                await getTeamBuildDetail()
            } else {
                state.isLoading = false
            }
        })

        const getTeamBuildDetail = () => {
            modalControler.ShowLoading()
            let params ={
                Info: route.params.qnaId
            }
            CompetitionApi.getTeamBuildDetail(route.params.competitionId, route.params.qnaId, utils.makeParams(params)).then(async response => {
                if (!response.data.error) {
                    state.qnaData = response.data.result.data
                    if (state.qnaData.files) {
                        state.qnaData.files.forEach(async file => {
                            await getBlobFromUrl(filr.url, file.name).then(file => {
                                state.attachement.push(file)
                            })
                        })
                    }

                    /* 
                    for QnA update
                    */
                    state.contentsTitle = state.qnaData.title
                    state.contents = state.qnaData.content
                    // state.attachment = state.qnaData.files
                    state.isNotice = state.qnaData.isnotice
                    state.isopen = (state.qnaData.isopen === 1) ? true : false
                    state.isLoading = false
                }
            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writeQnA = (params, files) => {
            const formData = new FormData()
            formData.append('data', new Blob([JSON.stringify(params)], { type: 'application/json' }))
            formData.append('fileList', files)
            // files.forEach(file => {
            // })
            modalControler.ShowLoading()
            CompetitionApi.writeTeamBuild(route.params.competitionId, formData).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('게시글이 등록되었습니다.')
                    router.goTaskPages('TeamBuild', route.params.taskId, route.params.competitionId)
                } else {
                    modalControler.ShowErrorToast('게시글 등록에 실패했습니다.')
                }

            }).catch(err => {
                // TODO : error alarm
            }).finally(() => {
                modalControler.HideLoading()
            })
        }


        const updateQnA = (params, files) => {
            const formData = new FormData()
            formData.append('data', new Blob([JSON.stringify(params)], { type: 'application/json' }))
            if (files && files.length && files.length >= 1) {
                formData.append('fileList', files[0])
            } else {
                formData.append('fileList', files)
            }
            // files.forEach(file => {
            // })
            modalControler.ShowLoading()
            CompetitionApi.updateTeamBuild(route.params.competitionId, route.params.qnaId ,formData).then(response => {
                modalControler.ShowSuccessToast('게시글이 등록되었습니다.')
                router.goTaskPages('TeamBuild', route.params.taskId, route.params.competitionId)
            }).catch(err => {
                modalControler.ShowErrorToast('작성에 실패했습니다.')
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const getBlobFromUrl = async (url, name) => {
            modalControler.ShowLoading()
            const response = await fetch(url)
            const data = await response.blob()
            const file = new File([data], name, { type: name.split('.').pop() });
            processFile(file).then((dataURL) => {
                state.instances.push(dataURL.slice(dataURL.indexOf(',') + 1));
            });
            modalControler.HideLoading()
            return file
        }

        const processFile = async (file) => {
            return await new Promise((resolve) => {
                let reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.readAsDataURL(file)
            })
        }

        return {
            ...toRefs(state),
            writeQnA,
            updateQnA,
        }
    }
}

</script>