<template>
  <section class="competition-list-section content-section--padding bg-gray">
    <div class="content-inner">
      <header class="content-section__header">
        <h2 class="content-section__title">모든 경진대회</h2>
      </header>
      <div v-for="(c, i) in competitionList" :key="'competition-' + i" :class="{
        'common-competition__disabled': (c.status === 2) ? true : false,
        'on': i === clickedCompetitionIdx
      }" class="common-competition competition-list__group">
        <div class="competition-list__content">
          <div class="competition-list__inner">
            <a @click="goToCompetition(c)"
              class="common-competition__box competition-list__box thumb-square thumb-square--m">
              <img :src="(c.imgurl) ? c.imgurl : '../../assets/images/img-main-dummy.png'" />
            </a>
            <div class="common-competition__header competition-list__header">
              <a @click="goToCompetition(c)" class="competition-list__content">
                <div class="common-competition__status-badge">
                  <!-- <span v-if="c.status === 2" class="status-badge status-badge--primary">오픈예정</span> -->
                  <span v-if="c.status === 3" class="status-badge status-badge--black">접수중</span>
                  <span v-else-if="c.status === 4" class="status-badge status-badge--primary">진행중</span>
                  <span v-else-if="c.status === 5" class="status-badge status-badge--white">점검중</span>
                  <span v-else-if="c.status === 6" class="status-badge status-badge--gray">
                    종료
                  </span>
                </div>
                <div class="title">{{ c.name }}</div>
                <p class="dec">
                  {{ c.desc }}
                </p>
                <div class="info--group">
                  <div class="item">
                    <span class="item__icon">
                      <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                        icon-name="icon-user"><icon-user /></icon-base>
                    </span>
                    <span class="item__text">{{ c.joinusercnt }}명 / {{ c.jointeamcnt }}팀</span>
                  </div>
                  <div class="item" v-if="c.fromdt">
                    <span class="item__icon">
                      <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                        icon-name="icon-calendar"><icon-calendar /></icon-base>
                    </span>
                    <span class="item__text"> {{ c.fromdt.split(" ")[0] }} ~ {{ c.todt.split(" ")[0] }}</span>
                    <span class="status-sub-badge status-sub-badge--primary ml--8" v-if="c.status === 2">오픈예정</span>
                  </div>
                  <div class="prize" v-if="c.prizemoney > 0">
                    <span class="prize__icon icon icon__size--300 icon--prize">
                      <span class="blind">상금</span>
                    </span>
                    <div class="prize__text">
                      <span class="money font-num-bold">
                        {{ utils.numberToKorean(c.prizemoney) }}
                      </span> 만원
                    </div>
                  </div>
                </div>
              </a>
              <button @click="openCompetition(c, i)" class="btn-arrow" type="button">
                <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#000"
                  icon-name="icon-arrow-up-circle"><icon-arrow-up-circle /></icon-base>
              </button>
            </div>
          </div>
          <div class="common-competition__body competition-list__body">
            <section class="swiper__banner swiper-unset">
              <template v-if="c.assignmentList.length > 2">
                <button type="button" class="swiper-btn swiper-btn__middle__left" slot="button-next">

                  <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                    icon-name="icon-chevron-right"><icon-chevron-right /></icon-base>
                </button>
                <button type="button" class="swiper-btn swiper-btn__middle__right" slot="button-prev">
                  <icon-base viewBox="0 0 24 24" width="24" height="24" iconColor="#101216"
                    icon-name="icon-chevron-left"><icon-chevron-left /></icon-base>
                </button>
              </template>
              <div v-swiper:[i]="swiperOption" class="swiper">
                <div class="swiper-wrapper">
                  <swiper-slide :key="'assignment-' + tti" v-for="(t, tti) in c.assignmentList">
                    <a @click="$router.goTaskPages('TaskInfo', t.assignmentId, t.competitionId)" class="task-list__item">
                      <h4 class="title">{{ t.name }}</h4>
                      <div class="tags__group">
                        <span class="tag" v-for="(t, tti) in t.keywords" :key="'keyword-' + tti">
                          {{ t.name }}
                        </span>
                      </div>
                      <div class="person">
                        <span class="icon"> <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
                            icon-name="icon-user"><icon-user /></icon-base></span>
                        <span class="text">{{ t.joinusercnt }}명 / {{ t.jointeamcnt }}팀</span>
                      </div>
                    </a>
                  </swiper-slide>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "@vue/composition-api";
import moment from "moment";
import utils from "@/helper/utils";
import { tokenStore } from "@/stores/auth";
import { stateStore } from "@/stores/state";
import CompetitionApi from "@/lib/api/Competition";
import BannerApi from "@/lib/api/Banner";
import modalControler from "@/helper/modalControler";
import Carousels from "@/components/competition/Carousels";
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'


export default {
  components: {
    SwiperSlide,
  },
  directive: {
    swiper: directive
  },
  props: ['competitionList'],
  setup(props, context) {
    const route = context.root.$route;
    const router = context.root.$router;
    const store = tokenStore();
    const swiper = ref(null);
    const state = reactive({
      competitionId: Number(route.params.competitionId),
      categories: [],
      doing: false,
      showingStatus: [2, 3, 4, 5, 6], // OPEN : 2, APPLYING : 3, RUNNING : 4 , HOLDING: 5
      currentStatus: [3, 4, 5],
      competition: 1,
      study: 2,
      recruitment: 3,
      options: {
        page: 1,
        itemsPerPage: 0,
        categoryId: 0,
      },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-btn__middle__right',
          prevEl: '.swiper-btn__middle__left'
        }

      },

      clickedRunningCompetitionIdx: null,
      clickedCompetitionIdx: null,
    });

    const goToCompetition = (c) => {
      if (c.status === 2) {
        modalControler.ShowWarningToast('준비중입니다. 잠시만 기다려주세요!')
        return
      }
      router.goCompetitionPages('CompetitionDetail', c.id)
    }

    const openCompetition = (c, i) => {
      if (c.status === 2) {
        modalControler.ShowWarningToast('준비중입니다. 잠시만 기다려주세요!')
        return
      }    // OPEN

      if (state.clickedCompetitionIdx === i)
        state.clickedCompetitionIdx = null
      else
        state.clickedCompetitionIdx = i
    }

    return {
      swiper,
      ...toRefs(state),
      utils,
      moment,
      openCompetition,
      goToCompetition,
    };
  }
}
</script>