<template>
    <div class="policy-wrap contents-wrap">
        <header class="page-header--line__section">
            <h2 class="page-header--line__title">이용약관</h2>
        </header>
        <div class="content-inner">
            <iframe height="100%" width="100%" class="iframe"
                src="https://ai-connect-promotion.s3.ap-northeast-2.amazonaws.com/terms/rule.html"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Service',

    setup(props, context) {

    }
}
</script>