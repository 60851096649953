<template>
  <v-app>
    <v-tabs-items>
      <div class="help-wrap bg-contents-wrap">
        <section class="search-section">
          <div class="help-search__bg"></div>
          <div class="help-search">
            <div class="help-search__inner">
              <div class="form-group">
                <v-text-field placeholder="궁금한 내용을 검색해보세요." persistent-hint class="search-form-control" outlined
                  hide-details append-icon="mdi-magnify" v-model="searchText" @keypress="onKeyPress($event)">
                </v-text-field>
              </div>
            </div>
          </div>
        </section>

        <template>
          <SearchResult :manuals="manuals" :faqs="faqs" :searchText="searchText" />
        </template>

      </div>
    </v-tabs-items>
  </v-app>
</template>


<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import SearchResult from '@/components/faq/SearchResult';
import SupportApi from '@/lib/api/Support.js'

export default {
  name: 'manualSearch',
  components: {
    SearchResult,
  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = tokenStore()
    const store2 = stateStore()
    const state = reactive({
      searchText: '',
      manuals: [],
      faqs: [],
    });

    onMounted(() => {
      if (route.query.searchText) {
        if (route.query.isKeyWord) {

        }
        state.searchText = route.query.searchText
        searchManuals()
      }
    })

    const searchManuals = () => {
      SupportApi.searchManuals(state.searchText).then(response => {
        if (response.data.code === 0) {
          state.searchStatus = true
          state.manuals = response.data.result.manuals
          state.faqs = response.data.result.faqs

        }
      })
    }

    const onKeyPress = (e) => {
      if (e.keyCode === 13) {
        searchManuals()
      }
    }



    return {
      utils,
      ...toRefs(state),
      store,
      props,
      onKeyPress,
      searchManuals,
    }
  }

}

</script>

