<template>
    <v-app>
        <v-tabs-items>
            <div class="board-wrap content-group">
                <!-- 작성, 게시글 옵션 -->
                <div class="option-group">
                    <div class="left">
                        <div class="search-box mr--12">
                            <v-text-field append-icon="mdi-magnify" v-model="searchKeyword" @keydown.enter="getQnA"
                                @click:append="getQnA" hide-details>
                            </v-text-field>
                        </div>
                        <div class="checks checks-inline">
                            <v-checkbox class="common-check" label="공지 숨기기" hide-details color="#009bfa"
                                v-model="showNoticement" @change="hideNoticement"></v-checkbox>
                            <v-checkbox v-if="accountInfo.nickname" class="common-check" label="내 글만 보기" hide-details
                                color="#009bfa" v-model="showMine" @change="getMyQnA">
                            </v-checkbox>
                        </div>
                    </div>
                    <div class="right">
                        <v-btn class="btn btn-primary btn-medium" @click="toQnaWritePage">
                            <div class="icon-left">
                                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                    icon-name="icon-pen"><icon-pen /></icon-base>
                            </div>
                            <strong class="text">
                                게시글 작성
                            </strong>
                        </v-btn>
                    </div>
                </div>
                <template v-if="props.qnaList.length">
                    <div class="card-list card-list--3">
                        <div :class="{ 'card-box--notice': item.notice }" class="card-box" :key="'item-' + i"
                            v-for="(item, i) in props.qnaList">
                            <div class="card-box__content">
                                <a @click.prevent.stop="toQnaDetailPage(item)" class="card-box__link">
                                    <div class="mb--4" v-if="item.notice">
                                        <span class="status-badge status-badge--primary"> 공지 </span>
                                    </div>
                                    <h3 class="card-box__title ellipsis-2">
                                        {{ item.title }}
                                    </h3>
                                    <div class="card-box__option">
                                        <div class="profile-box">
                                            <div class="profile-img profile-img__size--400">
                                                <img :src="(item.profileImg) ? item.profileImg :
                                                    require('@/assets/images/img-profile-blue.svg')">
                                            </div>
                                            <div class="profile-box__text">
                                                <div class="nickname color-primary">
                                                    <span class="icon mr--2" v-if="item.admin">
                                                        <font-awesome-icon :icon="['fas', 'star']" />
                                                    </span>
                                                    {{ item.nickname }}
                                                </div>
                                                <div class="date">{{ item.createDtm }}</div>
                                            </div>
                                        </div>
                                        <div class="info-box">
                                            <div class="info-box__item info-box__item--gray-600">
                                                <span class="info-box__icon">
                                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                                        icon-name="icon-eye"><icon-eye /></icon-base>
                                                </span>
                                                <span class="info-box__text">
                                                    {{ item.viewCount }}
                                                </span>
                                            </div>
                                            <div class="info-box__item info-box__item--gray-600">
                                                <span class="info-box__icon">
                                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                                        icon-name="icon-message"><icon-message /></icon-base>
                                                </span>
                                                <span class="info-box__text">
                                                    {{ item.replyCount }}
                                                </span>
                                            </div>
                                            <div class="info-box__item info-box__item--gray-600">
                                                <span class="info-box__icon">
                                                    <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                                        icon-name="icon-thumbs-up"><icon-thumbs-up /></icon-base>
                                                </span>
                                                <span class="info-box__text">
                                                    {{ item.likeCount }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="qnaTotalCount > qnaList.length" class="btn-inline btn-inline--section btn-inline--center">
                        <v-btn @click="showMoreBtn" class="btn btn-secondary btn-large"><strong>더보기</strong></v-btn>
                    </div>
                </template>

                <!--v-data-table class="common-board" hide-default-header :headers="tableHeader" :items="props.qnaList"
                    @click:row="toQnaDetailPage" :options.sync="options" :footer-props="{
                        'items-per-page-options': [10, 20, 50, 100],
                        'items-per-page-text': '',
                        'page-text': '{0}~{1} / {2}',
                    }" :server-items-length="props.qnaTotalCount">
                    <template v-slot:item.id="{ item }">
                        <span v-if="item.notice" class="status-sub-badge status-sub-badge--primary">
                            공지
                        </span>
                    </template>
                    <template v-slot:item.title="{ item }">
                        <div class="board-list-header">
                            <div class="board-list-header__title-area">
                                <span class="board-list-header__icon">
                                    <font-awesome-icon :icon="['fas', 'lock']" v-if="item.isopen === 0" />
                                </span>
                                <span class="board-list-header__title">{{ item.title }}</span>
                            </div>
                            <div class="writer-info">
                                <div class="writer">
                                    <div v-if="item.admin" class="operate color-primary">
                                        <span class="icon">
                                            <font-awesome-icon :icon="['fas', 'star']" />
                                        </span>
                                        <span>{{ item.nickname }}</span>
                                    </div>
                                    <span v-else class="color-black">{{ item.nickname }}</span>
                                </div>
                                <span>{{ item.createDtm }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.viewcount="{ item }" v-if="props.boardType !== 'TaskCodeShare'">
                        <div class="view-box">
                            <span class="like">
                                <font-awesome-icon :icon="['fas', 'thumbs-up']" />
                                {{ item.likeCount }}
                            </span>
                            <span class="view">
                                <font-awesome-icon :icon="['fas', 'eye']" />
                                {{ item.viewCount }}
                            </span>
                            <span class="comment">
                                <font-awesome-icon :icon="['fas', 'comment']" />
                                {{ item.replyCount }}
                            </span>
                        </div>
                    </template>
                </v-data-table-->
            </div>
            <!--div class="no-data">
                <p class="no-data__text">검색 대상이 없습니다.</p>
            </div-->
            <div class="no-data" v-if="!props.qnaList.length">
                <p class="no-data__text">게시글이 없습니다.</p>
            </div>
        </v-tabs-items>
        <!-- <v-card-text
            v-if="$vuetify.breakpoint.width <= $mobileWidth"
        >
            <v-fab-transition>
                <v-btn
                    fab
                    bottom
                    right
                    fixed
                    color="primary"
                    @click="toQnaWritePage"
                >
                    <b-icon icon="pencil-square"></b-icon>
                </v-btn>
            </v-fab-transition>
        
        </v-card-text> -->
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import _ from "lodash";
import { BIcon } from 'bootstrap-vue'
import { tokenStore } from '@/stores/auth';
import modalControler from '@/helper/modalControler';
import utils from '@/helper/utils';

export default {
    name: 'CompetitionNotice',
    props: {
        boardType: {
            type: String,
            required: true
        },
        qnaList: {
            type: Array,
            required: true
        },
        qnaTotalCount: {
            type: Number,
            required: false
        },
        mode: {
            type: String,
            required: false
        },
    },
    setup(props, context) {
        const vm = getCurrentInstance()
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const accountInfo = store.getAccountInfo
        const vuetify = context.root.$vuetify
        const state = reactive({
            options: {
                page: 1,
                itemsPerPage: 21
            },
            showNoticement: false,
            showMine: false,
            showNotice: true,
            searchKeyword: '',
            tableHeader: [
                { text: '#', value: 'id', align: 'left', width: 40 },
                { text: '제목', value: 'title', align: 'left' },
                { test: '조회수', value: 'viewcount', align: 'right', width: 10 }
            ],
            windowWidth: window.innerWidth,
            maxListTitleLength: 60,
            mobileWidth: 996,
            clickedMoreBtn: false,
        });


        const boardInfo = computed(() => {
            if (props.boardType === 'CompetitionNotice') {
                return {
                    title: "대회 공지/문의",
                    emoji: '📢'
                }
            } else if (props.boardType === 'TaskNotice') {
                return {
                    title: "과제 공지/문의",
                    emoji: '📢'
                }
            } else if (props.boardType === 'TaskCodeShare') {
                return {
                    title: "코드 공유",
                    emoji: '🙋‍♀️'
                }
            } else if (props.boardType === 'CompetitionQuestion') {
                return {
                    title: "대회 공지/문의",
                    emoji: '📢'
                }
            } else if (props.boardType === 'TeamBuild') {
                return {
                    title: "팀 빌딩 게시판",
                    emoji: '📢'
                }
            } else if (props.boardType === 'Community' || props.boardType === 'TaskCommunity') {
                return {
                    title: "AI CONNECT 커뮤니티",
                    emoji: '📢'
                }
            } else if (props.boardType === 'LeagueCommunity') {
                return {
                    title: "League 커뮤니티",
                    emoji: '🙋‍♀️'
                }
            }

        })


        onMounted(async () => {
            await getQnA()    // 게시판 글 조회
            // window.addEventListener('resize', onResize);
            // if (state.mobileWidth >= vuetify.breakpoint.width) {
            //     state.tableHeader = [
            //         {text: '#', value: 'id', align: 'center', width: 40},
            //         {text: '제목', value: 'title', align: 'left'},
            //         {text: '상태', value: 'status', align: 'right', width: 20}
            //     ]
            // }
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onResize);
        });

        watch(() => _.cloneDeep(state.options), async (currentValue, oldValue) => {
            await getQnA()    // 게시판 글 조회
        });

        watch(() => state.windowWidth, (newValue, oldValue) => {
            // if (state.mobileWidth >= vuetify.breakpoint.width) {
            //     state.tableHeader = [
            //         {text: '#', value: 'id', align: 'center', width: 40},
            //         {text: '제목', value: 'title', align: 'left'},
            //     ]
            // } else {
            //     state.tableHeader = [
            //         {text: '#', value: 'id', align: 'center', width: 40},
            //         {text: '제목', value: 'title', align: 'left'},
            //         {test: '조회수', value: 'viewcount', align: 'right', width: 10}
            //     ]
            // }
        })

        const getQnA = () => {
            context.emit('doSearch', _.cloneDeep(state.options), state.searchKeyword, state.showNotice, state.clickedMoreBtn)
            state.clickedMoreBtn = false
        }

        const getMyQnA = () => {
            if (state.showMine) {
                state.options.myPost = 1
                context.emit('onlyMyQnA', _.cloneDeep(state.options), state.searchKeyword, state.showNotice)
            } else {
                state.options.myPost = ''
                context.emit('doSearch', _.cloneDeep(state.options), state.searchKeyword, state.showNotice)
            }
        }

        // 공지 숨기기
        const hideNoticement = () => {
            if (state.showNotice === true) {
                state.showNotice = false
            } else {
                state.showNotice = true
            }
            getQnA()
        }



        function toQnaDetailPage(item) {            // 상세페이지 이동
            if (props.boardType === 'TaskCodeShare') {
                router.goQnaDetails('TaskCodeShareDetail', item.id, route.params.competitionId, route.params.taskId)
            }
            else if (props.boardType === 'TeamBuild') {
                router.goQnaDetails('TeamBuildDetail', item.id, route.params.competitionId, route.params.taskId)
            } else if (props.boardType === 'Community') {
                router.push({
                    name: 'CommunityDetail',
                    params: {
                        postId: item.id
                    }
                })
            } else if (props.boardType === 'TaskCommunity') {
                router.push({
                    name: 'TaskCommunityDetail',
                    params: {
                        postId: item.id
                    }
                })
            } else if (props.boardType === 'LeagueCommunity') {
                router.push({
                    name: 'LeagueCommunityDetail',
                    params: {
                        leagueId: route.params.leagueId,
                        postId: item.id
                    }
                })
            }
            else {
                if (item.isopen === 1 || item.createauthornickname === store.accountInfo.nickname || store.accountInfo.userrole === 1
                    || utils.isHostAccount(route.params.competitionId, store.accountInfo)
                    || utils.isMonitorAccount(route.params.competitionId, store.accountInfo)) {
                    if (props.boardType === 'CompetitionNotice') {
                        router.goQnaDetails('CompetitionNoticeDetail', item.id, route.params.competitionId, route.params.taskId)
                    } else if (props.boardType === 'TaskNotice') {
                        router.goQnaDetails('TaskNoticeDetail', item.id, route.params.competitionId, route.params.taskId)
                    } else if (props.boardType === 'CompetitionQuestion') {
                        router.goQnaDetails('CompetitionQuestionDetail', item.id, route.params.competitionId, route.params.taskId)
                    }
                } else {
                    modalControler.ShowWarningToast('대회 관리자만 볼 수 있습니다.')
                }
            }

        }

        function toQnaWritePage() {                 // QnA 작성 페이지 이동
            let name = ''
            if (props.boardType === 'CompetitionNotice') {
                name = 'CompetitionNoticeEdit'
            } else if (props.boardType === 'TaskNotice') {
                name = 'TaskNoticeEdit'
            } else if (props.boardType === 'TaskCodeShare') {
                name = 'TaskCodeShareEdit'
            } else if (props.boardType === 'CompetitionQuestion') {
                name = 'CompetitionQuestionEdit'
                router.push({
                    name: name,
                    params: {
                        competitionId: route.params.competitionId,
                        boardType: props.boardType,
                    }
                })
                return
            } else if (props.boardType === 'TeamBuild') {
                name = 'TeamBuildEdit'
            } else if (props.boardType === 'Community') {
                router.push({
                    name: 'CommunityEdit'
                })
                return
            } else if (props.boardType === 'TaskCommunity') {
                router.push({
                    name: 'TaskCommunityEdit',
                    params: {
                        competitionId: route.params.competitionId,
                        taskId: route.params.taskId,
                    }
                })
                return
            } else if (props.boardType === 'LeagueCommunity') {
                router.push({
                    name: 'LeagueCommunityEdit',
                    params: {
                        leagueId: route.params.leagueId,
                        boardType: props.boardType,
                    }
                })
            } else {
                router.push({
                    name: name,
                    params: {
                        taskId: route.params.taskId,
                        competitionId: route.params.competitionId,
                        boardType: props.boardType
                    }
                })

            }
        }

        function onResize() {
            state.windowWidth = window.innerWidth
        }

        const titleSlicer = (title) => {
            if (title.length > state.maxListTitleLength) {
                title = title.slice(0, state.maxListTitleLength) + '...'
            }
            return title
        }

        const showMoreBtn = () => {
            state.clickedMoreBtn = true
            state.options.page++

        }

        return {
            props,
            ...toRefs(state),
            getQnA,
            getMyQnA,
            accountInfo,
            boardInfo,
            hideNoticement,
            toQnaDetailPage,
            toQnaWritePage,
            titleSlicer,
            showMoreBtn,
        }
    },

}
</script>
