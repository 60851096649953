<template>
  <section class="large-content-section">
    <div class="content-inner">
      <header class="large-content-section__header">
        <h2 class="large-content-section__title">No.1 AI Competition Platform</h2>
        <p class="large-content-section__text">
          AI CONNECT는 인공지능 전문가들이 만든 경진대회 전용 온라인 플랫폼입니다.<br />
          문제 출제부터 데이터셋 구축, GPU 제공, 채점 서버 운영 등 경진대회의 End-to-End 서비스를 제공하고 있습니다.</p>
        <a class="mt--12 content-header__link" @click.prevent.stop="toManual" target="_blank">
          대회 참여 방법이 궁금하신가요?
          <span class="icon">
            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#101216"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </a>
      </header>
      <div class="competition-info__group">
        <div class="competition-info__item">
          <div class="competition-info__img"><img src="../../assets/images/img-main-01.svg" alt="대회 개최수" /></div>
          <div class="competition-info__text-box">
            <div class="font-num-bold">{{ summaryData.competitioncount }}</div>
            <div class="competition-info__text">대회 개최수</div>
          </div>
        </div>
        <div class="competition-info__item">
          <div class="competition-info__img"><img src="../../assets/images/img-main-02.svg" alt="대회 참여 인원" /></div>
          <div class="competition-info__text-box">
            <div class="font-num-bold">{{ summaryData.totaluser }}</div>
            <div class="competition-info__text">대회 참여 인원</div>
          </div>

        </div>
        <div class="competition-info__item">
          <div class="competition-info__img"><img src="../../assets/images/img-main-03.svg" alt="사업화 지원금 및 상금 규모" />
          </div>
          <div class="competition-info__text-box">
            <div class="font-num-bold">{{ summaryData.totalprizemoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
            </div>
            <div class="competition-info__text">사업화 지원금 및 상금 규모</div>
          </div>
        </div>
      </div>
      <div class="large-content-section__btn-area">
        <a href="https://docs.google.com/forms/d/184ppGB9z3I8mpHVF9MaNnxW07jxG6293xMFF7efVvP8/viewform?edit_requested=true"
          target="_blank" class="btn btn-primary btn-large"><strong>AI 경진대회 개최 문의</strong>
          <span class="icon-right">
            <icon-base viewBox="0 0 24 24" width="20" height="20" iconColor="#fff"
              icon-name="icon-arrow-right-circle"><icon-arrow-right-circle /></icon-base>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  props: [
    'summaryData',
  ],

  setup(props, context) {
    const route = context.root.$route;
    const router = context.root.$router;

    const toManual = () => {
      let manualId
      if (process.env.VUE_APP_MODE === 'prod') {
        manualId = 8
      } else {
        manualId = 27
      }
      router.push({
        name: 'HelpDetail',
        params: {
          manualId: manualId
        }
      })
    }

    return {
      toManual
    }
  }
}
</script>