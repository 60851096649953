<template>
    <v-dialog v-model="dialogOpen" content-class="dialog" width="380px" :fullscreen="getWindowWidthSize < 768"
        @keydown.esc="onClickClose" @input="(v) => v || onClickClose()">
        <v-card class="dialog-section">
            <header class="dialog-section__header center-type">
                <h3 class="dialog-section__title ">탈퇴 하기</h3>
            </header>
            <div class="dialog-section__body">
                <p class="dialog-section__text--small">
                    <strong class="dialog-section__text--strong">AI Connect 플랫폼 페이지를 탈퇴 하시겠습니까?</strong>

                    탈퇴를 원하시는 경우 아래에 '탈퇴요청'을 작성해 주시기 바랍니다.
                </p>
                <div class="form-box mt--16">
                    <v-text-field class="common-form-control" outlined hide-details placeholder="탈퇴요청"
                        v-model="deleteUser"></v-text-field>
                </div>
                <div class="mt--16">
                    <span class="list__title">탈퇴 유의사항</span>
                    <ul class="list">
                        <li class="list__item">탈퇴 시 향후 1년 간 해당 계정으로 재가입이 불가능합니다.</li>
                        <li class="list__item">탈퇴하더라도 작성한 게시글 및 리더보드 내 결과 데이터는 삭제되지 않습니다.</li>
                        <li class="list__item">팀 리더로 참여중인 대회가 있을 경우, 자동으로 다른 팀원에게 리더 권한이 양도됩니다.</li>
                    </ul>
                </div>
                <div class="dialog-section__btns">
                    <v-btn class="btn btn-small btn-secondary" @click="onClickSubmit()" :disabled="validate">
                        <strong>탈퇴하기</strong></v-btn>
                    <v-btn class="btn btn-small btn-primary" @click="onClickClose()"> <strong>취소</strong></v-btn>
                </div>
            </div>

        </v-card>
    </v-dialog>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import Authenticate from '@/lib/api/Authenticate';
import modalControler from '@/helper/modalControler';

export default {
    name: 'UserWithdrawal',
    props: ['visible'],
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const state = reactive({
            dialogOpen: false,
            deleteUser: null,
        });

        watch(() => props.visible, (nv, ov) => {
            state.dialogOpen = nv
        });

        const validate = computed(() => {
            return state.deleteUser !== '탈퇴요청'
        })


        const getWindowWidthSize = computed(() => {
            return context.root.$vuetify.breakpoint.width
        });

        const onClickClose = () => {
            context.emit('dialogClose')
            state.deleteUser = null
        }

        const onClickSubmit = () => {
            context.emit('submit')
            state.deleteUser = null
        }


        return {
            ...toRefs(state),
            validate,
            onClickClose,
            onClickSubmit,
            getWindowWidthSize,

        }


    }

}


</script>

<style scoped lang="scss"></style>

