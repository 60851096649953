<template>
  <v-app>
    <v-tabs-items>
      <div class="help-wrap bg-contents-wrap">
        <section class="search-section">
          <div class="help-search__bg"></div>
          <div class="help-search">
            <div class="help-search__inner">
              <div class="form-group">
                <v-text-field placeholder="궁금한 내용을 검색해보세요." persistent-hint class="search-form-control" outlined
                  hide-details append-icon="mdi-magnify" v-model="searchText" @keypress="onKeyPress($event)">
                </v-text-field>
              </div>
            </div>
          </div>
        </section>

        <!-- Befor search -->
        <section class="help-main-section">
          <Manual />
          <FAQ />
          <MyInquiryList />
        </section>

      </div>
    </v-tabs-items>
  </v-app>
</template>


<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import Manual from '@/components/faq/Manual';
import FAQ from '@/components/faq/FAQ';
import MyInquiryList from '@/components/faq/MyInquiryList';
import SupportApi from '@/lib/api/Support.js';

export default {
  name: 'faq',
  props: ['competitionId', 'ruleDesc'],
  components: {
    Manual,
    FAQ,
    MyInquiryList,
  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const store = tokenStore()
    const store2 = stateStore()
    const swiperWrapper = ref(null)
    const state = reactive({
      currentIndex: 0,
      currentMenu: 'TaskInfo',
      searchText: '',
      manuals: [],
      faqs: [],

    });

    const searchManuals = () => {
      router.push({
        name: 'manualSearch',
        query: {
          searchText: state.searchText
        }
      })
    }

    const onKeyPress = (e) => {
      if (e.keyCode === 13) {
        searchManuals()
      }
    }

    return {
      utils,
      ...toRefs(state),
      store,
      props,
      swiperWrapper,
      searchManuals,
      onKeyPress,
    }
  }

}

</script>


