<template>
    <div class="community-wrap contents-wrap">
        <div class="medium-content-inner">
            <BoardDetailView v-if="!isLoading" detailType="Community" @updateQnA="updatePost" @deleteQnA="deletePost"
                @writeReply="writePostReply" @updateReply="updatePostReply" @deleteReply="deletePostReply"
                @updateLikePost="updateLikePost" :replyList="replyList" :qnaData="postData" :isLiked="isLiked" />
        </div>

    </div>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api'
import utils from '@/helper/utils'
import CommunityAPI from '@/lib/api/Community'

import modalControler from '@/helper/modalControler'
import BoardDetailView from '@/components/BoardDetailView'
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'

export default {
    name: 'CommunityDetail',
    components: {
        BoardDetailView,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const preview = ref(null)
        const state = reactive({
            options: {
                page: 1,
                itemsPerPage: 0
            },
            postData: {},
            replyList: [],
            isLiked: null,
            isLoading: false,
        })

        onMounted(() => {
            getCommunityDetail()

        })

        const katexRender = () => {
            const previewDiv = preview.value
            utils.katexRender(previewDiv)
            hljs.highlightAll()
        }

        const getCommunityDetail = () => {
            state.isLoading = true
            modalControler.ShowLoading()
            CommunityAPI.getCommunityDetail(route.params.postId).then(response => {
                if (!response.data.error) {
                    state.postData = response.data.result
                    state.isLiked = response.data.isLiked
                    state.replyList = []
                    response.data.result.reply.forEach(el => {
                        state.replyList.push(el)
                    })
                    state.isLoading = false
                    katexRender()
                } else {
                    // 
                }

            }).catch(err => {

            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const updatePost = () => {
            router.push({
                name: 'CommunityEdit',
                params: { postId: route.params.postId },
                query: { type: 'update', postId: route.params.postId }
            })
        }

        const deletePost = () => {
            modalControler.ShowLoading()
            CommunityAPI.deleteCommunity(route.params.postId).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('삭제되었습니다.')
                    router.push({ name: 'CommunityList' })
                } else {
                    modalControler.ShowErrorToast('삭제 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                modalControler.HideLoading()
            })
        }

        const writePostReply = (content) => {
            let params = {
                content: content
            }
            CommunityAPI.writeCommunityReply(route.params.postId, params).then(response => {
                if (!response.data.error) {
                    getCommunityDetail()
                } else {
                    modalControler.ShowErrorToast('댓글 작성에 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 작성에 실패했습니다.')
            })
        }

        const updatePostReply = (replyId, content) => {
            let params = {
                content: content
            }
            CommunityAPI.updateCommunityReply(route.params.postId, replyId, params).then(response => {
                if (!response.data.error) {
                    modalControler.ShowSuccessToast('댓글을 수정했습니다.')
                    getCommunityDetail()
                } else {
                    modalControler.ShowErrorToast('댓글 수정에 실패했습니다.')
                }
            }).catch(err => {
                console.log(err)
                modalControler.ShowErrorToast('댓글 수정에 실패했습니다.')
            })
        }

        const deletePostReply = (replyId) => {
            CommunityAPI.deleteCommunityReply(route.params.postId, replyId).then(response => {
                if (!response.data.error) {
                    getCommunityDetail()
                } else {
                    modalControler.ShowErrorToast('댓글 삭제에 실패했습니다.')
                }
            }).catch(err => {
                modalControler.ShowErrorToast('댓글 삭제에 실패했습니다.')
            })
        }

        const updateLikePost = () => {
            CommunityAPI.updateLikePost(route.params.postId).then(response => {
                if (!response.data.error) {
                    getCommunityDetail()
                } else {
                    modalControler.ShowErrorToast('좋아요 누르기에 실패했습니다.')
                }
            }).catch(err => {
                modalControler.ShowErrorToast('좋아요 누르기에 실패했습니다.')
            })
        }

        // const fetchMoreReply = () => {
        //     if (state.postData.reply.length > (state.options.page * state.options.itemsPerPage)) {
        //         ++state.options.page
        //         let params = {
        //             page
        //         }
        //     }
        // }

        return {
            ...toRefs(state),
            updatePost,
            deletePost,
            writePostReply,
            updatePostReply,
            deletePostReply,
            updateLikePost,
        }
    }
}

</script>