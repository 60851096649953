<template>
  <article class="content-group">
    <!--div class="btn-toggles">
      <div class="btn-toggle">
        <input type="radio" name="level" id="public" value="public" v-model="leaderboardType"
          @change="getTaskLeaderboard('public', scoreIndex)" />
        <label for="public" class="btn-toggle__label">
          Public
        </label>
      </div>
      <div class="btn-toggle">
        <input v-if="privateExpose" type="radio" name="level" id="private" value="private" v-model="leaderboardType"
          @change="getTaskLeaderboard('private', scoreIndex)" />
        <label v-if="privateExpose" for="private" class="btn-toggle__label">
          Private
        </label>
      </div>
      <div class="btn-toggle">
        <input v-if="finalExpose" type="radio" name="level" id="final" value="final" v-model="leaderboardType"
          @change="getTaskLeaderboard('final', scoreIndex)" />
        <label v-if="finalExpose" for="final" class="btn-toggle__label">
          Final
        </label>
      </div>
    </div-->
    <div class="list__box--area">
      <div class="list list__box list__box--line">
        <p class="list__item">결과제출 후, 일정 시간(약 5분 이내) 후에 리더보드에 표시됩니다.</p>
      </div>
    </div>

    <div class="content__container">
      <div class="btn-final-group">
        <v-btn v-if="leaderboardType === 'final' && clickedParameterHeader && evaluationParameters.length > 1"
          class="btn-xsmall btn-secondary" @click="checkFinalRank">최종 순위 확인</v-btn>
      </div>

      <!-- New Table -->
      <div class="leaderboard-table">
        <Table 
          v-if="isDone" 
          :boardType="'leaderboard'" 
          :defaultClickedHeaderIdx="defaultClickedHeaderIdx"
          :evaluationParams="evaluationParameters"
          :tableHeader="tableHeader" 
          :data="tableData"
          :practiceData="practiceData"
        >
          <template v-slot:ranking="{ row }">
            <template v-if="!row.isPractice">
              <template v-if="row.ranking === 1">
                <div class="ranking-box">
                  <img src="@/assets/images/icon-medal-01.png" alt="1등" />
                </div>
              </template>
              <template v-else-if="row.ranking === 2">
                <div class="ranking-box">
                  <img src="@/assets/images/icon-medal-02.png" alt="2등" />
                </div>
              </template>
              <template v-else-if="row.ranking === 3">
                <div class="ranking-box">
                  <img src="@/assets/images/icon-medal-03.png" alt="3등" />
                </div>
              </template>
              <template v-else>
                <div class="ranking-box">
                  <span class="ranking-box__item">
                    {{ row.ranking }}
                  </span>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="ranking-box"></div>
            </template>
          </template>
          <template v-slot:nickname="{ row }">
            <div class="user--group">
              <div class="profile-img profile-img__size--200">
                <img :src="
                  row.profileImg
                    ? row.profileImg
                    : require('@/assets/images/img-profile-gray.svg')
                " alt="" />
              </div>
            </div>
            <div class="team">
              <strong class="team__name">{{ row.nickname }}</strong>
              <div class="team__submit">
                <span class="team__submit__title">제출 수</span>
                <strong class="team__submit__text">{{ row.submitCount }} 회</strong>
              </div>
              <div class="team__submit">
                <span class="team__submit__title">최종제출</span>
                <strong class="team__submit__text">
                  {{ utils.beforeDate(row.lastSubmitDtm) }}
                </strong>
              </div>              
            </div>
          </template>
        </Table>
      </div>
    </div>
    <div v-if="showMorebtn" @click.prevent="showMoreLeaderboard" class="bottom__container">
      <p class="section-2__btn--more"><a>더보기</a></p>
    </div>
  </article>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  provide,
  watch
} from "@vue/composition-api";
import utils from "@/helper/utils";
import TaskApi from "@/lib/api/Task";
import LeagueApi from '@/lib/api/League'
import modalControler from "@/helper/modalControler";
import { BIcon } from "bootstrap-vue";
import Table from "@/components/Table";
import { EventBus } from '@/lib/event-bus';
import moment from 'moment'


export default {
  name: "LeagueLeaderboard",
  components: {
    Table,
  },
  props: {
    privateExpose: {
      type: Boolean,
      required: false,
    },
    finalExpose: {
      type: Boolean,
      required: false,
    },
    scoreSortBy: {
      required: false,
    },
    scoreMeta: {
      type: Array,
      required: false,
    }
  },
  setup(props, context) {
    const route = context.root.$route;
    const state = reactive({
      leagueId: route.params.leagueId,
      leaderboard: [],
      leaderboardType: "public",
      options: {
        page: 1,
        // itemsPerPage: 0,
        rankSize: 0,
      },
      showingList: [],
      showMorebtn: false,
      maxShowRow: 10,
      scoreSortMethod: props.scoreSortBy.toLowerCase(),
      boardType: "public",
      scoreIndex: 0,
      tableHeader: [
        { title: "순위", value: "ranking" },
        { title: "닉네임", value: "nickname", width: 200 },
        { title: "제출시간", value: "createDtm", width: 130 },
      ],
      evaluationParameters: [],
      tableData: [],
      insertTableIndex: 2,
      isDone: true,
      clickedParameterHeader: false,
      defaultClickedHeaderIdx: 2,
      clickedMoreBtn: false,
      practiceData: null,
    });

    onMounted(() => {
      EventBus.$on('clickedTableHeader', checkClickedTableHeader)

      if (props.finalExpose) {
        state.leaderboardType = "final";
        getTaskLeaderboard("final", state.scoreIndex);
      } else {
        getTaskLeaderboard("public", state.scoreIndex);
      }
    });

    watch(() => state.leaderboardType, (nv, ov) => {
      if (state.clickedMoreBtn) {
        state.clickedMoreBtn = false
      }
    })

    const getTaskLeaderboard = (boardType, scoreIndex) => {
      state.isDone = false
      state.scoreSortMethod = props.scoreSortBy.toLowerCase()
      let params = {
        scoreIndex: state.scoreIndex
      }
      modalControler.ShowLoading()
      LeagueApi.getLeagueLeaderboard(state.leagueId).then(response => {
        state.showingList = []
        if (!response.data.error) {
          state.leaderboard = response.data.result
          state.showingList = state.leaderboard
          state.evaluationParameters = response.data.scoreMeta
          // 평가지표 - 헤더 적용
          state.tableHeader = [
            { title: "순위", value: "ranking" },
            { title: "닉네임", value: "nickname", width: 200 },
            { title: "제출시간", value: "createDtm", width: 130 },
          ];

          let scoreValue = "publicScore-";
          let scoreTitle = " Score";


          for (var i = state.evaluationParameters.length - 1; i >= 0; i--) {
            if (boardType === "final") {
              state.tableHeader.splice(state.insertTableIndex, 0, {
                title: state.evaluationParameters[i].name,
                value: scoreValue + i,
                width: 210,
                sortable: true,
                sortBy: state.scoreSortMethod,
                submitScore: true,

              });
            } else {
              state.tableHeader.splice(state.insertTableIndex, 0, {
                title: state.evaluationParameters[i].name,
                value: scoreValue + i,
                width: 210,
                sortable: true,
                sortBy: state.scoreSortMethod,
                // submitScore: true,

              });
            }
          }
          if (state.showingList.length) {
            // 평가지표 적용 - 데이터
            state.showingList.map((row) => {
              for (var i = 0; i < row.scores.length; i++) {
                row["publicScore-" + i] = row.scores[i].publicScore
                // row["privateScore-" + i] = row.scores[i].privateScore;
                // row["finalScore-" + i] = row.scores[i].finalScore;
                if (boardType === 'final') {
                  row.ranking = row.avgRank
                }
              }
            });
          }


          state.tableData = state.showingList
          state.isDone = true
        } else {

        }
      }).catch(err => {
        console.log(err)
        // modalControler.ShowErrorToast('리더보드를 불러올 수 없습니다.')
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

    const showMoreLeaderboard = () => {
      state.tableData = state.leaderboard
      state.showMorebtn = false
      state.clickedMoreBtn = true
      getTaskLeaderboard(state.leaderboardType, state.scoreIndex);

    }


    const checkFinalRank = () => {
      state.clickedParameterHeader = false
      state.showingList.sort((i, j) => {
        const a = i.avgRank
        const b = j.avgRank
        if (a < b) return -1;
        if (a > b) return 1;
        if (i.createDtm) {
          const c = i.createDtm
          const d = j.createDtm
          if (moment(c).isAfter(d)) return 1
          else return -1
        }
      })
      state.tableData = state.showingList
      state.defaultClickedHeaderIdx = 0
      EventBus.$emit('changeClickedHeaderIdx', state.defaultClickedHeaderIdx)
    }

    const checkClickedTableHeader = (clickedIdx) => {
      if (clickedIdx === 0) {
        state.clickedParameterHeader = false
      } else {
        state.clickedParameterHeader = true
      }
    }

    return {
      ...toRefs(state),
      props,
      utils,
      getTaskLeaderboard,
      showMoreLeaderboard,
      checkFinalRank,
    };
  },
};
</script>

