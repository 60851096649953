<template>
    <Certification />
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { BIcon } from 'bootstrap-vue'
import Certification from '@/components/global/Certification'

export default {
    name: 'PrintPage',
    components: {
        Certification
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router

    }
}

</script>

<style scoped lang="scss">

</style>