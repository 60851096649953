<template>
  <div class="news-wrap contents-wrap">
    <section class="news-list-section">
      <div class="content-inner">
        <header class="page-header--line__section">
          <h2 class="page-header--line__title">News</h2>
          <p class="page-header--line__sub-text">AI Connect는 데이터에 관심 있는 사람들이 모이고,<br /> 전문가들과 함께 더 나은 기술발전을 향해 나아갑니다.
          </p>
        </header>
        <div class="card-list card-list--3">
          <div class="card-box" v-for="(news, i) in newsLists" :key="i">
            <a class="card-box__link" @click="onClickNews(news.id)">
              <div class="card-box__inner">
                <div class="card-box--news">
                  <div class="card-box__img">
                    <img :src="news.image.url" />
                  </div>
                  <div class="card-box__content">
                    <h3 class="card-box__title ellipsis-1">{{ news.subject }}</h3>
                    <span class="card-box__text" v-html="utils.sanitizeHtml(news.content, sanitizeOptions)"></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="btn-inline btn-inline--section btn-inline--center" v-if="totalCount > 9">
          <v-btn v-if="!(totalCount <= newsLists.length)" @click="getAllNewsList(++moreCount)"
            class="btn btn-secondary btn-large">
            <strong>더보기</strong>
          </v-btn>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref, watch
} from '@vue/composition-api';
import utils from '@/helper/utils';
import NewsApi from "@/lib/api/News";
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
// import sanitizeHtml from 'sanitize-html';

export default {
  name: 'CompetitionNews',
  components: {

  },
  setup(props, context) {
    const route = context.root.$route
    const router = context.root.$router
    const state = reactive({
      newsLists: [],
      totalCount: 0,
      moreCount: 1,
      sanitizeOptions: {      // 모든 tag 제거
        allowedTags: [],
        allowedAttributes: {}
      }
    });

    onMounted(() => {
      getAllNewsList(state.moreCount)
    })

    const getAllNewsList = (count) => {
      let page = {
        page: count,
        itemsPerPage: 9
      }
      modalControler.ShowLoading()
      NewsApi.getAllNewsList(utils.getPage(page)).then(response => {
        state.newsLists.push(...response.data.result.data)
        state.totalCount = response.data.result.totalcount
      }).catch(err => {
        modalControler.ShowErrorToast('뉴스를 불러 올 수 없습니다.')
        console.log(err)
      }).finally(() => {
        modalControler.HideLoading()
      })
    }

    const onClickNews = (id) => {
      router.push({ name: 'CompetitionNewsDetail', params: { newsId: id } })
    }

    return {
      ...toRefs(state),
      utils,
      onClickNews,
      getAllNewsList
    }


  }
}
</script>

<style scoped lnag="scss"></style>