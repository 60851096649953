<template>
  <body>
      <header class="landing_header">
        <h1 class="landing_main-title">2022년 인공지능 온라인 경진대회 사전접수
            <!--span>
                <v-chip
                    color="#343B94"
                    text-color="#fff"
                >
                    D-{{ dday }}
                </v-chip>
            </span-->
        </h1>
      </header>
      <main>
          <div class="landing_main-banner">
            <img src="@/assets/images/landing/nipa/section1.png" class="landing_main-banner-img" alt="2022년 인공지능 온라인 경진대회">
          </div>
      </main>
      <article class="landing_article">
        <section class="landing_section">
            <div class="landing_section-youtube pb40">
                <h6 class="landing_article-title pb10">사전설명회: 2022년 5월 27일 (금) 15:00 ~ 16:30</h6>
                <div class="video-wrap">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/6f3iB5NbAmY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <h6 class="landing_article-title pb10">본접수 기간: 2022년 5월 30일 (월) 11:00 ~ 2022년 6월 3일 (금) 15:00</h6>
            <p>본 접수가 시작되면 팀 구성 및 과제참여가 가능합니다.</p>
        </section>
        <section class="landing_section">
                <img src="@/assets/images/landing/nipa/section_1_asset_1.png" class="landing-section_img" alt="사업화지원금 총 40억">
        </section>
        <section class="landing_section">
                <img src="@/assets/images/landing/nipa/section_2_asset.png" class="landing-section_img">
                <ul class="landing_list_desc">
                    <li>이미지 분야</li>
                    <li>자연어 분야</li>
                    <li>수치해석 분야</li>
                </ul>
                <p>제시된 문제 중 1개 과제만 참여 가능</p>
        </section>
        <section class="landing_section">
            <img src="@/assets/images/landing/nipa/section_3_asset_3.png" class="landing-section_img">
        </section>
        <section class="landing_section">
            <img src="@/assets/images/landing/nipa/section_4_asset.png" class="landing-section_img">
            <p>사전접수 기간: 2022년 5월 26일 15:00까지</p>
            <div class="landing_signin_btn" @click="apply">
                사전접수 바로가기 
            </div>
        </section>
        <footer class="landing_section">
            <div class="landing_footer_title">
                <img src="@/assets/images/landing/nipa/section_5_asset.png" class="landing-section_img footer_title_img">
                <img src="@/assets/images/landing/nipa/section_6_asset.png" class="landing-section_img">
            </div>
        </footer>
    </article>
  </body>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, ref
} from '@vue/composition-api';
import Vue from 'vue'
import moment from 'moment'
import modalControler from '@/helper/modalControler';

export default {
    name: 'LandingPage2',
    components: {

    },
    setup(props, context) {
        const router = context.root.$router
        const state = reactive({

        });

        const dday = computed(() => {
            const now = moment()
            const due = moment('2022-05-26 14:59:59')
            return moment.duration(due.diff(now)).asDays().toFixed()
        });

        const apply = () => {
            const now = moment()
            const due = moment('2022-05-09 11:00:00')

            router.push({ name: 'SignupEmail' })

            // if (moment.duration(due.diff(now)).asSeconds() > 0) {
            //     Vue.$toast.open({
            //         type: 'error',
            //         position: 'top',
            //         message: '사전접수 기간(2022-05-09 11:00:00 ~ )이 아닙니다.',
            //         duration: 5000
            //     })
            //     // modalControler.ShowModalDialog('가입 실패', '사전 접수기간(2022-05-09 11:00:00 ~)이 아닙니다.', '', '확인')
            //     return
            // } else {
            //     router.push({ name: 'SignupEmail' })
            // }
        }

        return {
            ...toRefs(state),
            dday,
            apply,
        }
    }
}
</script>

<style scoped lang="scsss">


</style>