<template>
    <article class="swiper-slide-group swiper-tab">
      <div class="swiper-tab__container">
        <!-- menu nav slide btn -->
        <div class="swiper-button-next" slot="button-next" @click="nextTab()"></div>
        <div class="swiper-button-prev" slot="button-prev" @click="prevTab()"></div>

        <!-- task tab menu -->
        <div v-swiper:slideSwiper="swiperOption">
          <div class="swiper-wrapper" ref="swiperWrapper">
            <swiper-slide>
              <button class="swiper-tab__btn" type="button" :class=" {'active': currentActive === 'TaskInfo' ? true : false}" @click.prevent="clickEvent('TaskInfo')">과제 설명</button>
            </swiper-slide>

            <template v-if="props.isJoin === 3 || props.agreed || (store.accountInfo && (store.accountInfo.userrole === 1 
              || utils.isHostAccount($route.params.competitionId, store.accountInfo) 
              || utils.isMonitorAccount($route.params.competitionId, store.accountInfo)))">
              <swiper-slide>
                <button class="swiper-tab__btn" :class="{'active': currentActive === 'TaskData' ? true : false}" @click.prevent="clickEvent('TaskData')">데이터</button>
              </swiper-slide>
            </template>

            <template v-if="category === 'A001' || category === 'A003'">
              <swiper-slide>
                <button class="swiper-tab__btn" type="button" :class="{'active': currentActive === 'TaskLeaderboard' ? true : false}" @click.prevent="clickEvent('TaskLeaderboard')">리더보드</button>
              </swiper-slide>
            </template>

            <template v-if="props.isJoin === 3 || props.agreed || (store.accountInfo && (store.accountInfo.userrole === 1 
              || utils.isHostAccount($route.params.competitionId, store.accountInfo) 
              || utils.isMonitorAccount($route.params.competitionId, store.accountInfo)))">
              <swiper-slide>
                <button class="swiper-tab__btn"  :class="{'active': currentActive === 'TaskSubmit' ? true : false}" @click.prevent="clickEvent('TaskSubmit')">결과제출</button>
              </swiper-slide>
              <!--swiper-slide>
                <button class="swiper-tab__btn" :class="{'active': currentActive === 'TaskCodeShare' ? true : false}" @click.prevent="clickEvent('TaskCodeShare')">
                  코드 공유
                  <span class="icon-new" v-if="props.shareRecentNotice.length"><span class="new">N</span></span>
                </button>
              </swiper-slide-->
            </template>

            <swiper-slide>
              <button class="swiper-tab__btn" :class="{'active': currentActive === 'TaskCommunityList' ? true : false}" @click.prevent="clickEvent('TaskCommunityList')">
                커뮤니티
                  <span class="icon-new" v-if="props.taskRecentNotice.length"><span class="new">N</span></span>
              </button>
            </swiper-slide>
          </div>
        
        </div>
      </div>
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';    
import utils from '@/helper/utils';
import TaskApi from '@/lib/api/Task';
import { tokenStore } from '@/stores/auth';
import { stateStore } from '@/stores/state';
import modalControler from '@/helper/modalControler';
// import "swiper/swiper-bundle.css";
import 'swiper/css/swiper.css'

// import SwiperClass, { Pagination } from 'swiper';
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
// import { Swiper as SwiperClass, Pagination, Navigation, ScrollBar } from 'swiper/swiper.esm.js';
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'



// SwiperClass.use([Pagination, Navigation, ScrollBar])
// const {directive} = getAwesomeSwiper(SwiperClass)
// const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

export default {
    name: 'LeftNavbar', 
    components: {
      // SwiperSlide,
    },
    directive: {
      // swiper: directive
    },
    props: {
        competitionId: {
          type: Number,
          required: true
        },
        taskId: {
            type: Number,
            required: true
        },
        taskTitle: {
          type: String,
          required: false
        },
        status: {
          type: Number,
          required: false
        },
        isJoin : {
          type: Number,
          required: true
        },
        isOpen: {
          type: Number,
          required: true
        },
        container: {
          type: Object,
          required: false
        },
        competitionRecentNotice: {
          type: Array,
          required: false
        },
        taskRecentNotice: {
          type: Array,
          required: false
        },
        shareRecentNotice: {
          type: Array,
          required: false
        },
        category: {
          required: false
        },
        agreed: {
          required: false
        },
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const store2 = stateStore()
        const swiperWrapper = ref(null)
        const state = reactive({
          isAuthenticated: store.isAuthenticated,
          currentActive: router.currentRoute.name,
          currentIndex: 0,            // 과제 상세 기본
          currentMenu: 'TaskInfo',    // 과제 상세 기본
          tabs: [
            'TaskInfo',
            'TaskData',
            'TaskTeam',
            'TeamBuild',
            'TaskLeaderboard',
            'TaskSubmit',
            'TaskCodeShare',
            'CompetitionNotice',
            'TaskNotice',
            'TaskCommunityList',
          ],
          swiperOption: {
            loop: false,
            // slidesPerView: 'auto',
            // freeMode: true,
            slidesPerView:'auto',
            spaceBetween: 0,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          },
          
        });

        onMounted(() => {
          state.currentActive = store2.taskActiveRoute
          state.currentIndex = state.tabs.indexOf(route.name)
        })

        watch(() => store2.taskActiveRoute, (newValue, oldValue) => {
          state.currentActive = store2.taskActiveRoute
        })

        const clickEvent = (routeName) => {
          state.currentIndex = state.tabs.indexOf(routeName)
          store2.setTaskActiveRoute(routeName)
          state.currentActive = routeName
          router.goTaskPages(routeName, props.taskId, props.competitionId)
        }

        const nextTab = () => {
          // if (state.currentIndex + 1 <= state.tabs.length - 1) {
          //   state.currentIndex = state.currentIndex + 1
          //   clickEvent(state.tabs[state.currentIndex])
          // }
          // // context.$refs.slideSwiper.$swiper.slideNext()
          // swiperWrapper.value.style.transform = `translate3d(${-30}px ,0px,0px)`;
        }

        const prevTab = () => {
          // state.currentIndex = state.currentIndex - 1
          // if (state.currentIndex >= 0) {
          //   clickEvent(state.tabs[state.currentIndex])
          // }
          // swiperWrapper.value.style.transform = `translate3d(${30}px ,0px,0px)`;

        }

        const applyTask = () => {


          modalControler.ShowModalDialog('과제참여', `${props.taskTitle} 에 참가 신청하시겠습니까?<br>\
          대회가 시작되면 변경할 수 없습니다.`, '취소', '참가하기', (val) => {
            if (val) {
              let params = {
                competitionId: route.params.competitionId,
                assignmentId: route.params.taskId
              }
              modalControler.ShowLoading()
              TaskApi.joinAssignment(params).then(response => {
                if (!response.data.error) {
                  modalControler.HideLoading()
                  modalControler.ShowSuccessToast('과제참여 완료!')
                  router.goTaskPages('TaskInfo', route.params.taskId, route.params.competitionId)
                  location.reload()
                  
                } else {
                  // 
                  // if (response.data.error_code === 12002) {
                  //   modalControler.ShowErrorToast(response.data.error_detail)
                  // } else {
                  //   // modalControler.ShowErrorToast(response.data.error_detail)
                  // }
                }
              }).catch(err => {
                console.log(err)
                // modalControler.ShowErrorToast(err.response.error_detail)
              }).finally(() => {
                modalControler.HideLoading()
              })
            }
          })
        }

        const applyCompetition = () => {
          router.goCompetitionPages('CompetitionApply', route.params.competitionId)
        }

        const openContainer = (IP, PORT) => {
          if (props.container) {
            if (props.container.JUPYTERIP !== "" && props.container.JUPYTERPORT !== "") {
              utils.openContainer(props.container.JUPYTERIP, props.container.JUPYTERPORT)
            } else if (props.container.JUPYTERIP === "" && props.container.JUPYTERPORT === "" && props.container.SSHIP !== "" && props.container.SSHPORT !== "") {
              modalControler.ShowModalDialog('컨테이너 접속', `터미널에서 아래 명령어를 실행해주세요<br> ssh root@${props.container.SSHIP} -p ${props.container.SSHPORT}`, (val) => {});
            }
          } else {
            modalControler.ShowErrorToast('컨테이너가 할당되지 않았습니다.')
          }
        }

        const dropTask = () => {
          if (props.status !== 3) {
            modalControler.ShowErrorToast("과제참여 취소는 대회가 접수중일때만 가능합니다.")
            return
          }

          modalControler.ShowModalDialog('과제참여취소', `${props.taskTitle} 과제 참여를 취소하시겠습니까?<br>\
            취소 시, 모든 팀원들이 자동으로 과제 참여 취소됩니다.`, '취소', '확인', (val) => {
            if (val) {
              let params = {
                competitionId: route.params.competitionId,
                assignmentId: route.params.taskId
              }
              modalControler.ShowLoading()
              TaskApi.dropAssignment(params).then(response => {
                if (!response.data.error) {
                  modalControler.HideLoading()
                  modalControler.ShowSuccessToast('과제 참여를 취소하셨습니다.')
                  router.goTaskPages('TaskInfo', route.params.taskId, route.params.competitionId)
                  location.reload()
                } else {
                  if (response.data.error_code === 7500) {
                    modalControler.ShowErrorToast(response.data.error_detail)
                  }
                }
              }).catch(err => {
                modalControler.ShowErrorToast(err.response.error_detail)
              }).finally(() => {
                modalControler.HideLoading()
              })

            }
          })

        }

        return {
          utils,
          ...toRefs(state),
          store,
          props,
          applyTask,
          applyCompetition,
          clickEvent,
          openContainer,
          dropTask,
          nextTab,
          prevTab,
          swiperWrapper,
        }
    }
}
// var swiper = new Swiper('.swiper', {
//       slidesPerView: 3,
//       direction: getDirection(),
 
//       on: {
//         resize: function () {
//           swiper.changeDirection(getDirection());
//         },
//       },
//     });


</script>

<style scoped lang="scss">

</style>