<template>
    <article class="section-2__content">
        <Team 
            :status="props.status"
            :memberLimit="props.memberLimit"
            :isJoin="props.isJoin"
        />  
    </article>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch
} from '@vue/composition-api';
import {debounce} from 'lodash'
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import CompetitionApi from "@/lib/api/Competition";
import modalControler from '@/helper/modalControler';
import Team from '@/components/competition/Team'

export default {
    name: 'TaskTeam',
    props: {
        status: {
            required: false,
            type: Number
        },
        memberLimit: {
            required: false,
            type: Number
        },
        isJoin: {
            required: false,
            type: Number
        },
    },
    components: {
        Team,
    },
    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const store = tokenStore()
        const state = reactive({

        });

        return {
            ...toRefs(state),
            props,
            store,
        }
    }
}
</script>

<style scoped lang="scss">

</style>

