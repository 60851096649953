<template>
    <v-app>
        <v-tabs-items>
            <section class="board-section">
                <header class="board-header">
                    <h2 class="board-header__title">{{ props.qnaData.title }}</h2>
                    <div class="writer-detail-info">
                        <div class="writer-info ">
                            <!-- <div class="profile-img profile-img__size--300">
                                <img src="/img/img-profile-blue.06b7e8ba.svg">
                            </div> -->
                            <div class="profile-box__text">
                                <strong v-if="props.qnaData.admin" class="nickname color-primary">
                                    <span class="icon mr--2">
                                        <font-awesome-icon :icon="['fas', 'star']" />
                                    </span>
                                    {{ props.qnaData.nickname }}
                                </strong>
                                <strong v-else class="nickname">{{ props.qnaData.nickname }}</strong>
                                <strong class="date ml--8">{{ props.qnaData.createDtm }}</strong>
                            </div>
                            <!-- <div class="writer">
                                <div v-if="props.qnaData.admin" class="operate color-primary">
                                    <span class="icon">
                                        <font-awesome-icon :icon="['fas', 'star']" />
                                    </span>
                                    <span>{{ props.qnaData.nickname }}</span>
                                </div>
                                <span v-else class="color-black">{{ props.qnaData.nickname }}</span>
                            </div> -->
                            <!-- <span>{{ props.qnaData.createDtm }}</span> -->
                            <template v-if="detailType !== 'TeamBuild' && detailType !== 'TaskCodeShare'">
                                <!--div class="board-status">
                                                                                <span v-if="props.qnaData.status === 1" class="status-sub-badge status-sub-badge--red">답변완료</span>
                                                                                <span v-else  class="status-sub-badge status-sub-badge--default">미답변</span>
                                                                            </div-->
                            </template>
                        </div>

                        <div class="view-right">
                            <div class="info-box mt--0">
                                <div class="info-box__item info-box__item--gray-600">
                                    <span class="info-box__icon">
                                        <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                            icon-name="icon-thumbs-up"><icon-thumbs-up /></icon-base>
                                    </span>
                                    <strong class="info-box__text"> {{ props.qnaData.likeCount }}</strong>
                                </div>
                                <div class="info-box__item info-box__item--gray-600">
                                    <span class="info-box__icon">
                                        <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                            icon-name="icon-eye"><icon-eye /></icon-base>
                                    </span>
                                    <strong class="info-box__text">{{ props.qnaData.viewCount }}</strong>
                                </div>
                                <div class="info-box__item info-box__item--gray-600">
                                    <span class="info-box__icon">
                                        <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                            icon-name="icon-message"><icon-message /></icon-base>
                                    </span>
                                    <strong class="info-box__text" v-if="props.qnaData.reply">{{ props.qnaData.reply.length
                                    }}</strong>
                                </div>
                                <div class="info-box__item info-box__item--gray-600">
                                    <v-menu offset-y v-if="accountInfo && (accountInfo.userrole === 1
                                            || accountInfo.nickname === props.qnaData.nickname)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                                    icon-name="icon-more"><icon-more /></icon-base>
                                            </div>

                                        </template>
                                        <ul class="edit-box">
                                            <li class="edit-box__item" @click="onClickUpdate">
                                                수정
                                            </li>

                                            <li class="edit-box__item" @click="onClickDelete">
                                                삭제
                                            </li>
                                        </ul>
                                    </v-menu>
                                </div>
                            </div>

                        </div>

                    </div>
                </header>
                <div ref="preview" class="ProseMirror">
                    <div v-html="props.qnaData.content"></div>
                </div>
                <!-- 첨부파일 란 -->
                <template v-if="props.qnaData.fileUrl && props.qnaData.fileStr">
                    <div class="btn-filedown-area">
                        <a class="btn-filedown" :href="props.qnaData.fileUrl">
                            <font-awesome-icon :icon="['fas', 'paperclip']" /><strong class="file-name">{{
                                props.qnaData.fileStr }}</strong>
                        </a>
                    </div>
                </template>
                <div class="btn-inline btn-inline--section btn-inline--center">
                    <button type="button" class="btn btn-like" :class="props.isLiked ? 'btn-primary' : 'btn-line'"
                        @click="clickLikeIcon">
                        <span class="icon-left">
                            <icon-base viewBox="0 0 24 24" width="16" height="16" iconColor="#000"
                                icon-name="icon-thumbs-up"><icon-thumbs-up /></icon-base>
                        </span>
                        <span class="mr-2">좋아요</span>
                        <strong class="font-num-bold"> {{ props.qnaData.likeCount }}</strong>
                    </button>
                </div>
                <div class="reply">
                    <!-- 댓글 -->
                    <div class="reply__header">
                        <h4 class="reply__title">댓글 <span class="reply__count color-primary">{{ props.qnaData.replycount
                        }}</span></h4>
                    </div>
                    <div class="reply__body">
                        <div class="reply-box" v-for="(reply, idx) in props.replyList" :key="idx + '-reply'"
                            :class="{ 'admin': reply.admin === true || reply.competitionUserrole === 2 }">
                            <div class="writer-info-area">
                                <div class="profile-box">
                                    <div class="profile-img profile-img__size--400">
                                        <img :src="(reply.profileImg) ? reply.profileImg :
                                                require('@/assets/images/img-profile-blue.svg')">
                                    </div>
                                    <div class="profile-box__text">
                                        <div v-if="(reply.admin === true || reply.competitionUserrole === 2)"
                                            class="color-primary nickname">
                                            <span class="mr--2">
                                                <font-awesome-icon :icon="['fas', 'star']" />
                                            </span>
                                            <span>{{ reply.nickname }}</span>
                                        </div>
                                        <div v-else class="nickname">{{ reply.nickname }}</div>
                                        <div class="date">{{ reply.createDtm }}</div>
                                    </div>

                                </div>
                                <div class="detail-more-menu">
                                    <v-menu offset-y v-if="accountInfo.userrole === 1
                                        || accountInfo.nickname === reply.nickname">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                                        </template>
                                        <ul class="edit-box">
                                            <li class="edit-box__item" @click="updateReply(reply)">
                                                수정
                                            </li>

                                            <li class="edit-box__item" @click="deleteQnaReply(reply)">
                                                삭제
                                            </li>
                                        </ul>
                                        <!-- <v-list>
                                            <v-list-item style="cursor: pointer;">
                                                <v-list-item-title @click="updateReply(reply)">수정</v-list-item-title>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item style="cursor: pointer;">
                                                <v-list-item-title @click="deleteQnaReply(reply)">삭제</v-list-item-title>
                                            </v-list-item>
                                        </v-list> -->
                                    </v-menu>
                                </div>
                            </div>
                            <!-- 댓글 내용 -->
                            <div class="reply-text" v-html="utils.formatNewLine(reply.content)">
                            </div>
                            <!-- 댓글 수정창 -->
                            <div v-if="updateState && replyId === returnReplyId(reply)">
                                <div class="reply__foot">
                                    <div class="common-comment">
                                        <v-textarea height="100" placeholder="내용을 입력해주세요(최대 1500자)" no-resize
                                            v-model="updateReplyContent"
                                            :error-messages="errorStatus.updateReplyErrMsg"></v-textarea>
                                        <div class="common-comment__btns">
                                            <v-btn @click="cancleUpdateReply" class="btn btn-tertiary btn-small ">취소</v-btn>
                                            <v-btn @click="updateQnaReply(reply)"
                                                class="btn btn-small btn-primary">수정</v-btn>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="reply__foot">
                        <div class="common-comment">
                            <v-textarea height="100" placeholder="내용을 입력해주세요(최대 1500자)" no-resize v-model="replyContent"
                                :error-messages="errorStatus.replyErrMsg"></v-textarea>
                            <div class="common-comment__btns">
                                <v-btn @click="writeQnaReply" depressed class="btn btn-small btn-primary">
                                    저장
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-inline btn-inline--section btn-inline--center">
                    <v-btn class="btn btn-secondary btn-large" @click="toQnaList"><strong>목록</strong></v-btn>
                </div>

            </section>
        </v-tabs-items>
    </v-app>
</template>

<script>
import {
    computed, getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch, ref
} from '@vue/composition-api';
import utils from '@/helper/utils';
import { tokenStore } from '@/stores/auth';
import modalControler from '@/helper/modalControler';
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'


export default {
    name: 'BoardDetailView',
    props: {
        detailType: {
            type: String,
            required: true
        },
        qnaData: {
            type: Object,
            required: false
        },
        replyList: {
            type: Array,
            required: false
        },
        isLiked: {
            type: Boolean,
            required: false
        },
    },

    setup(props, context) {
        const route = context.root.$route
        const router = context.root.$router
        const vuetify = context.root.$vuetify
        const store = tokenStore()
        const preview = ref(null)
        const accountInfo = store.getAccountInfo
        const state = reactive({
            summaryMobile: null,
            windowWidth: window.innerWidth,
            replyContent: '',
            updateState: false,
            replyId: 0,
            updateReplyContent: '',
            maxReplyLength: 1500,
            errorStatus: {
                replyErrMsg: '',
                updateReplyErrMsg: '',
            },
            attachments: [],

        });

        onMounted(async () => {
            // 위 Summary 가리기
            if (props.qnaData.content) {
                props.qnaData.content = await utils.paragraphSeperator(props.qnaData.content)
                katexRender()
                hljs.highlightAll()
            }
        });


        watch(() => props.qnaData.content, async (nv, ov) => {
            props.qnaData.content = await utils.paragraphSeperator(props.qnaData.content)
            katexRender()
            hljs.highlightAll()

        })

        watch(() => state.replyContent, (nv, ov) => {
            if ((nv !== ov) && (nv.length < state.maxReplyLength)) {
                state.errorStatus.replyErrMsg = ''
            } else {
                state.errorStatus.replyErrMsg = `${state.replyContent.length}/${state.maxReplyLength}`
            }
        });

        watch(() => state.updateReplyContent, (nv, ov) => {
            if ((nv !== ov) && (nv.length < state.maxReplyLength)) {
                state.errorStatus.updateReplyErrMsg = ''
            } else {
                state.errorStatus.updateReplyErrMsg = `${state.updateReplyContent.length}/${state.maxReplyLength}`
            }
        })

        function onResize() {
            state.windowWidth = window.innerWidth
        }

        const katexRender = () => {
            const previewDiv = preview.value
            utils.katexRender(previewDiv)
            hljs.highlightAll()
        }

        const onClickUpdate = () => {
            context.emit('updateQnA')
        }

        const onClickDelete = () => {
            context.emit('deleteQnA')
        }

        const returnReplyId = (reply) => {
            if (props.detailType === 'CompetitionNotice' || props.detailType === 'TeamBuild') {
                return reply.competitionQnaReplyId
            } else if (props.detailType === 'TaskNotice') {
                return reply.assignmentQnaReplyId
            } else if (props.detailType === 'TaskCodeShare') {
                return reply.codeshareReplyId
            } else if (props.detailType === 'CompetitionQuestion') {
                return reply.competitionQnaReplyId
            } else {
                return reply.id
            }
        }

        const updateReply = (reply) => {
            if (props.detailType === 'CompetitionNotice' || props.detailType === 'TeamBuild') {
                state.replyId = reply.competitionQnaReplyId
            } else if (props.detailType === 'TaskNotice') {
                state.replyId = reply.assignmentQnaReplyId
            } else if (props.detailType === 'TaskCodeShare') {
                state.replyId = reply.codeshareReplyId
            } else if (props.detailType === 'CompetitionQuestion') {
                state.replyId = reply.competitionQnaReplyId
            } else {
                state.replyId = reply.id
            }
            state.updateState = true
        }

        const cancleUpdateReply = () => {
            state.updateState = false
            state.updateReplyContent = ''
        }

        const writeQnaReply = () => {
            // Login 한 계정만 댓글 작성
            if (!store.isAuthenticated) {
                router.push({ name: 'SignIn' })
                return
            }

            if (state.replyContent === '') {
                modalControler.ShowWarningToast('댓글을 입력해주세요.')
                return
            }

            if (state.replyContent.length > state.maxReplyLength) {
                state.errorStatus.replyErrMsg = `${state.replyContent.length}/${state.maxReplyLength}`
                modalControler.ShowWarningToast(`댓글은 최대 ${state.maxReplyLength}자 입니다.`)
                return
            }

            context.emit('writeReply', state.replyContent)
            state.replyContent = ''
        }

        const updateQnaReply = (reply) => {
            if (state.updateReplyContent === '') {
                modalControler.ShowWarningToast('댓글을 입력해주세요.')
                return
            }

            if (state.updateReplyContent.length > state.maxReplyLength) {
                state.errorStatus.updateReplyErrMsg = `${state.updateReplyContent.length}/${state.maxReplyLength}`
                modalControler.ShowWarningToast(`댓글은 최대 ${state.maxReplyLength}자 입니다.`)
                return
            }

            if (props.detailType === 'CompetitionNotice' || props.detailType === 'TeamBuild') {
                context.emit('updateReply', reply.competitionQnaReplyId, state.updateReplyContent)
            } else if (props.detailType === 'TaskNotice') {
                context.emit('updateReply', reply.assignmentQnaReplyId, state.updateReplyContent)
            } else if (props.detailType === 'TaskCodeShare') {
                context.emit('updateReply', reply.codeshareReplyId, state.updateReplyContent)
            } else if (props.detailType === 'CompetitionQuestion') {
                context.emit('updateReply', reply.competitionQnaReplyId, state.updateReplyContent)
            } else {
                context.emit('updateReply', reply.id, state.updateReplyContent)
            }

            state.updateState = false
            state.updateReplyContent = ''
        }

        const deleteQnaReply = (reply) => {

            modalControler.ShowModalDialog('댓글삭제', '삭제하시겠습니까?', '취소', '확인', (val) => {
                if (val) {
                    if (props.detailType === 'CompetitionNotice' || props.detailType === 'TeamBuild') {
                        context.emit('deleteReply', reply.competitionQnaReplyId)
                    } else if (props.detailType === 'TaskNotice') {
                        context.emit('deleteReply', reply.assignmentQnaReplyId)
                    } else if (props.detailType === 'TaskCodeShare') {
                        context.emit('deleteReply', reply.codeshareReplyId)
                    } else if (props.detailType === 'CompetitionQuestion') {
                        context.emit('deleteReply', reply.competitionQnaReplyId)
                    } else {
                        context.emit('deleteReply', reply.id)
                    }
                }
            })

        }

        const toQnaList = () => {
            if (props.detailType === 'Community') {
                router.push({
                    name: 'CommunityList'
                })
            }
            else if (props.detailType === 'TaskCommunity') {
                router.push({
                    name: 'TaskCommunityList',
                    params: {
                        competitionId: route.params.competitionId,
                        taskId: route.params.taskId
                    }
                })
            }
            else if (props.detailType === 'LeagueCommunity') {
                router.push({
                    name: 'LeagueCommunity',
                    params: {
                        leagueId: route.params.leagueId,
                    }
                })
            }

            else {
                router.goTaskPages(props.detailType, route.params.taskId, route.params.competitionId)
            }
        }

        const fetchMoreReply = () => {
            context.emit('fetchMoreReply')
        }

        const clickLikeIcon = () => {
            if (!accountInfo.nickname) {
                router.push({ name: 'SignIn' })
            } else {
                context.emit('updateLikePost')
            }
        }


        return {
            ...toRefs(state),
            utils,
            props,
            onClickUpdate,
            onClickDelete,
            updateReply,
            cancleUpdateReply,
            writeQnaReply,
            updateQnaReply,
            deleteQnaReply,
            toQnaList,
            accountInfo,
            fetchMoreReply,
            returnReplyId,
            preview,
            clickLikeIcon,
        }
    }
}
</script>
